import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react'
import { useState } from 'react';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import ApiService from '../../../../../services/ApiService';
import ModalListOR from '../Modal/ModalListOR';
import { Paginate, showToast } from '../../../../../components/ui';
import { convertToNominal } from '../../../../../utils/masks.jsx';
import TableCheck from '../../../../../components/Tables/TableCheck.jsx';
import LoadingSpinner from '../../../../../components/Components/LoadingSpinner.jsx';
import { handleDataTable } from '../../../../../utils/reusableFunction.jsx';

const ReceiptPPh23Detail = (props) => {
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#F2FAFF',
      color: 'var(--Primary-One, #083A50)',
      fontFamily: 'Nunito',
      fontSize: '14px',
      fontstyle: 'normal',
      fontWeight: '600',
      lineHeight: 'normal',
      padding: '8px 16px !important',
    },
  }));

  const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.body}`]: {
      color: 'var(--Blue-Primary-1, #083A50)',
      fontFamily: 'Nunito',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: 'normal',
      padding: '0px 16px !important',
      height: '35px !important'
    },
  }));

  const selectedStyle = { bgcolor: '#CCCCCC', "&.MuiTableRow-hover:hover": { backgroundColor: "#CCCCCC" } };
  const deletedDetailStyle = { bgcolor: (theme) => theme.palette.text.disabled };

  const [isModalDetail, setIsModalDetail] = useState(false);
  const [titleModalDetail, setTitleModalDetail] = useState('');

  const [isLoadingDetail, setIsLoadingDetail] = useState(false);
  const [dataDetail, setDataDetail] = useState([]);
  const [dataMapDetail, setDataMapDetail] = useState([])
  const [dataTableDetail, setDataTableDetail] = useState([])
  const [columnDataDetail, setColumnDataDetail] = useState([])
  const [SelectedDataDetail, setSelectedDataDetail] = useState({});
  const [totalRowsDetail, setTotalRowsDetail] = useState(50)
  const paramDetail = "officialReceiptPph23/officialReceiptPph23";

  const [rowsCount, setRowsCount] = useState(50)
  const [numPage, setNumPage] = useState(1)
  const [filter, setFilter] = useState([]);
  const [filterBy, setFilterBy] = useState([]);
  const [stateX, setStateX] = useState({});
  const [sortArr, setSortArr] = useState([]);
  const [goTo, setGoTo] = useState(1);

  const handleAdd = () => {
    if (props.customerId === 0) {
      showToast({ type: 'error', message: 'Please select Customer!' });
    } else {
      handleClick()
    }
  }

  const handleClick = () => {
    if (isModalDetail === false) {
      setIsModalDetail(true);
      getDataDetail(1, 50)
    } else {
      setIsModalDetail(false);
    }
  }

  const handleDelete = () => {
    if (props.employeeId === 0) {
      showToast({ type: 'error', message: 'Please select Personal Code First!' });
      return
    } else {
      if (props.selectedDetail.sequence) {
        let delRow = props.selectedDetail.sequence
        const tempData = props.dataDetail.filter(dt => dt.sequence !== delRow)
        let tempSequence = 0
        tempData.forEach(row => {
          tempSequence++
          row.sequence = tempSequence
        })
        props.setDataDetail(tempData)
        props.SumTotal(tempData)
      } else {
        showToast({ type: 'error', message: 'Please select data!' });
      }
    }
  }

  const getDataDetail = (pageNumber, pageSize, filters) => {
    setIsLoadingDetail(true);
    let params = [
      {
        "attr": "customerId",
        "value": '' + props.customerId
      },
    ]
    ApiService.FinAccCustomPostByPage('PostByDetailRetrieve', paramDetail, 'PAGE', pageNumber, pageSize, params, filters)
      .then((response) => {
        if (response.data.code === 200) {
          setSelectedDataDetail({})
          let sequence = 1;
          let data = response.data.data.officialReceiptPph23DetailRetrieve;
          data.forEach(element => {
            element.id = sequence;
            sequence++;
          });
          setDataDetail(data);

          let temp = data
          let indexed = temp.map((el, index) => {
            let indexedTemp = {
              ...el,
              index
            }
            return indexedTemp
          })

          setDataMapDetail(indexed)
          handleDataTable(indexed, response.data.data.columns.headerColumns, setDataTableDetail, 'id');
          let headerData = response.data.data.columns.headerColumns;
          headerData.forEach(element => {
            element.hidden = false;
          });
          setColumnDataDetail(headerData)
          setTotalRowsDetail(response.data.totalRowCount)

          setIsLoadingDetail(false)
        }
        setIsLoadingDetail(false)
      })
      .catch(function (error) {
        setIsLoadingDetail(false)
        console.error('error saat fetch', error)
      })
  }

  const searchDetail = () => {
    if (isModalDetail === false) {
      setIsModalDetail(true);
      setTitleModalDetail('Official Receipt')
      getDataDetail(1, 50)
    } else {
      setIsModalDetail(false);
    }

  }

  const handleDoubleClick = () => {

  }
  const emptyState = () => {
    props.setSelectedData({});
    setNumPage(1);
    setRowsCount(50);
    props.setTotalRows(50);
    setFilter([]);
    setFilterBy([]);
    setGoTo(1);
    setSortArr([]);
    setStateX({});
    props.setDataCheck([]);
  };

  const handleReset = () => {
    emptyState();
    props.getDataOR(1, 50);
  };

  const onChangePaging = (value) => {
    setNumPage(value);
    props.getDataOR(value, rowsCount, filter);
  }

  const onChangeLimit = (value) => {
    setNumPage(1);
    setRowsCount(value);
    props.getDataOR(1, value, filter);
  }

  const mainContainer = (
    <>
      <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
        <div>
          {
            ButtonComponent.RefreshButton(
              sortArr,
              rowsCount,
              props.columnData,
              setGoTo,
              handleReset,
              setFilterBy,
              filterBy,
              setFilter,
              setNumPage,
              setRowsCount,
              props.getDataOR,
              stateX,
              setStateX,
              false
            )
          }
        </div>

        <div
          className="rounded-10 table-responsive"
          style={{
            maxHeight: '375px',
            marginTop: '16px',
            padding: '2px 16px',
            borderRadius: '12px',
            background: '#F2F2F2'
          }}
        >
          {
            props.isLoading ?
              (
                <LoadingSpinner />
              )
              :
              (
                <>
                  <div className="mt-1">
                    <TableCheck
                      uniqueKey={'invoiceId'}
                      rowsCount={rowsCount}
                      setNumPage={setNumPage}
                      setRowsCount={setRowsCount}
                      getData={props.getDataOR}
                      columnData={props.columnData}
                      dataMap={props.dataMap}
                      SelectedData={props.SelectedData}
                      setSelectedData={props.setSelectedData}
                      setFilter={setFilter}
                      setFilterBy={setFilterBy}
                      dataCheck={props.dataCheck}
                      setDataCheck={props.setDataCheck}
                      stateX={stateX}
                      setStateX={setStateX}
                      sortArr={sortArr}
                      setSortArr={setSortArr}
                      setGoTo={setGoTo}
                      goTo={goTo}
                      calculateTotal={props.SumTotal}
                    />
                  </div>
                </>
              )
          }
        </div>

        <div style={{ marginTop: '16px' }}>
          <Paginate
            page={numPage}
            limit={rowsCount}
            totalData={props.totalRows}
            onChange={onChangePaging}
            onChangeLimit={onChangeLimit}
            size='small'
          />
        </div>
      </Grid>
    </>
  );

  return (
    <>
      <Typography
        sx={{
          color: 'var(--Blue-Primary-1, #083A50)',
          fontFamily: 'Nunito',
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: '600',
          lineHeight: 'normal',
          marginTop: '8px'
        }}
      >
        Detail
      </Typography>

      {
        props.isAdjustment ?
          <>
            {mainContainer}
          </>
          :
          <TableContainer sx={{ maxHeight: 235, marginTop: '16px', background: '#FFF', borderRadius: '12px' }}>
            <Table sx={{ minWidth: 650 }} stickyHeader>
              <TableHead
                sx={{
                  '& th:first-of-type': {
                    borderRadius: '12px 0 0'
                  },
                  '& th:last-child': {
                    borderRadius: '0 12px 0 0'
                  }
                }}
              >
                <TableRow>
                  <StyledTableCell>OR No</StyledTableCell>
                  <StyledTableCell>Invoice No</StyledTableCell>
                  <StyledTableCell>Shipment No</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'right', }}>PPh23</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'right', }}>Amount USD</StyledTableCell>
                  <StyledTableCell sx={{ textAlign: 'right', }}>Amount IDR</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  props.dataDetail.length > 0 ?
                    props.dataDetail.map((el) => {
                      return (
                        <TableRow
                          key={el.sequence}
                          onClick={() => props.setSelectedDetail(el)}
                          onDoubleClick={() => handleDoubleClick()}
                          sx={props.selectedDetail.sequence === el.sequence ? selectedStyle : el.rowStatus === 'DEL' ? deletedDetailStyle : {}}
                        >
                          <StyledTableBodyCell>{el.orNo}</StyledTableBodyCell>
                          <StyledTableBodyCell>{el.invoiceNo}</StyledTableBodyCell>
                          <StyledTableBodyCell>{el.shipmentNo}</StyledTableBodyCell>
                          <StyledTableBodyCell sx={{ textAlign: 'right', }}>{new Intl.NumberFormat().format(el.pph23Amount)}</StyledTableBodyCell>
                          <StyledTableBodyCell sx={{ textAlign: 'right', }}>
                            {convertToNominal(el.amountUSD)}
                          </StyledTableBodyCell>
                          <StyledTableBodyCell sx={{ textAlign: 'right', }}>
                            {convertToNominal(el.amountIDR)}
                          </StyledTableBodyCell>
                        </TableRow>
                      )
                    })
                    :
                    <TableRow sx={{ backgroundColor: '#FFFFFF' }}>
                      <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                        <Typography
                          sx={{
                            color: 'var(--Primary-One, #083A50)',
                            fontFamily: 'Nunito',
                            fontSize: '12px',
                            fontstyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal'
                          }}
                        >
                          Data Empty
                        </Typography>
                      </StyledTableBodyCell>
                    </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
      }

      <div className={!props.isAdjustment ? 'd-flex' : null} style={{ marginTop: '16px', columnGap: '12px' }} hidden={props.isAdjustment}>
        {ButtonComponent.AddButton(handleAdd, '', props.isApprovedAcc)}
        {ButtonComponent.DeleteButton(handleDelete, '', props.isApprovedAcc)}
      </div>

      {
        isModalDetail && (
          <ModalListOR
            isModal={isModalDetail}
            handleClick={searchDetail}
            titleModal={titleModalDetail}
            setIsModal={setIsModalDetail}

            isLoading={isLoadingDetail}
            data={dataDetail}
            dataMap={dataMapDetail}
            dataTable={dataTableDetail}
            columnData={columnDataDetail}
            SelectedData={SelectedDataDetail}
            totalRows={totalRowsDetail}
            setSelectedData={setSelectedDataDetail}
            getData={getDataDetail}

            setSelectedDetail={props.setSelectedDetail}
            setDataDetail={props.setDataDetail}
            dataDetail={props.dataDetail}

            SumTotal={props.SumTotal}
            isApprovedAcc={props.isApprovedAcc}
          />
        )
      }
    </>
  )
}

export default ReceiptPPh23Detail