import React, { forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import { MenuItem, Grid, Card, CardContent, Box } from '@mui/material';
import HeaderInfo from '../../../components/Components/HeaderInfo';
import ButtonInfo from '../../../components/Components/ButtonInfo';
import { useNavigate, useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';

import InvoiceHeader from './Components/InvoiceHeader';
import ButtonCreateEditInvoice from './Buttons/ButtonCreateEditInvoice';
import InvoiceBeingForPayment from './Components/InvoiceBeingForPayment';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../components/Modals/ModalConfirmation';
import ApiService, { branchId, companyId, countryId, userId, userCode, username, isInfossNo } from '../../../services/ApiService';
import CustomToastContainer, { Selection, showToast } from '../../../components/ui';
import { defaultDate, getDefaultDateFormat, setDateFormat, updateDateFormat } from '../../../utils/date';
import { convertToNominal, convertToNumeric, newWindowConfig } from '../../../utils/masks.jsx';
import { PrintPDF } from '../../../utils/reusableFunction.jsx';

const CreateEditInvoice = forwardRef((props, ref) => {
    const dataLocal = JSON.parse(localStorage.getItem(username + "-DataInvoice"));

    const { sJobTypeId } = useParams();
    const { invoiceId } = useParams();
    const { eplId } = useParams();
    const { isToDo } = useParams();
    const { fakturType } = useParams();
    const { customerId } = useParams();
    const { customerTypeId } = useParams();
    const { dcNote } = useParams();

    const [check, setCheck] = useState(0);

    const paramInvoice = 'invoice/invoice';
    const paramShipmentOrder = 'shipmentOrder/shipmentOrder';
    const paramEPL = 'estimateProfitLoss/estimateProfitLoss';
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const paramRegClosingPeriod = 'regClosingPeriod/regClosingPeriod';
    // const paramGL = 'generalLedger/generalLedger';
    const paramContact = "regContact/regContact";

    const paramRegJobType = 'regJobType/regJobType';
    const [jobType, setJobType] = useState([]);
    const [typeJobId, setTypeJobId] = useState('1');
    const [idJobType, setIdJobType] = useState(0);

    const paramVAT = 'vat/vat';
    const [VATData, setVATData] = useState([]);

    const paramInvoicesTo = 'invoicesTo/invoicesTo';
    const [InvoicesToData, setInvoicesToData] = useState([]);

    const paramJobOwner = 'jobOwner/jobOwner';
    const [jobOwnerList, setJobOwnerList] = useState([]);

    const history = useNavigate();

    //Invoice Variable
    const [PaymentFrom, setPaymentFrom] = useState(0);
    const [Type, setType] = useState(false);
    const [InvoiceNo, setInvoiceNo] = useState(0);
    const [InvoiceForAgentNo, setInvoiceForAgentNo] = useState('');
    const [PrintCount, setPrintCount] = useState(0);
    const [PrintOn, setPrintOn] = useState('');
    const [ET, setET] = useState('');
    const [PrincipleBy, setPrincipleBy] = useState(0);
    const [HeaderInvoice, setHeaderInvoice] = useState(0);
    const [PackingList, setPackingList] = useState('');
    const [SICustomer, setSICustomer] = useState('');
    const [EPLId, setEPLId] = useState(0);
    const [ShipmentId, setShipmentId] = useState(0);
    const [ShipmentOrderNo, setShipmentOrderNo] = useState('');
    const [PaymentType, setPaymentType] = useState('D');
    const [CustomerTo, setCustomerTo] = useState('2');
    const [CustomerId, setCustomerId] = useState(0);
    const [CustomerName, setCustomerName] = useState('');
    const [CustomerAddress, setCustomerAddress] = useState('');
    const [BillToId, setBillToId] = useState(0);
    const [BillToName, setBillToName] = useState('');
    const [BillToAddress, setBillToAddress] = useState('');
    const [RefEFaktur, setRefEFaktur] = useState('');
    const [RevisedTaxId, setRevisedTaxId] = useState(0);
    const [RevisedTaxNo, setRevisedTaxNo] = useState('');
    const [IncludeValue, setIncludeValue] = useState('0');
    const [Include, setInclude] = useState(false);
    const [Amount, setAmount] = useState(0);
    const [ticketId, setTicketId] = useState(0);
    const [invoiceStatus, setInvoiceStatus] = useState(0);
    const [period, setPeriod] = useState(0);
    const [yearPeriod, setYearPeriod] = useState(0);
    const [invoicesEdit, setInvoicesEdit] = useState(false);
    const [linkTo, setLinkTo] = useState('');
    const [dueDate, setDueDate] = useState(0);
    const [paidOn, setPaidOn] = useState('');
    const [saveOR, setSaveOR] = useState(false);
    const [badDebt, setBadDebt] = useState(false);
    const [badDebtOn, setBadDebtOn] = useState('');
    const [reBadDebt, setReBadDebt] = useState(false);
    const [dateReBadDebt, setDateReBadDebt] = useState('');
    const [deleted, setDeleted] = useState(false);
    const [deletedOn, setDeletedOn] = useState('');
    const [invoiceNo2, setInvoiceNo2] = useState('');
    const [rePrintApproved, setRePrintApproved] = useState(false);
    const [rePrintApprovedOn, setRePrintApprovedOn] = useState('');
    const [rePrintApprovedBy, setRePrintApprovedBy] = useState('');
    const [deletedRemarks, setDeletedRemarks] = useState('');
    const [sfpId, setSfpId] = useState(0);
    const [sfpNoFormat, setSfpNoFormat] = useState('');
    const [sfpDetailId, setSfpDetailId] = useState(0);
    const [uniqueKeySFP, setUniqueKeySFP] = useState('');
    const [uniqueKeyInvoice, setUniqueKeyInvoice] = useState('');
    const [deleteType, setDeleteType] = useState(0);
    const [deleteTypeRefInvId, setDeleteTypeRefInvId] = useState(0);
    const [kursKMKId, setKursKMKId] = useState(0);
    const [isDelivered, setIsDelivered] = useState(false);
    const [deliveredOn, setDeliveredOn] = useState('');
    const [deliveredRemarks, setDeliveredRemarks] = useState('');
    const [approvedCredit, setApprovedCredit] = useState(false);
    const [approvedCreditBy, setApprovedCreditBy] = useState('');
    const [approvedCreditOn, setApprovedCreditOn] = useState('');
    const [approvedCreditRemarks, setApprovedCreditRemarks] = useState('');
    const [pejkpNumber, setPejkpNumber] = useState(0);
    const [pejkpReference, setPejkpReference] = useState('');
    const [transactionOn, setTransactionOn] = useState('');
    const [requester, setRequester] = useState('');
    const [reference, setReference] = useState('');
    const [deleteSeq, setDeleteSeq] = useState(0);
    const [vatId, setVatId] = useState(0);
    const [percentVat, setPercentVat] = useState(0);

    const [portType, setPortType] = useState('');
    const [serviceType, setServiceType] = useState('');

    const [DataPaymentSC, setDataPaymentSC] = useState([]);
    const [DataPaymentA, setDataPaymentA] = useState([]);
    const [DataPayment, setDataPayment] = useState([]);
    const [TempDataPayment, setTempDataPayment] = useState([]);

    const [VatValue, setVatValue] = useState(0);
    const [PaymentStatus, setPaymentStatus] = useState(false);
    const [Rate, setRate] = useState(0);
    const [RateReal, setRateReal] = useState(0);
    const [ExRateId, setExRateId] = useState(0);
    const [ExRateDate, setExRateDate] = useState('');
    const [Currency, setCurrency] = useState('All');
    const [KursKMK, setKursKMK] = useState(0);
    const [PaymentUSD, setPaymentUSD] = useState(0);
    const [PaymentIDR, setPaymentIDR] = useState(0);
    const [TotalVatUSD, setTotalVatUSD] = useState(0);
    const [TotalVatIDR, setTotalVatIDR] = useState(0);

    const [isAgent, setIsAgent] = useState(true);
    const [Customer, setCustomer] = useState('Shipper');
    const [isExport, setIsExport] = useState(false);
    const [isImport, setIsImport] = useState(false);
    const [isSeaImport, setIsSeaImport] = useState(true);
    const [isSea, setIsSea] = useState(false);
    const [isAir, setIsAir] = useState(false);
    const [isInclude, setIsInclude] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [isInvoice, setIsInvoice] = useState(true);
    const [CustomerSCId, setCustomerSCId] = useState(0);
    const [CustomerSCName, setCustomerSCName] = useState('');
    const [CustomerSCAddress, setCustomerSCAddress] = useState('');
    const [CustomerAId, setCustomerAId] = useState(0);
    const [CustomerAName, setCustomerAName] = useState('');
    const [CustomerAAddress, setCustomerAAddress] = useState('');
    const [CustomerSCTempId, setCustomerSCTempId] = useState(0);
    const [CustomerSCTempName, setCustomerSCTempName] = useState('');
    const [CustomerSCTempAddress, setCustomerSCTempAddress] = useState('');
    const [CustomerATempId, setCustomerATempId] = useState(0);
    const [CustomerATempName, setCustomerATempName] = useState('');
    const [CustomerATempAddress, setCustomerATempAddress] = useState('');

    const [AmountCrr, setAmountCrr] = useState(0);
    const [isCircleProgress, setIsCircleProgress] = useState(false);

    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');

    const [isViewOnly, setIsViewOnly] = useState(false);
    const [ORId, setORId] = useState(0);
    const [PVId, setPVId] = useState(0);

    const [isDraft, setIsDraft] = useState(true);
    const [containerInfo, setContainerInfo] = useState(true);
    const [shipperInfo, setShipperInfo] = useState(true);

    const [jobTypeCode, setJobTypeCode] = useState('');

    const [volume20, setVolume20] = useState(0);
    const [volume40, setVolume40] = useState(0);
    const [volume45, setVolume45] = useState(0);
    const [volumeCBM, setVolumeCBM] = useState(0);
    const [isVolume20, setIsVolume20] = useState(false);
    const [isVolume40, setIsVolume40] = useState(false);
    const [isVolume45, setIsVolume45] = useState(false);
    const [isVolumeCBM, setIsVolumeCBM] = useState(false);

    const [bookingConfirmationId, setBookingConfirmationId] = useState(0);
    const [bookingConfirmationNumber, setBookingConfirmationNumber] = useState('');

    const [containerSizeData, setContainerSizeData] = useState([]);
    const [isClosed, setIsClosed] = useState(false);

    const [documentStatus, setDocumentStatus] = useState('');
    const [isApprovedGen, setIsApprovedGen] = useState(false);

    const [infossNo, setInfossNo] = useState('');
    const [isRevised, setIsRevised] = useState(false);

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Invoice', link: '/Majura/Invoice', style: '' },
        { label: 'Create Invoice', link: '/Majura/Invoice/Create/' + sJobTypeId, style: { color: '#0087C2', fontWeight: '700' } },
    ];

    const BreadcrumbsEditItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Invoice', link: '/Majura/Invoice', style: '' },
        { label: 'Detail Invoice', link: '/Majura/Invoice/' + sJobTypeId + '/' + invoiceId, style: { color: '#0087C2', fontWeight: '700' } },
    ];

    const simpanLocal = () => {
        let id = 0;
        let datePaid = paidOn === '' ? defaultDate : paidOn;
        let datePrint = PrintOn === '' ? defaultDate : PrintOn;
        let eta = '';
        let etd = '';
        let dateBadDebt = badDebtOn === '' ? defaultDate : badDebtOn;
        let dateReBadDebts = dateReBadDebt === '' ? defaultDate : dateReBadDebt;
        let dateDeleted = deletedOn === '' ? defaultDate : deletedOn;
        let dateRePrintApproved = rePrintApprovedOn === '' ? defaultDate : rePrintApprovedOn;
        let dateDelivered = deliveredOn === '' ? defaultDate : deliveredOn;
        let dateApprovedCredit = approvedCreditOn === '' ? defaultDate : approvedCreditOn;
        let dateTransaction = transactionOn === '' ? defaultDate : transactionOn;
        let dateExRate = ExRateDate === '' ? defaultDate : ExRateDate;
        let invoicesTo = '';
        let isGeneral = false;

        if (invoiceId) {
            id = parseInt(invoiceId);
        }

        if (isImport === true) {
            if (ET !== '') {
                etd = ET;
            }
            else {
                etd = defaultDate;
            }
            eta = defaultDate;
        }
        else {
            if (ET !== '') {
                eta = ET;
            }
            else {
                eta = defaultDate;
            }
            etd = defaultDate;
            eta = ET;
        }

        for (let i = 0; i < InvoicesToData.length; i++) {
            if (AmountCrr === InvoicesToData[i].amountCrr && Customer === InvoicesToData[i].name) {
                invoicesTo = InvoicesToData[i].code;
                break;
            }
        }

        if (PaymentFrom === 0) {
            isGeneral = false;
        }
        else if (PaymentFrom === 1) {
            isGeneral = true;
        }

        let totalPaymentIDR = 0;
        let totalPaymentUSD = 0;
        let stampDuty = 0;
        stampDuty = parseFloat(convertToNumeric(Amount)) * parseFloat(TempDataPayment.length);

        if (PaymentIDR > 0) {
            totalPaymentIDR = PaymentIDR + stampDuty + TotalVatIDR;
        }
        else {
            if (PaymentUSD > 0) {
                totalPaymentUSD = PaymentUSD + stampDuty + TotalVatUSD;
            }
        }

        var data = {
            "invoice": {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,

                "rowStatus": "ACT",
                'noInfoss': infossNo,
                "id": id,
                "ticketId": ticketId,
                "invoiceNo": InvoiceNo,
                "debetCredit": PaymentType,
                "eplId": EPLId,
                "shipmentId": ShipmentId,
                "shipmentNo": ShipmentOrderNo,
                "bookingId": bookingConfirmationId,
                "bookingNo": bookingConfirmationNumber,
                "jobTypeId": parseInt(idJobType),
                "jobTypeCode": jobTypeCode,
                "reference": reference,
                "etd": etd,
                "eta": eta,
                "invoicesTo": Customer,
                "customerTypeId": parseInt(CustomerTo),
                "customerId": CustomerId,
                "customerName": CustomerName,
                "customerAddress": CustomerAddress,
                "billId": BillToId,
                "billName": BillToName,
                "billAddress": BillToAddress,
                "invoicesToCode": invoicesTo,
                "invoiceStatus": invoiceStatus,
                "paymentUSD": PaymentUSD,
                "paymentIDR": PaymentIDR,
                "totalPaymentUSD": totalPaymentUSD,
                "totalPaymentIDR": totalPaymentIDR,
                "totalVatUSD": TotalVatUSD,
                "totalVatIDR": TotalVatIDR,
                "rate": RateReal,
                "originalInvoiceId": RevisedTaxId,
                "originalInvoiceNo": RevisedTaxNo,
                "jobOwnerId": PrincipleBy,
                "exRateDate": dateExRate,
                "period": period,
                "yearPeriod": yearPeriod,
                "invoicesAgent": InvoiceForAgentNo,
                "isEdited": invoicesEdit,
                "isGeneral": isGeneral,
                "linkTo": linkTo,
                "dueDate": dueDate,
                "paid": PaymentStatus,
                "paidOn": datePaid,
                "saveOR": saveOR,
                "badDebt": badDebt,
                "badDebtOn": dateBadDebt,
                "reBadDebt": reBadDebt,
                "dateReBadDebt": dateReBadDebts,
                "printing": PrintCount,
                "printedOn": datePrint,
                "deleted": deleted,
                "deletedOn": dateDeleted,
                "invoiceNo2": invoiceNo2,
                "invHeader": HeaderInvoice,
                "exRateId": ExRateId,
                "rePrintApproved": rePrintApproved,
                "rePrintApprovedOn": dateRePrintApproved,
                "rePrintApprovedBy": rePrintApprovedBy,
                "deletedRemarks": deletedRemarks,
                "isCostToCost": Type,
                "sfpId": sfpId,
                "sfpNoFormat": sfpNoFormat,
                "sfpDetailId": sfpDetailId,
                "uniqueKeySFP": uniqueKeySFP,
                "uniqueKeyInvoice": uniqueKeyInvoice,
                "deleteType": deleteType,
                "deleteTypeRefInvId": deleteTypeRefInvId,
                "kursKMK": KursKMK,
                "deleteTypeSeq": deleteSeq,
                "kursKMKId": kursKMKId,
                "isDelivered": isDelivered,
                "deliveredOn": dateDelivered,
                "deliveredRemarks": deliveredRemarks,
                "sfpReference": RefEFaktur,
                "approvedCredit": approvedCredit,
                "approvedCreditBy": approvedCreditBy,
                "approvedCreditOn": dateApprovedCredit,
                "approvedCreditRemarks": approvedCreditRemarks,
                "packingListNo": PackingList,
                "siCustomerNo": SICustomer,
                "isStampDuty": Include,
                "shipVol20Amount": volume20,
                "shipVol40Amount": volume40,
                "shipVol45Amount": volume45,
                "shipVolCBMAmount": volumeCBM,
                "stampDutyAmount": convertToNumeric(Amount),
                "pejkpNumber": pejkpNumber,
                "pejkpReference": pejkpReference,
                "transactionOn": dateTransaction,
                "vatId": vatId,
                "percentVAT": percentVat,
                "orId": ORId,
                "pvId": PVId,
                "requester": requester,
                'approvedGeneral': isApprovedGen,

                "idLama": 0,
            },
            "invoiceDetails": TempDataPayment,
        };

        localStorage.setItem(username + "-DataInvoice", JSON.stringify(data));
    }

    useImperativeHandle(ref, () => ({
        localSave() {
            simpanLocal();
        }
    }));

    function handleDocumentStatus(rowStatus, isApproved = false, isPosted = false) {
        if (rowStatus !== 'ACT') {
            setDocumentStatus('DELETED');
        }
        else if (isApproved) {
            setDocumentStatus('APPROVED');
        }
        else if (isPosted) {
            setDocumentStatus('PREVIEW');
        }
        else {
            setDocumentStatus('ONGOING');
        }
    }

    useEffect(() => {
        localStorage.setItem('isClosedEPL', JSON.stringify(false))
        document.title = 'Invoice - Detail - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDataLocal = () => {
        if (invoiceId) {
            setIsEdit(true);
        }

        let typeJob;
        let dataDetailInvoice = dataLocal.invoiceDetails;
        let dataInvoice = dataLocal.invoice;

        if (dataInvoice.orId !== 0 || dataInvoice.pvId !== 0) {
            setIsViewOnly(true);
        }
        setORId(dataInvoice.orId);
        setPVId(dataInvoice.pvId);

        if (dataInvoice.isGeneral === true) {
            setPaymentFrom(1);
            setIsInvoice(false);
        }
        else if (dataInvoice.isGeneral === false) {
            setPaymentFrom(0);
            setIsInvoice(true);
        }
        handleDocumentStatus(dataInvoice.rowStatus, false, dataInvoice.printing > 0)
        setInfossNo(dataInvoice.noInfoss);
        setType(dataInvoice.isCostToCost);
        setBookingConfirmationId(dataInvoice.bookingId);
        setBookingConfirmationNumber(dataInvoice.bookingNo);
        setInvoiceNo(dataInvoice.invoiceNo);
        setJobTypeCode(dataInvoice.jobTypeCode);
        setInvoiceForAgentNo(dataInvoice.invoicesAgent);
        setPrincipleBy(dataInvoice.jobOwnerId);
        setHeaderInvoice(dataInvoice.invHeader);
        setPackingList(dataInvoice.packingListNo);
        setSICustomer(dataInvoice.siCustomerNo);
        setEPLId(dataInvoice.eplId);
        setShipmentId(dataInvoice.shipmentId);
        setShipmentOrderNo(dataInvoice.shipmentNo);
        setReference(dataInvoice.reference);
        setPaymentType(dataInvoice.debetCredit);
        setCustomerTo(dataInvoice.customerTypeId);
        setDeleteSeq(dataInvoice.deleteTypeSeq);
        setVatId(dataInvoice.vatId);
        setIsApprovedGen(dataInvoice.approvedGeneral);
        setPercentVat(dataInvoice.percentVAT);
        if (dataInvoice.customerTypeId !== 1) {
            setCustomerSCId(dataInvoice.customerId);
            setCustomerSCName(dataInvoice.customerId);
            setCustomerSCAddress(dataInvoice.customerAddress);
        }
        else {
            setCustomerAId(dataInvoice.customerId);
            setCustomerAName(dataInvoice.customerId);
            setCustomerAAddress(dataInvoice.customerAddress);
        }
        setCustomerId(dataInvoice.customerId);
        setCustomerName(dataInvoice.customerName);
        setCustomerAddress(dataInvoice.customerAddress);
        setBillToId(dataInvoice.billId);
        setBillToName(dataInvoice.billName);
        setBillToAddress(dataInvoice.billAddress);

        if (fakturType !== 'H') {
            setRefEFaktur(dataInvoice.sfpReference);
        }

        if (fakturType === undefined) {
            setRevisedTaxId(dataInvoice.originalInvoiceId);
            setRevisedTaxNo(dataInvoice.originalInvoiceNo);
        }
        else {
            setRevisedTaxId(dataInvoice.id);
            setRevisedTaxNo(dataInvoice.invoiceNo2);
        }

        setInclude(dataInvoice.isStampDuty);
        if (dataInvoice.isStampDuty === true) {
            setIncludeValue('1');
            setIsInclude(false);
        }
        else {
            setIncludeValue('0');
            setIsInclude(true);
        }
        setAmount(dataInvoice.stampDutyAmount);
        setTicketId(dataInvoice.ticketId);
        if (dataInvoice.customerTypeId === 1) {
            setCustomer('Agent');
        }
        else if (dataInvoice.customerTypeId === 2) {
            setCustomer('Shipper');
        }
        else if (dataInvoice.customerTypeId === 3) {
            setCustomer('Consignee');
        }
        setInvoiceStatus(dataInvoice.invoiceStatus);
        setPeriod(dataInvoice.period);
        setYearPeriod(dataInvoice.yearPeriod);
        setInvoicesEdit(dataInvoice.isEdited);
        setLinkTo(dataInvoice.linkTo);
        setDueDate(dataInvoice.dueDate);
        setPaymentStatus(dataInvoice.paid);
        setSaveOR(dataInvoice.saveOR);
        setBadDebt(dataInvoice.badDebt);
        setReBadDebt(dataInvoice.reBadDebt);
        setDeleted(dataInvoice.deleted);
        if (fakturType === undefined) {
            setInvoiceNo2(dataInvoice.invoiceNo2);
            setPrintCount(dataInvoice.printing);
            setDateFormat(dataInvoice.printedOn, setPrintOn);
        }
        setRePrintApproved(dataInvoice.rePrintApproved);
        setRePrintApprovedBy(dataInvoice.rePrintApprovedBy);
        setDeletedRemarks(dataInvoice.deletedRemarks);
        setSfpId(dataInvoice.sfpId);
        setSfpNoFormat(dataInvoice.sfpNoFormat);
        setSfpDetailId(dataInvoice.sfpDetailId);
        setUniqueKeySFP(dataInvoice.uniqueKeySFP);
        setUniqueKeyInvoice(dataInvoice.uniqueKeyInvoice);
        setDeleteType(dataInvoice.deleteType);
        setDeleteTypeRefInvId(dataInvoice.deleteTypeRefInvId);
        setKursKMKId(dataInvoice.kursKMKId);
        setIsDelivered(dataInvoice.isDelivered);
        setDeliveredRemarks(dataInvoice.deliveredRemarks);
        setApprovedCredit(dataInvoice.approvedCredit);
        setApprovedCreditBy(dataInvoice.approvedCreditBy);
        setApprovedCreditRemarks(dataInvoice.approvedCreditRemarks);
        setPejkpNumber(dataInvoice.pejkpNumber);
        setPejkpReference(dataInvoice.pejkpReference);
        setRequester(dataInvoice.requester);

        setVolume20(dataInvoice.shipVol20Amount);
        if (dataInvoice.shipVol20Amount > 0) {
            setIsVolume20(true);
        }

        setVolume40(dataInvoice.shipVol40Amount);
        if (dataInvoice.shipVol40Amount > 0) {
            setIsVolume40(true);
        }

        setVolume45(dataInvoice.shipVol45Amount);
        if (dataInvoice.shipVol45Amount > 0) {
            setIsVolume45(true);
        }

        setVolumeCBM(dataInvoice.shipVolCBMAmount);
        if (dataInvoice.shipVolCBMAmount > 0) {
            setIsVolumeCBM(true);
        }

        setRate(convertToNominal(dataInvoice.rate));
        setRateReal(dataInvoice.rate);
        setExRateId(dataInvoice.exRateId);
        setKursKMK(dataInvoice.kursKMK);
        setPaymentUSD(dataInvoice.paymentUSD);
        setPaymentIDR(dataInvoice.paymentIDR);
        setTotalVatUSD(dataInvoice.totalVatUSD);
        setTotalVatIDR(dataInvoice.totalVatIDR);

        if (dataInvoice.printing > 0 && fakturType === undefined) {
            setIsViewOnly(true);
        }

        setDateFormat(dataInvoice.etd, setET);
        setDateFormat(dataInvoice.eta, setET);
        setDateFormat(dataInvoice.paidOn, setPaidOn);
        setDateFormat(dataInvoice.badDebtOn, setBadDebtOn);
        setDateFormat(dataInvoice.dateReBadDebt, setDateReBadDebt);
        setDateFormat(dataInvoice.deletedOn, setDeletedOn);
        setDateFormat(dataInvoice.rePrintApprovedOn, setRePrintApprovedOn);
        setDateFormat(dataInvoice.deliveredOn, setDeliveredOn);
        setDateFormat(dataInvoice.approvedCreditOn, setApprovedCreditOn);
        setDateFormat(dataInvoice.transactionOn, setTransactionOn);
        setDateFormat(dataInvoice.exRateDate, setExRateDate);

        setTempDataPayment(dataDetailInvoice);
        setDataPayment(dataDetailInvoice);
        calculateTotal(dataDetailInvoice);
        setVATValueData(dataDetailInvoice);
        if (dataInvoice.customerTypeId !== 1) {
            setDataPaymentSC(dataDetailInvoice);
            setDataPaymentA([]);
        }
        else {
            setDataPaymentSC([]);
            setDataPaymentA(dataDetailInvoice);
        }
        GetContainerSizeData(1, 100, dataInvoice.shipmentId);

        ApiService.PostById(paramRegJobType, dataInvoice.jobTypeId)
            .then((response) => {
                if (response.data.code === 200) {
                    let data = response.data.data.jobType;
                    if (data.name === 'Domestic') {
                        data.serviceType = 'Export';
                    }
                    setJobType(data);
                    setIdJobType(data.jobTypeId);
                    typeJob = data;
                }
                else {
                    Back();
                }
            })
            .then(() => {
                checkExportImport(typeJob);
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
            })

        localStorage.removeItem(username + '-DataInvoice');
        setTimeout(function () { setIsLoadingBackDrop(false); }, 1250);
    }

    const getData = (id, typeJob, isRevised = false) => {
        if (isRevised === true) {
            setIsLoadingBackDrop(true);
        }

        ApiService.OperationPostById(paramInvoice, id)
            .then((response) => {
                if (response.data.code === 200) {
                    if ((response.data.data.invoice.rowStatus !== 'DEL') || isRevised === true) {
                        setIsEdit(true);
                        setIsRevised(isRevised);

                        if ((response.data.data.invoice.orId !== 0 || response.data.data.invoice.pvId !== 0) && isRevised === false) {
                            setIsViewOnly(true);
                        }

                        if (response.data.data.invoice.isGeneral === true) {
                            setPaymentFrom(1);
                            setIsInvoice(false);
                        }
                        else if (response.data.data.invoice.isGeneral === false) {
                            setPaymentFrom(0);
                            setIsInvoice(true);
                        }
                        setInfossNo(response.data.data.invoice.noInfoss);
                        setORId(response.data.data.invoice.orId);
                        setPVId(response.data.data.invoice.pvId);
                        setType(response.data.data.invoice.isCostToCost);
                        setBookingConfirmationId(response.data.data.invoice.bookingId);
                        setBookingConfirmationNumber(response.data.data.invoice.bookingNo);
                        setIsApprovedGen(response.data.data.invoice.approvedGeneral);
                        setVatId(response.data.data.invoice.vatId);
                        setPercentVat(response.data.data.invoice.percentVAT);
                        if (isRevised === false) {
                            setInvoiceNo(response.data.data.invoice.invoiceNo);
                        }
                        else {
                            setInvoiceNo(0);
                        }
                        setJobTypeCode(response.data.data.invoice.jobTypeCode);
                        setInvoiceForAgentNo(response.data.data.invoice.invoicesAgent);
                        setPrincipleBy(response.data.data.invoice.jobOwnerId);
                        setHeaderInvoice(response.data.data.invoice.invHeader);
                        setPackingList(response.data.data.invoice.packingListNo);
                        setSICustomer(response.data.data.invoice.siCustomerNo);
                        setEPLId(response.data.data.invoice.eplId);
                        setDeleteSeq(response.data.data.invoice.deleteTypeSeq);
                        setShipmentId(response.data.data.invoice.shipmentId);
                        setShipmentOrderNo(response.data.data.invoice.shipmentNo);
                        setReference(response.data.data.invoice.reference);
                        if (!isRevised) {
                            handleDocumentStatus(response.data.data.invoice.rowStatus, false, response.data.data.invoice.printing > 0)
                        }

                        setPaymentType(response.data.data.invoice.debetCredit);

                        setCustomerTo(response.data.data.invoice.customerTypeId);
                        if (response.data.data.invoice.customerTypeId !== 1) {
                            setCustomerSCId(response.data.data.invoice.customerId);
                            setCustomerSCName(response.data.data.invoice.customerName);
                            setCustomerSCAddress(response.data.data.invoice.customerAddress);
                        }
                        else {
                            setIsAgent(false);
                            setCustomerAId(response.data.data.invoice.customerId);
                            setCustomerAName(response.data.data.invoice.customerName);
                            setCustomerAAddress(response.data.data.invoice.customerAddress);
                        }
                        setCustomerId(response.data.data.invoice.customerId);
                        setCustomerName(response.data.data.invoice.customerName);
                        setCustomerAddress(response.data.data.invoice.customerAddress);
                        setBillToId(response.data.data.invoice.billId);
                        setBillToName(response.data.data.invoice.billName);
                        setBillToAddress(response.data.data.invoice.billAddress);

                        if (fakturType !== 'H') {
                            setRefEFaktur(response.data.data.invoice.sfpReference);
                        }

                        if (!isRevised) {
                            if (fakturType === undefined) {
                                setRevisedTaxId(response.data.data.invoice.originalInvoiceId);
                                setRevisedTaxNo(response.data.data.invoice.originalInvoiceNo);
                            }
                            else {
                                setRevisedTaxId(response.data.data.invoice.id);
                                setRevisedTaxNo(response.data.data.invoice.invoiceNo2);
                            }
                        }

                        setInclude(response.data.data.invoice.isStampDuty);
                        if (response.data.data.invoice.isStampDuty === true) {
                            setIncludeValue('1');
                            setIsInclude(false);
                        }
                        else {
                            setIncludeValue('0');
                            setIsInclude(true);
                        }
                        setAmount(response.data.data.invoice.stampDutyAmount);
                        setTicketId(response.data.data.invoice.ticketId);
                        if (response.data.data.invoice.customerTypeId === 1) {
                            setCustomer('Agent');
                        }
                        else if (response.data.data.invoice.customerTypeId === 2) {
                            setCustomer('Shipper');
                        }
                        else if (response.data.data.invoice.customerTypeId === 3) {
                            setCustomer('Consignee');
                        }
                        setInvoiceStatus(response.data.data.invoice.invoiceStatus);
                        setPeriod(response.data.data.invoice.period);
                        setYearPeriod(response.data.data.invoice.yearPeriod);
                        setInvoicesEdit(response.data.data.invoice.isEdited);
                        setLinkTo(response.data.data.invoice.linkTo);
                        setDueDate(response.data.data.invoice.dueDate);
                        setPaymentStatus(response.data.data.invoice.paid);
                        setSaveOR(response.data.data.invoice.saveOR);
                        setBadDebt(response.data.data.invoice.badDebt);
                        setReBadDebt(response.data.data.invoice.reBadDebt);
                        setDeleted(response.data.data.invoice.deleted);

                        if (fakturType === undefined) {
                            if (isRevised === false) {
                                setInvoiceNo2(response.data.data.invoice.invoiceNo2);
                                setPrintCount(response.data.data.invoice.printing);
                                setDateFormat(response.data.data.invoice.printedOn, setPrintOn);
                            }
                        }

                        setRePrintApproved(response.data.data.invoice.rePrintApproved);
                        setRePrintApprovedBy(response.data.data.invoice.rePrintApprovedBy);
                        setDeletedRemarks(response.data.data.invoice.deletedRemarks);
                        setSfpId(response.data.data.invoice.sfpId);
                        setSfpNoFormat(response.data.data.invoice.sfpNoFormat);
                        setSfpDetailId(response.data.data.invoice.sfpDetailId);
                        setUniqueKeySFP(response.data.data.invoice.uniqueKeySFP);
                        setUniqueKeyInvoice(response.data.data.invoice.uniqueKeyInvoice);

                        if (isRevised === false) {
                            setDeleteType(response.data.data.invoice.deleteType);
                            setDeleteTypeRefInvId(response.data.data.invoice.deleteTypeRefInvId);
                        }
                        else {
                            setDeleteType(0);
                            setDeleteTypeRefInvId(0);
                        }

                        setKursKMKId(response.data.data.invoice.kursKMKId);
                        setIsDelivered(response.data.data.invoice.isDelivered);
                        setDeliveredRemarks(response.data.data.invoice.deliveredRemarks);
                        setApprovedCredit(response.data.data.invoice.approvedCredit);
                        setApprovedCreditBy(response.data.data.invoice.approvedCreditBy);
                        setApprovedCreditRemarks(response.data.data.invoice.approvedCreditRemarks);
                        setPejkpNumber(response.data.data.invoice.pejkpNumber);
                        setPejkpReference(response.data.data.invoice.pejkpReference);
                        setRequester(response.data.data.invoice.requester);
                        setRate(convertToNominal(response.data.data.invoice.rate));
                        setRateReal(response.data.data.invoice.rate);
                        setExRateId(response.data.data.invoice.exRateId);
                        setKursKMK(response.data.data.invoice.kursKMK);
                        setPaymentUSD(response.data.data.invoice.paymentUSD);
                        setPaymentIDR(response.data.data.invoice.paymentIDR);
                        setTotalVatUSD(response.data.data.invoice.totalVatUSD);
                        setTotalVatIDR(response.data.data.invoice.totalVatIDR);

                        setVolume20(response.data.data.invoice.shipVol20Amount);
                        if (response.data.data.invoice.shipVol20Amount > 0) {
                            setIsVolume20(true);
                        }

                        setVolume40(response.data.data.invoice.shipVol40Amount);
                        if (response.data.data.invoice.shipVol40Amount > 0) {
                            setIsVolume40(true);
                        }

                        setVolume45(response.data.data.invoice.shipVol45Amount);
                        if (response.data.data.invoice.shipVol45Amount > 0) {
                            setIsVolume45(true);
                        }

                        setVolumeCBM(response.data.data.invoice.shipVolCBMAmount);
                        if (response.data.data.invoice.shipVolCBMAmount > 0) {
                            setIsVolumeCBM(true);
                        }

                        if (response.data.data.invoice.printing > 0 && fakturType === undefined && isRevised === false) {
                            setIsViewOnly(true);
                        }

                        if (typeJob.serviceType === 'Export') {
                            setDateFormat(response.data.data.invoice.etd, setET);
                        }
                        else {
                            setDateFormat(response.data.data.invoice.eta, setET);
                        }
                        setDateFormat(response.data.data.invoice.paidOn, setPaidOn);
                        setDateFormat(response.data.data.invoice.badDebtOn, setBadDebtOn);
                        setDateFormat(response.data.data.invoice.dateReBadDebt, setDateReBadDebt);
                        setDateFormat(response.data.data.invoice.deletedOn, setDeletedOn);
                        setDateFormat(response.data.data.invoice.rePrintApprovedOn, setRePrintApprovedOn);
                        setDateFormat(response.data.data.invoice.deliveredOn, setDeliveredOn);
                        setDateFormat(response.data.data.invoice.approvedCreditOn, setApprovedCreditOn);
                        setDateFormat(response.data.data.invoice.transactionOn, setTransactionOn);
                        setDateFormat(response.data.data.invoice.exRateDate, setExRateDate);

                        setTempDataPayment(response.data.data.invoice.invoiceDetails);
                        setDataPayment(response.data.data.invoice.invoiceDetails);
                        setVATValueData(response.data.data.invoice.invoiceDetails);
                        if (response.data.data.invoice.customerTypeId !== 1) {
                            setDataPaymentSC(response.data.data.invoice.invoiceDetails);
                            setDataPaymentA([]);
                        }
                        else {
                            setDataPaymentSC([]);
                            setDataPaymentA(response.data.data.invoice.invoiceDetails);
                        }
                        calculateTotal(response.data.data.invoice.invoiceDetails);

                        GetContainerSizeData(1, 100, response.data.data.invoice.shipmentId);

                        if (isRevised) {
                            ApiService.PostById('regContact/regContact', response.data.data.invoice.customerId)
                                .then((res) => {
                                    if (res.data.code === 200) {
                                        setCustomerAddress(res.data.data.contact.contactAddress);

                                        if (response.data.data.invoice.billId !== 0) {
                                            ApiService.PostById('regContact/regContact', response.data.data.invoice.billId)
                                                .then((res) => {
                                                    if (res.data.code === 200) {
                                                        setBillToAddress(res.data.data.contact.contactAddress);
                                                        setIsLoadingBackDrop(false);
                                                    }
                                                })
                                                .catch(function (error) {
                                                    console.error('error saat fetch', error);
                                                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
                                                })
                                        }
                                        else {
                                            setIsLoadingBackDrop(false);
                                        }
                                    }
                                })
                                .catch(function (error) {
                                    console.error('error saat fetch', error);
                                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
                                })
                        }

                        if (!dataLocal && !isRevised) {
                            setIsLoadingBackDrop(false);
                        }
                    }
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
            })
    };

    const getDataAccess = () => {
        if (!dataLocal) {
            setIsLoadingBackDrop(true);
        }

        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'OINVO')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        let tempArray = [];
        let checked = 0;

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);
                        tempArray = response.data.data.userAccess;

                        for (let i = 0; i < tempArray.length; i++) {
                            if (invoiceId) {
                                if (tempArray[i].accessCode === 'UPD' && tempArray[i].hidden === false) {
                                    checked = 1;
                                    break;
                                }
                            }
                            else {
                                if (tempArray[i].accessCode === 'CRE') {
                                    checked = 1;
                                    break;
                                }
                            }
                        };

                        setCheck(checked);

                        if (hasAccess) {
                            GetJobOwnerList(1, 20);
                        }
                    }
                    else {
                        Back();
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
                })
        }
        else {
            history('/Majura');
        }
    }

    const GetJobType = (id, eta, etd, eplId) => {
        let typeJob = '';
        let dataId = '';
        if (id === undefined) {
            dataId = sJobTypeId;
        }
        else {
            dataId = id;
        }
        setTypeJobId(dataId);

        ApiService.PostById(paramRegJobType, dataId)
            .then((response) => {
                if (response.data.code === 200) {
                    let data = response.data.data.jobType;
                    if (data.name === 'Domestic') {
                        data.serviceType = 'Export';
                    }

                    setJobType(data);
                    setIdJobType(response.data.data.jobType.jobTypeId);
                    setJobTypeCode(response.data.data.jobType.code);
                    setServiceType(response.data.data.jobType.name === 'Domestic' ? 'Export' : response.data.data.jobType.serviceType);
                    setPortType(response.data.data.jobType.portType);
                    typeJob = response.data.data.jobType;
                }
                else {
                    Back();
                }
            })
            .then(() => {
                if (!eplId) {
                    if (invoiceId) {
                        getData(invoiceId, typeJob);
                    }
                    else {
                        // GetExRate();
                        if (!dataLocal) {
                            setIsLoadingBackDrop(false);
                        }
                    }
                }
                else {
                    if (typeJob.serviceType === 'Export') {
                        setDateFormat(etd, setET);
                    }
                    else {
                        setDateFormat(eta, setET);
                    }

                    if (!dataLocal) {
                        setIsLoadingBackDrop(false);
                    }
                }

                checkExportImport(typeJob);
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
            })
    };

    const GetEPLData = (id) => {
        if (id !== null || id !== '') {
            // let params = [
            //     {
            //         "attr": "IsForEPL",
            //         "value": "1"
            //     },
            //     {
            //         "attr": "EPLClosingDate",
            //         "value": getDefaultDateFormat(new Date())
            //     }
            // ]

            let eplData = [];
            ApiService.OperationPostById(paramEPL, id)
                .then((response) => {
                    if (response.data.code === 200) {
                        eplData = response.data.data.estimateProfitLoss;
                    }
                    else {
                        Back();
                    }
                })
                .then(() => {
                    ApiService.RegClosingPeriodPostById(paramRegClosingPeriod, getDefaultDateFormat(new Date()))
                        .then((response) => {
                            if (response.data.code === 200) {
                                let data = response.data.data.closingPeriod;

                                if (eplData.yearPeriod === data.yearPeriod) {
                                    if (eplData.period === data.period) {
                                        let currentDate = new Date();
                                        let dayOfMonth = currentDate.getDate();
                                        let currentMonth = currentDate.getMonth();
                                        if (currentMonth > eplData.period) {
                                            if (dayOfMonth > 5) {
                                                props.setIsClosed(true);
                                                localStorage.setItem('isClosedEPL', JSON.stringify(true))
                                            }
                                            else {
                                                props.setIsClosed(false);
                                                localStorage.setItem('isClosedEPL', JSON.stringify(false))
                                            }
                                        }
                                        else {
                                            props.setIsClosed(false);
                                            localStorage.setItem('isClosedEPL', JSON.stringify(false))
                                        }
                                    }
                                    else if (eplData.period < data.period) {
                                        props.setIsClosed(true);
                                        localStorage.setItem('isClosedEPL', JSON.stringify(true))
                                    }
                                    else {
                                        setIsClosed(false);
                                        localStorage.setItem('isClosedEPL', JSON.stringify(false))
                                    }
                                }
                                else {
                                    setIsClosed(true);
                                    localStorage.setItem('isClosedEPL', JSON.stringify(true))
                                }
                            }
                        })
                        .catch(function (error) {
                            // console.error('error saat fetch', error);
                        })

                    setEPLId(eplData.id);
                    setTicketId(eplData.ticketId);
                    setShipmentId(eplData.shipmentId);
                    GetContainerSizeData(1, 100, eplData.shipmentId);
                    setShipmentOrderNo(eplData.shipmentNo);
                    setPrincipleBy(eplData.jobOwnerId);
                    setRequester(eplData.requester);

                    setBookingConfirmationId(eplData.bookingId);
                    setBookingConfirmationNumber(eplData.bookingNo);

                    // setCustomerAId(eplData.agentId);
                    // setCustomerAName(eplData.agentName);
                    // setCustomerAAddress(eplData.agentAddress);

                    // setCustomerATempId(eplData.agentId);
                    // setCustomerATempName(eplData.agentName);
                    // setCustomerATempAddress(eplData.agentAddress);

                    setVolume20(eplData.shipVol20Amount);
                    if (eplData.shipVol20Amount > 0) {
                        setIsVolume20(true);
                    }

                    setVolume40(eplData.shipVol40Amount);
                    if (eplData.shipVol40Amount > 0) {
                        setIsVolume40(true);
                    }

                    setVolume45(eplData.shipVol45Amount);
                    if (eplData.shipVol45Amount > 0) {
                        setIsVolume45(true);
                    }

                    setVolumeCBM(eplData.shipVolCBMAmount);
                    if (eplData.shipVolCBMAmount > 0) {
                        setIsVolumeCBM(true);
                    }

                    GetDataCustomer();

                    GetJobType(eplData.jobTypeId, eplData.eta, eplData.etd, eplData.id);
                })
                .catch(function (error) {
                    setIsLoadingBackDrop(false);
                    console.error('error saat fetch', error);
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
                })
        }
    };

    const GetDataCustomer = () => {
        let params = [
            { 'attr': 'contactId', 'value': '' + customerId },
            { 'attr': 'contactTypeId', 'value': '' + customerTypeId }
        ];
        ApiService.DynamicPostByPage(paramContact, 'ID', 1, 1, params, [])
            .then((response) => {
                if (response.data.code === 200) {
                    setCustomerId(response.data.data.contact[0].contactId);
                    setCustomerName(response.data.data.contact[0].contactName);
                    setCustomerAddress(response.data.data.contact[0].contactAddress);
                    setCustomerTo(customerTypeId);

                    let customerType = [
                        { id: 1, code: '2', name: 'Shipper' },
                        { id: 2, code: '3', name: 'Consignee' },
                        { id: 3, code: '1', name: 'Agent' }
                    ]

                    customerType.forEach(element => {
                        if (element.code === customerTypeId) {
                            setCustomer(element.name);
                        }
                    });

                    if (customerTypeId === '1') {
                        setIsAgent(false);
                    }
                    else {
                        setIsAgent(true);
                    }

                    setPaymentType(dcNote);
                    let DCNote = false;

                    if (dcNote === 'D') {
                        DCNote = true;
                    }
                    else {
                        DCNote = false;
                    }

                    getDataDetail(eplId, response.data.data.contact[0].contactId, false, DCNote);
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
            })
    }

    const getDataDetail = (id, customerId, Type = false, isIncome = true, customerTo = CustomerTo) => {
        let params = [];
        let dataArray = [];
        let newArray1 = [];
        let newArray2 = [];
        let Sequence = 1;
        let Sequence1 = 1;
        let Sequence2 = 1;
        let amount = 0;
        let invId = 0;
        let customer = '';
        let custTypeId = '';

        let customerType = [
            { id: 1, code: '2', name: 'Shipper' },
            { id: 2, code: '3', name: 'Consignee' },
            { id: 3, code: '1', name: 'Agent' }
        ]

        if (customerTypeId) {
            custTypeId = customerTypeId;
        }
        else {
            custTypeId = customerTo;
        }

        customerType.forEach(element => {
            if (element.code === custTypeId) {
                customer = element.name;
            }
        });

        params = [
            ...params,
            { 'attr': 'eplId', 'value': '' + id },
            { 'attr': 'isIncome', 'value': '' + isIncome },
            { 'attr': 'refId', 'value': '' + 0 },
            { 'attr': 'customerId', 'value': '' + customerId },
        ];

        if (!Type) {
            params = [
                ...params,
                { 'attr': 'isCostToCost', 'value': '' + Type }
            ];
        }

        ApiService.OperationPostByDetail(paramEPL, 'SEARCH', 1, 100, params)
            .then((response) => {
                setIsCircleProgress(true);
                if (response.data.code === 200) {
                    dataArray = response.data.data.estimateProfitLossDetail;

                    if (invoiceId !== undefined || invoiceId !== null) {
                        invId = invoiceId;

                        if (invId === undefined) {
                            invId = 0;
                        }
                    }
                    else {
                        invId = 0;
                    }

                    if (dataArray.length > 0) {
                        setPaymentFrom(0);

                        for (let i = 0; i < dataArray.length; i++) {
                            if (dataArray[i].rowStatus === 'ACT' && dataArray[i].isIgnoreItem === false) {
                                setAmountCrr(dataArray[i].amountCrr);

                                if (dataArray[i].amountCrr === 0) {
                                    amount = dataArray[i].amountUSD;
                                }
                                else if (dataArray[i].amountCrr === 1) {
                                    amount = dataArray[i].amountIDR;
                                }

                                if (dataArray[i].customerTypeId !== 1) {
                                    Sequence = Sequence1;
                                }
                                else {
                                    Sequence = Sequence2;
                                }

                                let data = {
                                    "countryId": countryId,
                                    "companyId": companyId,
                                    "branchId": branchId,
                                    "userId": userId,
                                    "userCode": userCode,

                                    "rowStatus": "ACT",
                                    "invoiceId": invId,
                                    "sequence": Sequence,
                                    "debetCredit": PaymentType,
                                    "accountId": dataArray[i].accountId,
                                    "accountCode": dataArray[i].accountCode,
                                    "accountName": dataArray[i].accountName,
                                    "coaCode": dataArray[i].coaCode,
                                    "coaName": dataArray[i].coaName,
                                    "description": dataArray[i].description,
                                    "type": dataArray[i].type,
                                    "containerSize": dataArray[i].containerSize,
                                    "formatContainer": dataArray[i].formatContainer,
                                    "containerTypeId": dataArray[i].containerTypeId,
                                    "containerTypeCode": dataArray[i].containerTypeCode,
                                    "containerTypeName": dataArray[i].containerTypeName,
                                    "isContainer": dataArray[i].isContainer,
                                    "codingQuantity": dataArray[i].codingQuantity,
                                    "quantity": parseFloat(dataArray[i].quantity),
                                    "perQty": dataArray[i].perQty,
                                    "sign": dataArray[i].sign,
                                    "amountCrr": dataArray[i].amountCrr,
                                    "amount": amount,
                                    "percentVat": 0,
                                    "amountVat": 0,
                                    "eplDetailId": dataArray[i].id,
                                    "vatId": 0,
                                    "isCostToCost": dataArray[i].isCostToCost,
                                    "originalUsd": dataArray[i].originalUsd,
                                    "originalRate": dataArray[i].originalRate,

                                    "idLama": 0,
                                    "invoiceDetailStorages": [],
                                }

                                if (dataArray[i].customerTypeId !== 1) {
                                    newArray1 = [...newArray1, data];
                                    Sequence1 = parseInt(Sequence1) + parseInt(1);
                                }
                                else {
                                    newArray2 = [...newArray2, data];
                                    Sequence2 = parseInt(Sequence2) + parseInt(1);
                                }
                            }
                        }

                        if (customer !== 'Agent') {
                            setDataPayment(newArray1);
                            setTempDataPayment(newArray1);
                            calculateTotal(newArray1);
                        }
                        else {
                            setDataPayment(newArray2);
                            setTempDataPayment(newArray2);
                            calculateTotal(newArray2);
                        }

                        setDataPaymentSC(newArray1);
                        setDataPaymentA(newArray2);
                        setIsInvoice(true);
                    }
                    else {
                        let isClosedEPL = JSON.parse(localStorage.getItem('isClosedEPL'));
                        if (isClosedEPL === true) {
                            setPaymentFrom(1);
                            setIsInvoice(false);
                        }
                        else {
                            showToast({ type: 'error', message: 'EPL Still Open, Please Input Detail on EPL!' });
                        }

                        setDataPayment([]);
                        setTempDataPayment([]);

                        setDataPaymentSC([]);
                        setDataPaymentA([]);
                        calculateTotal([]);
                    }
                    setIsCircleProgress(false);

                    setIsLoadingBackDrop(false);
                }
                else {
                    Back();
                    setIsLoadingBackDrop(false);
                }
                setIsCircleProgress(false);

                if (!dataLocal) {
                    setIsLoadingBackDrop(false);
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                setIsCircleProgress(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
            })
    };

    function checkExportImport(jobType) {
        if (jobType.portType === 'SEA') {
            setIsSea(false);
            setIsAir(true);
        }
        else if (jobType.portType === 'AIR') {
            setIsSea(true);
            setIsAir(false);
        }
        else {
            setIsSea(true);
            setIsAir(true);
        }

        if (jobType.serviceType === 'Export') {
            setCustomerTo('2');
            setCustomer('Shipper');
            setIsExport(false);
            setIsImport(true);
            setIsSeaImport(true);
        }
        else if (jobType.serviceType === 'Import') {
            setCustomerTo('3');
            setCustomer('Consignee');
            setIsExport(true);
            setIsImport(false);
            if (jobType.portType === 'SEA') {
                setIsSeaImport(false);
            }
            else {
                setIsSeaImport(true);
            }
        }
    }

    //Function for Principle By
    const GetJobOwnerList = (pageNumber, pageSize) => {
        ApiService.PostByPage(paramJobOwner, pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    if (response.data.data.jobOwner.length > 0) {
                        setHeaderInvoice(response.data.data.jobOwner[0].id);
                    }
                    setJobOwnerList(response.data.data.jobOwner);
                    GetVATData(1, 20);
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
            })
    };

    const selectInvoiceHeader = (HeaderInvoice, setHeaderInvoice, isDisable, title = 'Principle By') => (
        <>
            <Selection
                id='0'
                label={title}
                fullWidth={true}
                placeholder={'Select ' + title}
                value={HeaderInvoice}
                onChange={(e) => setHeaderInvoice(e.target.value)}
                disabled={isDisable}
            >
                {
                    jobOwnerList.map((data, index) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.id}>{data.masterCode}</MenuItem>
                    ))
                }
            </Selection>
        </>
    );

    //Function for VAT Type
    const GetVATData = (pageNumber, pageSize) => {
        let arrayData = [{ "id": 0, "value": 0.00 }];
        ApiService.PostByPage(paramVAT, pageNumber, pageSize)
            .then((response) => {
                if (response.status === 200) {
                    for (let i = 0; i < response.data.data.vat.length; i++) {
                        arrayData = [...arrayData, response.data.data.vat[i]];
                    }
                    setVATData(arrayData);

                    GetInvoicesToData(1, 20);
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
            })
    };

    const selectVATData = (Vat, setVat, name, isDisable, title = 'VAT') => (
        <>
            <Selection
                id='0'
                label={title}
                fullWidth={true}
                placeholder={'Select VAT'}
                value={Vat}
                onChange={(e) => setVat(e.target.value)}
                formControlProps={{
                    sx: {
                        marginTop: '16px'
                    }
                }}
                disabled={isDisable}
            >
                {
                    VATData.map((data, index) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.id}>{name}{data.value}%</MenuItem>
                    ))
                }
            </Selection>
        </>
    );

    function setVATValueData(data) {
        let tempData = 0;
        let isSame = true;

        if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                if (data[0].vatId !== data[i].vatId) {
                    isSame = false;
                    break;
                }
            }

            if (isSame === true) {
                tempData = data[0].vatId;
            }
        }

        if (isSame === true) {
            setVatValue(tempData);
        }
        else {
            setVatValue(0);
        }
    }

    const GetInvoicesToData = (pageNumber, pageSize) => {
        ApiService.PostByPage(paramInvoicesTo, pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    setInvoicesToData(response.data.data.invoicesTo);

                    if (dataLocal) {
                        setIsLoadingBackDrop(true);
                        setTimeout(function () { getDataLocal(); }, 2000);
                    }
                    else {
                        if (eplId) {
                            GetEPLData(eplId);
                            // GetExRate();
                            handleDocumentStatus('ACT')
                        }
                        else {
                            GetJobType();
                        }
                    }
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
            })
    };

    const GetContainerSizeData = (pageNumber, pageSize, shipmentId, filter, orderBy) => {
        let params = [];
        params = [
            {
                'attr': 'shipmentId',
                'value': '' + shipmentId,
                'isDistinct': true
            },
            {
                'attr': 'containerTypeId',
                'value': '',
                'isDistinct': true
            },
            {
                'attr': 'containerSize',
                'value': '',
                'isDistinct': true
            }
        ]
        ApiService.ShipmentSeaContainerPostBySearch(paramShipmentOrder, pageNumber, pageSize, params, filter, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setContainerSizeData(response.data.data.shipmentOrderSeaContainer);
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
            })
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsDraft(true);
            setContainerInfo(true);
            setShipperInfo(true);
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationInvoice() {
        checkDataCondition(titleConfirmation);
        setIsModalConfirmation(false);
    }

    const checkDataCondition = (isAction) => {
        if (invoiceId) {
            setIsLoadingBackDrop(true);
            ApiService.OperationPostById(paramInvoice, invoiceId)
                .then((response) => {
                    if (response.data.code === 200) {
                        let data = response.data.data.invoice;

                        const handleCheckPrint = () => {
                            if (isDraft === true) {
                                PrintDataPDF();
                            }
                            else {
                                if (data.rePrintApproved) {
                                    if (data.isGeneral) {
                                        if (data.approvedGeneral) {
                                            UpdateStatusPrint();
                                        } else {
                                            setIsLoadingBackDrop(false);
                                            showToast({ type: 'error', message: 'Please Approve This General Inv First!' });
                                            setIsModalConfirmation(false);
                                        }
                                    } else {
                                        UpdateStatusPrint();
                                    }
                                }
                                else {
                                    setIsLoadingBackDrop(false);
                                    showToast({ type: 'error', message: 'Can\'t Print Data, Need Reprint Approval!' });
                                    setIsModalConfirmation(false);
                                }
                            }
                        }

                        if (isAction === 'Save') {
                            if (data.printing === 0) {
                                Save(false);
                            }
                            else {
                                showToast({ type: 'error', message: 'Invoice Already Printed, Can\'t Save Data!' });
                            }
                        }
                        else if (isAction === 'Print') {
                            if (data.printing === 0) {
                                Save(true);
                            }
                            else {
                                handleCheckPrint();
                            }
                        }
                        else if (isAction === 'CheckPrint') {
                            handleCheckPrint();
                        }
                        else if (isAction === 'PrintPDF') {
                            setIsLoadingBackDrop(false);
                            setTitleConfirmation('Print')
                            setTitleModalConfirmation('Invoice');
                            handleClickConfirmation();
                        }
                        else if (isAction === 'Reprint') {
                            getData(invoiceId, jobType);
                            if (data.rePrintApproved === true) {
                                showToast({ type: 'error', message: 'Invoice Already Can Be Printed!' });
                            }
                            else {
                                RePrint();
                            }
                        }
                        else if (isAction === 'Reprint Data') {
                            setIsLoadingBackDrop(false);
                            if (data.rePrintApproved === false) {
                                setTitleConfirmation('Reprint')
                                setTitleModalConfirmation('Invoice');
                                handleClickConfirmation();
                            }
                            else {
                                showToast({ type: 'error', message: 'Invoice Already Can Be Printed!' });
                            }
                        }
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error);
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
                })
        }
        else if (isAction === 'Save') {
            Save(false);
        }
        else {
            showToast({ type: 'error', message: 'Please Save Data First!' });
        }
    }

    function Save(isPrint = false) {
        if (isLoadingBackDrop) return
        setIsLoadingBackDrop(true);

        let id = 0;
        let datePaid = paidOn === '' ? defaultDate : paidOn;
        let datePrint = PrintOn === '' ? defaultDate : PrintOn;
        let eta = '';
        let etd = '';
        let dateBadDebt = badDebtOn === '' ? defaultDate : badDebtOn;
        let dateReBadDebts = dateReBadDebt === '' ? defaultDate : dateReBadDebt;
        let dateDeleted = deletedOn === '' ? defaultDate : deletedOn;
        let dateRePrintApproved = rePrintApprovedOn === '' ? defaultDate : rePrintApprovedOn;
        let dateDelivered = deliveredOn === '' ? defaultDate : deliveredOn;
        let dateApprovedCredit = approvedCreditOn === '' ? defaultDate : approvedCreditOn;
        let dateTransaction = transactionOn === '' ? defaultDate : transactionOn;
        let dateExRate = ExRateDate === '' ? defaultDate : ExRateDate;
        let invoicesTo = '';
        let isGeneral = false;
        let isReplacementInv = RevisedTaxId === 0 ? false : true;

        if (invoiceId) {
            id = parseInt(invoiceId);
        }

        if (isImport === true) {
            if (ET !== '') {
                etd = ET;
            }
            else {
                etd = defaultDate;
            }
            eta = defaultDate;
        }
        else {
            if (ET !== '') {
                eta = ET;
            }
            else {
                eta = defaultDate;
            }
            etd = defaultDate;
        }

        for (let i = 0; i < InvoicesToData.length; i++) {
            if (AmountCrr === InvoicesToData[i].amountCrr && Customer === InvoicesToData[i].name) {
                invoicesTo = InvoicesToData[i].code;
                break;
            }
        }

        if (PaymentFrom === 0) {
            isGeneral = false;
        }
        else if (PaymentFrom === 1) {
            isGeneral = true;
        }

        let totalPaymentIDR = 0;
        let totalPaymentUSD = 0;
        let stampDuty = 0;
        stampDuty = parseFloat(convertToNumeric(Amount));

        if (PaymentIDR > 0) {
            totalPaymentIDR = PaymentIDR + stampDuty + TotalVatIDR;
        }
        else {
            if (PaymentUSD > 0) {
                totalPaymentUSD = PaymentUSD + stampDuty + TotalVatUSD;
            }
        }

        var data = {
            "invoice": {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,

                "rowStatus": "ACT",
                'noInfoss': infossNo,
                "id": id,
                "ticketId": ticketId,
                "invoiceNo": InvoiceNo,
                "debetCredit": PaymentType,
                "eplId": EPLId,
                "shipmentId": ShipmentId,
                "shipmentNo": ShipmentOrderNo,
                "bookingId": bookingConfirmationId,
                "bookingNo": bookingConfirmationNumber,
                "jobTypeId": parseInt(idJobType),
                "jobTypeCode": jobTypeCode,
                "etd": etd,
                "eta": eta,
                "invoicesTo": Customer,
                "customerTypeId": parseInt(CustomerTo),
                "customerId": CustomerId,
                "customerName": CustomerName,
                "customerAddress": CustomerAddress,
                "billId": BillToId,
                "billName": BillToName,
                "billAddress": BillToAddress,
                "invoicesToCode": invoicesTo,
                "invoiceStatus": invoiceStatus,
                "paymentUSD": PaymentUSD,
                "paymentIDR": PaymentIDR,
                "totalPaymentUSD": totalPaymentUSD,
                "totalPaymentIDR": totalPaymentIDR,
                "deleteTypeSeq": deleteSeq,
                "totalVatUSD": TotalVatUSD,
                "totalVatIDR": TotalVatIDR,
                "originalInvoiceId": RevisedTaxId,
                "originalInvoiceNo": RevisedTaxNo,
                "rate": RateReal,
                "jobOwnerId": PrincipleBy,
                "exRateDate": dateExRate,
                "period": period,
                "yearPeriod": yearPeriod,
                "invoicesAgent": InvoiceForAgentNo,
                "isEdited": invoicesEdit,
                "isGeneral": isGeneral,
                "linkTo": linkTo,
                "dueDate": dueDate,
                "paid": PaymentStatus,
                "paidOn": datePaid,
                "saveOR": saveOR,
                "badDebt": badDebt,
                "badDebtOn": dateBadDebt,
                "reBadDebt": reBadDebt,
                "dateReBadDebt": dateReBadDebts,
                "isReplacementInv": isReplacementInv,
                "printing": PrintCount,
                "printedOn": datePrint,
                "deleted": deleted,
                "deletedOn": dateDeleted,
                "invoiceNo2": invoiceNo2,
                "invHeader": HeaderInvoice,
                "exRateId": ExRateId,
                "rePrintApproved": rePrintApproved,
                "rePrintApprovedOn": dateRePrintApproved,
                "rePrintApprovedBy": rePrintApprovedBy,
                "deletedRemarks": deletedRemarks,
                "isCostToCost": Type,
                "sfpId": sfpId,
                "sfpNoFormat": sfpNoFormat,
                "sfpDetailId": sfpDetailId,
                "uniqueKeySFP": uniqueKeySFP,
                "uniqueKeyInvoice": uniqueKeyInvoice,
                "deleteType": deleteType,
                "deleteTypeRefInvId": deleteTypeRefInvId,
                "kursKMK": KursKMK,
                "kursKMKId": kursKMKId,
                "isDelivered": isDelivered,
                "deliveredOn": dateDelivered,
                "deliveredRemarks": deliveredRemarks,
                "sfpReference": RefEFaktur,
                "approvedCredit": approvedCredit,
                "approvedCreditBy": approvedCreditBy,
                "approvedCreditOn": dateApprovedCredit,
                "approvedCreditRemarks": approvedCreditRemarks,
                "packingListNo": PackingList,
                "siCustomerNo": SICustomer,
                "reference": '',
                "isStampDuty": Include,
                "shipVol20Amount": volume20,
                "shipVol40Amount": volume40,
                "shipVol45Amount": volume45,
                "shipVolCBMAmount": volumeCBM,
                "stampDutyAmount": convertToNumeric(Amount),
                "pejkpNumber": pejkpNumber,
                "pejkpReference": pejkpReference,
                "transactionOn": dateTransaction,
                "vatId": vatId,
                "percentVAT": percentVat,
                "requester": requester,

                "idLama": 0,
            },
            "invoiceDetails": TempDataPayment,
        };

        let errorMessage = undefined;

        if (data.invoiceDetails.length === 0) {
            errorMessage = 'Please Add Invoice Detail!';
        }
        else {
            data.invoiceDetails.forEach(element => {
                if (element.vatId !== vatId) {
                    errorMessage = 'Please Set All The Detail with Same VAT!';
                }
            });

            if (errorMessage === undefined) {
                if (data.invoice.debetCredit === 'C' && data.invoice.vatId !== 0) {
                    errorMessage = 'Please Select VAT 0% for Credit Typed Invoice!';
                }
                else if (data.invoice.noInfoss === '' && isInfossNo) {
                    errorMessage = 'Please Fill Infoss Number!';
                }
            }
        }

        if (errorMessage === undefined) {
            ApiService.HelpCreateData(data, paramInvoice, id)
                .then((res) => {
                    if (res.data.code === 200) {
                        let message = '';
                        if (ORId === 0 && PVId === 0) {
                            if (HeaderInvoice !== 0) {
                                if (id === 0) {
                                    ApiService.CreateOperation(paramInvoice, data).then((res) => {
                                        if (res.data.code === 200) {

                                            message = 'Create Data Success'
                                            showToast({ type: 'success', message: message });

                                            setTimeout(
                                                function () {
                                                    setIsLoadingBackDrop(false);
                                                    localStorage.setItem('tempId', JSON.stringify(res.data.data.Id));
                                                    history('/Majura/Invoice/' + sJobTypeId + '/' + JSON.parse(localStorage.getItem("tempId")), { replace: true });
                                                    window.location.reload();
                                                    localStorage.removeItem('tempId');
                                                },
                                                1250);
                                        }
                                        else {
                                            setIsLoadingBackDrop(false);

                                            message = res.data.message;
                                            showToast({ type: 'error', message: message });
                                        }
                                    });
                                }
                                else {
                                    if (fakturType === undefined) {
                                        ApiService.EditOperation(paramInvoice, data).then((res) => {
                                            if (res.data.code === 200) {
                                                if (!isPrint) {
                                                    message = 'Update Data Success';

                                                    showToast({ type: 'success', message: message });

                                                    setTimeout(
                                                        function () {
                                                            setIsLoadingBackDrop(false);
                                                            history('/Majura/Invoice/' + sJobTypeId + '/' + invoiceId, { replace: true });
                                                            window.location.reload();
                                                        },
                                                        1250);
                                                }
                                                else {
                                                    if (isDraft === true) {
                                                        PrintDataPDF();
                                                    }
                                                    else {
                                                        checkDataCondition('CheckPrint');
                                                    }
                                                }
                                            }
                                            else {
                                                setIsLoadingBackDrop(false);
                                                message = res.data.message;
                                                showToast({ type: 'error', message: message });
                                            }
                                        });
                                    }
                                    else {
                                        var oldData = {};
                                        let fakturTypes = 0;

                                        let etd = '';
                                        let eta = '';
                                        let datePaid = '';
                                        let dateBadDebt = '';
                                        let dateReBadDebts = '';
                                        let datePrint = '';
                                        let dateDeleted = '';
                                        let dateRePrintApproved = '';
                                        let dateDelivered = '';
                                        let dateApprovedCredit = '';
                                        let dateTransaction = '';
                                        let dateRateEx = '';

                                        ApiService.OperationPostById(paramInvoice, id)
                                            .then((response) => {
                                                if (response.status === 200) {
                                                    if (fakturType === 'H') {
                                                        fakturTypes = 1;
                                                    }
                                                    else if (fakturType === 'S') {
                                                        fakturTypes = 2;
                                                    }
                                                    else if (fakturType === 'P') {
                                                        fakturTypes = 3;
                                                    }

                                                    etd = updateDateFormat(response.data.data.invoice.etd, true);
                                                    eta = updateDateFormat(response.data.data.invoice.eta, true);
                                                    datePaid = updateDateFormat(response.data.data.invoice.paidOn, true);
                                                    dateBadDebt = updateDateFormat(response.data.data.invoice.badDebtOn, true);
                                                    dateReBadDebts = updateDateFormat(response.data.data.invoice.dateReBadDebt, true);
                                                    datePrint = updateDateFormat(response.data.data.invoice.printedOn, true);
                                                    dateDeleted = updateDateFormat(response.data.data.invoice.deletedOn, true);
                                                    dateRePrintApproved = updateDateFormat(response.data.data.invoice.rePrintApprovedOn, true);
                                                    dateDelivered = updateDateFormat(response.data.data.invoice.deliveredOn, true);
                                                    dateApprovedCredit = updateDateFormat(response.data.data.invoice.approvedCreditOn, true);
                                                    dateTransaction = updateDateFormat(response.data.data.invoice.transactionOn, true);
                                                    dateRateEx = updateDateFormat(response.data.data.invoice.exRateDate, true);

                                                    oldData = {
                                                        "invoice": {
                                                            "countryId": countryId,
                                                            "companyId": companyId,
                                                            "branchId": branchId,
                                                            "userId": userId,
                                                            "userCode": userCode,

                                                            "rowStatus": "DEL",
                                                            "id": id,
                                                            "ticketId": response.data.data.invoice.ticketId,
                                                            "invoiceNo": response.data.data.invoice.invoiceNo,
                                                            "debetCredit": response.data.data.invoice.debetCredit,
                                                            "eplId": response.data.data.invoice.eplId,
                                                            "shipmentId": response.data.data.invoice.shipmentId,
                                                            "shipmentNo": response.data.data.invoice.shipmentNo,
                                                            "bookingId": response.data.data.invoice.bookingId,
                                                            "bookingNo": response.data.data.invoice.bookingNo,
                                                            "jobTypeId": response.data.data.invoice.jobTypeId,
                                                            "jobTypeCode": response.data.data.invoice.jobTypeCode,
                                                            "etd": etd,
                                                            "eta": eta,
                                                            "invoicesTo": response.data.data.invoice.invoicesTo,
                                                            "customerTypeId": response.data.data.invoice.customerTypeId,
                                                            "customerId": response.data.data.invoice.customerId,
                                                            "customerName": response.data.data.invoice.customerName,
                                                            "customerAddress": response.data.data.invoice.customerAddress,
                                                            "billId": response.data.data.invoice.billId,
                                                            "billName": response.data.data.invoice.billName,
                                                            "billAddress": response.data.data.invoice.billAddress,
                                                            "invoicesToCode": response.data.data.invoice.invoicesToCode,
                                                            "invoiceStatus": response.data.data.invoice.invoiceStatus,
                                                            "paymentUSD": response.data.data.invoice.paymentUSD,
                                                            "paymentIDR": response.data.data.invoice.paymentIDR,
                                                            "totalPaymentUSD": response.data.data.invoice.totalPaymentUSD,
                                                            "totalPaymentIDR": response.data.data.invoice.totalPaymentIDR,
                                                            "totalVatUSD": response.data.data.invoice.totalVatUSD,
                                                            "totalVatIDR": response.data.data.invoice.totalVatIDR,
                                                            "originalInvoiceId": response.data.data.invoice.originalInvoiceId,
                                                            "originalInvoiceNo": response.data.data.invoice.originalInvoiceNo,
                                                            "rate": response.data.data.invoice.rate,
                                                            "jobOwnerId": response.data.data.invoice.jobOwnerId,
                                                            "exRateDate": dateRateEx,
                                                            "period": response.data.data.invoice.period,
                                                            "yearPeriod": response.data.data.invoice.yearPeriod,
                                                            "invoicesAgent": response.data.data.invoice.invoicesAgent,
                                                            "isEdited": response.data.data.invoice.isEdited,
                                                            "isGeneral": response.data.data.invoice.isGeneral,
                                                            "linkTo": response.data.data.invoice.linkTo,
                                                            "dueDate": response.data.data.invoice.dueDate,
                                                            "paid": response.data.data.invoice.paid,
                                                            "paidOn": datePaid,
                                                            "saveOR": response.data.data.invoice.saveOR,
                                                            "badDebt": response.data.data.invoice.badDebt,
                                                            "badDebtOn": dateBadDebt,
                                                            "reBadDebt": response.data.data.invoice.reBadDebt,
                                                            "dateReBadDebt": dateReBadDebts,
                                                            "printing": response.data.data.invoice.printing,
                                                            "printedOn": datePrint,
                                                            "deleted": response.data.data.invoice.deleted,
                                                            "deletedOn": dateDeleted,
                                                            "invoiceNo2": response.data.data.invoice.invoiceNo2,
                                                            "invHeader": response.data.data.invoice.invHeader,
                                                            "exRateId": response.data.data.invoice.exRateId,
                                                            "isPosted": response.data.data.invoice.isPosted,
                                                            "rePrintApproved": response.data.data.invoice.rePrintApproved,
                                                            "rePrintApprovedOn": dateRePrintApproved,
                                                            "rePrintApprovedBy": response.data.data.invoice.rePrintApprovedBy,
                                                            "deletedRemarks": response.data.data.invoice.deletedRemarks,
                                                            "isCostToCost": response.data.data.invoice.isCostToCost,
                                                            "sfpId": response.data.data.invoice.sfpId,
                                                            "sfpNoFormat": response.data.data.invoice.sfpNoFormat,
                                                            "sfpDetailId": response.data.data.invoice.sfpDetailId,
                                                            "uniqueKeySFP": response.data.data.invoice.uniqueKeySFP,
                                                            "uniqueKeyInvoice": response.data.data.invoice.uniqueKeyInvoice,
                                                            "deleteType": fakturTypes,
                                                            "deleteTypeRefInvId": response.data.data.invoice.deleteTypeRefInvId,
                                                            "kursKMK": response.data.data.invoice.kursKMK,
                                                            "kursKMKId": response.data.data.invoice.kursKMKId,
                                                            "isDelivered": response.data.data.invoice.isDelivered,
                                                            "deliveredOn": dateDelivered,
                                                            "deliveredRemarks": response.data.data.invoice.deliveredRemarks,
                                                            "sfpReference": response.data.data.invoice.sfpReference,
                                                            "approvedCredit": response.data.data.invoice.approvedCredit,
                                                            "approvedCreditBy": response.data.data.invoice.approvedCreditBy,
                                                            "approvedCreditOn": dateApprovedCredit,
                                                            "approvedCreditRemarks": response.data.data.invoice.approvedCreditRemarks,
                                                            "packingListNo": response.data.data.invoice.packingListNo,
                                                            "siCustomerNo": response.data.data.invoice.siCustomerNo,
                                                            "reference": response.data.data.invoice.reference,
                                                            "isStampDuty": response.data.data.invoice.isStampDuty,
                                                            "stampDutyAmount": response.data.data.invoice.stampDutyAmount,
                                                            "pejkpNumber": response.data.data.invoice.pejkpNumber,
                                                            "pejkpReference": response.data.data.invoice.pejkpReference,
                                                            "transactionOn": dateTransaction,
                                                            "vatId": response.data.data.invoice.vatId,
                                                            "percentVAT": response.data.data.invoice.percentVAT,
                                                            "requester": response.data.data.invoice.requester,
                                                            "IsUpdateInvoice": true,
                                                            "isReplacementInv": true,

                                                            "idLama": 0,
                                                        },
                                                        "invoiceDetails": response.data.data.invoice.invoiceDetails
                                                    };

                                                    ApiService.EditOperation(paramInvoice, oldData)
                                                        .then((res) => {
                                                            if (res.data.code === 200) {
                                                                // var dataGL = {
                                                                //     "generalLedger": {
                                                                //         "source": "INV",
                                                                //         "refId": res.data.data.Id,
                                                                //         "methodPost": "AUTO",
                                                                //         "procedureName": '[finance].SP_GeneralLedger_SBSendRequestMessage',
                                                                //     }
                                                                // }

                                                                // ApiService.CreateFinAcc(paramGL, dataGL)
                                                                //     .then((resGL) => {
                                                                //         let message = '';
                                                                //         message = resGL.data.message;
                                                                //         if (resGL.data.code === 200) {
                                                                data.invoice.deleteTypeSeq = parseInt(data.invoice.deleteTypeSeq) + parseInt(1);
                                                                data.invoice.isReplacementInv = true;
                                                                ApiService.CreateOperation(paramInvoice, data)
                                                                    .then((resCreate) => {
                                                                        if (resCreate.data.code === 200) {
                                                                            setIsLoadingBackDrop(false);

                                                                            message = 'Create New Data Success'
                                                                            showToast({ type: 'success', message: message });

                                                                            setTimeout(
                                                                                function () {
                                                                                    localStorage.setItem('tempId', JSON.stringify(resCreate.data.data.Id));
                                                                                    history('/Majura/Invoice/' + sJobTypeId + '/' + JSON.parse(localStorage.getItem("tempId")), { replace: true });
                                                                                    window.location.reload();
                                                                                    localStorage.removeItem('tempId');
                                                                                },
                                                                                1250);
                                                                        }
                                                                        else {
                                                                            setIsLoadingBackDrop(false);

                                                                            message = resCreate.data.message;
                                                                            showToast({ type: 'error', message: message });
                                                                        }
                                                                    })
                                                                    .catch(function (error) {
                                                                        setIsLoadingBackDrop(false);
                                                                        message = 'Updated Data Contra Failed';
                                                                        showToast({ type: 'error', message: error });
                                                                    });
                                                                //     }
                                                                //     else {
                                                                //         setIsLoadingBackDrop(false);
                                                                //         message = 'Posting Data Failed';
                                                                //         showToast({ type: 'error', message: message });
                                                                //     }
                                                                // })
                                                                // .catch(function (error) {
                                                                //     setIsLoadingBackDrop(false);
                                                                //     message = 'Create Data Invoice Failed';
                                                                //     showToast({ type: 'error', message: error });
                                                                // });
                                                            }
                                                            else {
                                                                setIsLoadingBackDrop(false);
                                                                message = 'Create Data GL Failed';
                                                                showToast({ type: 'error', message: message });
                                                            }
                                                        })
                                                        .catch(function (error) {
                                                            setIsLoadingBackDrop(false);
                                                            message = 'Update Data Contra Failed';
                                                            showToast({ type: 'error', message: message });
                                                        });
                                                }
                                            })
                                            .catch(function (error) {
                                                setIsLoadingBackDrop(false);
                                                message = 'Read Data Contra Failed';
                                                showToast({ type: 'error', message: message });
                                            })
                                    }
                                }
                            }
                            else {
                                setIsLoadingBackDrop(false);
                                showToast({ type: 'error', message: 'Please Add Invoice Header!' });
                            }
                        }
                        else {
                            setIsLoadingBackDrop(false);
                            showToast({ type: 'error', message: 'Can\'t Update Invoice, Invoice Already Used!' });
                        }
                    }
                    else {
                        setIsLoadingBackDrop(false);
                        showToast({ type: 'error', message: 'Failed to Create Help Data!' });
                    }
                })
                .catch(function (error) {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'You Don\'t Have Access, Please Contact The Administrator!' });
                })
        }
        else {
            setIsLoadingBackDrop(false);
            showToast({ type: 'error', message: errorMessage });
        }
    }

    function Back() {
        if (!isToDo) {
            history('/Majura/Invoice');
        }
        else {
            history('/Majura');
            window.location.reload();
        }
    }

    function UpdateStatusPrint() {
        let data = {
            'invoice': {
                'id': invoiceId,
                'printing': PrintCount,
                'debetCredit': PaymentType,
                'vatId': vatId,
                'percentVAT': percentVat,
                'OriginalInvId': RevisedTaxId,
            }
        };
        ApiService.OperationUpdateStatusPrint(paramInvoice, invoiceId, data).then((res) => {
            let message = '';
            message = res.data.message;
            if (res.data.code === 200) {
                if (PrintCount === 0 && isDraft !== true) {
                    // CreateGL(invoiceId);
                }

                let count = parseInt(PrintCount) + parseInt(1);
                setPrintCount(count);

                PrintDataPDF();
            }
            else {
                showToast({ type: 'error', message: message });
            }
        });
    }

    const PrintDataPDF = () => {
        setIsLoadingBackDrop(true);
        let reportLink = 'Transactions/Operation/InvoiceReport/InvoiceReport/PrintInvoice?Id=' + invoiceId
            + '&isDraft=' + isDraft + '&viewContainerInfo=' + containerInfo + '&viewShipperInfo=' + shipperInfo;
        PrintPDF(setIsLoadingBackDrop, 'Invoice', reportLink, [], true);
    }

    function AddNew() {
        window.open('/Majura/Invoice/Create/' + sJobTypeId, newWindowConfig);
    }

    function RePrint() {
        ApiService.OperationUpdateStatusRePrint(paramInvoice, invoiceId).then((res) => {
            let message = '';
            message = res.data.message;
            if (res.data.code === 200) {
                setRePrintApproved(true);
                showToast({ type: 'success', message: message });
            }
            else {
                showToast({ type: 'error', message: message });
            }
        });
    }

    function ChangeInvHeader() {

    }

    // const CreateGL = (id) => {
    //     var data = {
    //         "generalLedger": {
    //             "source": "INV",
    //             "refId": id,
    //             "methodPost": "AUTO",
    //             "procedureName": '[finance].SP_GeneralLedger_SBSendRequestMessage',
    //         }
    //     }

    //     ApiService.CreateFinAcc(paramGL, data).then((res) => {
    //         let message = '';
    //         message = res.data.message;
    //         if (res.data.code !== 200) {
    //             showToast({ type: 'error', message: message });
    //         }
    //     });
    // }

    function calculateTotal(result) {
        let count1 = 0;
        let count2 = 0;
        let count3 = 0;
        let count4 = 0;

        for (let i = 0; i < result.length; i++) {
            if (result[i].amountCrr === 0) {
                if (result[i].sign === true) {
                    count1 = parseFloat(result[i].amount) + parseFloat(count1);
                    count3 = parseFloat(result[i].amountVat) + parseFloat(count3);
                }
                else {
                    count1 = parseFloat(count1) - parseFloat(result[i].amount);
                    count3 = parseFloat(count3) - parseFloat(result[i].amountVat);
                }
            }
            else {
                if (result[i].sign === true) {
                    count2 = parseFloat(result[i].amount) + parseFloat(count2);
                    count4 = parseFloat(result[i].amountVat) + parseFloat(count4);
                }
                else {
                    count2 = parseFloat(count2) - parseFloat(result[i].amount);
                    count4 = parseFloat(count4) - parseFloat(result[i].amountVat);
                }
            }
        }

        setPaymentUSD(count1);
        setPaymentIDR(count2);
        setTotalVatUSD(count3);
        setTotalVatIDR(count4);
    }

    function emptyState() {
        setCustomerId(0);
        setCustomerName('');
        setCustomerAddress('');

        setCustomerSCId(0);
        setCustomerSCName('');
        setCustomerSCAddress('');
        setDataPayment([]);
        setTempDataPayment([]);
        setDataPaymentSC([]);

        setCustomerAId(0);
        setCustomerAName('');
        setCustomerAAddress('');
        setDataPayment([]);
        setTempDataPayment([]);
        setDataPaymentA([]);

        setBillToId(0);
        setBillToName('');
        setBillToAddress('');

        setDataPayment([]);
        setTempDataPayment([]);

        setDataPaymentSC([]);
        setDataPaymentA([]);
        calculateTotal([]);
    }

    const ButtonComponents = () => {
        return (
            <ButtonCreateEditInvoice
                Save={() => checkDataCondition('Save')}
                Back={Back}
                Print={() => checkDataCondition('PrintPDF')}
                AddNew={AddNew}
                RePrint={() => checkDataCondition('Reprint Data')}
                ChangeInvHeader={ChangeInvHeader}
                DataRegUserAccess={DataRegUserAccess}
                check={check}
                isViewOnly={isViewOnly}
                fakturType={fakturType}
                isEdit={isEdit}
            />
        )
    }

    return (
        <>
            <Box sx={{ backgroundColor: '#E4E8EC', width: '100%', p: '24px 32px 4px' }}>
                <CustomToastContainer />
                <Grid container spacing={0} direction="column">
                    <HeaderInfo
                        title={
                            invoiceId ?
                                'Invoice - ' + jobType.name + ' (' + reference + ')'
                                :
                                'Invoice - ' + jobType.name + ' (New Document)'
                        }
                        BreadcrumbsItems={invoiceId ? BreadcrumbsEditItems : BreadcrumbsItems}
                        type={'Form'}
                        ticketId={ticketId}
                        BackFunction={Back}
                        documentStatus={documentStatus}
                    />

                    <fieldset>
                        <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                            <CardContent>
                                <InvoiceHeader
                                    isRevised={isRevised}
                                    infossNo={infossNo}
                                    setInfossNo={setInfossNo}
                                    jobType={jobType}
                                    typeJobId={typeJobId}
                                    calculateTotal={calculateTotal}
                                    selectInvoiceHeader={selectInvoiceHeader}
                                    setVatValue={setVatValue}
                                    isToDo={isToDo}
                                    isAgent={isAgent}
                                    setIsAgent={setIsAgent}
                                    eplId={eplId}
                                    setAmountCrr={setAmountCrr}
                                    isExport={isExport}
                                    isImport={isImport}
                                    isEdit={isEdit}
                                    isClosed={isClosed}
                                    isInclude={isInclude}
                                    setIsInclude={setIsInclude}
                                    setIsLoadingBackDrop={setIsLoadingBackDrop}
                                    invoiceId={invoiceId}
                                    isSea={isSea}
                                    isAir={isAir}
                                    setIsClosed={setIsClosed}
                                    Customer={Customer}
                                    setCustomer={setCustomer}
                                    setIsInvoice={setIsInvoice}
                                    CustomerSCId={CustomerSCId}
                                    setCustomerSCId={setCustomerSCId}
                                    CustomerSCName={CustomerSCName}
                                    setCustomerSCName={setCustomerSCName}
                                    CustomerSCAddress={CustomerSCAddress}
                                    setCustomerSCAddress={setCustomerSCAddress}
                                    CustomerAId={CustomerAId}
                                    setCustomerAId={setCustomerAId}
                                    CustomerAName={CustomerAName}
                                    setCustomerAName={setCustomerAName}
                                    CustomerAAddress={CustomerAAddress}
                                    setCustomerAAddress={setCustomerAAddress}
                                    CustomerSCTempId={CustomerSCTempId}
                                    setCustomerSCTempId={setCustomerSCTempId}
                                    CustomerSCTempName={CustomerSCTempName}
                                    setCustomerSCTempName={setCustomerSCTempName}
                                    CustomerSCTempAddress={CustomerSCTempAddress}
                                    setCustomerSCTempAddress={setCustomerSCTempAddress}
                                    CustomerATempId={CustomerATempId}
                                    setCustomerATempId={setCustomerATempId}
                                    CustomerATempName={CustomerATempName}
                                    setCustomerATempName={setCustomerATempName}
                                    CustomerATempAddress={CustomerATempAddress}
                                    setCustomerATempAddress={setCustomerATempAddress}

                                    PaymentFrom={PaymentFrom}
                                    setPaymentFrom={setPaymentFrom}
                                    Type={Type}
                                    setType={setType}
                                    setTicketId={setTicketId}
                                    InvoiceNo={invoiceNo2}
                                    setInvoiceNo={setInvoiceNo}
                                    InvoiceForAgentNo={InvoiceForAgentNo}
                                    setInvoiceForAgentNo={setInvoiceForAgentNo}
                                    PrintCount={PrintCount}
                                    setPrintCount={setPrintCount}
                                    PrintOn={PrintOn}
                                    setPrintOn={setPrintOn}
                                    ET={ET}
                                    setET={setET}
                                    setRequester={setRequester}
                                    PrincipleBy={PrincipleBy}
                                    setPrincipleBy={setPrincipleBy}
                                    HeaderInvoice={HeaderInvoice}
                                    setHeaderInvoice={setHeaderInvoice}
                                    PackingList={PackingList}
                                    setPackingList={setPackingList}
                                    SICustomer={SICustomer}
                                    setSICustomer={setSICustomer}
                                    EPLId={EPLId}
                                    setEPLId={setEPLId}
                                    ShipmentId={ShipmentId}
                                    setShipmentId={setShipmentId}
                                    ShipmentOrderNo={ShipmentOrderNo}
                                    setShipmentOrderNo={setShipmentOrderNo}
                                    PaymentType={PaymentType}
                                    setPaymentType={setPaymentType}
                                    CustomerTo={CustomerTo}
                                    setCustomerTo={setCustomerTo}
                                    CustomerId={CustomerId}
                                    setCustomerId={setCustomerId}
                                    CustomerName={CustomerName}
                                    setCustomerName={setCustomerName}
                                    CustomerAddress={CustomerAddress}
                                    setCustomerAddress={setCustomerAddress}
                                    BillToId={BillToId}
                                    setBillToId={setBillToId}
                                    BillToName={BillToName}
                                    setBillToName={setBillToName}
                                    BillToAddress={BillToAddress}
                                    setBillToAddress={setBillToAddress}
                                    RefEFaktur={RefEFaktur}
                                    setRefEFaktur={setRefEFaktur}
                                    RevisedTaxId={RevisedTaxId}
                                    setRevisedTaxId={setRevisedTaxId}
                                    RevisedTaxNo={RevisedTaxNo}
                                    setRevisedTaxNo={setRevisedTaxNo}
                                    IncludeValue={IncludeValue}
                                    setIncludeValue={setIncludeValue}
                                    Include={Include}
                                    setInclude={setInclude}
                                    Amount={Amount}
                                    setAmount={setAmount}

                                    setCurrency={setCurrency}
                                    fakturType={fakturType}
                                    DataPaymentSC={DataPaymentSC}
                                    setDataPaymentSC={setDataPaymentSC}
                                    DataPaymentA={DataPaymentA}
                                    setDataPaymentA={setDataPaymentA}
                                    DataPayment={DataPayment}
                                    setDataPayment={setDataPayment}
                                    TempDataPayment={TempDataPayment}
                                    setTempDataPayment={setTempDataPayment}
                                    getDataDetail={getDataDetail}
                                    isViewOnly={isViewOnly}

                                    volume20={volume20}
                                    setVolume20={setVolume20}
                                    volume40={volume40}
                                    setVolume40={setVolume40}
                                    volume45={volume45}
                                    setVolume45={setVolume45}
                                    volumeCBM={volumeCBM}
                                    setVolumeCBM={setVolumeCBM}
                                    isVolume20={isVolume20}
                                    setIsVolume20={setIsVolume20}
                                    isVolume40={isVolume40}
                                    setIsVolume40={setIsVolume40}
                                    isVolume45={isVolume45}
                                    setIsVolume45={setIsVolume45}
                                    isVolumeCBM={isVolumeCBM}
                                    setIsVolumeCBM={setIsVolumeCBM}

                                    bookingConfirmationId={bookingConfirmationId}
                                    setBookingConfirmationId={setBookingConfirmationId}
                                    bookingConfirmationNumber={bookingConfirmationNumber}
                                    setBookingConfirmationNumber={setBookingConfirmationNumber}
                                    GetContainerSizeData={GetContainerSizeData}
                                    getData={getData}
                                    emptyState={emptyState}
                                />
                            </CardContent>
                        </Card>

                        <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                            <CardContent>
                                <InvoiceBeingForPayment
                                    selectVATData={selectVATData}
                                    VATData={VATData}
                                    isCircleProgress={isCircleProgress}
                                    invoiceId={invoiceId}
                                    setPaymentFrom={setPaymentFrom}
                                    VatId={vatId}
                                    setVatId={setVatId}
                                    setPercentVat={setPercentVat}
                                    DataPayment={DataPayment}
                                    setDataPayment={setDataPayment}
                                    calculateTotal={calculateTotal}
                                    TempDataPayment={TempDataPayment}
                                    setTempDataPayment={setTempDataPayment}
                                    DataPaymentSC={DataPaymentSC}
                                    setDataPaymentSC={setDataPaymentSC}
                                    DataPaymentA={DataPaymentA}
                                    setDataPaymentA={setDataPaymentA}
                                    Customer={Customer}
                                    PaymentFrom={PaymentFrom}
                                    isEdit={isEdit}
                                    isClosed={isClosed}
                                    PaymentType={PaymentType}
                                    isSea={isSea}
                                    isAir={isAir}
                                    BillToId={BillToId}
                                    BillToName={BillToName}
                                    CustomerId={CustomerId}
                                    CustomerName={CustomerName}
                                    setIsLoadingBackDrop={setIsLoadingBackDrop}
                                    isAgent={isAgent}
                                    isSeaImport={isSeaImport}
                                    EPLId={EPLId}
                                    isExport={isExport}
                                    isImport={isImport}
                                    isInvoice={isInvoice}
                                    setIsInvoice={setIsInvoice}

                                    VatValue={VatValue}
                                    setVatValue={setVatValue}
                                    setVATValueData={setVATValueData}
                                    PaymentStatus={PaymentStatus}
                                    setPaymentStatus={setPaymentStatus}
                                    Rate={Rate}
                                    ExRateId={ExRateId}
                                    ExRateDate={ExRateDate}
                                    Currency={Currency}
                                    setCurrency={setCurrency}
                                    fakturType={fakturType}
                                    KursKMK={KursKMK}
                                    setKursKMK={setKursKMK}
                                    Type={Type}
                                    PaymentUSD={PaymentUSD}
                                    setPaymentUSD={setPaymentUSD}
                                    PaymentIDR={PaymentIDR}
                                    setPaymentIDR={setPaymentIDR}
                                    TotalVatUSD={TotalVatUSD}
                                    setTotalVatUSD={setTotalVatUSD}
                                    TotalVatIDR={TotalVatIDR}
                                    setTotalVatIDR={setTotalVatIDR}
                                    isViewOnly={isViewOnly}

                                    volume20={volume20}
                                    setVolume20={setVolume20}
                                    volume40={volume40}
                                    setVolume40={setVolume40}
                                    volume45={volume45}
                                    setVolume45={setVolume45}
                                    volumeCBM={volumeCBM}
                                    setVolumeCBM={setVolumeCBM}
                                    isVolume20={isVolume20}
                                    setIsVolume20={setIsVolume20}
                                    isVolume40={isVolume40}
                                    setIsVolume40={setIsVolume40}
                                    isVolume45={isVolume45}
                                    setIsVolume45={setIsVolume45}
                                    isVolumeCBM={isVolumeCBM}
                                    setIsVolumeCBM={setIsVolumeCBM}
                                    containerSizeData={containerSizeData}
                                    serviceType={serviceType}
                                    portType={portType}
                                />
                            </CardContent>
                        </Card>
                    </fieldset>
                </Grid>

                {
                    isModalConfirmation && (
                        <ModalConfirmation
                            isModal={isModalConfirmation}
                            titleModal={titleModalConfirmation}
                            title={titleConfirmation}
                            handleClick={handleClickConfirmation}
                            ConfirmationData={ConfirmationInvoice}

                            isDraft={isDraft}
                            setIsDraft={setIsDraft}
                            containerInfo={containerInfo}
                            setContainerInfo={setContainerInfo}
                            shipperInfo={shipperInfo}
                            setShipperInfo={setShipperInfo}
                        />
                    )
                }
            </Box>

            <ButtonInfo
                ButtonComponents={ButtonComponents}
            />

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
})

export default CreateEditInvoice;