import { Grid } from '@mui/material';
import React, { useState } from 'react'
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import TableX from '../../../../components/Tables/TableX';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';
import { Icon, Paginate, PopUp, showToast } from '../../../../components/ui';
import ApiService from '../../../../services/ApiService';
import { getDefaultDateFormat, nullDate } from '../../../../utils/date';

const SelectDataEPL = (props) => {
    const paramRegClosingPeriod = 'regClosingPeriod/regClosingPeriod';
    const [rowsCount, setRowsCount] = useState(50);
    const [numPage, setNumPage] = useState(1);
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);

    const emptyState = () => {
        setNumPage(1);
        setRowsCount(50);
        props.setTotalRows(50);
        setGoTo(1);
        setSortArr([]);
        setFilter([]);
        setFilterBy([]);
        setStateX({});
    };

    function Select() {
        // let params = [
        //     {
        //         "attr": "IsForEPL",
        //         "value": "1"
        //     },
        //     {
        //         "attr": "EPLClosingDate",
        //         "value": getDefaultDateFormat(new Date())
        //     }
        // ]

        if (props.SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            props.setIsLoadingBackDrop(true);

            ApiService.RegClosingPeriodPostById(paramRegClosingPeriod, getDefaultDateFormat(new Date()))
                .then((response) => {
                    if (response.data.code === 200) {
                        let data = response.data.data.closingPeriod;

                        if (props.SelectedData.yearPeriod === data.yearPeriod) {
                            if (props.SelectedData.period === data.period) {
                                let currentDate = new Date();
                                let dayOfMonth = currentDate.getDate();
                                let currentMonth = currentDate.getMonth();
                                if (currentMonth > props.SelectedData.period) {
                                    if (dayOfMonth > 5) {
                                        props.setIsClosed(true);
                                        localStorage.setItem('isClosedEPL', JSON.stringify(true))
                                    }
                                    else {
                                        props.setIsClosed(false);
                                        localStorage.setItem('isClosedEPL', JSON.stringify(false))
                                    }
                                }
                                else {
                                    props.setIsClosed(false);
                                    localStorage.setItem('isClosedEPL', JSON.stringify(false))
                                }
                            }
                            else if (props.SelectedData.period < data.period) {
                                props.setIsClosed(true);
                                localStorage.setItem('isClosedEPL', JSON.stringify(true))
                            }
                            else {
                                props.setIsClosed(false);
                                localStorage.setItem('isClosedEPL', JSON.stringify(false))
                            }
                        }
                        else {
                            props.setIsClosed(true);
                            localStorage.setItem('isClosedEPL', JSON.stringify(true))
                        }
                    }

                    props.setVatValue(0);
                    props.setCurrency('All');
                    props.setEPLId(props.SelectedData.id);
                    props.GetContainerSizeData(1, 100, props.PaymentFrom === 0 ? props.SelectedData.shipmentId : props.SelectedData.id);
                    props.setBookingConfirmationId(props.SelectedData.bookingId);
                    props.setBookingConfirmationNumber(props.SelectedData.bookingNo);
                    props.setShipmentId(props.PaymentFrom === 0 ? props.SelectedData.shipmentId : props.SelectedData.id);
                    props.setShipmentOrderNo(props.SelectedData.shipmentNo);
                    props.setTicketId(props.SelectedData.ticketId);
                    props.setPrincipleBy(props.SelectedData.jobOwnerId);
                    props.setRequester(props.SelectedData.requester);

                    props.setVolume20(props.SelectedData.shipVol20Amount);
                    if (props.SelectedData.shipVol20Amount > 0) {
                        props.setIsVolume20(true);
                    }

                    props.setVolume40(props.SelectedData.shipVol40Amount);
                    if (props.SelectedData.shipVol40Amount > 0) {
                        props.setIsVolume40(true);
                    }

                    props.setVolume45(props.SelectedData.shipVol45Amount);
                    if (props.SelectedData.shipVol45Amount > 0) {
                        props.setIsVolume45(true);
                    }

                    props.setVolumeCBM(props.SelectedData.shipVolCBMAmount);
                    if (props.SelectedData.shipVolCBMAmount > 0) {
                        props.setIsVolumeCBM(true);
                    }

                    if (props.jobType.serviceType === 'Export') {
                        var tglEtd = getDefaultDateFormat(props.SelectedData.etd);
                        if (tglEtd !== nullDate) {
                            props.setET(tglEtd);
                        }
                    }
                    else {
                        var tglEta = getDefaultDateFormat(props.SelectedData.eta);
                        if (tglEta !== nullDate) {
                            props.setET(tglEta);
                        }
                    }

                    props.emptyState();

                    if (props.Customer !== 'Agent') {
                        if (props.jobType.serviceType === 'Export') {
                            props.setCustomerSCId(props.SelectedData.shipperId);
                            props.setCustomerSCName(props.SelectedData.shipperName);
                            props.setCustomerSCAddress(props.SelectedData.shipperAddress);

                            props.setCustomerId(props.SelectedData.shipperId);
                            props.setCustomerName(props.SelectedData.shipperName);
                            props.setCustomerAddress(props.SelectedData.shipperAddress);

                            props.setCustomerSCTempId(props.SelectedData.shipperId);
                            props.setCustomerSCTempName(props.SelectedData.shipperName);
                            props.setCustomerSCTempAddress(props.SelectedData.shipperAddress);

                            props.getDataDetail(props.SelectedData.id, props.SelectedData.shipperId, props.Type);
                        }
                        else if (props.jobType.serviceType === 'Import') {
                            props.setCustomerSCId(props.SelectedData.consigneeId);
                            props.setCustomerSCName(props.SelectedData.consigneeName);
                            props.setCustomerSCAddress(props.SelectedData.consigneeAddress);

                            props.setCustomerId(props.SelectedData.consigneeId);
                            props.setCustomerName(props.SelectedData.consigneeName);
                            props.setCustomerAddress(props.SelectedData.consigneeAddress);

                            props.setCustomerSCTempId(props.SelectedData.consigneeId);
                            props.setCustomerSCTempName(props.SelectedData.consigneeName);
                            props.setCustomerSCTempAddress(props.SelectedData.consigneeAddress);

                            props.getDataDetail(props.SelectedData.id, props.SelectedData.consigneeId, props.Type);
                        }

                        props.setCustomerAId(props.SelectedData.agentId);
                        props.setCustomerAName(props.SelectedData.agentName);
                        props.setCustomerAAddress(props.SelectedData.agentAddress);
                    }
                    else {
                        props.setCustomerAId(props.SelectedData.agentId);
                        props.setCustomerAName(props.SelectedData.agentName);
                        props.setCustomerAAddress(props.SelectedData.agentAddress);

                        props.setCustomerId(props.SelectedData.agentId);
                        props.setCustomerName(props.SelectedData.agentName);
                        props.setCustomerAddress(props.SelectedData.agentAddress);

                        props.setCustomerATempId(props.SelectedData.agentId);
                        props.setCustomerATempName(props.SelectedData.agentName);
                        props.setCustomerATempAddress(props.SelectedData.agentAddress);

                        props.getDataDetail(props.SelectedData.id, props.SelectedData.agentId, props.Type);

                        if (props.jobType.serviceType === 'Export') {
                            props.setCustomerSCId(props.SelectedData.shipperId);
                            props.setCustomerSCName(props.SelectedData.shipperName);
                            props.setCustomerSCAddress(props.SelectedData.shipperAddress);
                        }
                        else if (props.jobType.serviceType === 'Import') {
                            props.setCustomerSCId(props.SelectedData.consigneeId);
                            props.setCustomerSCName(props.SelectedData.consigneeName);
                            props.setCustomerSCAddress(props.SelectedData.consigneeAddress);
                        }
                    }
                })
                .catch(function (error) {
                    // console.error('error saat fetch', error);
                })
        }

        emptyState();

        props.setIsModal(false);
    }

    function Cancel() {
        emptyState();
        props.handleClick();
    };

    const handleReset = () => {
        emptyState();
        props.getDataHeader(1, 50);
        setStateX({})
    };

    function CellDoubleClick(row) {
        Select();
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        props.getDataHeader(value, rowsCount, filter, sortArr);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        props.getDataHeader(1, value, filter, sortArr);
    }

    const mainContainer = (
        <>
            <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
                <div>
                    {
                        ButtonComponent.RefreshButton(
                            sortArr,
                            rowsCount,
                            props.columnData,
                            setGoTo,
                            handleReset,
                            setFilterBy,
                            filterBy,
                            setFilter,
                            setNumPage,
                            setRowsCount,
                            props.getDataHeader,
                            stateX,
                            setStateX
                        )
                    }
                </div>

                <div
                    className="rounded-10 table-responsive"
                    style={{
                        maxHeight: '375px',
                        marginTop: '16px',
                        padding: '2px 16px',
                        borderRadius: '12px',
                        background: '#F2F2F2'
                    }}
                >
                    {
                        props.isLoading ?
                            (
                                <LoadingSpinner />
                            )
                            :
                            (
                                <>
                                    <div className="mt-1">
                                        <TableX 
                                            uniqueKey={'id'}
                                            goTo={goTo}
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={props.getDataHeader}
                                            columnData={props.columnData}
                                            dataMap={props.dataMap}
                                            dataTable={props.dataTable}
                                            SelectedData={props.SelectedData}
                                            setSelectedData={props.setSelectedData}
                                            setFilter={setFilter}
                                            setFilterBy={setFilterBy}
                                            CellDoubleClick={CellDoubleClick}
                                            stateX={stateX}
                                            setStateX={setStateX}
                                            sortArr={sortArr}
                                            setSortArr={setSortArr}
                                            setGoTo={setGoTo}
                                        />
                                    </div>
                                </>
                            )
                    }
                </div>

                <div style={{ marginTop: '16px' }}>
                    <Paginate
                        page={numPage}
                        limit={rowsCount}
                        totalData={props.totalRows}
                        onChange={onChangePaging}
                        onChangeLimit={onChangeLimit}
                    />
                </div>
            </Grid>
        </>
    );

    return (
        <PopUp
            id='modal-id'
            icon={
                <Icon
                    iconName={'warehouse'}
                    svgIconProps={{
                        htmlColor: '#0087C2',
                        sx: {
                            height: '24px',
                            width: '24px'
                        }
                    }}
                />
            }
            title={'Select ' + props.titleModal}
            isOpen={props.isModal}
            onClose={Cancel}
            draggable={false}
            shrinkable={false}
            disableScrollLock={false}
            maxWidth={'lg'}
            fullWidth={true}
        >
            <div style={{ padding: '0px 6px' }}>
                {mainContainer}

                <div style={{ padding: '24px 0px 0px' }}>
                    {CustomizeButtonComponent.SelectModalButton(Select)}
                </div>
            </div>
        </PopUp>
    )
}

export default SelectDataEPL;