import { Grid } from '@mui/material';
import React, { useState } from 'react';

import LoadingSpinner from '../../../../../components/Components/LoadingSpinner';
import TableCheck from '../../../../../components/Tables/TableCheck';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';
import { Icon, Paginate, PopUp, showToast } from '../../../../../components/ui';
import TableX from '../../../../../components/Tables/TableX';
import ApiService from '../../../../../services/ApiService';

const SelectDataReference = (props) => {
    const [rowsCount, setRowsCount] = useState(50);
    const [numPage, setNumPage] = useState(1);
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);

    const emptyState = () => {
        setNumPage(1);
        setRowsCount(50);
        props.setTotalRows(50);
        setFilter([]);
        setFilterBy([]);
        setGoTo(1);
        setSortArr([]);
        setStateX({});
    };

    function Select() {
        if (props.SelectedData?.id !== undefined || props.dataCheck.length !== 0) {
            if (props.Type === 2) {
                if (props.dataCheck.length > 0) {
                    if (props.dataCheck.length === 1) {
                        props.setRefId(props.dataCheck[0].id);
                        props.setRefNo(props.dataCheck[0].prNo2);
                        props.setShipmentId(props.dataCheck[0].shipmentId);
                        props.setShipmentNo(props.dataCheck[0].shipmentNo);
                        props.setAmountUSD(props.dataCheck[0].paymentUSD);
                        props.setAmountIDR(props.dataCheck[0].paymentIDR);
                        props.setRefExRateId(props.dataCheck[0].exRateId);
                        props.setRefRate(props.dataCheck[0].rate);
                        props.setRefDetailId(0);
                    }
                    else {
                        let amountUSD = 0;
                        let amountIDR = 0;
                        props.setRefNo('Some Refs');
                        props.setShipmentNo('Some Shipments');
                        props.setRefId(0);
                        props.setShipmentId(0);
                        props.setRefExRateId(0);
                        props.setRefRate(0);
                        props.setRefDetailId(0);

                        props.dataCheck.forEach(element => {
                            amountUSD = amountUSD + element.paymentUSD;
                            amountIDR = amountIDR + element.paymentIDR;
                        });

                        props.setAmountUSD(amountUSD);
                        props.setAmountIDR(amountIDR);
                    }
                }
            }
            else {
                if (props.Type === 1) {
                    if (props.SettlementTo === 'P05') {
                        props.setRefId(props.SelectedData.id);
                        props.setRefNo(props.SelectedData.invoiceNo2);
                        props.setShipmentId(props.SelectedData.shipmentId);
                        props.setShipmentNo(props.SelectedData.shipmentNo);
                        props.setRefExRateId(props.SelectedData.exRateId);
                        props.setRefRate(props.SelectedData.rate);
                    }
                    else if (props.SettlementTo === 'P04') {
                        props.setIsLoadingBackDrop(true);
                        let params = [
                            { 'attr': 'EntryFor', 'value': 'CT-' + props.CustomerTo }
                        ];
                        ApiService.DynamicPostByPage("regChartOfAccountEntry/regChartOfAccountEntry", 'SEARCH', 1, 50, params, [])
                            .then((response) => {
                                if (response.data.code === 200) {
                                    if (response.data.data.chartOfAccountEntry[0] !== undefined) {
                                        let amountUSD = 0;
                                        let amountIDR = 0;
                                        props.setRefNo('Some Refs');
                                        props.setShipmentNo('Some Shipments');
                                        props.setRefId(0);
                                        props.setShipmentId(0);
                                        props.setRefExRateId(0);
                                        props.setRefRate(0);
                                        props.setRefDetailId(0);

                                        props.dataCheck.forEach(element => {
                                            amountUSD = amountUSD + element.amountUsd;
                                            amountIDR = amountIDR + element.amountIdr;
                                        });

                                        props.setAmountUSD(amountUSD);
                                        props.setAmountIDR(amountIDR);

                                        props.setAccountCode(response.data.data.chartOfAccountEntry[0].accountCode);
                                        props.setAccountName(response.data.data.chartOfAccountEntry[0].accountTitle);
                                        props.setDescription(response.data.data.chartOfAccountEntry[0].accountTitle);
                                        props.setIsLoadingBackDrop(false);
                                    }
                                    else {
                                        props.setIsLoadingBackDrop(false);
                                        showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
                                    }
                                }
                            })
                            .catch(function (error) {
                                props.setIsLoadingBackDrop(false);
                                console.error('error saat fetch', error);
                                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
                            })
                    }
                }
            }

            emptyState();
            props.handleClick();

            props.setIsModal(false);
        }
        else {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
    };

    function Cancel() {
        emptyState();
        props.setDataCheck([]);
        props.handleClick();
    };

    const handleReset = () => {
        emptyState();
        props.getData(1, 50);
    };

    function CellDoubleClick(row) {
        Select();
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        props.getData(value, rowsCount, filter, sortArr);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        props.getData(1, value, filter, sortArr);
    }

    const mainContainer = (
        <>
            <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
                <div>
                    {
                        ButtonComponent.RefreshButton(
                            sortArr,
                            rowsCount,
                            props.columnData,
                            setGoTo,
                            handleReset,
                            setFilterBy,
                            filterBy,
                            setFilter,
                            setNumPage,
                            setRowsCount,
                            props.getData,
                            stateX,
                            setStateX
                        )
                    }
                </div>

                <div
                    className="rounded-10 table-responsive"
                    style={{
                        maxHeight: '375px',
                        marginTop: '16px',
                        padding: '2px 16px',
                        borderRadius: '12px',
                        background: '#F2F2F2'
                    }}
                >
                    {
                        props.isLoading ?
                            (
                                <LoadingSpinner />
                            )
                            :
                            (
                                <>
                                    <div className="mt-1">
                                        {
                                            props.Type === 2 || (props.Type === 1 && props.SettlementTo === 'P04') ?
                                                <TableCheck
                                                    uniqueKey={props.Type === 1 && props.SettlementTo === 'P04' ? 'eplDetailId' : 'id'}
                                                    goTo={goTo}
                                                    rowsCount={rowsCount}
                                                    setNumPage={setNumPage}
                                                    setRowsCount={setRowsCount}
                                                    getData={props.getData}
                                                    columnData={props.columnData}
                                                    dataMap={props.dataMap}
                                                    SelectedData={props.SelectedData}
                                                    setSelectedData={props.setSelectedData}
                                                    setFilter={setFilter}
                                                    setFilterBy={setFilterBy}
                                                    CellDoubleClick={CellDoubleClick}
                                                    stateX={stateX}
                                                    setStateX={setStateX}
                                                    sortArr={sortArr}
                                                    setSortArr={setSortArr}
                                                    setGoTo={setGoTo}
                                                    dataCheck={props.dataCheck}
                                                    setDataCheck={props.setDataCheck}
                                                />
                                                :
                                                <TableX 
                                                    uniqueKey={props.Type === 1 && props.SettlementTo === 'P04' ? 'eplDetailId' : 'id'}
                                                    goTo={goTo}
                                                    rowsCount={rowsCount}
                                                    setNumPage={setNumPage}
                                                    setRowsCount={setRowsCount}
                                                    getData={props.getData}
                                                    columnData={props.columnData}
                                                    dataMap={props.dataMap}
                                                    dataTable={props.dataTable}
                                                    SelectedData={props.SelectedData}
                                                    setSelectedData={props.setSelectedData}
                                                    setFilter={setFilter}
                                                    setFilterBy={setFilterBy}
                                                    CellDoubleClick={CellDoubleClick}
                                                    stateX={stateX}
                                                    setStateX={setStateX}
                                                    sortArr={sortArr}
                                                    setSortArr={setSortArr}
                                                    setGoTo={setGoTo}
                                                />
                                        }
                                    </div>
                                </>
                            )
                    }
                </div>

                <div style={{ marginTop: '16px' }}>
                    <Paginate
                        page={numPage}
                        limit={rowsCount}
                        totalData={props.totalRows}
                        onChange={onChangePaging}
                        onChangeLimit={onChangeLimit}
                    />
                </div>
            </Grid>
        </>
    );

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'account'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={'Select ' + props.titleModal}
                isOpen={props.isModal}
                onClose={Cancel}
                draggable={false}
                shrinkable={false}
                disableScrollLock={false}
                maxWidth={'lg'}
                fullWidth={true}
            >
                <div style={{ padding: '0px 6px' }}>
                    {mainContainer}

                    <div style={{ padding: '24px 0px 0px' }}>
                        {CustomizeButtonComponent.SelectModalButton(Select)}
                    </div>
                </div>
            </PopUp>
        </>
    )
}

export default SelectDataReference;