import React from 'react';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import Grid from '@mui/material/Grid';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';

const ButtonReceiptVoucher = ({
    isApprove,
    isUnApprove,
    DataRegUserAccess,
    ReloadData,
    AddRV,
    isDisabled,
    EditRV,
    DeleteData,
    Print,
    handleApprove,
    handleUnApprove,
    DropDownTitle,
    DropDownList,
    Approve,
    UnApprove,
    HandleStatus
}) => {
    const renderButton = (accessCode) => {
        switch (accessCode) {
            case 'CRE':
                return ButtonComponent.AddButton(AddRV, 'New', false, isDisabled);
            case 'REI':
                return ButtonComponent.EditButton(EditRV, 'Data');
            case 'DEL':
                return DropDownTitle !== 'Deleted' && ButtonComponent.DeleteButton(DeleteData, 'Data', false);
            case 'PRI':
                return DropDownTitle !== 'Deleted' && ButtonComponent.PrintButton(Print);
            case 'APP':
                return (DropDownTitle === 'All' || DropDownTitle === 'Active') && ButtonComponent.ApproveButton(handleApprove, 'Approve');
            case 'UPP':
                return (DropDownTitle === 'All' || DropDownTitle === 'Posted') && ButtonComponent.UnApproveButton(handleUnApprove, 'Un Approve');
            default:
                return null;
        }
    };

    const renderButtons = () => {
        if (isApprove) {
            return (
                <>
                    {ButtonComponent.BackButton(handleApprove)}
                    {ButtonComponent.ReloadButton(ReloadData)}
                    {DataRegUserAccess.map((data) =>
                        data.accessCode === 'APP' ? (
                            <React.Fragment key={data.accessId}>
                                {ButtonComponent.ApproveButton(Approve, 'Approve Data')}
                            </React.Fragment>
                        ) : null
                    )}
                </>
            );
        }

        if (isUnApprove) {
            return (
                <>
                    {ButtonComponent.BackButton(handleUnApprove)}
                    {ButtonComponent.ReloadButton(ReloadData)}
                    {DataRegUserAccess.map((data) =>
                        data.accessCode === 'UPP' ? (
                            <React.Fragment key={data.accessId}>
                                {ButtonComponent.UnApproveButton(UnApprove, 'Un Approve Data')}
                            </React.Fragment>
                        ) : null
                    )}
                </>
            );
        }

        return (
            <>
                {ButtonComponent.ReloadButton(ReloadData)}
                {DataRegUserAccess.map((data) => (
                    <React.Fragment key={data.accessId}>
                        {renderButton(data.accessCode)}
                    </React.Fragment>
                ))}
                {CustomizeButtonComponent.DropDownButtonStatus(HandleStatus, DropDownTitle, DropDownList)}
            </>
        );
    };

    return (
        <div className="row">
            <div className='col-12'>
                <Grid container item spacing={1} direction="row" style={{ maxWidth: '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
                    {renderButtons()}
                </Grid>
            </div>
        </div>
    );
};

export default ButtonReceiptVoucher;
