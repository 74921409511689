import React, { useEffect, useImperativeHandle, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ApiService, { branchId, companyId, countryId, isInfossNo, userCode, username } from '../../../../services/ApiService'
import { forwardRef } from 'react';
import { Box, CardContent, Grid, Card } from '@mui/material'
import ButtonCreateEditCashAdvSettlement from './Buttons/ButtonCreateEditCashAdvSettlement'
import Backdrop from '@mui/material/Backdrop';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import CashAdvSettlementHeader from './Components/CashAdvSettlementHeader';
import CashAdvSettlementDetail from './Components/CashAdvSettlementDetail';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import ButtonInfo from '../../../../components/Components/ButtonInfo';
import { setDateFormat, updateDateFormat } from '../../../../utils/date.jsx';
import { convertToNominal, convertToNumeric, newWindowConfig } from '../../../../utils/masks.jsx';
import CustomToastContainer, { showToast } from '../../../../components/ui/index.jsx';
import { PrintPDF } from '../../../../utils/reusableFunction.jsx';

const CreateEditCashAdvSettlement = forwardRef((props, ref) => {
    const dataLocal = JSON.parse(localStorage.getItem(username + "-DataCAS"));

    const { CashAdvSetId } = useParams()
    const history = useNavigate()
    const paramCashAdvSettlement = 'cashAdvanceSettlement/cashAdvanceSettlement'
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);

    const [CashAdvanceId, setCashAdvanceId] = useState(0);
    const [Reference, setReference] = useState('');
    const [EmployeeId, setEmployeeId] = useState(0);
    const [SettlementCashAdvanceNo, setSettlementCashAdvanceNo] = useState('');
    const [SettlementCashAdvanceNo2, setSettlementCashAdvanceNo2] = useState('');
    const [TotalUSD, setTotalUSD] = useState(0);
    const [TotalIDR, setTotalIDR] = useState(0);
    const [Rate, setRate] = useState(0);
    const [RateReal, setRateReal] = useState(0);
    const [ExRateDate, setExRateDate] = useState('');
    const [ExRateId, setExRateId] = useState(0);
    const [Approved, setApproved] = useState(false);
    const [Printing, setPrinting] = useState(0);
    const [PrintedOn, setPrintedOn] = useState('');
    const [Deleted, setDeleted] = useState(false);
    const [DeletedOn, setDeletedOn] = useState('');

    const [CashAdvanceNo, setCashAdvanceNo] = useState('');
    const [EmployeeCode, setEmployeeCode] = useState('');
    const [EmployeeName, setEmployeeName] = useState('');
    const [EmployeeNickname, setEmployeeNickname] = useState('');
    const [StatusCashAdvance, setStatusCashAdvance] = useState('Part');

    const [BalanceCAIDR, setBalanceCAIDR] = useState(0);
    const [BalanceCAUSD, setBalanceCAUSD] = useState(0);
    const [BalanceSettlementIDR, setBalanceSettlementIDR] = useState(0);
    const [BalanceSettlementUSD, setBalanceSettlementUSD] = useState(0);
    const [BalanceIDR, setBalanceIDR] = useState(0);
    const [BalanceUSD, setBalanceUSD] = useState(0);

    const [BalanceCAIDRReal, setBalanceCAIDRReal] = useState(0);
    const [BalanceCAUSDReal, setBalanceCAUSDReal] = useState(0);
    const [BalanceSettlementIDRReal, setBalanceSettlementIDRReal] = useState(0);
    const [BalanceSettlementUSDReal, setBalanceSettlementUSDReal] = useState(0);
    const [BalanceIDRReal, setBalanceIDRReal] = useState(0);
    const [BalanceUSDReal, setBalanceUSDReal] = useState(0);

    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);
    const [check, setCheck] = useState(0);

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');

    const [isEdit, setIsEdit] = useState(false);
    const [documentStatus, setDocumentStatus] = useState('');

    const [infossNo, setInfossNo] = useState('');

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Cash Advance Settlement', link: '/Majura/CashAdvanceSettlement', style: '' },
        { label: 'Create Cash Advance Settlement', link: '/Majura/CashAdvanceSettlement/Create', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    const BreadcrumbsEditItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Cash Advance Settlement', link: '/Majura/CashAdvanceSettlement', style: '' },
        { label: 'Detail Cash Advance Settlement', link: '/Majura/CashAdvanceSettlement/' + CashAdvSetId, style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useEffect(() => {
        document.title = 'Cash Advance Settlement - Detail - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [CashAdvSetId])

    useImperativeHandle(ref, () => ({
        localSave() {
            saveLocalData();
        }
    }));

    function handleDocumentStatus(rowStatus = 'ACT', isApproved = false) {
        if (rowStatus !== 'ACT') {
            setDocumentStatus('DELETED');
        }
        else if (isApproved) {
            setDocumentStatus('PAID');
        }
        else {
            setDocumentStatus('ONGOING');
        }
    }

    const saveLocalData = () => {
        let id = CashAdvSetId ? CashAdvSetId : 0;
        let datePrint = updateDateFormat(PrintedOn, true);
        let dateDelete = updateDateFormat(DeletedOn, true);
        let dateExRate = updateDateFormat(ExRateDate, true);

        const payload = {
            "cashAdvanceSettlement": {
                "rowStatus": "ACT",
                'noInfoss': infossNo,
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "id": id,
                "cashAdvanceId": CashAdvanceId,
                "cashAdvanceNo": CashAdvanceNo,
                "reference": Reference,
                "employeeId": EmployeeId,
                "settlementCashAdvanceNo": SettlementCashAdvanceNo,
                "settlementCashAdvanceNo2": SettlementCashAdvanceNo2,
                "totalUSD": parseFloat(TotalUSD),
                "totalIDR": parseFloat(TotalIDR),
                "rate": RateReal,
                "exRateDate": dateExRate,
                "exRateId": ExRateId,
                "approved": Approved,
                "printing": Printing,
                "printedOn": datePrint,
                "deleted": Deleted,
                "deletedOn": dateDelete,
                "idLama": 0,
                "userCode": userCode,
                "employeeCode": EmployeeCode,
                "employeeName": EmployeeName,
                "balanceCAIDR": BalanceCAIDRReal,
                "balanceCAUSD": BalanceCAUSDReal,
                "balanceCASIDR": BalanceSettlementIDRReal,
                "balanceCASUSD": BalanceSettlementUSDReal,
                "balanceIDR": BalanceIDRReal,
                "balanceUSD": BalanceUSDReal,
                "paidStatus": StatusCashAdvance,
            },
        };

        localStorage.setItem(username + "-DataCAS", JSON.stringify(payload));
    }

    const getLocalData = () => {
        let cashAdvanceSettlementData = dataLocal.cashAdvanceSettlement;

        if (CashAdvSetId) {
            setIsEdit(true);
        }
        setInfossNo(cashAdvanceSettlementData.noInfoss);
        setCashAdvanceId(cashAdvanceSettlementData.cashAdvanceId);
        setReference(cashAdvanceSettlementData.reference);
        setEmployeeId(cashAdvanceSettlementData.employeeId);
        setSettlementCashAdvanceNo(cashAdvanceSettlementData.settlementCashAdvanceNo);
        setSettlementCashAdvanceNo2(cashAdvanceSettlementData.settlementCashAdvanceNo2);
        setTotalUSD(cashAdvanceSettlementData.totalUSD);
        setTotalIDR(cashAdvanceSettlementData.totalIDR);
        handleDocumentStatus(cashAdvanceSettlementData.rowStatus, cashAdvanceSettlementData.printing > 0 ? true : false)
        let count = convertToNominal(cashAdvanceSettlementData.rate);
        setRate(count);
        setRateReal(cashAdvanceSettlementData.rate);
        setExRateId(cashAdvanceSettlementData.exRateId);
        setApproved(cashAdvanceSettlementData.approved);
        setPrinting(cashAdvanceSettlementData.printing);
        setDeleted(cashAdvanceSettlementData.deleted);

        setDateFormat(cashAdvanceSettlementData.exRateDate, setExRateDate);
        setDateFormat(cashAdvanceSettlementData.printedOn, setPrintedOn);
        setDateFormat(cashAdvanceSettlementData.deletedOn, setDeletedOn);

        setCashAdvanceNo(cashAdvanceSettlementData.cashAdvanceNo);
        setEmployeeCode(cashAdvanceSettlementData.employeeCode);
        setEmployeeName(cashAdvanceSettlementData.employeeName);
        setStatusCashAdvance(cashAdvanceSettlementData.paidStatus);

        let balanceCAIDR = convertToNominal(cashAdvanceSettlementData.balanceCAIDR);
        setBalanceCAIDR(balanceCAIDR);
        let balanceCAUSD = convertToNominal(cashAdvanceSettlementData.balanceCAUSD);
        setBalanceCAUSD(balanceCAUSD);
        let balanceSettleIDR = convertToNominal(cashAdvanceSettlementData.balanceCASIDR);
        setBalanceSettlementIDR(balanceSettleIDR);
        let balanceSettleUSD = convertToNominal(cashAdvanceSettlementData.balanceCASUSD);
        setBalanceSettlementUSD(balanceSettleUSD);
        let balanceIDR = convertToNominal(cashAdvanceSettlementData.balanceIDR);
        setBalanceIDR(balanceIDR);
        let balanceUSD = convertToNominal(cashAdvanceSettlementData.balanceUSD);
        setBalanceUSD(balanceUSD);

        setBalanceCAIDRReal(cashAdvanceSettlementData.balanceCAIDR);
        setBalanceCAUSDReal(cashAdvanceSettlementData.balanceCAUSD);
        setBalanceSettlementIDRReal(cashAdvanceSettlementData.balanceCASIDR - cashAdvanceSettlementData.totalIDR);
        setBalanceSettlementUSDReal(cashAdvanceSettlementData.balanceCASUSD - cashAdvanceSettlementData.totalUSD);
        setBalanceIDRReal(cashAdvanceSettlementData.balanceIDR);
        setBalanceUSDReal(cashAdvanceSettlementData.balanceUSD);

        calculateBalance(0, 0, cashAdvanceSettlementData.balanceCAIDR, cashAdvanceSettlementData.balanceCAUSD, cashAdvanceSettlementData.balanceCASIDR, cashAdvanceSettlementData.balanceCASUSD);

        localStorage.removeItem(username + '-DataCAS');
        setTimeout(function () { setIsLoadingBackDrop(false); }, 1250);
    }

    const getData = () => {
        ApiService.FinAccPostById(paramCashAdvSettlement, CashAdvSetId)
            .then((response) => {
                if (response.data.code === 200) {
                    setIsEdit(true);
                    let cashAdvanceSettlementData = response.data.data.cashAdvanceSettlement;
                    setInfossNo(cashAdvanceSettlementData.noInfoss);
                    setCashAdvanceId(cashAdvanceSettlementData.cashAdvanceId);
                    setCashAdvanceNo(cashAdvanceSettlementData.cashAdvanceNo);
                    setEmployeeCode(cashAdvanceSettlementData.employeeCode);
                    setEmployeeName(cashAdvanceSettlementData.employeeName);
                    setStatusCashAdvance(cashAdvanceSettlementData.paidStatus);
                    setReference(cashAdvanceSettlementData.reference);
                    setEmployeeId(cashAdvanceSettlementData.employeeId);
                    setSettlementCashAdvanceNo(cashAdvanceSettlementData.settlementCashAdvanceNo);
                    setSettlementCashAdvanceNo2(cashAdvanceSettlementData.settlementCashAdvanceNo2);
                    setTotalUSD(cashAdvanceSettlementData.totalUSD);
                    setTotalIDR(cashAdvanceSettlementData.totalIDR);
                    handleDocumentStatus(cashAdvanceSettlementData.rowStatus, cashAdvanceSettlementData.printing > 0 ? true : false)
                    let count = convertToNominal(cashAdvanceSettlementData.rate);
                    setRate(count);
                    setRateReal(cashAdvanceSettlementData.rate);
                    setExRateId(cashAdvanceSettlementData.exRateId);
                    setApproved(cashAdvanceSettlementData.approved);
                    setPrinting(cashAdvanceSettlementData.printing);
                    setDeleted(cashAdvanceSettlementData.deleted);

                    setDateFormat(cashAdvanceSettlementData.exRateDate, setExRateDate);
                    setDateFormat(cashAdvanceSettlementData.printedOn, setPrintedOn);
                    setDateFormat(cashAdvanceSettlementData.deletedOn, setDeletedOn);


                    setBalanceSettlementIDR(convertToNominal(cashAdvanceSettlementData.balanceCASIDR));
                    setBalanceSettlementUSD(convertToNominal(cashAdvanceSettlementData.balanceCASUSD));
                    setBalanceCAIDR(convertToNominal(cashAdvanceSettlementData.balanceCAIDR));
                    setBalanceCAUSD(convertToNominal(cashAdvanceSettlementData.balanceCAUSD));
                    setBalanceIDR(convertToNominal(cashAdvanceSettlementData.balanceIDR));
                    setBalanceUSD(convertToNominal(cashAdvanceSettlementData.balanceUSD));
                    setTotalUSD(cashAdvanceSettlementData.totalUSD);
                    setTotalIDR(cashAdvanceSettlementData.totalIDR);

                    setBalanceCAIDRReal(cashAdvanceSettlementData.balanceCAIDR);
                    setBalanceCAUSDReal(cashAdvanceSettlementData.balanceCAUSD);
                    setBalanceIDRReal(cashAdvanceSettlementData.balanceIDR);
                    setBalanceUSDReal(cashAdvanceSettlementData.balanceUSD);
                    setBalanceSettlementIDRReal((cashAdvanceSettlementData.balanceCASIDR - cashAdvanceSettlementData.totalIDR));
                    setBalanceSettlementUSDReal((cashAdvanceSettlementData.balanceCASUSD - cashAdvanceSettlementData.totalUSD));

                    calculateBalance(cashAdvanceSettlementData.totalIDR, cashAdvanceSettlementData.totalUSD, cashAdvanceSettlementData.balanceCAIDR, cashAdvanceSettlementData.balanceCAUSD, (cashAdvanceSettlementData.balanceCASIDR - cashAdvanceSettlementData.totalIDR), (cashAdvanceSettlementData.balanceCASUSD - cashAdvanceSettlementData.totalUSD));

                    if (!dataLocal) {
                        setIsLoadingBackDrop(false);
                    }
                }
                else {
                    handleBack();
                }
            })
            .catch(function (error) {
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
            })
    }

    const getDataAccess = () => {
        if (!dataLocal) {
            setIsLoadingBackDrop(true);
        }

        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false
        let tempArray = [];
        let checked = 0;

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'FCAS')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);
                        tempArray = response.data.data.userAccess;

                        tempArray.forEach(element => {
                            if (element.accessCode === 'CRE' || element.accessCode === 'UPD') {
                                checked = 1;
                            }
                        });

                        setCheck(checked);

                        if (dataLocal) {
                            setIsLoadingBackDrop(true);
                            setTimeout(function () { getLocalData(); }, 2000);
                        } else {
                            if (!CashAdvSetId) {
                                getDateExchRate();
                                handleDocumentStatus();
                            } else {
                                getData();
                            }
                        }
                    }
                    else {
                        handleBack();
                    }
                })
                .catch(function (error) {
                    setIsLoadingBackDrop(false);
                    console.error('error saat fetch', error)
                    history('/Majura');
                })
        }
        else {
            if (!dataLocal) {
                setIsLoadingBackDrop(false);
            }

            history('/Majura');
        }
    }

    const handleSave = (isPrint = false) => {
        setIsLoadingBackDrop(true);
        let id = CashAdvSetId ? CashAdvSetId : 0;
        let datePrint = updateDateFormat(PrintedOn, true);
        let dateDelete = updateDateFormat(DeletedOn, true);
        let dateExRate = updateDateFormat(ExRateDate, true);

        const payload = {
            "cashAdvanceSettlement": {
                "rowStatus": "ACT",
                'noInfoss': infossNo,
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "id": parseFloat(id),
                "cashAdvanceId": CashAdvanceId,
                "cashAdvanceNo": CashAdvanceNo,
                "employeeId": EmployeeId,

                "totalUSD": parseFloat(TotalUSD),
                "totalIDR": parseFloat(TotalIDR),
                "rate": RateReal,
                "exRateDate": dateExRate,
                "exRateId": ExRateId,
                "approved": Approved,
                "printing": Printing,
                "printedOn": datePrint,
                "deleted": Deleted,
                "deletedOn": dateDelete,
                "idLama": 0,
                "userCode": userCode,

                "employeeCode": EmployeeCode,
                "employeeName": EmployeeName,
                "balanceCAIDR": convertToNumeric(BalanceCAIDR),
                "balanceCAUSD": convertToNumeric(BalanceCAUSD),
                "balanceCASIDR": convertToNumeric(BalanceSettlementIDR),
                "balanceCASUSD": convertToNumeric(BalanceSettlementUSD),
                "balanceIDR": BalanceIDRReal,
                "balanceUSD": BalanceUSDReal,
                "paidStatus": StatusCashAdvance,
            },
        }

        let errorMessage = undefined;

        if (payload.cashAdvanceSettlement.cashAdvanceId === 0) {
            errorMessage = 'Please Select The Cash Advance Data!';
        }
        else if (payload.cashAdvanceSettlement.totalUSD === 0 && payload.cashAdvanceSettlement.totalIDR === 0) {
            errorMessage = 'Please Input Total Settlement!';
        }
        else if (payload.cashAdvanceSettlement.balanceIDR < 0 || payload.cashAdvanceSettlement.balanceUSD < 0) {
            errorMessage = 'The Balance Need to Be Positive!';
        }
        else if (payload.cashAdvanceSettlement.noInfoss === '' && isInfossNo) {
            errorMessage = 'Please Fill Infoss Number!';
        }


        if (errorMessage === undefined) {
            ApiService.HelpCreateData(payload, paramCashAdvSettlement, id)
                .then((res) => {
                    if (res.data.code === 200) {
                        let message = '';
                        if (!CashAdvSetId) {
                            ApiService.CreateFinAcc(paramCashAdvSettlement, payload).then((res) => {
                                if (res.data.code === 200) {
                                    message = 'Create Data Success';

                                    showToast({ type: 'success', message: message });

                                    setTimeout(
                                        function () {
                                            setIsLoadingBackDrop(false);
                                            localStorage.setItem('tempId', JSON.stringify(res.data.data.Id));
                                            history('/Majura/CashAdvanceSettlement/' + JSON.parse(localStorage.getItem("tempId")), { replace: true });
                                            window.location.reload();
                                            localStorage.removeItem('tempId');
                                        },
                                        1250);
                                }
                                else {
                                    setIsLoadingBackDrop(false);

                                    showToast({ type: 'error', message: res.data.message });
                                }
                            });
                        }
                        else {
                            ApiService.EditFinAcc(paramCashAdvSettlement, payload).then((res) => {
                                if (res.data.code === 200) {
                                    if (!isPrint) {
                                        message = 'Update Data Success';

                                        showToast({ type: 'success', message: message });

                                        setTimeout(
                                            function () {
                                                setIsLoadingBackDrop(false);
                                                history('/Majura/CashAdvanceSettlement/' + CashAdvSetId, { replace: true });
                                                window.location.reload();
                                            },
                                            1250);
                                    }
                                    else {
                                        Print();
                                    }
                                }
                                else {
                                    setIsLoadingBackDrop(false);

                                    showToast({ type: 'error', message: res.data.message });
                                }
                            });
                        }
                    }
                    else {
                        setIsLoadingBackDrop(false);

                        showToast({ type: 'error', message: 'Failed to Create Help Data, ' + res.data.message });
                    }
                })
                .catch(function (error) {
                    setIsLoadingBackDrop(false);

                    showToast({ type: 'error', message: 'You Don\'t Have Access, Please Contact The Administrator, ' + error });
                })
        }
        else {
            setIsLoadingBackDrop(false);
            showToast({ type: 'error', message: errorMessage });
        }
    }

    const handleBack = () => {
        history('/Majura/CashAdvanceSettlement');
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationCAS() {
        if (titleConfirmation === 'Print') {
            checkDataCondition('Print');
        }
        setIsModalConfirmation(false);
    }

    const checkDataCondition = async (isAction) => {
        if (!CashAdvSetId && isAction !== 'Save') {
            showToast({ type: 'error', message: 'Create The Data First!' });
            return;
        }

        try {
            setIsLoadingBackDrop(true);

            if (isAction === 'Save' && !CashAdvSetId) {
                handleSave(false);
                return;
            }
            
            const response = CashAdvSetId ? await ApiService.FinAccPostById(paramCashAdvSettlement, CashAdvSetId) : null;

            if (response && response.data.code !== 200) {
                throw new Error('Failed to fetch data');
            }

            const data = response ? response.data.data.cashAdvanceSettlement : null;

            const showErrorToast = (message) => {
                showToast({ type: 'error', message });
            };

            const handlePrintPDF = () => {
                getData();
                setTitleConfirmation('Print');
                setTitleModalConfirmation('Cash Advance Settlement');
                handleClickConfirmation();
            };

            switch (isAction) {
                case 'Save':
                    if (data && data.printing > 0) {
                        getData();
                        showErrorToast('Data Already Printed, Cannot Be Edited!');
                    } else {
                        handleSave(false);
                    }
                    break;
                case 'Print':
                    getData();
                    data?.printing > 0 ? Print() : handleSave(true);
                    break;
                case 'PrintPDF':
                    handlePrintPDF();
                    break;
                default:
                    showErrorToast('Invalid Action!');
                    break;
            }
        } catch (error) {
            console.error('Error when fetching data:', error);
            showToast({ type: 'error', message: 'Error When Fetching Data, Please Contact The Administrator!' });
        }
    };


    const Print = () => {
        ApiService.FinAccUpdateStatusPrint(paramCashAdvSettlement, CashAdvSetId)
            .then((res) => {
                let message = '';
                message = res.data.message;
                if (res.data.code !== 200) {
                    showToast({ type: 'error', message: message });
                }
                else {
                    setIsLoadingBackDrop(true);
                    let reportLink = 'Transactions/Finance/CashAdvanceSettlementReport/CashAdvanceSettlementReport/PrintCashAdvanceSettlement?Id=' + CashAdvSetId;
                    PrintPDF(setIsLoadingBackDrop, 'Cash Advance Settlement', reportLink, [], true);
                }
            });
    }

    const AddNew = () => {
        window.open('/Majura/CashAdvanceSettlement/Create', newWindowConfig);
    }

    const getDateExchRate = () => {
        ApiService.PostByExRateDate()
            .then((response) => {
                if (response.data.code === 200) {
                    setExRateId(response.data.data.exchangeRate.id);
                    setRate(convertToNominal(response.data.data.exchangeRate.exRate1));
                    setRateReal(response.data.data.exchangeRate.exRate1);
                    setDateFormat(response.data.data.exchangeRate.exRateDate, setExRateDate);

                    GetFormatCurrency();
                }
                else {
                    handleBack();
                }
            })
            .catch(function (error) {
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator!' });
            })
    }

    const GetFormatCurrency = () => {

        setBalanceCAIDR(convertToNominal(0));
        setBalanceCAUSD(convertToNominal(0));
        setBalanceSettlementIDR(convertToNominal(0));
        setBalanceSettlementUSD(convertToNominal(0));
        setBalanceIDR(convertToNominal(0));
        setBalanceUSD(convertToNominal(0));

        if (!dataLocal) {
            setIsLoadingBackDrop(false);
        }
    }

    function calculateBalance(TotalIDR, TotalUSD, BalanceCAIDR, BalanceCAUSD, BalanceSetIDR, BalanceSetUSD) {
        let temp1 = 0;
        let temp2 = 0;
        temp1 = parseFloat(BalanceCAIDR) - parseFloat(BalanceSetIDR) - parseFloat(TotalIDR);
        temp2 = parseFloat(BalanceCAUSD) - parseFloat(BalanceSetUSD) - parseFloat(TotalUSD);

        setBalanceIDRReal(temp1);
        setBalanceUSDReal(temp2);
        setBalanceIDR(convertToNominal(temp1));
        setBalanceUSD(convertToNominal(temp2));
    }

    const ButtonComponents = () => {
        return (
            <ButtonCreateEditCashAdvSettlement
                Save={() => checkDataCondition('Save')}
                Back={handleBack}
                Print={() => checkDataCondition('PrintPDF')}
                DataRegUserAccess={DataRegUserAccess}
                check={check}
                isEdit={isEdit}
                AddNew={AddNew}
                Printing={Printing}
            />
        )
    }

    return (
        <>
            <Box sx={{ backgroundColor: '#E4E8EC', width: '100%', p: '24px 32px' }}>
                <CustomToastContainer />
                <Grid container spacing={0} direction="column">
                    <HeaderInfo
                        title={
                            CashAdvSetId ?
                                'Cash Advance Settlement (' + Reference + ')'
                                :
                                'Cash Advance Settlement ( New Document )'
                        }
                        BreadcrumbsItems={CashAdvSetId ? BreadcrumbsEditItems : BreadcrumbsItems}
                        type={'Form'}
                        ticketId={0}
                        BackFunction={handleBack}
                        documentStatus={documentStatus}
                    />

                    <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                        <CardContent>
                            <CashAdvSettlementHeader
                                infossNo={infossNo}
                                setInfossNo={setInfossNo}
                                CashAdvanceId={CashAdvanceId}
                                setCashAdvanceId={setCashAdvanceId}
                                Reference={Reference}
                                setReference={setReference}
                                EmployeeId={EmployeeId}
                                setEmployeeId={setEmployeeId}
                                SettlementCashAdvanceNo={SettlementCashAdvanceNo}
                                setSettlementCashAdvanceNo={setSettlementCashAdvanceNo}
                                SettlementCashAdvanceNo2={SettlementCashAdvanceNo2}
                                setSettlementCashAdvanceNo2={setSettlementCashAdvanceNo2}
                                Rate={Rate}
                                setRate={setRate}
                                ExRateDate={ExRateDate}
                                setExRateDate={setExRateDate}
                                ExRateId={ExRateId}
                                setExRateId={setExRateId}
                                Approved={Approved}
                                setApproved={setApproved}
                                Printing={Printing}
                                setPrinting={setPrinting}
                                PrintedOn={PrintedOn}
                                setPrintedOn={setPrintedOn}
                                CashAdvanceNo={CashAdvanceNo}
                                setCashAdvanceNo={setCashAdvanceNo}
                                EmployeeCode={EmployeeCode}
                                setEmployeeCode={setEmployeeCode}
                                EmployeeName={EmployeeName}
                                setEmployeeName={setEmployeeName}
                                EmployeeNickname={EmployeeNickname}
                                setEmployeeNickname={setEmployeeNickname}
                                StatusCashAdvance={StatusCashAdvance}
                                setStatusCashAdvance={setStatusCashAdvance}
                                TotalUSD={TotalUSD}
                                setTotalUSD={setTotalUSD}
                                TotalIDR={TotalIDR}
                                setTotalIDR={setTotalIDR}
                                setBalanceCAIDR={setBalanceCAIDR}
                                setBalanceCAUSD={setBalanceCAUSD}
                                setBalanceCAIDRReal={setBalanceCAIDRReal}
                                setBalanceCAUSDReal={setBalanceCAUSDReal}
                                setBalanceSettlementIDR={setBalanceSettlementIDR}
                                setBalanceSettlementUSD={setBalanceSettlementUSD}
                                setBalanceSettlementIDRReal={setBalanceSettlementIDRReal}
                                setBalanceSettlementUSDReal={setBalanceSettlementUSDReal}
                                setBalanceIDR={setBalanceIDR}
                                setBalanceUSD={setBalanceUSD}
                                calculateBalance={calculateBalance}
                                isEdit={isEdit}
                            />
                        </CardContent>
                    </Card>

                    <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                        <CardContent>
                            <CashAdvSettlementDetail
                                EmployeeId={EmployeeId}
                                TotalUSD={TotalUSD}
                                setTotalUSD={setTotalUSD}
                                TotalIDR={TotalIDR}
                                setTotalIDR={setTotalIDR}
                                StatusCashAdvance={StatusCashAdvance}
                                CashAdvanceId={CashAdvanceId}
                                BalanceCAIDR={BalanceCAIDR}
                                setBalanceCAIDR={setBalanceCAIDR}
                                BalanceCAUSD={BalanceCAUSD}
                                setBalanceCAUSD={setBalanceCAUSD}
                                BalanceSettlementIDR={BalanceSettlementIDR}
                                setBalanceSettlementIDR={setBalanceSettlementIDR}
                                BalanceSettlementIDRReal={BalanceSettlementIDRReal}
                                BalanceSettlementUSDReal={BalanceSettlementUSDReal}
                                BalanceCAIDRReal={BalanceCAIDRReal}
                                BalanceCAUSDReal={BalanceCAUSDReal}
                                BalanceSettlementUSD={BalanceSettlementUSD}
                                setBalanceSettlementUSD={setBalanceSettlementUSD}
                                BalanceIDR={BalanceIDR}
                                setBalanceIDR={setBalanceIDR}
                                BalanceUSD={BalanceUSD}
                                setBalanceUSD={setBalanceUSD}
                                setBalanceSettlementIDRReal={setBalanceSettlementIDRReal}
                                setBalanceSettlementUSDReal={setBalanceSettlementUSDReal}
                                calculateBalance={calculateBalance}
                                isEdit={isEdit}
                                Printing={Printing}
                            />
                        </CardContent>
                    </Card>
                </Grid>

                {
                    isModalConfirmation && (
                        <ModalConfirmation
                            isModal={isModalConfirmation}
                            titleModal={titleModalConfirmation}
                            title={titleConfirmation}
                            handleClick={handleClickConfirmation}
                            ConfirmationData={ConfirmationCAS}
                        />
                    )
                }
            </Box>

            <ButtonInfo
                ButtonComponents={ButtonComponents}
            />

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
})
export default CreateEditCashAdvSettlement