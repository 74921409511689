import React from 'react';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import Grid from '@mui/material/Grid';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';

const ButtonTemporaryPayment = ({
    isApprove,
    isUnApprove,
    ReloadData,
    DataRegUserAccess,
    DropDownTitle,
    AddTP,
    isDisabled,
    EditTP,
    DeleteData,
    Print,
    handleApprove,
    handleUnApprove,
    HandleStatus,
    DropDownList,
    Approve,
    UnApprove
}) => {

    const renderButton = (data) => {
        switch (data.accessCode) {
            case 'CRE':
                return ButtonComponent.AddButton(AddTP, 'New', false, isDisabled);
            case 'REI':
                return ButtonComponent.EditButton(EditTP, 'Data');
            case 'DEL':
                return DropDownTitle !== 'Deleted' ? ButtonComponent.DeleteButton(DeleteData, 'Data', false) : null;
            case 'PRI':
                return DropDownTitle !== 'Deleted' ? ButtonComponent.PrintButton(Print) : null;
            case 'APP':
                return (DropDownTitle === 'All' || DropDownTitle === 'Active') ? ButtonComponent.ApproveButton(handleApprove, 'Approve') : null;
            case 'UPP':
                return (DropDownTitle === 'All' || DropDownTitle === 'Posted') ? ButtonComponent.UnApproveButton(handleUnApprove, 'Un Approve') : null;
            default:
                return null;
        }
    };

    return (
        <div className="row">
            <div className='col-12'>
                <Grid container item spacing={1} direction="row" style={{ maxWidth: '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
                    {!isApprove && !isUnApprove ? (
                        <>
                            {ButtonComponent.ReloadButton(ReloadData)}

                            {DataRegUserAccess.map(data => (
                                <React.Fragment key={data.accessId}>
                                    {renderButton(data)}
                                </React.Fragment>
                            ))}

                            {CustomizeButtonComponent.DropDownButtonStatus(HandleStatus, DropDownTitle, DropDownList)}
                        </>
                    ) : isApprove ? (
                        <>
                            {ButtonComponent.BackButton(handleApprove)}
                            {ButtonComponent.ReloadButton(ReloadData)}

                            {DataRegUserAccess.map(data => (
                                data.accessCode === 'APP' && (
                                    <React.Fragment key={data.accessId}>
                                        {ButtonComponent.ApproveButton(Approve, 'Approve Data')}
                                    </React.Fragment>
                                )
                            ))}
                        </>
                    ) : (
                        <>
                            {ButtonComponent.BackButton(handleUnApprove)}
                            {ButtonComponent.ReloadButton(ReloadData)}

                            {DataRegUserAccess.map(data => (
                                data.accessCode === 'UPP' && (
                                    <React.Fragment key={data.accessId}>
                                        {ButtonComponent.UnApproveButton(UnApprove, 'Un Approve Data')}
                                    </React.Fragment>
                                )
                            ))}
                        </>
                    )}
                </Grid>
            </div>
        </div>
    );
};

export default ButtonTemporaryPayment;
