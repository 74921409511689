import { Backdrop, Box, Grid, MenuItem, Card, CardContent, Stack, InputAdornment, IconButton } from '@mui/material'
import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react';

import ApiService from '../../../../services/ApiService';
import SelectDataContact from './Selects/SelectDataContact';
import SelectDataAccount from './Selects/SelectDataAccount';
import ButtonRegisterBiayaView from './Buttons/ButtonRegisterBiayaView';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { CustomDatePicker, Icon, Input, Selection, showToast } from '../../../../components/ui';
import { encryptFunction, handleDataTable, PrintExcel } from '../../../../utils/reusableFunction';

const RegisterBiayaView = forwardRef((props, ref) => {
    const companyName = JSON.parse(localStorage.getItem("companyName"));

    const [isLoadingPrint, setIsLoadingPrint] = useState(true);
    const [customerTypeId, setCustomerTypeId] = useState(0);
    const [isAllCustomer, setIsAllCustomer] = useState(true);
    const [customerId, setCustomerId] = useState(0);
    const [customerCode, setCustomerCode] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [accountTypeId, setAccountTypeId] = useState(0);
    const [accountId, setAccountId] = useState(0);
    const [accountCode, setAccountCode] = useState('');
    const [accountName, setAccountName] = useState('');
    const [isPerClosingPeriod, setIsPerClosingPeriod] = useState(false);
    const [isCustom, setIsCustom] = useState(0);
    const [period, setPeriod] = useState(`${new Date().getFullYear()}-${new Date().getMonth() + 1}`);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const paramC = "regContact/regContact";
    const [isLoadingC, setIsLoadingC] = useState(false);
    const [titleModalC, setTitleModalC] = useState('');
    const [isModalC, setIsModalC] = useState(false);
    const [dataC, setDataC] = useState([]);
    const [dataMapC, setDataMapC] = useState([]);
    const [dataTableC, setDataTableC] = useState([]);
    const [SelectedDataC, setSelectedDataC] = useState({});
    const [totalRowsC, setTotalRowsC] = useState(50);
    const [columnDataC, setColumnDataC] = useState([]);

    const paramA = "account/account";
    const [isLoadingA, setIsLoadingA] = useState(false);
    const [titleModalA, setTitleModalA] = useState('');
    const [isModalA, setIsModalA] = useState(false);
    const [dataA, setDataA] = useState([]);
    const [dataMapA, setDataMapA] = useState([]);
    const [dataTableA, setDataTableA] = useState([]);
    const [columnDataA, setColumnDataA] = useState([]);
    const [SelectedDataA, setSelectedDataA] = useState({});
    const [totalRowsA, setTotalRowsA] = useState(50);

    const customerTypeList = [
        { id: 1, code: 0, name: 'All Type' },
        { id: 2, code: 4, name: 'SSLine' },
        { id: 1, code: 5, name: 'IATA' },
        { id: 2, code: 6, name: 'EMKL' },
        { id: 1, code: 2, name: 'Discount Shipper' },
        { id: 2, code: 7, name: 'Depo' },
        { id: 2, code: 3, name: 'Discount Consignee' },
    ];

    const allCustomerList = [
        { id: 1, code: true, name: 'All' },
        { id: 2, code: false, name: 'Custom' },
    ];

    const accountTypeList = [
        { id: 1, code: 0, name: 'ALL' },
        { id: 2, code: 1, name: 'SEA' },
        { id: 2, code: 2, name: 'AIR' },
    ];

    const optionReportList = [
        { id: 1, code: false, name: 'Monthly' },
        { id: 2, code: true, name: 'Custom Periode' },
    ]

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Register Biaya Report', link: '/Majura/RegisterBiayaReport', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'Register Biaya Report - ' + JSON.parse(localStorage.getItem('branchName'));
        setTimeout(function () { setIsLoadingPrint(false); }, 2000);
    }, []);

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationRB() {
        if (titleConfirmation === 'Print Excel') {
            PrintReport("xls")
        }
        setIsModalConfirmation(false);
    }

    const checkInput = () => {
        let check = 0;

        if (isAllCustomer === false && customerId === 0) {
            check = 1;
        }
        else if (accountTypeId !== 0 && accountId === 0) {
            check = 2;
        }

        if (check === 1) {
            showToast({ type: 'error', message: 'Please Select Data Customer!' })
        }
        else if (check === 2) {
            showToast({ type: 'error', message: 'Please Select Data Account!' })
        }

        return check;
    }

    const PrintData = () => {
        if (checkInput() === 0) {
            setTitleConfirmation('Print Excel')
            setTitleModalConfirmation('Register Biaya Report');
            handleClickConfirmation();
        }
    };

    const handleCustomerTypeId = (value) => {
        setCustomerTypeId(value);
        setIsAllCustomer(true);

        setCustomerId(0);
        setCustomerCode('');
        setCustomerName('');
    }

    const handleIsAllCustomer = (value) => {
        setIsAllCustomer(value);

        if (value === true) {
            setCustomerId(0);
            setCustomerCode('');
            setCustomerName('');
        }
    }

    const handleAccountTypeId = (value) => {
        setAccountTypeId(value);

        setAccountId(0);
        setAccountCode('');
        setAccountName('');
    }

    const handleIsClosingPeriod = (value) => {
        setIsPerClosingPeriod(value);

        if (value === true) {
            setIsCustom(1);
            getDateNow();
        }
        else {
            setIsCustom(0);
            let date = new Date();
            setPeriod(`${date.getFullYear()}-${date.getMonth() + 1}`);
        }
    }

    function getDateNow() {
        let dateNow = new Date()
        var formatDateNow = dateNow.toLocaleDateString("sv-se").split('T')[0];
        setStartDate(formatDateNow);
        setEndDate(formatDateNow);
    }

    function handleDate(source, value) {
        if (source === 'From') {
            if (value > endDate) {
                showToast({ type: 'error', message: 'Period From Can\'t Be Greater Than Period To' })
            }
            else {
                setStartDate(value);
            }
        }
        else if (source === 'To') {
            if (value < startDate) {
                showToast({ type: 'error', message: 'Period To Can\'t Be Smaller Than Period From' })
            }
            else {
                setEndDate(value);
            }
        }
    }

    const handleClickContact = () => {
        if (isModalC === false) {
            setIsModalC(true);
            getDataContact(1, 50);
        }
        else {
            setIsModalC(false);
        }
    };

    const searchCustomer = (data) => {
        if (data === 'Search') {
            setTitleModalC('Contact');
            handleClickContact();
        }
        else if (data === 'Delete') {
            setCustomerId(0);
            setCustomerCode('');
            setCustomerName('');
        }
    };

    const handleClickAccount = () => {
        if (isModalA === false) {
            setIsModalA(true);
            getDataAccount(1, 50);
        }
        else {
            setIsModalA(false);
        }
    };

    const searchAccount = (data) => {
        if (data === 'Search') {
            setTitleModalA('Account');
            handleClickAccount();
        }
        else if (data === 'Delete') {
            setAccountId(0);
            setAccountCode('');
            setAccountName('');
        }
    };

    const getDataContact = (pageNumber, pageSize, filters, orderBy) => {
        setIsLoadingC(true);
        ApiService.ContactPostByPage(paramC, customerTypeId, pageNumber, pageSize, filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataC({})
                    setDataC(response.data.data.contact);

                    let temp = response.data.data.contact
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapC(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTableC, 'id');
                    setColumnDataC(response.data.data.columns)
                    setTotalRowsC(response.data.totalRowCount)

                    setIsLoadingC(false)
                }
                setIsLoadingC(false)
            })
            .catch(function (error) {
                setIsLoadingC(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() })
            })
    };

    const getDataAccount = (pageNumber, pageSize, filters) => {
        setIsLoadingA(true);
        let params = [];
        if (accountTypeId === 1) {
            params = [...params, { 'attr': 'Type', 'value': 'SEA' }];
        }
        else if (accountTypeId === 2) {
            params = [...params, { 'attr': 'Type', 'value': 'AIR' }];
        }
        ApiService.DynamicPostByPage(paramA, 'SEARCH', pageNumber, pageSize, params, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataA({})
                    setDataA(response.data.data.account);

                    let temp = response.data.data.account
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapA(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTableA, 'id');
                    setColumnDataA(response.data.data.columns)
                    setTotalRowsA(response.data.totalRowCount)

                    setIsLoadingA(false)
                }
                setIsLoadingA(false)
            })
            .catch(function (error) {
                setIsLoadingA(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() })
            })
    };

    const PrintReport = (code) => {
        setIsLoadingPrint(true);
        let dateStart = '';
        let dateEnd = '';
        let accountType = '';

        if (isPerClosingPeriod === true) {
            dateStart = startDate;
            dateEnd = endDate;
        }
        else {
            let date = new Date(period);
            dateStart = new Date(date.getFullYear(), date.getMonth(), 1).toLocaleDateString("sv-se").split('T')[0];
            dateEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0).toLocaleDateString("sv-se").split('T')[0];
        }

        accountTypeList.forEach(element => {
            if (element.code === accountTypeId) {
                accountType = element.name;
            }
        });

        let params = [
            {
                "attr": "CustomerTypeId",
                "value": '' + customerTypeId
            },
            {
                "attr": "AccountType",
                "value": "" + accountType
            },
            {
                "attr": "AccountId",
                "value": '' + accountId
            },
            {
                "attr": "CustomerId",
                "value": '' + customerId
            },
            {
                "attr": "IsCustom",
                "value": '' + isCustom
            },
            {
                "attr": "StartDate",
                "value": dateStart
            },
            {
                "attr": "EndDate",
                "value": dateEnd
            }
        ];

        let reportLink = 'Reports/Finance/Print/Other/RegisterBiayaReport/RegisterBiayaReport/PrintRegisterBiaya?payloads=' + encryptFunction(params);
        PrintExcel(setIsLoadingPrint, 'Register Biaya', reportLink);
    };

    const ButtonComponents = () => {
        return (
            <ButtonRegisterBiayaView PrintData={PrintData} />
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: 3 }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    title={'Register Biaya Report'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div style={{ marginTop: '24px' }}>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                                    <CardContent>
                                        <Input
                                            disabled
                                            label="Company"
                                            variant="standard"
                                            value={companyName || ''}
                                            InputLabelProps={{ shrink: true }}
                                            formControlProps={{ sx: { width: '100%', } }}
                                        />

                                        <Grid
                                            container
                                            spacing={'32px'}
                                        >
                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Selection
                                                    id='0'
                                                    fullWidth={true}
                                                    label="Customer Type"
                                                    value={customerTypeId}
                                                    placeholder={'Select Customer Type'}
                                                    formControlProps={{ sx: { marginTop: '16px' } }}
                                                    onChange={(e) => handleCustomerTypeId(e.target.value)}
                                                >
                                                    {
                                                        customerTypeList.map((data) => (
                                                            <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                                                                {data.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Selection>
                                            </Grid>

                                            <Grid
                                                item
                                                xl={6}
                                                lg={6}
                                                sm={6}
                                                xs={12}
                                            >
                                                <Selection
                                                    id='0'
                                                    fullWidth={true}
                                                    label="All Customer"
                                                    value={isAllCustomer}
                                                    placeholder={'Select All Customer'}
                                                    formControlProps={{ sx: { marginTop: '16px' } }}
                                                    disabled={customerTypeId === 0 ? true : false}
                                                    onChange={(e) => handleIsAllCustomer(e.target.value)}
                                                >
                                                    {
                                                        allCustomerList.map((data) => (
                                                            <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                                                                {data.name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Selection>
                                            </Grid>
                                        </Grid>

                                        <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }} hidden={isAllCustomer}>
                                            <Input
                                                readOnly
                                                fullWidth
                                                variant="standard"
                                                value={customerCode}
                                                label='Customer Code'
                                                disabled
                                                InputLabelProps={{ shrink: true }}
                                            />

                                            <Input
                                                label=""
                                                variant="standard"
                                                value={customerName}
                                                InputLabelProps={{ shrink: true }}
                                                formControlProps={{ sx: { width: '75%', } }}
                                                disabled
                                                inputBaseProps={{
                                                    readOnly: true,
                                                    sx: { marginTop: '23px !important' },
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => searchCustomer('Delete')}
                                                                hidden={customerId !== 0 ? false : true}
                                                            >
                                                                <Icon
                                                                    iconName={'close'}
                                                                    svgIconProps={{
                                                                        fontSize: 'small',
                                                                        htmlColor: '#0087C2',
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>

                                                            <IconButton
                                                                onClick={() => searchCustomer('Search')}
                                                            >
                                                                <Icon
                                                                    iconName={'search'}
                                                                    svgIconProps={{
                                                                        fontSize: 'small',
                                                                        htmlColor: '#0087C2',
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Stack>
                                    </CardContent>
                                </Card>

                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px', marginTop: '24px' }}>
                                    <CardContent>
                                        <Selection
                                            id='0'
                                            fullWidth={true}
                                            label="Option Report"
                                            value={isPerClosingPeriod}
                                            placeholder={'Select Option Report'}
                                            onChange={(e) => handleIsClosingPeriod(e.target.value)}
                                        >
                                            {
                                                optionReportList.map((data) => (
                                                    <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                                                        {data.name}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Selection>

                                        <div hidden={isPerClosingPeriod} style={{ marginTop: '16px' }}>
                                            <CustomDatePicker
                                                fullWidth
                                                type='month'
                                                label="Period"
                                                value={period}
                                                variant="standard"
                                                InputLabelProps={{ shrink: true }}
                                                onChange={(e) => setPeriod(e)}
                                                formControlProps={{ sx: { width: '100%', } }}
                                            />
                                        </div>

                                        <div hidden={!isPerClosingPeriod} style={{ marginTop: '16px' }}>
                                            <Stack spacing={'32px'} direction="row">
                                                <CustomDatePicker
                                                    fullWidth
                                                    type='date'
                                                    value={startDate}
                                                    variant="standard"
                                                    label="Custom Period From"
                                                    InputLabelProps={{ shrink: true }}
                                                    formControlProps={{ sx: { width: '100%', } }}
                                                    onChange={(e) => handleDate('From', e)}
                                                />

                                                <CustomDatePicker
                                                    fullWidth
                                                    type='date'
                                                    value={endDate}
                                                    variant="standard"
                                                    label="Custom Period To"
                                                    InputLabelProps={{ shrink: true }}
                                                    formControlProps={{ sx: { width: '100%', } }}
                                                    onChange={(e) => handleDate('To', e)}
                                                />
                                            </Stack>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>

                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Card component="div" style={{ borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '16px 24px' }}>
                                    <CardContent>
                                        <Selection
                                            id='0'
                                            fullWidth={true}
                                            label="Account Type"
                                            value={accountTypeId}
                                            placeholder={'Select Account Type'}
                                            onChange={(e) => handleAccountTypeId(e.target.value)}
                                        >
                                            {
                                                accountTypeList.map((data) => (
                                                    <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                                                        {data.name}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Selection>

                                        <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                                            <Input
                                                readOnly
                                                fullWidth
                                                label='Account'
                                                variant="standard"
                                                value={accountCode}
                                                InputLabelProps={{ shrink: true }}
                                                disabled
                                            />

                                            <Input
                                                label=""
                                                variant="standard"
                                                value={accountName}
                                                InputLabelProps={{ shrink: true }}
                                                formControlProps={{ sx: { width: '75%', } }}
                                                disabled
                                                inputBaseProps={{
                                                    readOnly: true,
                                                    sx: { marginTop: '23px !important' },
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => searchAccount('Delete')}
                                                                disabled={accountTypeId === 0 ? true : false}
                                                                hidden={accountId !== 0 ? false : true}
                                                            >
                                                                <Icon
                                                                    iconName={'close'}
                                                                    svgIconProps={{
                                                                        fontSize: 'small',
                                                                        htmlColor: '#0087C2',
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>

                                                            <IconButton
                                                                onClick={() => searchAccount('Search')}
                                                                hidden={accountTypeId === 0 ? true : false}
                                                            >
                                                                <Icon
                                                                    iconName={'search'}
                                                                    svgIconProps={{
                                                                        fontSize: 'small',
                                                                        htmlColor: '#0087C2',
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Stack>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            {
                isLoadingPrint && (
                    <Backdrop
                        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingPrint}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }

            {
                isModalC && (
                    <SelectDataContact
                        isModal={isModalC}
                        handleClick={handleClickContact}
                        titleModal={titleModalC}
                        setIsModal={setIsModalC}

                        getData={getDataContact}
                        isLoading={isLoadingC}
                        totalRows={totalRowsC}
                        setTotalRows={setTotalRowsC}
                        SelectedData={SelectedDataC}
                        setSelectedData={setSelectedDataC}
                        data={dataC}
                        columnData={columnDataC}
                        dataMap={dataMapC}
                        dataTable={dataTableC}

                        setCustomerId={setCustomerId}
                        setCustomerCode={setCustomerCode}
                        setCustomerName={setCustomerName}
                    />
                )
            }

            {
                isModalA && (
                    <SelectDataAccount
                        isModal={isModalA}
                        handleClick={handleClickAccount}
                        titleModal={titleModalA}
                        setIsModal={setIsModalA}

                        getData={getDataAccount}
                        isLoading={isLoadingA}
                        totalRows={totalRowsA}
                        setTotalRows={setTotalRowsA}
                        SelectedData={SelectedDataA}
                        setSelectedData={setSelectedDataA}
                        data={dataA}
                        columnData={columnDataA}
                        dataMap={dataMapA}
                        dataTable={dataTableA}

                        setAccountId={setAccountId}
                        setAccountCode={setAccountCode}
                        setAccountName={setAccountName}
                    />
                )
            }

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationRB}
                    />
                )
            }
        </Box>
    )
})

export default RegisterBiayaView