import { Grid, IconButton, MenuItem, Stack, InputAdornment, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { formatCurrency } from '../../../../../services/ApiService';
import DollarInput, { convertMask } from '../../../../../components/Masks/DollarInput';
import RupiahInput, { convertMaskIDR } from '../../../../../components/Masks/RupiahInput';

import ApiService, { branchId, companyId, countryId, userId, userCode } from '../../../../../services/ApiService';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';
import SelectDataCOA from '../Selects/SelectDataCOA';
import { CustomDatePicker, Icon, Input, PopUp, Selection, showToast, TickBox } from '../../../../../components/ui';
import { convertToNumeric } from '../../../../../utils/masks';
import { updateDateFormat } from '../../../../../utils/date';
import { handleDataTable } from '../../../../../utils/reusableFunction';

const ModalBank = (props) => {
    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);

    const paramC = "regChartOfAccount/regChartOfAccount";
    const [isLoadingC, setIsLoadingC] = useState(false);
    const [dataC, setDataC] = useState([]);
    const [dataMapC, setDataMapC] = useState([]);
    const [dataTableC, setDataTableC] = useState([]);
    const [columnDataC, setColumnDataC] = useState([]);
    const [SelectedDataC, setSelectedDataC] = useState({});
    const [totalRowsC, setTotalRowsC] = useState(50);

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataCOA(1, 50);
        }
        else {
            setIsModal(false);
        }
    };

    const searchAccount = (data) => {
        if (data === 'search') {
            setTitleModal('Chart of Account (COA)');
            handleClick();
        }
        else if (data === 'delete') {
            props.setAccountCode('');
            props.setAccountName('');
        }
    };

    const handleClose = () => {
        emptyState();
        props.handleClick();
    };

    const emptyState = () => {
        props.setAccountCode('');
        props.setAccountName('');
        props.setCheckBG('');
        props.setRemarks('');
        props.setCurrency(1);
        props.setAmount(0);
        props.setDueDate('');
        props.setIsDueDate(false);
        props.setStatusDueDate(false);
    };

    const BankTypeData = [
        { id: 1, code: 0, name: 'USD' },
        { id: 2, code: 1, name: 'IDR' },
    ];

    const handleSave = () => {
        let errMessage = undefined;

        if (!props.AccountCode) {
            errMessage = 'Please Select Chart of Account!';
        }
        else if (props.Amount <= 0) {
            errMessage = 'Please Input Amount!';
        }
        else if (props.CheckBG === '') {
            errMessage = 'Please Input Check BG!';
        }

        if (errMessage !== undefined) {
            showToast({ type: 'error', message: errMessage });
        }
        else {
            let sequence = 1;
            let rvId = 0;
            let dueDate = updateDateFormat(props.DueDate, true);

            if (props.detailEdit) {
                sequence = props.selectedDetail.sequence;
            }
            else {
                if (props.detailSequence > 0) sequence = props.detailSequence + 1;
            }

            if (props.receiptVoucherId !== undefined || props.receiptVoucherId !== null) {
                rvId = props.receiptVoucherId;

                if (rvId === undefined) {
                    rvId = 0;
                }
            }
            else {
                rvId = 0;
            }

            let data = {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "userId": userId,
                "userCode": userCode,

                "rowStatus": "ACT",
                "rvId": rvId,
                "rvDetailId": props.selectedReceiptVoucher.id,
                "sequence": sequence,
                "coaCode": props.AccountCode,
                "coaName": props.AccountName,
                "checkBG": props.CheckBG,
                "remarks": props.Remarks,
                "amountCrr": parseFloat(props.Currency),
                "amount": convertToNumeric(props.Amount),
                "dueDate": dueDate,
                "statusDueDate": props.StatusDueDate,
                "tandaMigrasi": 0,

                "idLama": 0,
            }

            if (!props.detailEdit) {
                props.setBankData([...props.BankData, data]);

                const newArray = props.DataReceiptVoucher.slice();
                newArray.forEach(el => {
                    if (el.sequence === props.selectedReceiptVoucher.sequence) {
                        el.receiptVoucherDetailBanks = [...props.BankData, data];

                        let amountUSD = 0;
                        let amountIDR = 0;

                        el.receiptVoucherDetailBanks.forEach(element => {
                            if (element.amountCrr === 0) {
                                amountUSD = parseFloat(amountUSD) + parseFloat(element.amount);
                            }
                            else {
                                amountIDR = parseFloat(amountIDR) + parseFloat(element.amount);
                            }
                        });

                        el.receiptBankUSD = amountUSD;
                        el.receiptBankIDR = amountIDR;
                    }
                });
                props.setDataReceiptVoucher(newArray);
                props.calculateTotalDebitCredit(newArray, props.Rate);
            }
            else {
                const newArr = props.BankData.slice();
                newArr.forEach(el => {
                    if (el.sequence === data.sequence) {
                        el.coaCode = data.coaCode;
                        el.coaName = data.coaName;
                        el.checkBG = data.checkBG;
                        el.remarks = data.remarks;
                        el.amountCrr = data.amountCrr;
                        el.amount = data.amount;
                        el.dueDate = data.dueDate;
                    }
                })
                props.setBankData(newArr);

                const newArray = props.DataReceiptVoucher.slice();
                newArray.forEach(el => {
                    if (el.sequence === props.selectedReceiptVoucher.sequence) {
                        el.receiptVoucherDetailBanks = newArr;

                        let amountUSD = 0;
                        let amountIDR = 0;

                        el.receiptVoucherDetailBanks.forEach(element => {
                            if (element.amountCrr === 0) {
                                amountUSD = parseFloat(amountUSD) + parseFloat(element.amount);
                            }
                            else {
                                amountIDR = parseFloat(amountIDR) + parseFloat(element.amount);
                            }
                        });

                        el.receiptBankUSD = amountUSD;
                        el.receiptBankIDR = amountIDR;
                    }
                });
                props.setDataReceiptVoucher(newArray);
                props.calculateTotalDebitCredit(newArray, props.Rate);
            }

            emptyState();
            handleClose();
            props.setDetailEdit(false);
            props.setSelectedDetail({});
        }
    }

    const getDataCOA = (pageNumber, pageSize, filters) => {
        setIsLoadingC(true);
        let params = [];
        ApiService.COAPostBySearch(paramC, pageNumber, pageSize, params, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataC({})
                    setDataC(response.data.data.chartOfAccount);

                    let temp = response.data.data.chartOfAccount
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapC(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTableC, 'id');
                    setColumnDataC(response.data.data.columns)
                    setTotalRowsC(response.data.totalRowCount)

                    setIsLoadingC(false)
                }
                setIsLoadingC(false)
            })
            .catch(function (error) {
                setIsLoadingC(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    };

    function handleChange() {
        if (props.isDueDate === false) {
            props.getDateNow();
            props.setIsDueDate(true);
        }
        else {
            props.setDueDate('');
            props.setIsDueDate(false);
        }
    }

    const mainContainer = (
        <>
            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Stack direction={'row'} spacing={'16px'}>
                        <Input
                            label={'Account Code'}
                            value={props.AccountCode}
                            disabled
                            fullWidth
                            formControlProps={{
                                sx: {
                                    width: '50%',
                                }
                            }}
                        />

                        <Input
                            value={props.AccountName}
                            disabled
                            fullWidth
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                }
                            }}
                            inputBaseProps={{
                                sx: {
                                    marginTop: '23px !important',
                                },
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => searchAccount('delete')}
                                            disabled={props.isAccount || props.isApprovedAcc}
                                            hidden={props.isApprovedAcc ? true : props.AccountName ? false : true}
                                        >
                                            <Icon
                                                iconName={'close'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>

                                        <IconButton
                                            onClick={() => searchAccount('search')}
                                            hidden={props.isApprovedAcc}
                                        >
                                            <Icon
                                                iconName={'search'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Stack>
                </Grid>

                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Input
                        label="Check BG"
                        value={props.CheckBG}
                        onChange={(e) => props.setCheckBG(e.target.value)}
                        disabled={props.isApprovedAcc}
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                    />
                </Grid>
            </Grid>

            <Input
                label={'Remarks'}
                value={props.Remarks}
                onChange={(e) => props.setRemarks(e.target.value)}
                disabled={props.isApprovedAcc}
                multiline
                fullWidth
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                inputBaseProps={{
                    rows: 4,
                    sx: {
                        padding: '8px',
                    },
                }}
            />

            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={8}
                >
                    <Selection
                        id='0'
                        label={'Currency'}
                        fullWidth={true}
                        placeholder={'Select Currency'}
                        value={props.Currency}
                        onChange={(e) => props.setCurrency(e.target.value)}
                        formControlProps={{
                            sx: {
                                marginTop: '16px !important'
                            }
                        }}
                        disabled={props.isApprovedAcc}
                    >
                        {
                            BankTypeData.map((data, index) => (
                                <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                            ))
                        }
                    </Selection>
                </Grid>

                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={8}
                >
                    <Input
                        label="Amount"
                        textAlign='right'
                        fullWidth
                        value={props.Amount}
                        onChange={(e) => props.setAmount(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                        inputBaseProps={{
                            inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                        }}
                        disabled={props.isApprovedAcc}
                    />
                </Grid>

                <Grid
                    item
                    xl={4}
                    lg={4}
                    sm={4}
                    xs={8}
                >
                    <div variant='standard' disabled={props.isApprovedAcc} style={{ marginTop: '16px' }}>
                        <Typography
                            sx={{
                                color: 'var(--Primary-One, #083A50)',
                                fontFamily: 'Nunito',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: '19.6px',
                            }}
                        >
                            Due Date
                        </Typography>
                        <Stack direction='row' spacing={'16px'} alignItems={'center'} justifyContent={'center'}>
                            <TickBox
                                disabled={props.isApprovedAcc}
                                checked={props.isDueDate}
                                onChange={() => handleChange()}
                            />

                            <CustomDatePicker
                                type={'date'}
                                value={props.DueDate}
                                disabled={!props.isDueDate || props.isApprovedAcc}
                                onChange={props.setDueDate}
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '-3px !important'
                                    }
                                }}
                            />
                        </Stack>
                    </div>
                </Grid>
            </Grid>
        </>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={props.titleModal}
                isOpen={props.isModal}
                onClose={handleClose}
                draggable={true}
                shrinkable={true}
                disableScrollLock={true}
                maxWidth={'lg'}
                fullWidth={true}
                topButton={false}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    {mainContainer}

                    <div style={{ marginTop: '48px' }}>
                        {CustomizeButtonComponent.SaveCancelModalButton(handleSave, false, false)}
                    </div>
                </div>
            </PopUp>

            {
                isModal && (
                    <SelectDataCOA
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}
                        getData={getDataCOA}
                        isLoading={isLoadingC}
                        totalRows={totalRowsC}
                        setTotalRows={setTotalRowsC}
                        SelectedData={SelectedDataC}
                        setSelectedData={setSelectedDataC}
                        data={dataC}
                        columnData={columnDataC}
                        dataMap={dataMapC}
                        dataTable={dataTableC}
                        setRefAccountCode={props.setAccountCode}
                        setRefAccountName={props.setAccountName}
                    />
                )
            }
        </>
    )
}

export default ModalBank;