import React from 'react';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import Grid from '@mui/material/Grid';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';

const ButtonOfficialReceipt = ({
    isApprove,
    isUnApprove,
    ReloadData,
    DataRegUserAccess,
    HandleStatus,
    DropDownTitle,
    DropDownList,
    AddOR,
    EditOR,
    DeleteData,
    Print,
    RePrint,
    handleApprove,
    handleUnApprove,
    Approve,
    UnApprove,
    isDisabled
}) => {
    const renderButton = (data) => {
        switch (data.accessCode) {
            case 'CRE':
                return ButtonComponent.AddButton(AddOR, 'New', false, isDisabled);
            case 'REI':
                return ButtonComponent.EditButton(EditOR, 'Data');
            case 'DEL':
                return DropDownTitle !== 'Deleted' ? ButtonComponent.DeleteButton(DeleteData, 'Data', false) : null;
            case 'PRI':
                return DropDownTitle !== 'Deleted' ? ButtonComponent.PrintButton(Print) : null;
            case 'RPR':
                return DropDownTitle !== 'Deleted' ? ButtonComponent.ReprintButton(RePrint, 'Approval') : null;
            case 'APP':
                return (DropDownTitle === 'Active' || DropDownTitle === 'All') ?
                    ButtonComponent.ApproveButton(handleApprove, 'Approve') : null;
            case 'UPP':
                return (DropDownTitle === 'All' || DropDownTitle === 'Posted') ?
                    ButtonComponent.UnApproveButton(handleUnApprove, 'Un Approve') : null;
            default:
                return null;
        }
    };

    return (
        <div className="row">
            <div className="col-12">
                <Grid container item spacing={1} direction="row" style={{ maxWidth: '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
                    {isApprove === false && isUnApprove === false ? (
                        <>
                            {ButtonComponent.ReloadButton(ReloadData)}
                            {DataRegUserAccess.map(data => (
                                <React.Fragment key={data.accessId}>
                                    {renderButton(data)}
                                </React.Fragment>
                            ))}
                            {CustomizeButtonComponent.DropDownButtonStatus(HandleStatus, DropDownTitle, DropDownList)}
                        </>
                    ) : isApprove === true ? (
                        <>
                            {ButtonComponent.BackButton(handleApprove)}
                            {ButtonComponent.ReloadButton(ReloadData)}
                            {DataRegUserAccess.map(data => (
                                <React.Fragment key={data.accessId}>
                                    {data.accessCode === 'APP' && ButtonComponent.ApproveButton(Approve, 'Approve Data')}
                                </React.Fragment>
                            ))}
                        </>
                    ) : (
                        <>
                            {ButtonComponent.BackButton(handleUnApprove)}
                            {ButtonComponent.ReloadButton(ReloadData)}
                            {DataRegUserAccess.map(data => (
                                <React.Fragment key={data.accessId}>
                                    {data.accessCode === 'UPP' && ButtonComponent.UnApproveButton(UnApprove, 'Un Approve Data')}
                                </React.Fragment>
                            ))}
                        </>
                    )}
                </Grid>
            </div>
        </div>
    );
};

export default ButtonOfficialReceipt;