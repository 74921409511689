import React, { forwardRef, useImperativeHandle, useEffect, useState } from 'react';
import { MenuItem, Grid, Card, CardContent, Box } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';

import InvoiceHeader from '../../../Operation/Invoice/Components/InvoiceHeader';
import { convertMask } from '../../../../components/Masks/DollarInput';
import { convertMaskIDR } from '../../../../components/Masks/RupiahInput';
import InvoiceBeingForPayment from '../../../Operation/Invoice/Components/InvoiceBeingForPayment';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ApiService, { username, formatCurrency } from '../../../../services/ApiService';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Selection, showToast } from '../../../../components/ui';
import { defaultDate, setDateFormat, updateDateFormat } from '../../../../utils/date';
import { convertToNominal } from '../../../../utils/masks';

const CreateEditInvoiceDelete = forwardRef((props, ref) => {
    const dataLocal = JSON.parse(localStorage.getItem(username + "-DataInvoice"));

    const { sJobTypeId } = useParams();
    const { invoiceId } = useParams();
    const { branchId } = useParams();
    const { companyId } = useParams();
    const { countryId } = useParams();

    const paramInvoice = 'invoice/invoice';
    const paramShipmentOrder = 'shipmentOrder/shipmentOrder';
    const paramEPL = 'estimateProfitLoss/estimateProfitLoss';
    const paramRegUserAccess = 'regUserAccess/regUserAccess';

    const paramRegJobType = 'regJobType/regJobType';
    const [jobType, setJobType] = useState([]);
    const [typeJobId, setTypeJobId] = useState('1');
    const [idJobType, setIdJobType] = useState(0);

    const paramVAT = 'vat/vat';
    const [VATData, setVATData] = useState([]);

    const paramInvoicesTo = 'invoicesTo/invoicesTo';
    const [InvoicesToData, setInvoicesToData] = useState([]);

    const paramJobOwner = 'jobOwner/jobOwner';
    const [jobOwnerList, setJobOwnerList] = useState([]);

    const history = useNavigate();

    //Invoice Variable
    const [PaymentFrom, setPaymentFrom] = useState(0);
    const [Type, setType] = useState(false);
    const [InvoiceNo, setInvoiceNo] = useState(0);
    const [InvoiceForAgentNo, setInvoiceForAgentNo] = useState('');
    const [PrintCount, setPrintCount] = useState(0);
    const [PrintOn, setPrintOn] = useState('');
    const [ET, setET] = useState('');
    const [PrincipleBy, setPrincipleBy] = useState(0);
    const [HeaderInvoice, setHeaderInvoice] = useState(0);
    const [PackingList, setPackingList] = useState('');
    const [SICustomer, setSICustomer] = useState('');
    const [EPLId, setEPLId] = useState(0);
    const [ShipmentId, setShipmentId] = useState(0);
    const [ShipmentOrderNo, setShipmentOrderNo] = useState('');
    const [PaymentType, setPaymentType] = useState('D');
    const [CustomerTo, setCustomerTo] = useState('2');
    const [CustomerId, setCustomerId] = useState(0);
    const [CustomerName, setCustomerName] = useState('');
    const [CustomerAddress, setCustomerAddress] = useState('');
    const [BillToId, setBillToId] = useState(0);
    const [BillToName, setBillToName] = useState('');
    const [BillToAddress, setBillToAddress] = useState('');
    const [RefEFaktur, setRefEFaktur] = useState('');
    const [RevisedTaxId, setRevisedTaxId] = useState(0);
    const [RevisedTaxNo, setRevisedTaxNo] = useState('');
    const [IncludeValue, setIncludeValue] = useState('0');
    const [Include, setInclude] = useState(false);
    const [Amount, setAmount] = useState(0);
    const [ticketId, setTicketId] = useState(0);
    const [invoiceStatus, setInvoiceStatus] = useState(0);
    const [period, setPeriod] = useState(0);
    const [yearPeriod, setYearPeriod] = useState(0);
    const [invoicesEdit, setInvoicesEdit] = useState(false);
    const [linkTo, setLinkTo] = useState('');
    const [dueDate, setDueDate] = useState(0);
    const [paidOn, setPaidOn] = useState('');
    const [saveOR, setSaveOR] = useState(false);
    const [badDebt, setBadDebt] = useState(false);
    const [badDebtOn, setBadDebtOn] = useState('');
    const [reBadDebt, setReBadDebt] = useState(false);
    const [dateReBadDebt, setDateReBadDebt] = useState('');
    const [deleted, setDeleted] = useState(false);
    const [deletedOn, setDeletedOn] = useState('');
    const [invoiceNo2, setInvoiceNo2] = useState('');
    const [rePrintApproved, setRePrintApproved] = useState(false);
    const [rePrintApprovedOn, setRePrintApprovedOn] = useState('');
    const [rePrintApprovedBy, setRePrintApprovedBy] = useState('');
    const [deletedRemarks, setDeletedRemarks] = useState('');
    const [sfpId, setSfpId] = useState(0);
    const [sfpNoFormat, setSfpNoFormat] = useState('');
    const [sfpDetailId, setSfpDetailId] = useState(0);
    const [uniqueKeySFP, setUniqueKeySFP] = useState('');
    const [uniqueKeyInvoice, setUniqueKeyInvoice] = useState('');
    const [deleteType, setDeleteType] = useState(0);
    const [deleteTypeRefInvId, setDeleteTypeRefInvId] = useState(0);
    const [kursKMKId, setKursKMKId] = useState(0);
    const [isDelivered, setIsDelivered] = useState(false);
    const [deliveredOn, setDeliveredOn] = useState('');
    const [deliveredRemarks, setDeliveredRemarks] = useState('');
    const [approvedCredit, setApprovedCredit] = useState(false);
    const [approvedCreditBy, setApprovedCreditBy] = useState('');
    const [approvedCreditOn, setApprovedCreditOn] = useState('');
    const [approvedCreditRemarks, setApprovedCreditRemarks] = useState('');
    const [pejkpNumber, setPejkpNumber] = useState(0);
    const [pejkpReference, setPejkpReference] = useState('');
    const [transactionOn, setTransactionOn] = useState('');
    const [requester, setRequester] = useState('');
    const [reference, setReference] = useState('');
    const [deleteSeq, setDeleteSeq] = useState(0);

    const [portType, setPortType] = useState('');
    const [serviceType, setServiceType] = useState('');

    const [DataPaymentSC, setDataPaymentSC] = useState([]);
    const [DataPaymentA, setDataPaymentA] = useState([]);
    const [DataPayment, setDataPayment] = useState([]);
    const [TempDataPayment, setTempDataPayment] = useState([]);

    const [VatValue, setVatValue] = useState(0);
    const [PaymentStatus, setPaymentStatus] = useState(false);
    const [Rate, setRate] = useState(0);
    const [RateReal, setRateReal] = useState(0);
    const [ExRateId, setExRateId] = useState(0);
    const [ExRateDate, setExRateDate] = useState(0);
    const [Currency, setCurrency] = useState('All');
    const [KursKMK, setKursKMK] = useState(0);
    const [PaymentUSD, setPaymentUSD] = useState(0);
    const [PaymentIDR, setPaymentIDR] = useState(0);
    const [TotalVatUSD, setTotalVatUSD] = useState(0);
    const [TotalVatIDR, setTotalVatIDR] = useState(0);

    const [isAgent, setIsAgent] = useState(true);
    const [Customer, setCustomer] = useState('Shipper');
    const [isExport, setIsExport] = useState(false);
    const [isImport, setIsImport] = useState(false);
    const [isSeaImport, setIsSeaImport] = useState(true);
    const [isSea, setIsSea] = useState(false);
    const [isAir, setIsAir] = useState(false);
    const [isInclude, setIsInclude] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [isInvoice, setIsInvoice] = useState(true);
    const [CustomerSCId, setCustomerSCId] = useState(0);
    const [CustomerSCName, setCustomerSCName] = useState('');
    const [CustomerSCAddress, setCustomerSCAddress] = useState('');
    const [CustomerAId, setCustomerAId] = useState(0);
    const [CustomerAName, setCustomerAName] = useState('');
    const [CustomerAAddress, setCustomerAAddress] = useState('');
    const [CustomerSCTempId, setCustomerSCTempId] = useState(0);
    const [CustomerSCTempName, setCustomerSCTempName] = useState('');
    const [CustomerSCTempAddress, setCustomerSCTempAddress] = useState('');
    const [CustomerATempId, setCustomerATempId] = useState(0);
    const [CustomerATempName, setCustomerATempName] = useState('');
    const [CustomerATempAddress, setCustomerATempAddress] = useState('');

    const [AmountCrr, setAmountCrr] = useState(0);
    const [isCircleProgress, setIsCircleProgress] = useState(false);

    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);

    const [isViewOnly, setIsViewOnly] = useState(false);
    const [ORId, setORId] = useState(0);
    const [PVId, setPVId] = useState(0);

    const [jobTypeCode, setJobTypeCode] = useState('');

    const [volume20, setVolume20] = useState(0);
    const [volume40, setVolume40] = useState(0);
    const [volume45, setVolume45] = useState(0);
    const [volumeCBM, setVolumeCBM] = useState(0);
    const [isVolume20, setIsVolume20] = useState(false);
    const [isVolume40, setIsVolume40] = useState(false);
    const [isVolume45, setIsVolume45] = useState(false);
    const [isVolumeCBM, setIsVolumeCBM] = useState(false);

    const [bookingConfirmationId, setBookingConfirmationId] = useState(0);
    const [bookingConfirmationNumber, setBookingConfirmationNumber] = useState('');

    const [containerSizeData, setContainerSizeData] = useState([]);

    const BreadcrumbsEditItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Invoice Deletion Approval', link: '/Majura/InvoiceDeletionApproval', style: '' },
        { label: 'Detail Requested Invoice', link: '/Majura/Invoice/' + sJobTypeId + '/' + invoiceId + '/' + branchId + '/' + companyId + '/' + countryId, style: { color: '#0087C2', fontWeight: '700' } },
    ];

    const simpanLocal = () => {
        let id = invoiceId ? invoiceId : 0;
        let datePaid = updateDateFormat(paidOn, true);
        let datePrint = updateDateFormat(PrintOn, true);
        let eta = defaultDate;
        let etd = defaultDate;
        let dateBadDebt = updateDateFormat(badDebtOn, true);
        let dateReBadDebts = updateDateFormat(dateReBadDebt, true);
        let dateDeleted = updateDateFormat(deletedOn, true);
        let dateRePrintApproved = updateDateFormat(rePrintApprovedOn, true);
        let dateDelivered = updateDateFormat(deliveredOn, true);
        let dateApprovedCredit = updateDateFormat(approvedCreditOn, true);
        let dateTransaction = updateDateFormat(transactionOn, true);
        let invoicesTo = '';
        let isGeneral = false;

        if (isImport === true) {
            if (ET !== '') {
                etd = ET;
            }
            else {
                etd = defaultDate;
            }
            eta = defaultDate;
        }
        else {
            if (ET !== '') {
                eta = ET;
            }
            else {
                eta = defaultDate;
            }
            etd = defaultDate;
            eta = ET;
        }

        for (let i = 0; i < InvoicesToData.length; i++) {
            if (AmountCrr === InvoicesToData[i].amountCrr && Customer === InvoicesToData[i].name) {
                invoicesTo = InvoicesToData[i].code;
                break;
            }
        }

        if (PaymentFrom === 0) {
            isGeneral = false;
        }
        else if (PaymentFrom === 1) {
            isGeneral = true;
        }

        let totalPaymentIDR = 0;
        let totalPaymentUSD = 0;
        let stampDuty = 0;

        if (formatCurrency === 'USD') {
            stampDuty = parseFloat(convertMask(Amount)) * parseFloat(TempDataPayment.length);
        }
        else {
            stampDuty = parseFloat(convertMaskIDR(Amount)) * parseFloat(TempDataPayment.length);
        }

        if (PaymentIDR > 0) {
            totalPaymentIDR = PaymentIDR + stampDuty + TotalVatIDR;
        }
        else {
            if (PaymentUSD > 0) {
                totalPaymentUSD = PaymentUSD + stampDuty + TotalVatUSD;
            }
        }

        var data = {
            "invoice": {
                "rowStatus": "ACT",
                "id": id,
                "ticketId": ticketId,
                "invoiceNo": InvoiceNo,
                "debetCredit": PaymentType,
                "eplId": EPLId,
                "shipmentId": ShipmentId,
                "shipmentNo": ShipmentOrderNo,
                "bookingId": bookingConfirmationId,
                "bookingNo": bookingConfirmationNumber,
                "jobTypeId": parseFloat(idJobType),
                "jobTypeCode": jobTypeCode,
                "reference": reference,
                "etd": etd,
                "eta": eta,
                "invoicesTo": Customer,
                "customerTypeId": parseFloat(CustomerTo),
                "customerId": CustomerId,
                "customerName": CustomerName,
                "customerAddress": CustomerAddress,
                "billId": BillToId,
                "billName": BillToName,
                "billAddress": BillToAddress,
                "invoicesToCode": invoicesTo,
                "invoiceStatus": invoiceStatus,
                "paymentUSD": PaymentUSD,
                "paymentIDR": PaymentIDR,
                "totalPaymentUSD": totalPaymentUSD,
                "totalPaymentIDR": totalPaymentIDR,
                "totalVatUSD": TotalVatUSD,
                "totalVatIDR": TotalVatIDR,
                "rate": RateReal,
                "originalInvoiceId": RevisedTaxId,
                "originalInvoiceNo": RevisedTaxNo,
                "jobOwnerId": PrincipleBy,
                "exRateDate": ExRateDate,
                "period": period,
                "yearPeriod": yearPeriod,
                "invoicesAgent": InvoiceForAgentNo,
                "isEdited": invoicesEdit,
                "isGeneral": isGeneral,
                "linkTo": linkTo,
                "dueDate": dueDate,
                "paid": PaymentStatus,
                "paidOn": datePaid,
                "saveOR": saveOR,
                "badDebt": badDebt,
                "badDebtOn": dateBadDebt,
                "reBadDebt": reBadDebt,
                "dateReBadDebt": dateReBadDebts,
                "printing": PrintCount,
                "printedOn": datePrint,
                "deleted": deleted,
                "deletedOn": dateDeleted,
                "invoiceNo2": invoiceNo2,
                "invHeader": HeaderInvoice,
                "exRateId": ExRateId,
                "rePrintApproved": rePrintApproved,
                "rePrintApprovedOn": dateRePrintApproved,
                "rePrintApprovedBy": rePrintApprovedBy,
                "deletedRemarks": deletedRemarks,
                "isCostToCost": Type,
                "sfpId": sfpId,
                "sfpNoFormat": sfpNoFormat,
                "sfpDetailId": sfpDetailId,
                "uniqueKeySFP": uniqueKeySFP,
                "uniqueKeyInvoice": uniqueKeyInvoice,
                "deleteType": deleteType,
                "deleteTypeRefInvId": deleteTypeRefInvId,
                "kursKMK": KursKMK,
                "deleteTypeSeq": deleteSeq,
                "kursKMKId": kursKMKId,
                "isDelivered": isDelivered,
                "deliveredOn": dateDelivered,
                "deliveredRemarks": deliveredRemarks,
                "sfpReference": RefEFaktur,
                "approvedCredit": approvedCredit,
                "approvedCreditBy": approvedCreditBy,
                "approvedCreditOn": dateApprovedCredit,
                "approvedCreditRemarks": approvedCreditRemarks,
                "packingListNo": PackingList,
                "siCustomerNo": SICustomer,
                "isStampDuty": Include,
                "shipVol20Amount": volume20,
                "shipVol40Amount": volume40,
                "shipVol45Amount": volume45,
                "shipVolCBMAmount": volumeCBM,
                "stampDutyAmount": formatCurrency === 'USD' ? convertMask(Amount) : convertMaskIDR(Amount),
                "pejkpNumber": pejkpNumber,
                "pejkpReference": pejkpReference,
                "transactionOn": dateTransaction,
                "orId": ORId,
                "pvId": PVId,
                "requester": requester,

                "idLama": 0,
            },
            "invoiceDetails": TempDataPayment,
        };

        localStorage.setItem(username + "-DataInvoice", JSON.stringify(data));
    }

    useImperativeHandle(ref, () => ({
        localSave() {
            simpanLocal();
        }
    }));

    useEffect(() => {
        document.title = 'Requested Invoice - Detail (Preview)';
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getDataLocal = () => {
        if (invoiceId) {
            setIsEdit(true);
        }

        let typeJob;
        let dataDetailInvoice = dataLocal.invoiceDetails;
        let dataInvoice = dataLocal.invoice;

        if (dataInvoice.orId !== 0 || dataInvoice.pvId !== 0) {
            setIsViewOnly(true);
        }
        setORId(dataInvoice.orId);
        setPVId(dataInvoice.pvId);

        if (dataInvoice.isGeneral === true) {
            setPaymentFrom(1);
        }
        else if (dataInvoice.isGeneral === false) {
            setPaymentFrom(0);
        }
        setType(dataInvoice.isCostToCost);
        setBookingConfirmationId(dataInvoice.bookingId);
        setBookingConfirmationNumber(dataInvoice.bookingNo);
        setInvoiceNo(dataInvoice.invoiceNo);
        setJobTypeCode(dataInvoice.jobTypeCode);
        setInvoiceForAgentNo(dataInvoice.InvoiceForAgentNo);
        setPrincipleBy(dataInvoice.jobOwnerId);
        setHeaderInvoice(dataInvoice.invHeader);
        setPackingList(dataInvoice.packingListNo);
        setSICustomer(dataInvoice.siCustomerNo);
        setEPLId(dataInvoice.eplId);
        setShipmentId(dataInvoice.shipmentId);
        setShipmentOrderNo(dataInvoice.shipmentNo);
        setReference(dataInvoice.reference);
        setPaymentType(dataInvoice.debetCredit);
        setCustomerTo(dataInvoice.customerTypeId);
        setDeleteSeq(dataInvoice.deleteTypeSeq);
        if (dataInvoice.customerTypeId !== 1) {
            setCustomerSCId(dataInvoice.customerId);
            setCustomerSCName(dataInvoice.customerId);
            setCustomerSCAddress(dataInvoice.customerAddress);
        }
        else {
            setCustomerAId(dataInvoice.customerId);
            setCustomerAName(dataInvoice.customerId);
            setCustomerAAddress(dataInvoice.customerAddress);
        }
        setCustomerId(dataInvoice.customerId);
        setCustomerName(dataInvoice.customerName);
        setCustomerAddress(dataInvoice.customerAddress);
        setBillToId(dataInvoice.billId);
        setBillToName(dataInvoice.billName);
        setBillToAddress(dataInvoice.billAddress);
        setRevisedTaxId(dataInvoice.originalInvoiceId);
        setRevisedTaxNo(dataInvoice.originalInvoiceNo);

        setInclude(dataInvoice.isStampDuty);
        if (dataInvoice.isStampDuty === true) {
            setIncludeValue('1');
            setIsInclude(false);
        }
        else {
            setIncludeValue('0');
            setIsInclude(true);
        }
        setAmount(dataInvoice.stampDutyAmount);
        setTicketId(dataInvoice.ticketId);
        if (dataInvoice.customerTypeId === 1) {
            setCustomer('Agent');
        }
        else if (dataInvoice.customerTypeId === 2) {
            setCustomer('Shipper');
        }
        else if (dataInvoice.customerTypeId === 3) {
            setCustomer('Consignee');
        }
        setInvoiceStatus(dataInvoice.invoiceStatus);
        setPeriod(dataInvoice.period);
        setYearPeriod(dataInvoice.yearPeriod);
        setInvoicesEdit(dataInvoice.isEdited);
        setLinkTo(dataInvoice.linkTo);
        setDueDate(dataInvoice.dueDate);
        setPaymentStatus(dataInvoice.paid);
        setSaveOR(dataInvoice.saveOR);
        setBadDebt(dataInvoice.badDebt);
        setReBadDebt(dataInvoice.reBadDebt);
        setDeleted(dataInvoice.deleted);
        setInvoiceNo2(dataInvoice.invoiceNo2);

        setPrintCount(dataInvoice.printing);

        setDateFormat(dataInvoice.printedOn, setPrintOn);
        setRePrintApproved(dataInvoice.rePrintApproved);
        setRePrintApprovedBy(dataInvoice.rePrintApprovedBy);
        setDeletedRemarks(dataInvoice.deletedRemarks);
        setSfpId(dataInvoice.sfpId);
        setSfpNoFormat(dataInvoice.sfpNoFormat);
        setSfpDetailId(dataInvoice.sfpDetailId);
        setUniqueKeySFP(dataInvoice.uniqueKeySFP);
        setUniqueKeyInvoice(dataInvoice.uniqueKeyInvoice);
        setDeleteType(dataInvoice.deleteType);
        setDeleteTypeRefInvId(dataInvoice.deleteTypeRefInvId);
        setKursKMKId(dataInvoice.kursKMKId);
        setIsDelivered(dataInvoice.isDelivered);
        setDeliveredRemarks(dataInvoice.deliveredRemarks);
        setApprovedCredit(dataInvoice.approvedCredit);
        setApprovedCreditBy(dataInvoice.approvedCreditBy);
        setApprovedCreditRemarks(dataInvoice.approvedCreditRemarks);
        setPejkpNumber(dataInvoice.pejkpNumber);
        setPejkpReference(dataInvoice.pejkpReference);
        setRequester(dataInvoice.requester);

        setVolume20(dataInvoice.shipVol20Amount);
        if (dataInvoice.shipVol20Amount > 0) {
            setIsVolume20(true);
        }

        setVolume40(dataInvoice.shipVol40Amount);
        if (dataInvoice.shipVol40Amount > 0) {
            setIsVolume40(true);
        }

        setVolume45(dataInvoice.shipVol45Amount);
        if (dataInvoice.shipVol45Amount > 0) {
            setIsVolume45(true);
        }

        setVolumeCBM(dataInvoice.shipVolCBMAmount);
        if (dataInvoice.shipVolCBMAmount > 0) {
            setIsVolumeCBM(true);
        }
        setRate(convertToNominal(dataInvoice.rate));
        setRateReal(dataInvoice.rate);
        setExRateId(dataInvoice.exRateId);
        setKursKMK(dataInvoice.kursKMK);
        setPaymentUSD(dataInvoice.paymentUSD);
        setPaymentIDR(dataInvoice.paymentIDR);
        setTotalVatUSD(dataInvoice.totalVatUSD);
        setTotalVatIDR(dataInvoice.totalVatIDR);

        if (dataInvoice.printing > 0) {
            setIsViewOnly(true);
        }

        setDateFormat(dataInvoice.etd, setET);
        setDateFormat(dataInvoice.eta, setET);
        setDateFormat(dataInvoice.paidOn, setPaidOn);
        setDateFormat(dataInvoice.badDebtOn, setBadDebtOn);
        setDateFormat(dataInvoice.dateReBadDebt, setDateReBadDebt);
        setDateFormat(dataInvoice.deletedOn, setDeletedOn);
        setDateFormat(dataInvoice.rePrintApprovedOn, setRePrintApprovedOn);
        setDateFormat(dataInvoice.deliveredOn, setDeliveredOn);
        setDateFormat(dataInvoice.approvedCreditOn, setApprovedCreditOn);
        setDateFormat(dataInvoice.transactionOn, setTransactionOn);
        setDateFormat(dataInvoice.exRateDate, setExRateDate);

        setTempDataPayment(dataDetailInvoice);
        setDataPayment(dataDetailInvoice);
        calculateTotal(dataDetailInvoice);
        setVATValueData(dataDetailInvoice);
        if (dataInvoice.customerTypeId !== 1) {
            setDataPaymentSC(dataDetailInvoice);
            setDataPaymentA([]);
        }
        else {
            setDataPaymentSC([]);
            setDataPaymentA(dataDetailInvoice);
        }
        GetContainerSizeData(1, 100, dataInvoice.shipmentId);

        ApiService.PostById(paramRegJobType, dataInvoice.jobTypeId)
            .then((response) => {
                if (response.data.code === 200) {
                    setJobType(response.data.data.jobType);
                    setIdJobType(response.data.data.jobType.jobTypeId);
                    typeJob = response.data.data.jobType;
                }
                else {
                    Back();
                }
            })
            .then(() => {
                checkExportImport(typeJob);
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })

        localStorage.removeItem(username + '-DataInvoice');
        setTimeout(function () { setIsLoadingBackDrop(false); }, 1250);
    }

    const getData = (id, typeJob) => {
        let body = {
            "invoiceReqDel": {
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "invoiceId": id
            }
        };

        ApiService.InvoiceDeletePostById(paramInvoice, body)
            .then((response) => {
                if (response.data.code === 200) {
                    if ((response.data.data.invoice.deleteType === 0 && response.data.data.invoice.deleteTypeRefInvId === 0)) {
                        let dataInvoice = response.data.data.invoice;
                        setIsEdit(true);

                        if ((dataInvoice.orId !== 0 || dataInvoice.pvId !== 0)) {
                            setIsViewOnly(true);
                        }

                        if (dataInvoice.isGeneral === true) {
                            setPaymentFrom(1);
                        }
                        else if (dataInvoice.isGeneral === false) {
                            setPaymentFrom(0);
                        }
                        setORId(dataInvoice.orId);
                        setPVId(dataInvoice.pvId);
                        setType(dataInvoice.isCostToCost);
                        setBookingConfirmationId(dataInvoice.bookingId);
                        setBookingConfirmationNumber(dataInvoice.bookingNo);
                        setInvoiceNo(dataInvoice.invoiceNo);
                        setJobTypeCode(dataInvoice.jobTypeCode);
                        setInvoiceForAgentNo(dataInvoice.InvoiceForAgentNo);
                        setPrincipleBy(dataInvoice.jobOwnerId);
                        setHeaderInvoice(dataInvoice.invHeader);
                        setPackingList(dataInvoice.packingListNo);
                        setSICustomer(dataInvoice.siCustomerNo);
                        setEPLId(dataInvoice.eplId);
                        setDeleteSeq(dataInvoice.deleteTypeSeq);
                        setShipmentId(dataInvoice.shipmentId);
                        setShipmentOrderNo(dataInvoice.shipmentNo);
                        setReference(dataInvoice.reference);
                        setPaymentType(dataInvoice.debetCredit);
                        setCustomerTo(dataInvoice.customerTypeId);
                        if (dataInvoice.customerTypeId !== 1) {
                            setCustomerSCId(dataInvoice.customerId);
                            setCustomerSCName(dataInvoice.customerId);
                            setCustomerSCAddress(dataInvoice.customerAddress);
                        }
                        else {
                            setCustomerAId(dataInvoice.customerId);
                            setCustomerAName(dataInvoice.customerId);
                            setCustomerAAddress(dataInvoice.customerAddress);
                        }
                        setCustomerId(dataInvoice.customerId);
                        setCustomerName(dataInvoice.customerName);
                        setCustomerAddress(dataInvoice.customerAddress);
                        setBillToId(dataInvoice.billId);
                        setBillToName(dataInvoice.billName);
                        setBillToAddress(dataInvoice.billAddress);
                        setRevisedTaxId(dataInvoice.originalInvoiceId);
                        setRevisedTaxNo(dataInvoice.originalInvoiceNo);

                        setInclude(dataInvoice.isStampDuty);
                        if (dataInvoice.isStampDuty === true) {
                            setIncludeValue('1');
                            setIsInclude(false);
                        }
                        else {
                            setIncludeValue('0');
                            setIsInclude(true);
                        }
                        setAmount(dataInvoice.stampDutyAmount);
                        setTicketId(dataInvoice.ticketId);
                        if (dataInvoice.customerTypeId === 1) {
                            setCustomer('Agent');
                        }
                        else if (dataInvoice.customerTypeId === 2) {
                            setCustomer('Shipper');
                        }
                        else if (dataInvoice.customerTypeId === 3) {
                            setCustomer('Consignee');
                        }
                        setInvoiceStatus(dataInvoice.invoiceStatus);
                        setPeriod(dataInvoice.period);
                        setYearPeriod(dataInvoice.yearPeriod);
                        setInvoicesEdit(dataInvoice.isEdited);
                        setLinkTo(dataInvoice.linkTo);
                        setDueDate(dataInvoice.dueDate);
                        setPaymentStatus(dataInvoice.paid);
                        setSaveOR(dataInvoice.saveOR);
                        setBadDebt(dataInvoice.badDebt);
                        setReBadDebt(dataInvoice.reBadDebt);
                        setDeleted(dataInvoice.deleted);
                        setInvoiceNo2(dataInvoice.invoiceNo2);
                        setPrintCount(dataInvoice.printing);
                        setRePrintApproved(dataInvoice.rePrintApproved);
                        setRePrintApprovedBy(dataInvoice.rePrintApprovedBy);
                        setDeletedRemarks(dataInvoice.deletedRemarks);
                        setSfpId(dataInvoice.sfpId);
                        setSfpNoFormat(dataInvoice.sfpNoFormat);
                        setSfpDetailId(dataInvoice.sfpDetailId);
                        setUniqueKeySFP(dataInvoice.uniqueKeySFP);
                        setUniqueKeyInvoice(dataInvoice.uniqueKeyInvoice);
                        setDeleteType(dataInvoice.deleteType);
                        setDeleteTypeRefInvId(dataInvoice.deleteTypeRefInvId);

                        setKursKMKId(dataInvoice.kursKMKId);
                        setIsDelivered(dataInvoice.isDelivered);
                        setDeliveredRemarks(dataInvoice.deliveredRemarks);
                        setApprovedCredit(dataInvoice.approvedCredit);
                        setApprovedCreditBy(dataInvoice.approvedCreditBy);
                        setApprovedCreditRemarks(dataInvoice.approvedCreditRemarks);
                        setPejkpNumber(dataInvoice.pejkpNumber);
                        setPejkpReference(dataInvoice.pejkpReference);
                        setRequester(dataInvoice.requester);
                        setRate(convertToNominal(dataInvoice.rate));
                        setRateReal(dataInvoice.rate);
                        setExRateId(dataInvoice.exRateId);
                        setKursKMK(dataInvoice.kursKMK);
                        setPaymentUSD(dataInvoice.paymentUSD);
                        setPaymentIDR(dataInvoice.paymentIDR);
                        setTotalVatUSD(dataInvoice.totalVatUSD);
                        setTotalVatIDR(dataInvoice.totalVatIDR);

                        setVolume20(dataInvoice.shipVol20Amount);
                        if (dataInvoice.shipVol20Amount > 0) {
                            setIsVolume20(true);
                        }

                        setVolume40(dataInvoice.shipVol40Amount);
                        if (dataInvoice.shipVol40Amount > 0) {
                            setIsVolume40(true);
                        }

                        setVolume45(dataInvoice.shipVol45Amount);
                        if (dataInvoice.shipVol45Amount > 0) {
                            setIsVolume45(true);
                        }

                        setVolumeCBM(dataInvoice.shipVolCBMAmount);
                        if (dataInvoice.shipVolCBMAmount > 0) {
                            setIsVolumeCBM(true);
                        }

                        if (dataInvoice.printing > 0) {
                            setIsViewOnly(true);
                        }

                        if (typeJob.serviceType === 'Export') {
                            setDateFormat(dataInvoice.etd, setET);
                        }
                        else {
                            setDateFormat(dataInvoice.eta, setET);
                        }

                        setDateFormat(dataInvoice.printedOn, setPrintOn);
                        setDateFormat(dataInvoice.paidOn, setPaidOn);
                        setDateFormat(dataInvoice.badDebtOn, setBadDebtOn);
                        setDateFormat(dataInvoice.dateReBadDebt, setDateReBadDebt);
                        setDateFormat(dataInvoice.deletedOn, setDeletedOn);
                        setDateFormat(dataInvoice.rePrintApprovedOn, setRePrintApprovedOn);
                        setDateFormat(dataInvoice.deliveredOn, setDeliveredOn);
                        setDateFormat(dataInvoice.approvedCreditOn, setApprovedCreditOn);
                        setDateFormat(dataInvoice.transactionOn, setTransactionOn);
                        setDateFormat(dataInvoice.exRateDate, setExRateDate);

                        setTempDataPayment(dataInvoice.invoiceDetails);
                        setDataPayment(dataInvoice.invoiceDetails);
                        setVATValueData(dataInvoice.invoiceDetails);
                        if (dataInvoice.customerTypeId !== 1) {
                            setDataPaymentSC(dataInvoice.invoiceDetails);
                            setDataPaymentA([]);
                        }
                        else {
                            setDataPaymentSC([]);
                            setDataPaymentA(dataInvoice.invoiceDetails);
                        }
                        calculateTotal(dataInvoice.invoiceDetails);

                        GetContainerSizeData(1, 100, dataInvoice.shipmentId);

                        if (!dataLocal) {
                            setIsLoadingBackDrop(false);
                        }
                    }
                    else {
                        // history('/Majura/Invoice');
                    }
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    const getDataAccess = () => {
        if (!dataLocal) {
            setIsLoadingBackDrop(true);
        }

        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'FINVRD')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        if (hasAccess) {
                            GetJobOwnerList(1, 20);
                        }
                    }
                    else {
                        Back();
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
                })
        }
        else {
            history('/Majura');
        }
    }

    const GetJobType = (id) => {
        let typeJob = '';
        let dataId = '';
        if (id === undefined) {
            dataId = sJobTypeId;
        }
        else {
            dataId = id;
        }
        setTypeJobId(dataId);

        ApiService.PostById(paramRegJobType, dataId)
            .then((response) => {
                if (response.data.code === 200) {
                    setJobType(response.data.data.jobType);
                    setIdJobType(response.data.data.jobType.jobTypeId);
                    setJobTypeCode(response.data.data.jobType.code);
                    setServiceType(response.data.data.jobType.serviceType);
                    setPortType(response.data.data.jobType.portType);
                    typeJob = response.data.data.jobType;
                }
                else {
                    Back();
                }
            })
            .then(() => {
                if (invoiceId) {
                    getData(invoiceId, typeJob);
                }

                checkExportImport(typeJob);
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };


    const getDataDetail = (id, customerId, Type = false, isIncome = true, customerTo = CustomerTo) => {
        let params = [];
        let dataArray = [];
        let newArray1 = [];
        let newArray2 = [];
        let Sequence = 1;
        let Sequence1 = 1;
        let Sequence2 = 1;
        let amount = 0;
        let invId = 0;
        let customer = '';
        let custTypeId = '';

        let customerType = [
            { id: 1, code: '2', name: 'Shipper' },
            { id: 2, code: '3', name: 'Consignee' },
            { id: 3, code: '1', name: 'Agent' }
        ]

        custTypeId = customerTo;

        customerType.forEach(element => {
            if (element.code === custTypeId) {
                customer = element.name;
            }
        });

        params = [...params,
        { 'attr': 'eplId', 'value': '' + id },
        { 'attr': 'isIncome', 'value': '' + isIncome },
        { 'attr': 'refId', 'value': '' + 0 },
        { 'attr': 'customerId', 'value': '' + customerId },
        { 'attr': 'isCostToCost', 'value': '' + Type }
        ];

        ApiService.OperationPostByDetail(paramEPL, 'SEARCH', 1, 100, params)
            .then((response) => {
                setIsCircleProgress(true);
                if (response.data.code === 200) {
                    dataArray = response.data.data.estimateProfitLossDetail;

                    if (invoiceId !== undefined || invoiceId !== null) {
                        invId = invoiceId;

                        if (invId === undefined) {
                            invId = 0;
                        }
                    }
                    else {
                        invId = 0;
                    }

                    if (dataArray.length > 0) {
                        setPaymentFrom(0);

                        for (let i = 0; i < dataArray.length; i++) {
                            if (dataArray[i].rowStatus === 'ACT' && dataArray[i].isIgnoreItem === false) {
                                setAmountCrr(dataArray[i].amountCrr);

                                if (dataArray[i].amountCrr === 0) {
                                    amount = dataArray[i].amountUSD;
                                }
                                else if (dataArray[i].amountCrr === 1) {
                                    amount = dataArray[i].amountIDR;
                                }

                                if (dataArray[i].customerTypeId !== 1) {
                                    Sequence = Sequence1;
                                }
                                else {
                                    Sequence = Sequence2;
                                }

                                let data = {
                                    "rowStatus": "ACT",
                                    "invoiceId": invId,
                                    "sequence": Sequence,
                                    "debetCredit": PaymentType,
                                    "accountId": dataArray[i].accountId,
                                    "accountCode": dataArray[i].accountCode,
                                    "accountName": dataArray[i].accountName,
                                    "coaCode": dataArray[i].coaCode,
                                    "coaName": dataArray[i].coaName,
                                    "description": dataArray[i].description,
                                    "type": dataArray[i].type,
                                    "containerSize": dataArray[i].containerSize,
                                    "formatContainer": dataArray[i].formatContainer,
                                    "containerTypeId": dataArray[i].containerTypeId,
                                    "containerTypeCode": dataArray[i].containerTypeCode,
                                    "containerTypeName": dataArray[i].containerTypeName,
                                    "isContainer": dataArray[i].isContainer,
                                    "codingQuantity": dataArray[i].codingQuantity,
                                    "quantity": parseFloat(dataArray[i].quantity),
                                    "perQty": dataArray[i].perQty,
                                    "sign": dataArray[i].sign,
                                    "amountCrr": dataArray[i].amountCrr,
                                    "amount": amount,
                                    "percentVat": 0,
                                    "amountVat": 0,
                                    "eplDetailId": dataArray[i].id,
                                    "vatId": 0,
                                    "isCostToCost": dataArray[i].isCostToCost,
                                    "originalUsd": dataArray[i].originalUsd,
                                    "originalRate": dataArray[i].originalRate,

                                    "idLama": 0,
                                    "invoiceDetailStorages": [],
                                }

                                if (dataArray[i].customerTypeId !== 1) {
                                    newArray1 = [...newArray1, data];
                                    Sequence1 = parseFloat(Sequence1) + parseFloat(1);
                                }
                                else {
                                    newArray2 = [...newArray2, data];
                                    Sequence2 = parseFloat(Sequence2) + parseFloat(1);
                                }
                            }
                        }

                        if (customer !== 'Agent') {
                            setDataPayment(newArray1);
                            setTempDataPayment(newArray1);
                            calculateTotal(newArray1);
                        }
                        else {
                            setDataPayment(newArray2);
                            setTempDataPayment(newArray2);
                            calculateTotal(newArray2);
                        }

                        setDataPaymentSC(newArray1);
                        setDataPaymentA(newArray2);
                        setIsInvoice(true);
                    }
                    else {
                        setPaymentFrom(1);
                        setIsInvoice(false);

                        setDataPayment([]);
                        setTempDataPayment([]);

                        setDataPaymentSC([]);
                        setDataPaymentA([]);
                        calculateTotal([]);
                    }
                    setIsCircleProgress(false);

                    setIsLoadingBackDrop(false);
                }
                else {
                    Back();
                    setIsLoadingBackDrop(false);
                }
                setIsCircleProgress(false);

                if (!dataLocal) {
                    setIsLoadingBackDrop(false);
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                setIsCircleProgress(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    function checkExportImport(jobType) {
        if (jobType.portType === 'SEA') {
            setIsSea(false);
            setIsAir(true);
        }
        else if (jobType.portType === 'AIR') {
            setIsSea(true);
            setIsAir(false);
        }
        else {
            setIsSea(true);
            setIsAir(true);
        }

        if (jobType.serviceType === 'Export') {
            setCustomerTo('2');
            setCustomer('Shipper');
            setIsExport(false);
            setIsImport(true);
            setIsSeaImport(true);
        }
        else if (jobType.serviceType === 'Import') {
            setCustomerTo('3');
            setCustomer('Consignee');
            setIsExport(true);
            setIsImport(false);
            if (jobType.portType === 'SEA') {
                setIsSeaImport(false);
            }
            else {
                setIsSeaImport(true);
            }
        }
    }

    //Function for Principle By
    const GetJobOwnerList = (pageNumber, pageSize) => {
        ApiService.PostByPage(paramJobOwner, pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    if (response.data.data.jobOwner.length > 0) {
                        setHeaderInvoice(response.data.data.jobOwner[0].id);
                    }
                    setJobOwnerList(response.data.data.jobOwner);
                    GetVATData(1, 20);
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    const selectInvoiceHeader = (HeaderInvoice, setHeaderInvoice, isDisable, title = 'Principle By') => (
        <>
            <Selection
                id='0'
                label={title}
                fullWidth={true}
                placeholder={'Select ' + title}
                value={HeaderInvoice}
                onChange={(e) => setHeaderInvoice(e.target.value)}
                disabled={isDisable}
            >
                {
                    jobOwnerList.map((data, index) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.id}>{data.masterCode}</MenuItem>
                    ))
                }
            </Selection>
        </>
    );

    //Function for VAT Type
    const GetVATData = (pageNumber, pageSize) => {
        let arrayData = [{ "id": 0, "value": 0.00 }];
        ApiService.PostByPage(paramVAT, pageNumber, pageSize)
            .then((response) => {
                if (response.status === 200) {
                    for (let i = 0; i < response.data.data.vat.length; i++) {
                        arrayData = [...arrayData, response.data.data.vat[i]];
                    }
                    setVATData(arrayData);

                    GetInvoicesToData(1, 20);
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    const selectVATData = (Vat, setVat, name, isDisable, title = 'VAT') => (
        <>
            <Selection
                id='0'
                label={title}
                fullWidth={true}
                placeholder={'Select VAT'}
                value={Vat}
                onChange={(e) => setVat(e.target.value)}
                formControlProps={{
                    sx: {
                        marginTop: '16px'
                    }
                }}
                disabled={isDisable}
            >
                {
                    VATData.map((data, index) => (
                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.id}>{name}{data.value}%</MenuItem>
                    ))
                }
            </Selection>
        </>
    );

    function setVATValueData(data) {
        let tempData = 0;
        let isSame = true;

        if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                if (data[0].vatId !== data[i].vatId) {
                    isSame = false;
                    break;
                }
            }

            if (isSame === true) {
                tempData = data[0].vatId;
            }
        }

        if (isSame === true) {
            setVatValue(tempData);
        }
        else {
            setVatValue(0);
        }
    }

    const GetInvoicesToData = (pageNumber, pageSize) => {
        ApiService.PostByPage(paramInvoicesTo, pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    setInvoicesToData(response.data.data.invoicesTo);

                    if (dataLocal) {
                        setIsLoadingBackDrop(true);
                        setTimeout(function () { getDataLocal(); }, 2000);
                    }
                    else {
                        GetJobType();
                    }
                }
                else {
                    Back();
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    };

    const GetContainerSizeData = (pageNumber, pageSize, shipmentId, filter, orderBy) => {
        let params = [];
        params = [
            {
                'attr': 'shipmentId',
                'value': '' + shipmentId,
                'isDistinct': true
            },
            {
                'attr': 'containerTypeId',
                'value': '',
                'isDistinct': true
            },
            {
                'attr': 'containerSize',
                'value': '',
                'isDistinct': true
            }
        ]
        ApiService.ShipmentSeaContainerPostBySearch(paramShipmentOrder, pageNumber, pageSize, params, filter, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setContainerSizeData(response.data.data.shipmentOrderSeaContainer);
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    }

    function Back() {
        history('/Majura/InvoiceDeletionApproval');
    }

    function calculateTotal(result) {
        let totalUSD = 0;
        let totalIDR = 0;
        let totalTaxUSD = 0;
        let totalTaxIDR = 0;

        for (let i = 0; i < result.length; i++) {
            if (result[i].amountCrr === 0) {
                if (result[i].sign === true) {
                    totalUSD = parseFloat(result[i].amount) + parseFloat(totalUSD);
                    totalTaxUSD = parseFloat(result[i].amountVat) + parseFloat(totalTaxUSD);
                }
                else {
                    totalUSD = parseFloat(totalUSD) - parseFloat(result[i].amount);
                    totalTaxUSD = parseFloat(totalTaxUSD) - parseFloat(result[i].amountVat);
                }
            }
            else {
                if (result[i].sign === true) {
                    totalIDR = parseFloat(result[i].amount) + parseFloat(totalIDR);
                    totalTaxIDR = parseFloat(result[i].amountVat) + parseFloat(totalTaxIDR);
                }
                else {
                    totalIDR = parseFloat(totalIDR) - parseFloat(result[i].amount);
                    totalTaxIDR = parseFloat(totalTaxIDR) - parseFloat(result[i].amountVat);
                }
            }
        }

        setPaymentUSD(totalUSD);
        setPaymentIDR(totalIDR);
        setTotalVatUSD(totalTaxUSD);
        setTotalVatIDR(totalTaxIDR);
    }

    return (
        <Box sx={{ backgroundColor: '#E4E8EC', width: '100%', p: '24px 32px' }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    title={'Invoice - ' + jobType.name + ' (' + reference + ')'}
                    BreadcrumbsItems={BreadcrumbsEditItems}
                    type={'Form'}
                    ticketId={ticketId}
                    BackFunction={Back}
                    documentStatus={'PREVIEW'}
                />

                <fieldset>
                    <Card component="div" style={{ marginTop: '32px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                        <CardContent>
                            <InvoiceHeader
                                jobType={jobType}
                                typeJobId={typeJobId}
                                calculateTotal={calculateTotal}
                                selectInvoiceHeader={selectInvoiceHeader}
                                setVatValue={setVatValue}
                                isToDo={null}
                                isAgent={isAgent}
                                setIsAgent={setIsAgent}
                                eplId={null}
                                setAmountCrr={setAmountCrr}
                                isExport={isExport}
                                isImport={isImport}
                                isEdit={isEdit}
                                isInclude={isInclude}
                                setIsInclude={setIsInclude}
                                setIsLoadingBackDrop={setIsLoadingBackDrop}
                                invoiceId={invoiceId}
                                isSea={isSea}
                                isAir={isAir}
                                Customer={Customer}
                                setCustomer={setCustomer}
                                setIsInvoice={setIsInvoice}
                                CustomerSCId={CustomerSCId}
                                setCustomerSCId={setCustomerSCId}
                                CustomerSCName={CustomerSCName}
                                setCustomerSCName={setCustomerSCName}
                                CustomerSCAddress={CustomerSCAddress}
                                setCustomerSCAddress={setCustomerSCAddress}
                                CustomerAId={CustomerAId}
                                setCustomerAId={setCustomerAId}
                                CustomerAName={CustomerAName}
                                setCustomerAName={setCustomerAName}
                                CustomerAAddress={CustomerAAddress}
                                setCustomerAAddress={setCustomerAAddress}
                                CustomerSCTempId={CustomerSCTempId}
                                setCustomerSCTempId={setCustomerSCTempId}
                                CustomerSCTempName={CustomerSCTempName}
                                setCustomerSCTempName={setCustomerSCTempName}
                                CustomerSCTempAddress={CustomerSCTempAddress}
                                setCustomerSCTempAddress={setCustomerSCTempAddress}
                                CustomerATempId={CustomerATempId}
                                setCustomerATempId={setCustomerATempId}
                                CustomerATempName={CustomerATempName}
                                setCustomerATempName={setCustomerATempName}
                                CustomerATempAddress={CustomerATempAddress}
                                setCustomerATempAddress={setCustomerATempAddress}

                                PaymentFrom={PaymentFrom}
                                setPaymentFrom={setPaymentFrom}
                                Type={Type}
                                setType={setType}
                                setTicketId={setTicketId}
                                InvoiceNo={invoiceNo2}
                                setInvoiceNo={setInvoiceNo}
                                InvoiceForAgentNo={InvoiceForAgentNo}
                                setInvoiceForAgentNo={setInvoiceForAgentNo}
                                PrintCount={PrintCount}
                                setPrintCount={setPrintCount}
                                PrintOn={PrintOn}
                                setPrintOn={setPrintOn}
                                ET={ET}
                                setET={setET}
                                setRequester={setRequester}
                                PrincipleBy={PrincipleBy}
                                setPrincipleBy={setPrincipleBy}
                                HeaderInvoice={HeaderInvoice}
                                setHeaderInvoice={setHeaderInvoice}
                                PackingList={PackingList}
                                setPackingList={setPackingList}
                                SICustomer={SICustomer}
                                setSICustomer={setSICustomer}
                                EPLId={EPLId}
                                setEPLId={setEPLId}
                                ShipmentId={ShipmentId}
                                setShipmentId={setShipmentId}
                                ShipmentOrderNo={ShipmentOrderNo}
                                setShipmentOrderNo={setShipmentOrderNo}
                                PaymentType={PaymentType}
                                setPaymentType={setPaymentType}
                                CustomerTo={CustomerTo}
                                setCustomerTo={setCustomerTo}
                                CustomerId={CustomerId}
                                setCustomerId={setCustomerId}
                                CustomerName={CustomerName}
                                setCustomerName={setCustomerName}
                                CustomerAddress={CustomerAddress}
                                setCustomerAddress={setCustomerAddress}
                                BillToId={BillToId}
                                setBillToId={setBillToId}
                                BillToName={BillToName}
                                setBillToName={setBillToName}
                                BillToAddress={BillToAddress}
                                setBillToAddress={setBillToAddress}
                                RefEFaktur={RefEFaktur}
                                setRefEFaktur={setRefEFaktur}
                                RevisedTaxId={RevisedTaxId}
                                setRevisedTaxId={setRevisedTaxId}
                                RevisedTaxNo={RevisedTaxNo}
                                setRevisedTaxNo={setRevisedTaxNo}
                                IncludeValue={IncludeValue}
                                setIncludeValue={setIncludeValue}
                                Include={Include}
                                setInclude={setInclude}
                                Amount={Amount}
                                setAmount={setAmount}

                                setCurrency={setCurrency}
                                fakturType={null}
                                DataPaymentSC={DataPaymentSC}
                                setDataPaymentSC={setDataPaymentSC}
                                DataPaymentA={DataPaymentA}
                                setDataPaymentA={setDataPaymentA}
                                DataPayment={DataPayment}
                                setDataPayment={setDataPayment}
                                TempDataPayment={TempDataPayment}
                                setTempDataPayment={setTempDataPayment}
                                getDataDetail={getDataDetail}
                                isViewOnly={isViewOnly}

                                volume20={volume20}
                                setVolume20={setVolume20}
                                volume40={volume40}
                                setVolume40={setVolume40}
                                volume45={volume45}
                                setVolume45={setVolume45}
                                volumeCBM={volumeCBM}
                                setVolumeCBM={setVolumeCBM}
                                isVolume20={isVolume20}
                                setIsVolume20={setIsVolume20}
                                isVolume40={isVolume40}
                                setIsVolume40={setIsVolume40}
                                isVolume45={isVolume45}
                                setIsVolume45={setIsVolume45}
                                isVolumeCBM={isVolumeCBM}
                                setIsVolumeCBM={setIsVolumeCBM}

                                bookingConfirmationId={bookingConfirmationId}
                                setBookingConfirmationId={setBookingConfirmationId}
                                bookingConfirmationNumber={bookingConfirmationNumber}
                                setBookingConfirmationNumber={setBookingConfirmationNumber}
                                GetContainerSizeData={GetContainerSizeData}
                                getData={getData}
                                emptyState={null}
                            />
                        </CardContent>
                    </Card>

                    <Card component="div" style={{ marginTop: '32px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                        <CardContent>
                            <InvoiceBeingForPayment
                                selectVATData={selectVATData}
                                VATData={VATData}
                                isCircleProgress={isCircleProgress}
                                invoiceId={invoiceId}
                                setPaymentFrom={setPaymentFrom}
                                DataPayment={DataPayment}
                                setDataPayment={setDataPayment}
                                calculateTotal={calculateTotal}
                                TempDataPayment={TempDataPayment}
                                setTempDataPayment={setTempDataPayment}
                                DataPaymentSC={DataPaymentSC}
                                setDataPaymentSC={setDataPaymentSC}
                                DataPaymentA={DataPaymentA}
                                setDataPaymentA={setDataPaymentA}
                                Customer={Customer}
                                PaymentFrom={PaymentFrom}
                                isEdit={isEdit}
                                PaymentType={PaymentType}
                                isSea={isSea}
                                isAir={isAir}
                                BillToId={BillToId}
                                CustomerId={CustomerId}
                                CustomerName={CustomerName}
                                setIsLoadingBackDrop={setIsLoadingBackDrop}
                                isAgent={isAgent}
                                isSeaImport={isSeaImport}
                                EPLId={EPLId}
                                isExport={isExport}
                                isImport={isImport}
                                isInvoice={isInvoice}
                                setIsInvoice={setIsInvoice}

                                VatValue={VatValue}
                                setVatValue={setVatValue}
                                setVATValueData={setVATValueData}
                                PaymentStatus={PaymentStatus}
                                setPaymentStatus={setPaymentStatus}
                                Rate={Rate}
                                ExRateId={ExRateId}
                                ExRateDate={ExRateDate}
                                Currency={Currency}
                                setCurrency={setCurrency}
                                KursKMK={KursKMK}
                                setKursKMK={setKursKMK}
                                Type={Type}
                                PaymentUSD={PaymentUSD}
                                setPaymentUSD={setPaymentUSD}
                                PaymentIDR={PaymentIDR}
                                setPaymentIDR={setPaymentIDR}
                                TotalVatUSD={TotalVatUSD}
                                setTotalVatUSD={setTotalVatUSD}
                                TotalVatIDR={TotalVatIDR}
                                setTotalVatIDR={setTotalVatIDR}
                                isViewOnly={isViewOnly}

                                volume20={volume20}
                                setVolume20={setVolume20}
                                volume40={volume40}
                                setVolume40={setVolume40}
                                volume45={volume45}
                                setVolume45={setVolume45}
                                volumeCBM={volumeCBM}
                                setVolumeCBM={setVolumeCBM}
                                isVolume20={isVolume20}
                                setIsVolume20={setIsVolume20}
                                isVolume40={isVolume40}
                                setIsVolume40={setIsVolume40}
                                isVolume45={isVolume45}
                                setIsVolume45={setIsVolume45}
                                isVolumeCBM={isVolumeCBM}
                                setIsVolumeCBM={setIsVolumeCBM}
                                containerSizeData={containerSizeData}
                                serviceType={serviceType}
                                portType={portType}
                            />
                        </CardContent>
                    </Card>
                </fieldset>
            </Grid>

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </Box>
    )
})

export default CreateEditInvoiceDelete;