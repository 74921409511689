import { Box, Typography, Grid, MenuItem, Button } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CustomDatePicker, Icon, Input, PopUp, Selection, TickBox } from '../ui';
import { getDefaultDateFormat } from '../../utils/date';

const ModalConfirmation = React.memo(({
    title,
    titleModal,
    isDraft,
    setIsDraft,
    isAllJob,
    setIsAllJob,
    isDueDate,
    dueDate,
    setDueDate,
    Remarks,
    setRemarks,
    setIsDueDate,
    PrintCount,
    getDateNow,
    shipperInfo,
    setShipperInfo,
    containerInfo,
    setContainerInfo,
    isModal,
    handleClick,
    ConfirmationData,
    inputLabel,
    CustomNote,
    isCustomNote,
    isRemarks,
    backDate
}) => {
    const today = getDefaultDateFormat(new Date());

    const currentDate = new Date();
    const previousMonth = currentDate.getMonth() - 1;
    const yearOfPreviousMonth = previousMonth < 0 ? currentDate.getFullYear() - 1 : currentDate.getFullYear();
    const minDate = `${yearOfPreviousMonth}-${String(previousMonth + 1).padStart(2, '0')}-01`;

    const [ConfirmationRemarks, setConfirmationRemarks] = useState('');

    useEffect(() => {
        setConfirmationRemarks('');
    }, [isModal]);


    const handleConfirmation = useCallback(() => {
        !isRemarks ? ConfirmationData() : ConfirmationData(ConfirmationRemarks);
    }, [ConfirmationData, isRemarks, ConfirmationRemarks]);

    const printTypeData = useMemo(() => [
        { id: 1, value: true, name: 'Draft' },
        { id: 2, value: false, name: 'Fixed' },
    ], []);

    const printJobData = useMemo(() => [
        { id: 1, value: true, name: 'Yes' },
        { id: 2, value: false, name: 'No' },
    ], []);

    const handleChange = useCallback((name, isChecked) => {
        if (name === 'Shipper') {
            setShipperInfo(!isChecked);
        } else if (name === 'Container') {
            setContainerInfo(!isChecked);
        }
    }, [setShipperInfo, setContainerInfo]);

    const handlePrintType = useCallback((value) => {
        setIsDraft(value);
        if (titleModal === 'General Invoice') {
            setIsDueDate(!value);

            if (value === false && PrintCount === 0) {
                getDateNow();
            }
        }
    }, [setIsDueDate, getDateNow, titleModal, PrintCount, setIsDraft]);

    const mainContainer = useMemo(() => (
        <>
            {title === 'Print' && (
                <>
                    {titleModal === 'Invoice' && (
                        <>
                            <Selection
                                id='0'
                                label={'Print Type'}
                                fullWidth
                                placeholder={'Select Print Type'}
                                value={isDraft}
                                onChange={(e) => setIsDraft(e.target.value)}
                                formControlProps={{ sx: { marginTop: '16px !important' } }}
                            >
                                {printTypeData.map((data, index) => (
                                    <MenuItem key={index} value={data.value} sx={{ fontSize: '12px' }}>
                                        {data.name}
                                    </MenuItem>
                                ))}
                            </Selection>

                            <div style={{ marginTop: '24px' }}>
                                <Typography
                                    sx={{
                                        color: 'var(--Primary-One, #083A50)',
                                        fontFamily: 'Nunito',
                                        fontSize: '14px',
                                        fontWeight: '600',
                                        lineHeight: '19.6px',
                                    }}
                                >
                                    Additional:
                                </Typography>

                                <Grid container sx={{ padding: '6px', alignItems: 'center' }}>
                                    <Grid item xl={6} lg={6} sm={6} xs={12}>
                                        <TickBox
                                            baseCheckboxProps={{ sx: { padding: '0px 8px 0px 0px !important' } }}
                                            size='small'
                                            label={
                                                <Typography
                                                    sx={{
                                                        color: 'var(--Blue-Primary-1, #083A50)',
                                                        fontFamily: 'Nunito',
                                                        fontSize: '12px !important',
                                                        fontWeight: '500',
                                                        lineHeight: '20px',
                                                    }}
                                                >
                                                    View Shipper Info
                                                </Typography>
                                            }
                                            checked={shipperInfo}
                                            onChange={() => handleChange('Shipper', shipperInfo)}
                                        />
                                    </Grid>

                                    <Grid item xl={6} lg={6} sm={6} xs={12}>
                                        <TickBox
                                            baseCheckboxProps={{ sx: { padding: '0px 8px 0px 0px !important' } }}
                                            size='small'
                                            label={
                                                <Typography
                                                    sx={{
                                                        color: 'var(--Blue-Primary-1, #083A50)',
                                                        fontFamily: 'Nunito',
                                                        fontSize: '12px !important',
                                                        fontWeight: '500',
                                                        lineHeight: '20px',
                                                    }}
                                                >
                                                    View Container Info
                                                </Typography>
                                            }
                                            checked={containerInfo}
                                            onChange={() => handleChange('Container', containerInfo)}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </>
                    )}

                    {titleModal === 'Payment Request' && (
                        <>
                            <Selection
                                id='0'
                                label={'Print Type'}
                                fullWidth
                                placeholder={'Select Print Type'}
                                value={isDraft}
                                onChange={(e) => setIsDraft(e.target.value)}
                                formControlProps={{ sx: { marginTop: '16px !important' } }}
                            >
                                {printTypeData.map((data, index) => (
                                    <MenuItem key={index} value={data.value} sx={{ fontSize: '12px' }}>
                                        {data.name}
                                    </MenuItem>
                                ))}
                            </Selection>

                            <Selection
                                id='0'
                                label={'Print All Job'}
                                fullWidth
                                placeholder={'Select Print All Job'}
                                value={isAllJob}
                                onChange={(e) => setIsAllJob(e.target.value)}
                                formControlProps={{ sx: { marginTop: '16px !important' } }}
                            >
                                {printJobData.map((data, index) => (
                                    <MenuItem key={index} value={data.value} sx={{ fontSize: '12px' }}>
                                        {data.name}
                                    </MenuItem>
                                ))}
                            </Selection>
                        </>
                    )}

                    {(titleModal === 'Memorial' || titleModal === 'General Invoice') && (
                        <Selection
                            id='0'
                            label={'Print Type'}
                            fullWidth
                            placeholder={'Select Print Type'}
                            value={isDraft}
                            onChange={(e) => handlePrintType(e.target.value)}
                            formControlProps={{ sx: { marginTop: '16px !important' } }}
                        >
                            {printTypeData.map((data, index) => (
                                <MenuItem key={index} value={data.value} sx={{ fontSize: '12px' }}>
                                    {data.name}
                                </MenuItem>
                            ))}
                        </Selection>
                    )}
                </>
            )}

            {isDueDate && (
                <Box display='flex' sx={{ marginTop: '16px !important' }}>
                    <CustomDatePicker
                        label={inputLabel || 'Print Date'}
                        type='date'
                        value={dueDate}
                        onChange={setDueDate}
                        formControlProps={{ sx: { width: '100%' } }}
                        customDateProps={{
                            minDate: backDate ? new Date(backDate) : new Date(minDate),
                            maxDate: new Date(today)
                        }}
                        disabled={PrintCount > 0}
                    />
                </Box>
            )}

            {isRemarks && (
                <Input
                    label={title + ' Reason'}
                    multiline
                    fullWidth
                    value={ConfirmationRemarks}
                    onChange={(e) => setConfirmationRemarks(e.target.value)}
                    formControlProps={{ sx: { width: '100%', marginTop: '16px !important' } }}
                    inputBaseProps={{ rows: 5, sx: { padding: '8px' } }}
                />
            )}
        </>
    ), [
        title,
        titleModal,
        isDraft,
        isAllJob,
        isDueDate,
        dueDate,
        PrintCount,
        shipperInfo,
        containerInfo,
        inputLabel,
        handleChange,
        handlePrintType,
        minDate,
        printJobData,
        printTypeData,
        setDueDate,
        setIsAllJob,
        setIsDraft,
        today,
        isRemarks,
        ConfirmationRemarks,
        setConfirmationRemarks,
        backDate
    ]);

    return (
        <PopUp
            id='modal-id'
            icon={
                <Icon
                    iconName={'approve-doc'}
                    svgIconProps={{
                        htmlColor: '#083A50',
                        sx: {
                            height: '20px',
                            width: '20px'
                        }
                    }}
                />
            }
            title={'Confirm ' + title + ' Data ' + titleModal}
            isOpen={isModal}
            onClose={handleClick}
            draggable={false}
            shrinkable={false}
            disableScrollLock={false}
            maxWidth={isCustomNote ? 'sm' : 'xs'}
            fullWidth={true}
            topButton={true}
        >
            <div style={{ padding: '16px 6px 16px' }}>
                <Box display='flex'>
                    <Typography
                        sx={{
                            fontFamily: 'Nunito',
                            marginTop: '3px !important',
                            fontSize: '14px',
                            fontWeight: '600',
                            lineHeight: 'normal',
                            letterSpacing: 'normal',
                            textAlign: 'left',
                        }}
                    >
                        {isCustomNote ? CustomNote : `Are You Sure Want To ${title} This Data?`}
                    </Typography>
                </Box>

                {mainContainer}
            </div>

            <div style={{ padding: '24px 6px 0px' }}>
                <Box display="flex" alignItems="center">
                    <Grid container item direction="row">
                        <Grid item xs={12} className='text-end' display={'flex'} justifyContent={'flex-end'}>
                            <Button
                                size='small'
                                variant="outlined"
                                className='d-flex align-items-center'
                                sx={{
                                    padding: '4px 12px !important',
                                    gap: '4px',
                                    marginInlineStart: '12px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '32px !important',
                                    borderRadius: '8px',
                                    background: 'var(--Blue-Button-Primary-1, #0087C2)',
                                    '&:hover': {
                                        backgroundColor: 'var(--Blue-Button-Primary-1, #0087C2) !important',
                                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                    }
                                }}
                                onClick={handleConfirmation}
                            >
                                <Icon
                                    svgIconProps={{
                                        htmlColor: '#E4E8EC',
                                        sx: {
                                            height: '16px',
                                            width: '16px',
                                            marginTop: '1px'
                                        }
                                    }}
                                    iconName={'checklist'}
                                />
                                <Typography
                                    sx={{
                                        fontFamily: 'Nunito',
                                        marginTop: '3px !important',
                                        fontSize: '12px',
                                        fontWeight: '600',
                                        lineHeight: '20px',
                                        letterSpacing: '0em',
                                        textAlign: 'center',
                                        color: '#E4E8EC',
                                    }}
                                >
                                    {title}
                                </Typography>
                            </Button>

                            <Button
                                size='small'
                                variant="outlined"
                                className='d-flex align-items-center'
                                sx={{
                                    padding: '4px 12px !important',
                                    gap: '4px',
                                    marginInlineStart: '12px',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '32px !important',
                                    borderRadius: '8px',
                                    border: '1px solid var(--Red-Alert, #D20C0C)',
                                    background: 'rgba(210, 12, 12, 0.25)',
                                    '&:hover': {
                                        border: '1px solid var(--Red-Alert, #D20C0C)',
                                        background: 'rgba(210, 12, 12, 0.25)',
                                        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.20)',
                                    }
                                }}
                                onClick={handleClick}
                            >
                                <Icon
                                    svgIconProps={{
                                        htmlColor: '#D20C0C',
                                        sx: {
                                            height: '14px',
                                            width: '14px',
                                            marginTop: '1px'
                                        }
                                    }}
                                    iconName={'close'}
                                />
                                <Typography
                                    sx={{
                                        fontFamily: 'Nunito',
                                        marginTop: '3px !important',
                                        fontSize: '12px',
                                        fontWeight: '600',
                                        lineHeight: '20px',
                                        textAlign: 'center',
                                        color: '#D20C0C',
                                    }}
                                >
                                    Cancel
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </PopUp>
    );
});

export default React.memo(ModalConfirmation);