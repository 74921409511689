import React, { useState, useEffect, useRef } from 'react';
import { Grid, Stack, IconButton, Box, InputAdornment, Backdrop, Typography, MenuItem, Tooltip } from '@mui/material';

import ApiService from '../../../../../services/ApiService';
import LoadingSpinner from '../../../../../components/Components/LoadingSpinner';
import TableNoFilter from '../../../../../components/Tables/TableNoFilter';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import ButtonAdditionalMemorial from './Buttons/ButtonAdditionalMemorial';
import ModalAdjustment from './Modals/ModalAdjustment';
import SelectDataPeriod from './Selects/SelectDataPeriod';
import SelectDataCOA from './Selects/SelectDataCOA';
import ModalDelete from '../../../../../components/Modals/ModalDelete';
import freezeContainer from '../../../../../Styles/freezeContainer';
import CustomToastContainer, { CustomDatePicker, Icon, Input, Selection, showToast } from '../../../../../components/ui';
import HeaderInfo from '../../../../../components/Components/HeaderInfo';
// import TableCheck from '../../../../../components/Tables/TableCheck';
import ModalConfirmation from '../../../../../components/Modals/ModalConfirmation';
import { updateDateFormat } from '../../../../../utils/date';
import { convertToNominal, newWindowConfig } from '../../../../../utils/masks.jsx';
import { handleDataTable, PrintExcel, PrintPDF } from '../../../../../utils/reusableFunction.jsx';
import MemorialStatus from './Components/MemorialStatus.jsx';

const OutstandingCostEPL = () => {
    const btnPageRef = useRef(null)
    const btnTableRef = useRef(null)
    const coaRef = useRef(null)

    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const paramEPL = 'estimateProfitLoss/estimateProfitLoss';
    const [rowsCount, setRowsCount] = useState(50);
    const [memoData, setMemoData] = useState({});
    const [dataStatus, setDataStatus] = useState('UNAPPROVED');
    // const [dataCheck, setDataCheck] = useState([]);

    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);

    const [dataMap, setDataMap] = useState([]);
    const [columnData, setColumnData] = useState([]);
    const [SelectedData, setSelectedData] = useState({});

    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);

    const [MemorialNo, setMemorialNo] = useState('');
    const [MemorialId, setMemorialId] = useState(0);
    const [MemorialDebit, setMemorialDebit] = useState(0);
    const [isMemorial, setIsMemorial] = useState(false);

    const [COAType, setCOAType] = useState(1);
    const [COACode1, setCOACode1] = useState('');
    const [COAName1, setCOAName1] = useState('');

    const [COACode2, setCOACode2] = useState('');
    const [COAName2, setCOAName2] = useState('');

    const [dataJobType, setDataJobType] = useState([]);
    const [jobTypeId, setJobTypeId] = useState('1');
    const paramRegJobType = 'regJobType/regJobType';

    const TypeData = [
        { id: 1, value: false, name: 'Non - Cost To Cost' },
        { id: 2, value: true, name: 'Cost To Cost' },
    ];
    const [isCostToCost, setIsCostToCost] = useState(false);

    const [PeriodFrom, setPeriodFrom] = useState('');
    const [PeriodTo, setPeriodTo] = useState('');
    const [Period, setPeriod] = useState(0);
    const [YearPeriod, setYearPeriod] = useState(0);
    const [LastMonth, setLastMonth] = useState('');

    const [isModal, setIsModal] = useState(false);
    const [titleModal, setTitleModal] = useState('');

    const [isModal2, setIsModal2] = useState(false);
    const [titleModal2, setTitleModal2] = useState('');

    const paramP = "regClosingPeriod/regClosingPeriod";
    const [isLoadingP, setIsLoadingP] = useState(false);
    const [dataP, setDataP] = useState([]);
    const [dataMapP, setDataMapP] = useState([]);
    const [dataTableP, setDataTableP] = useState([]);
    const [columnDataP, setColumnDataP] = useState([]);
    const [SelectedDataP, setSelectedDataP] = useState({});
    const [totalRowsP, setTotalRowsP] = useState(50);

    const [isModal3, setIsModal3] = useState(false);
    const [titleModal3, setTitleModal3] = useState('');

    const paramC = "regChartOfAccount/regChartOfAccount";
    const [isLoadingC, setIsLoadingC] = useState(false);
    const [dataC, setDataC] = useState([]);
    const [dataMapC, setDataMapC] = useState([]);
    const [dataTableC, setDataTableC] = useState([]);
    const [columnDataC, setColumnDataC] = useState([]);
    const [SelectedDataC, setSelectedDataC] = useState({});
    const [totalRowsC, setTotalRowsC] = useState(50);

    const [TotalUSD, setTotalUSD] = useState(0);
    const [TotalIDR, setTotalIDR] = useState(0);
    const [TotalInIDR, setTotalInIDR] = useState(0);

    const [ShipmentNo, setShipmentNo] = useState('');
    const [Customer, setCustomer] = useState('');
    const [EPLUSD, setEPLUSD] = useState(0);
    const [EPLIDR, setEPLIDR] = useState(0);
    const [InvoicePRUSD, setInvoicePRUSD] = useState(0);
    const [InvoicePRIDR, setInvoicePRIDR] = useState(0);
    const [PreviousUSD, setPreviousUSD] = useState(0);
    const [PreviousIDR, setPreviousIDR] = useState(0);
    const [CurrentUSD, setCurrentUSD] = useState(0);
    const [CurrentIDR, setCurrentIDR] = useState(0);
    const [NextUSD, setNextUSD] = useState(0);
    const [NextIDR, setNextIDR] = useState(0);

    const [isModalDelete, setIsModalDelete] = useState(false);
    const [titleModalDelete, setTitleModalDelete] = useState('');

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');

    const [isFilter, setIsFilter] = useState(false);
    const [isCOA, setIsCOA] = useState(false);

    let countRefresh = 0;

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Outstanding Cost EPL', link: '/Majura/OutstandingCostEPL', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useEffect(() => {
        if (countRefresh === 0) {
            document.title = 'Outstanding Cost EPL - ' + JSON.parse(localStorage.getItem('branchName'))
            ApiService.refreshToken();
            countRefresh++;

            GetJobType(1, 20);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = (pageNumber, pageSize, filters, orderBy, jobTypeId, isCostToCost, Period, YearPeriod, lastDay) => {
        let params = [];
        params = [...params,
        { 'attr': 'jobTypeId', 'value': '' + localStorage.getItem('jobTypeId') },
        { 'attr': 'isCostToCost', 'value': '' + localStorage.getItem('isCostToCost') },
        { 'attr': 'period', 'value': '' + localStorage.getItem('Period') },
        { 'attr': 'yearPeriod', 'value': '' + localStorage.getItem('YearPeriod') },
            // { 'attr': 'lastDayOfMonth' , 'value': '' + localStorage.getItem('lastDay') }
        ]

        if (isLoadingBackDrop === true) {
            setIsLoadingBackDrop(true);
        }
        else {
            setIsLoading(true);
        }

        ApiService.FinAccCustomPostByPage('PostByOSCost', 'memorial/memorial', 'PAGE', pageNumber, pageSize, params, filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({})

                    if (PeriodFrom !== '' && PeriodTo !== '') {
                        if (response.data.data.memorialOSCost.length > 0 && response.data.data.memorial.id !== 0) {
                            setIsMemorial(true);
                        }
                        else {
                            setIsMemorial(false);
                        }

                        setMemoData(response.data.data.memorial);
                        setMemorialId(response.data.data.memorial.id);
                        setMemorialNo(response.data.data.memorial.memorialNo2);
                        setDataStatus(response.data.data.memorial.verifyAcc ? 'APPROVED' : 'UNAPPROVED');
                        setMemorialDebit(convertToNominal(response.data.data.memorial.totalDebitIDR));

                        if (response.data.data.memorial.memorialDetails.length > 0) {
                            if (response.data.data.memorial.memorialDetails[0].dcNote === 'D') {
                                setCOACode1(response.data.data.memorial.memorialDetails[0].coaCode);
                                setCOAName1(response.data.data.memorial.memorialDetails[0].coaName);
                                setCOACode2(response.data.data.memorial.memorialDetails[1].coaCode);
                                setCOAName2(response.data.data.memorial.memorialDetails[1].coaName);
                            }
                            else if (response.data.data.memorial.memorialDetails[0].dcNote === 'C') {
                                setCOACode1(response.data.data.memorial.memorialDetails[1].coaCode);
                                setCOAName1(response.data.data.memorial.memorialDetails[1].coaName);
                                setCOACode2(response.data.data.memorial.memorialDetails[0].coaCode);
                                setCOAName2(response.data.data.memorial.memorialDetails[0].coaName);
                            }
                        }
                        else {
                            setCOACode1('');
                            setCOAName1('');
                            setCOACode2('');
                            setCOAName2('');
                        }

                        calculateTotal(response.data.data.memorialOSCost, true);

                        let temp = response.data.data.memorialOSCost
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataMap(indexed)
                    }

                    setColumnData(response.data.data.columns.osCostColumns)

                    setIsLoadingBackDrop(false);
                    setIsLoading(false)
                }
                else {
                    setIsLoadingBackDrop(false);
                    setIsLoading(false)
                    emptyState();

                    setSelectedData({})
                    setIsMemorial(false);
                    setDataMap([]);
                    calculateTotal([]);
                    setMemorialDebit(0);
                    setMemoData([]);
                    setMemorialNo('');
                    setCOACode1('');
                    setCOAName1('');
                    setCOACode2('');
                    setCOAName2('');
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                setIsLoading(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const GetJobType = (pageNumber, pageSize) => {
        ApiService.PostByPage(paramRegJobType, pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    setDataJobType(response.data.data.jobType);
                    setIsLoadingBackDrop(false);

                    // onRefreshPage();
                } else {
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + response.data.message });
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                console.error('error', error)
                showToast({ type: 'error', message: 'You Don\'t Have Access, Please Contact The Administrator, ' + error.toString() });
            })
    }

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
        }
        else {
            setIsModal(false);
        }
    };

    const onRefreshPage = (jobTypeId = 0, isCostToCost = false, Period = 0, YearPeriod = 0, LastMonth = '', filter = [], sortArr = []) => {
        let defaultJobTypeId = 1;
        const dateNow = new Date();

        let month = parseFloat(dateNow.getMonth() + 1);
        let year = parseFloat(dateNow.getFullYear());

        if (month === 0) {
            month = 11;
            year -= 1;
        }
        else {
            month -= 1;
        }

        let lastDay = new Date(year, month, 0).toISOString();

        if (jobTypeId !== 0) {
            defaultJobTypeId = jobTypeId
        }

        if (Period !== 0) {
            month = Period;
        }

        if (YearPeriod !== 0) {
            year = YearPeriod;
        }

        if (LastMonth !== '') {
            lastDay = LastMonth;
        }

        localStorage.setItem('jobTypeId', defaultJobTypeId)
        localStorage.setItem('isCostToCost', isCostToCost)
        localStorage.setItem('Period', month)
        localStorage.setItem('YearPeriod', year)
        localStorage.setItem('lastDay', lastDay)

        getData(1, 9999999, filter, []);
    }

    const handleReset = () => {
        emptyState();
        onRefreshPage(jobTypeId, isCostToCost, Period, YearPeriod, LastMonth, sortArr);
    };

    const Memorial = () => {
        setIsLoadingBackDrop(true);

        let adjustList = [];

        dataMap.forEach(element => {
            let object = {
                "shipmentId": element.shipmentId,
                "adjustIdr": element.adjustIDR,
                "adjustUsd": element.adjustUSD,
                "customerId": element.customerId,
                "invoiceTo": element.invoiceTo
            }

            adjustList = [...adjustList, object];
        });

        let data = {
            "memorialId": memoData?.id !== undefined ? memoData.id : 0,
            "period": memoData?.period !== 0 ? memoData?.period : Period,
            "yearPeriod": memoData?.yearPeriod !== 0 ? memoData?.yearPeriod : YearPeriod,
            "entryDate": updateDateFormat(memoData.entryDate),
            "jobTypeId": jobTypeId,
            "isCostToCost": isCostToCost,
            "accountDebitCode": COACode1,
            "accountCreditCode": COACode2,
            "adjustList": adjustList
        }

        let message = '';
        if (data.accountDebitCode !== '' && data.accountCreditCode !== '') {
            ApiService.FinAccCustomPost('Memorial/Memorial/CreateMemorialByOSCost', data)
                .then((res) => {
                    if (res.data.code === 200) {
                        message = 'Create Memorial Success';
                        showToast({ type: 'success', message: message });

                        setTimeout(
                            function () {
                                setIsLoadingBackDrop(false);
                                handleReset();
                            },
                            1250);
                    }
                    else {
                        setIsLoadingBackDrop(false);
                        if (res.data.code === 500 && res.data.error === 'Already closed') {
                            showToast({ type: 'error', message: 'Already Closed!' });
                        }
                        else {
                            showToast({ type: 'error', message: 'Failed To Create Data, ' + res.data.message });
                        }
                    }
                })
                .catch(function (error) {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'You Don\'t Have Access, Please Contact The Administrator, ' + error.toString() });
                });
        }
        else {
            setIsLoadingBackDrop(false);
            showToast({ type: 'error', message: 'Please Fill The Chart Of Account First!' });
        }
    }

    const Adjust = () => {
        if (SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            setTitleModal('Adjustment');
            setShipmentNo(SelectedData.shipmentNo);
            setCustomer(SelectedData.customerName);

            setEPLUSD(SelectedData.amountUSDEPL);
            setEPLIDR(SelectedData.amountIDREPL);
            setInvoicePRUSD(SelectedData.amountUSDInv);
            setInvoicePRIDR(SelectedData.amountIDRInv);
            setPreviousUSD(SelectedData.prevAdjustUSD);
            setPreviousIDR(SelectedData.prevAdjustIDR);
            setNextUSD(SelectedData.nextAdjustUSD);
            setNextIDR(SelectedData.nextAdjustIDR);
            setCurrentIDR(SelectedData.adjustIDR);
            setCurrentUSD(SelectedData.adjustUSD);

            handleClick();
        }
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationOIE() {
        if (titleConfirmation === 'Print PDF') {
            HandlePrint('PDF');
        }
        else if (titleConfirmation === 'Print Excel') {
            HandlePrint('Excel');
        }
        setIsModalConfirmation(false);
    }

    const Assessment = () => {
        if (Period !== 0 && YearPeriod !== 0) {
            setTitleConfirmation('Print PDF')
            setTitleModalConfirmation('Outstanding Cost EPL Report');
            handleClickConfirmation();
        }
        else {
            showToast({ type: 'error', message: 'Please Select The Period First!' });
        }
    }

    const PrintXLS = () => {
        if (Period !== 0 && YearPeriod !== 0) {
            setTitleConfirmation('Print Excel')
            setTitleModalConfirmation('Outstanding Cost EPL Report');
            handleClickConfirmation();
        }
        else {
            showToast({ type: 'error', message: 'Please Select The Period First!' });
        }
    }

    const HandlePrint = (type) => {
        setIsLoadingBackDrop(true);
        let types = type === 'PDF' ? 'pdf' : 'xls';
        let reportLink = 'Transactions/Finance/memorialReport/memorialReport/PrintOutstandingCostEPL?typeReport=' + types + '&period=' + Period + '&yearPeriod=' + YearPeriod + '&isCostToCost=' + isCostToCost + '&jobTypeId=' + jobTypeId;
        if (types === 'pdf') {
            PrintPDF(setIsLoadingBackDrop, 'Outstanding Cost EPL', reportLink);
        }
        else {
            PrintExcel(setIsLoadingBackDrop, 'Outstanding Cost EPL', reportLink);
        }
    }

    const ReloadData = () => {
        showToast({ type: 'success', message: 'Reload Data' });
        emptyState();
        onRefreshPage(jobTypeId, isCostToCost, Period, YearPeriod, LastMonth, []);
    }

    const handleClickDelete = () => {
        if (isModalDelete === false) {
            setIsModalDelete(true);
        }
        else {
            setIsModalDelete(false);
        }
    }

    const DeleteData = () => {
        if (SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            setTitleModalDelete('Memorial');
            handleClick();
        }
    }

    const Delete = () => {
        ApiService.FinAccDelete(paramEPL, SelectedData.id).then((res) => {
            if (res.data.code === 200) {
                emptyState();
                getData(1, 99999, filter, [], jobTypeId, isCostToCost, Period, YearPeriod, LastMonth);
                showToast({ type: 'success', message: 'Delete Data Success' });
            }
            else {
                showToast({ type: 'error', message: 'Delete Data Failed' });
            }
        });
    }

    const emptyState = () => {
        setRowsCount(50);
        setGoTo(1);
        setSortArr([]);
        setStateX({});
        setFilter([]);
        setFilterBy([]);
    };

    function CellDoubleClick(row) {
        Adjust();
    };

    function handleSelect(e, c) {
        setJobTypeId(c.props.id);
        emptyState();
        // onRefreshPage(c.props.id, isCostToCost, Period, YearPeriod, LastMonth, []);
    }

    function handleChangeCTC(value) {
        setIsCostToCost(value);
        emptyState();
        // onRefreshPage(jobTypeId, value, Period, YearPeriod, LastMonth, []);
    }

    const handleClick2 = () => {
        if (isModal2 === false) {
            setIsModal2(true);
            getDataPeriod(1, 50);
        }
        else {
            setIsModal2(false);
        }
    };

    const setDataPeriod = () => {
        setTitleModal2('Data Period');
        handleClick2();
    };

    const handleClick3 = () => {
        if (isModal3 === false) {
            setIsModal3(true);
            getDataCOA(1, 50);
        }
        else {
            setIsModal3(false);
        }
    };

    const searchAccount1 = (data) => {
        if (data === 'search') {
            setTitleModal3('Chart of Account (COA)');
            setCOAType(1);
            handleClick3();
        }
        else if (data === 'delete') {
            setCOACode1('');
            setCOAName1('');
        }
    };

    const searchAccount2 = (data) => {
        if (data === 'search') {
            setTitleModal3('Chart of Account (COA)');
            setCOAType(2);
            handleClick3();
        }
        else if (data === 'delete') {
            setCOACode2('');
            setCOAName2('');
        }
    };

    function calculateTotal(result, isFirst = false) {
        var totalUSD = 0;
        var totalIDR = 0;
        var grandTotal = 0;

        result.forEach(element => {
            var AmountUSD = parseFloat(element.amountUSDEPL);

            var InvUSD = parseFloat(element.amountUSDInv);

            var PrevAdjustUSD = parseFloat(element.prevAdjustUSD);

            var AdjustUSD = parseFloat(element.adjustUSD);

            var AmountIDR = parseFloat(element.amountIDREPL);

            var InvIDR = parseFloat(element.amountIDRInv);

            var PrevAdjustIDR = parseFloat(element.prevAdjustIDR);

            var AdjustIDR = parseFloat(element.adjustIDR);

            var Rate = parseFloat(element.rate);

            if (AmountUSD - InvUSD - PrevAdjustUSD - AdjustUSD > 0 || AmountIDR - InvIDR - PrevAdjustIDR - AdjustIDR > 0) {
                if (AmountUSD - InvUSD - AdjustUSD - PrevAdjustUSD > 0) {
                    totalUSD += parseFloat(AmountUSD - InvUSD - AdjustUSD - PrevAdjustUSD);
                }

                if (AmountIDR - InvIDR - AdjustIDR - PrevAdjustIDR > 0) {
                    totalIDR += parseFloat(AmountIDR - InvIDR - AdjustIDR - PrevAdjustIDR);
                }

                if (AmountUSD - InvUSD - AdjustUSD - PrevAdjustUSD > 0) {
                    grandTotal += parseFloat(AmountUSD - InvUSD - AdjustUSD - PrevAdjustUSD) * Rate;
                }

                if (AmountIDR - InvIDR - AdjustIDR - PrevAdjustIDR > 0) {
                    grandTotal += parseFloat(AmountIDR - InvIDR - AdjustIDR - PrevAdjustIDR);
                }
            }
        });

        setTotalUSD(convertToNominal(totalUSD));
        setTotalIDR(convertToNominal(totalIDR));
        setTotalInIDR(convertToNominal(grandTotal));
    }

    const getDataPeriod = (pageNumber, pageSize, filters) => {
        setIsLoadingP(true);
        ApiService.DynamicPostByPage(paramP, 'SEARCH', pageNumber, pageSize, [], filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataP({})
                    setDataP(response.data.data.closingPeriod);

                    let temp = response.data.data.closingPeriod
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapP(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTableP, 'id');
                    setColumnDataP(response.data.data.columns)
                    setTotalRowsP(response.data.totalRowCount)

                    setIsLoadingP(false)
                }
                setIsLoading(false)
            })
            .catch(function (error) {
                setIsLoadingP(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const getDataCOA = (pageNumber, pageSize, filters) => {
        setIsLoadingC(true);
        let params = [];
        ApiService.COAPostBySearch(paramC, pageNumber, pageSize, params, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataC({})
                    setDataC(response.data.data.chartOfAccount);

                    let temp = response.data.data.chartOfAccount
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapC(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTableC, 'id');
                    setColumnDataC(response.data.data.columns)
                    setTotalRowsC(response.data.totalRowCount)

                    setIsLoadingC(false)
                }
                setIsLoadingC(false)
            })
            .catch(function (error) {
                setIsLoadingC(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const getContainerHeight = () => {
        const hTitle = 64
        const hHeader = 116
        const coaHeight = !isCOA ? coaRef?.current?.clientHeight || 0 : 0
        const hPagination = filter.length > 0 ? 195 : 0
        const hPadding = isCOA ? 35 * 2 : 10
        const hBtnPage = btnPageRef?.current?.clientHeight || 28
        const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

        const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable + hPagination + coaHeight
        return `${totalHeight}px`
    }

    const handleShowWidget = (type, value) => {
        if (type === 'filter') {
            setIsFilter(value)
        }
        else if (type === 'coa') {
            setIsCOA(value)
        }

        setTimeout(function () { setIsLoading(false); }, 1250);
    }

    const ButtonComponents = () => {
        return (
            <div>
                <ButtonAdditionalMemorial
                    Memorial={Memorial}
                    Adjust={Adjust}
                    Assessment={Assessment}
                    ReloadData={ReloadData}
                    DeleteData={DeleteData}
                    PrintExcel={PrintXLS}
                    jobTypeId={jobTypeId}
                    handleSelect={handleSelect}
                    dataJobType={dataJobType}
                    isCostToCost={isCostToCost}
                    setIsCostToCost={setIsCostToCost}
                    TypeData={TypeData}
                    PeriodFrom={PeriodFrom}
                    PeriodTo={PeriodTo}
                    setDataPeriod={setDataPeriod}
                    isFilter={isFilter}
                    handleShowWidget={handleShowWidget}
                    isCOA={isCOA}
                />

                <Stack spacing={'16px'} direction="row" alignItems={'center'} sx={{ width: '75%', marginTop: '16px' }} hidden={isFilter}>
                    <Selection
                        id='0'
                        label={'Job Type'}
                        value={jobTypeId}
                        minWidth={263}
                        placeholder={'Select Job Type'}
                        onChange={(e, c) => handleSelect(e, c)}
                        formControlProps={{
                            sx: {
                                width: '50%'
                            }
                        }}
                    >
                        {
                            dataJobType.map((data) => (
                                <MenuItem sx={{ fontSize: '12px', 'textAlign': 'center' }} id={data.id} key={data.code} value={data.id}>
                                    {data.name}
                                </MenuItem>
                            ))
                        }
                    </Selection>

                    <Selection
                        id='0'
                        label={'Type'}
                        minWidth={263}
                        placeholder={'Select Type'}
                        value={isCostToCost}
                        onChange={(e) => handleChangeCTC(e.target.value)}
                        formControlProps={{
                            sx: {
                                width: '50%'
                            }
                        }}
                    >
                        {
                            TypeData.map((data, index) => (
                                <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.value}>{data.name}</MenuItem>
                            ))
                        }
                    </Selection>

                    <CustomDatePicker
                        type={'date'}
                        label='Periode From'
                        value={PeriodFrom}
                        formControlProps={{
                            sx: {
                                width: '50%'
                            }
                        }}
                        disabled
                    />

                    <CustomDatePicker
                        type={'date'}
                        label='Periode To'
                        value={PeriodTo}
                        formControlProps={{
                            sx: {
                                width: '50%'
                            }
                        }}
                        disabled
                    />

                    <Tooltip title={'Select Period'}>
                        <IconButton onClick={setDataPeriod} sx={{ marginTop: '20px !important' }}>
                            <Icon
                                iconName={'three-dot'}
                                svgIconProps={{
                                    fontSize: 'small',
                                    htmlColor: '#0087C2',
                                    sx: {
                                        height: '18px',
                                        width: '18px'
                                    }
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </div>
        )
    }

    function setNumPage() {
        return null;
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: '24px 32px', ...freezeContainer }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    btnPageRef={btnPageRef}
                    title={'Outstanding Cost EPL'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
                    <div ref={btnTableRef}>
                        {
                            ButtonComponent.RefreshButton(
                                sortArr,
                                rowsCount,
                                columnData,
                                setGoTo,
                                handleReset,
                                setFilterBy,
                                filterBy,
                                setFilter,
                                setNumPage,
                                setRowsCount,
                                getData,
                                stateX,
                                setStateX
                            )
                        }
                    </div>

                    <div
                        className="rounded-10 table-responsive"
                        style={{
                            maxHeight: `calc(100vh - ${getContainerHeight()})`,
                            marginTop: '16px',
                            padding: '2px 16px',
                            borderRadius: '12px',
                            background: '#F2F2F2'
                        }}
                    >
                        {
                            isLoading ?
                                <LoadingSpinner />
                                :
                                <>
                                    {/* <TableCheck uniqueKey = {'id'} 
                                        rowsCount={rowsCount} setNumPage={setNumPage} 
                                        setRowsCount={setRowsCount} getData={getData}
                                        columnData={columnData} dataMap={dataMap}
                                        SelectedData={SelectedData} setSelectedData={setSelectedData}
                                        setFilter={setFilter} setFilterBy={setFilterBy}
                                        dataCheck={dataCheck} setDataCheck={setDataCheck}
                                        stateX={stateX} setStateX={setStateX}
                                        sortArr={sortArr} setSortArr={setSortArr}
                                        setGoTo={setGoTo} goTo = {goTo}
                                        CellDoubleClick={CellDoubleClick}
                                    /> */}

                                    <TableNoFilter
                                        uniqueKey={'id'}
                                        goTo={goTo}
                                        rowsCount={rowsCount}
                                        setNumPage={setNumPage}
                                        setRowsCount={setRowsCount}
                                        getData={getData}
                                        columnData={columnData}
                                        dataMap={dataMap}
                                        SelectedData={SelectedData}
                                        setSelectedData={setSelectedData}
                                        setFilter={setFilter}
                                        setFilterBy={setFilterBy}
                                        CellDoubleClick={CellDoubleClick}
                                        stateX={stateX}
                                        setStateX={setStateX}
                                        sortArr={sortArr}
                                        setSortArr={setSortArr}
                                        setGoTo={setGoTo}
                                    />
                                </>
                        }
                    </div>

                    <div style={{ marginTop: '16px' }}>
                        <Grid
                            container
                            item
                            direction="row"
                            spacing={'64px'}
                        >
                            <Grid item xs={6} display={'flex'}>
                                <Stack direction="column" sx={{ width: '100%' }}>
                                    {
                                        isMemorial ?
                                            <Stack direction='row' spacing={'8px'} sx={{ width: '100%', alignItems: 'center' }}>
                                                <Typography
                                                    component="a"
                                                    onClick={() => window.open('/Majura/Memorial/' + MemorialId, newWindowConfig)}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    sx={{
                                                        color: 'var(--Primary-One, #D20C0C)',
                                                        fontFamily: 'Nunito',
                                                        fontSize: '16px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '700',
                                                        textDecoration: 'none',
                                                        '&:hover': {
                                                            textDecoration: 'underline',
                                                        },
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    MEMORIAL NO. {!isLoadingBackDrop ? `${MemorialNo}` : 0} = Rp {!isLoadingBackDrop ? `${MemorialDebit}` : 0}
                                                </Typography>

                                                <MemorialStatus documentStatus={dataStatus} />
                                            </Stack>
                                            :
                                            <></>
                                    }

                                    <div hidden={isCOA} ref={coaRef} style={{ width: '100%' }}>
                                        <Stack columnGap={'24px'} direction="row" sx={{ marginTop: '10px !important' }}>
                                            <Input
                                                label='Account Code'
                                                value={COACode1}
                                                disabled
                                                formLabelProps={{
                                                    sx: {
                                                        fontSize: '12px',
                                                    }
                                                }}
                                                inputBaseProps={{
                                                    sx: {
                                                        fontSize: '12px !important',
                                                        minHeight: '28px !important',
                                                        maxHeight: '28px !important',
                                                        padding: '3px 6px',
                                                    }
                                                }}
                                            />

                                            <Input
                                                variant="standard"
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                value={COAName1}
                                                type='text'
                                                label={'Account Name'}
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                    }
                                                }}
                                                formLabelProps={{
                                                    sx: {
                                                        fontSize: '12px',
                                                    }
                                                }}
                                                inputBaseProps={{
                                                    sx: {
                                                        fontSize: '12px !important',
                                                        minHeight: '28px !important',
                                                        maxHeight: '28px !important',
                                                        padding: '3px 6px',
                                                    },
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => searchAccount1('delete')}
                                                                hidden={COAName1 ? false : true}
                                                            >
                                                                <Icon
                                                                    iconName={'close'}
                                                                    svgIconProps={{
                                                                        fontSize: 'small',
                                                                        htmlColor: '#0087C2',
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>

                                                            <IconButton onClick={() => searchAccount1('search')}>
                                                                <Icon
                                                                    iconName={'search'}
                                                                    svgIconProps={{
                                                                        fontSize: 'small',
                                                                        htmlColor: '#0087C2',
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                disabled
                                            />
                                        </Stack>

                                        <Stack spacing={'24px'} direction="row" sx={{ marginTop: '12px' }}>
                                            <Input
                                                label='Account Code'
                                                value={COACode2}
                                                disabled
                                                formControlProps={{
                                                    sx: {
                                                        marginTop: '24px'
                                                    }
                                                }}
                                                formLabelProps={{
                                                    sx: {
                                                        fontSize: '12px',
                                                    }
                                                }}
                                                inputBaseProps={{
                                                    sx: {
                                                        fontSize: '12px !important',
                                                        minHeight: '28px !important',
                                                        maxHeight: '28px !important',
                                                        padding: '3px 6px',
                                                    }
                                                }}
                                            />

                                            <Input
                                                variant="standard"
                                                InputLabelProps={{ shrink: true }}
                                                fullWidth
                                                value={COAName2}
                                                type='text'
                                                label={'Account Name'}
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                    }
                                                }}
                                                formLabelProps={{
                                                    sx: {
                                                        fontSize: '12px',
                                                    }
                                                }}
                                                inputBaseProps={{
                                                    sx: {
                                                        fontSize: '12px !important',
                                                        minHeight: '28px !important',
                                                        maxHeight: '28px !important',
                                                        padding: '3px 6px',
                                                    },
                                                    readOnly: true,
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => searchAccount2('delete')}
                                                                hidden={COAName2 ? false : true}
                                                            >
                                                                <Icon
                                                                    iconName={'close'}
                                                                    svgIconProps={{
                                                                        fontSize: 'small',
                                                                        htmlColor: '#0087C2',
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>

                                                            <IconButton onClick={() => searchAccount2('search')}>
                                                                <Icon
                                                                    iconName={'search'}
                                                                    svgIconProps={{
                                                                        fontSize: 'small',
                                                                        htmlColor: '#0087C2',
                                                                        sx: {
                                                                            height: '12px',
                                                                            width: '12px'
                                                                        }
                                                                    }}
                                                                />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                                disabled
                                            />
                                        </Stack>
                                    </div>
                                </Stack>
                            </Grid>

                            <Grid item xs={6} alignItems='right' className='text-end' display={'flex'} justifyContent={'flex-end'}>
                                <Stack direction={'column'}>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Nunito',
                                            fontSize: '16px',
                                            fontStyle: 'normal',
                                            fontWeight: '700',
                                            lineHeight: '19.6px',
                                            marginBottom: '8px'
                                        }}
                                    >
                                        Total
                                    </Typography>

                                    <Grid
                                        container
                                        spacing={'32px'}
                                    >
                                        <Grid
                                            item
                                            xl={4}
                                            lg={4}
                                            sm={4}
                                            xs={12}
                                        >
                                            <Input
                                                label='USD'
                                                value={TotalUSD}
                                                textAlign='right'
                                                disabled
                                                fullWidth
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                    }
                                                }}
                                                formLabelProps={{
                                                    sx: {
                                                        fontSize: '12px',
                                                    }
                                                }}
                                                inputBaseProps={{
                                                    sx: {
                                                        fontSize: '12px !important',
                                                        minHeight: '28px !important',
                                                        maxHeight: '28px !important',
                                                        padding: '3px 6px',
                                                    }
                                                }}
                                            />
                                        </Grid>

                                        <Grid
                                            item
                                            xl={4}
                                            lg={4}
                                            sm={4}
                                            xs={12}
                                        >
                                            <Input
                                                label='IDR'
                                                value={TotalIDR}
                                                textAlign='right'
                                                disabled
                                                fullWidth
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                    }
                                                }}
                                                formLabelProps={{
                                                    sx: {
                                                        fontSize: '12px',
                                                    }
                                                }}
                                                inputBaseProps={{
                                                    sx: {
                                                        fontSize: '12px !important',
                                                        minHeight: '28px !important',
                                                        maxHeight: '28px !important',
                                                        padding: '3px 6px',
                                                    }
                                                }}
                                            />
                                        </Grid>

                                        <Grid
                                            item
                                            xl={4}
                                            lg={4}
                                            sm={4}
                                            xs={12}
                                        >
                                            <Input
                                                label='In IDR'
                                                value={TotalInIDR}
                                                textAlign='right'
                                                disabled
                                                fullWidth
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                    }
                                                }}
                                                formLabelProps={{
                                                    sx: {
                                                        fontSize: '12px',
                                                    }
                                                }}
                                                inputBaseProps={{
                                                    sx: {
                                                        fontSize: '12px !important',
                                                        minHeight: '28px !important',
                                                        maxHeight: '28px !important',
                                                        padding: '3px 6px',
                                                    }
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Stack>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            {
                isModal && (
                    <ModalAdjustment
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}

                        ShipmentNo={ShipmentNo}
                        Customer={Customer}
                        calculateTotal={calculateTotal}
                        EPLUSD={EPLUSD}
                        setEPLUSD={setEPLUSD}
                        EPLIDR={EPLIDR}
                        setEPLIDR={setEPLIDR}
                        InvoicePRUSD={InvoicePRUSD}
                        setInvoicePRUSD={setInvoicePRUSD}
                        InvoicePRIDR={InvoicePRIDR}
                        setInvoicePRIDR={setInvoicePRIDR}
                        PreviousUSD={PreviousUSD}
                        setPreviousUSD={setPreviousUSD}
                        PreviousIDR={PreviousIDR}
                        setPreviousIDR={setPreviousIDR}
                        CurrentUSD={CurrentUSD}
                        setCurrentUSD={setCurrentUSD}
                        CurrentIDR={CurrentIDR}
                        setCurrentIDR={setCurrentIDR}
                        NextUSD={NextUSD}
                        setNextUSD={setNextUSD}
                        NextIDR={NextIDR}
                        setNextIDR={setNextIDR}
                        SelectedData={SelectedData}
                        dataMap={dataMap}
                        setDataMap={setDataMap}
                    />
                )
            }

            {
                isModal2 && (
                    <SelectDataPeriod
                        isModal={isModal2}
                        handleClick={handleClick2}
                        titleModal={titleModal2}
                        setIsModal={setIsModal2}

                        getData={getDataPeriod}
                        isLoading={isLoadingP}
                        totalRows={totalRowsP}
                        setTotalRows={setTotalRowsP}
                        SelectedData={SelectedDataP}
                        setSelectedData={setSelectedDataP}
                        data={dataP}
                        columnData={columnDataP}
                        dataMap={dataMapP}
                        dataTable={dataTableP}

                        setPeriodFrom={setPeriodFrom}
                        setPeriodTo={setPeriodTo}
                        setPeriod={setPeriod}
                        setYearPeriod={setYearPeriod}
                        setLastMonth={setLastMonth}
                    />
                )
            }

            {
                isModal3 && (
                    <SelectDataCOA
                        isModal={isModal3}
                        handleClick={handleClick3}
                        titleModal={titleModal3}
                        setIsModal={setIsModal3}
                        COAType={COAType}

                        getData={getDataCOA}
                        isLoading={isLoadingC}
                        totalRows={totalRowsC}
                        setTotalRows={setTotalRowsC}
                        SelectedData={SelectedDataC}
                        setSelectedData={setSelectedDataC}
                        data={dataC}
                        columnData={columnDataC}
                        dataMap={dataMapC}
                        dataTable={dataTableC}

                        setCOACode1={setCOACode1}
                        setCOAName1={setCOAName1}
                        setCOACode2={setCOACode2}
                        setCOAName2={setCOAName2}
                    />
                )
            }

            {
                isModalDelete && (
                    <ModalDelete
                        isModal={isModalDelete}
                        titleModal={titleModalDelete}
                        handleClick={handleClickDelete}
                        DeleteData={Delete}
                    />
                )
            }

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationOIE}
                    />
                )
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </Box >
    )
}

export default OutstandingCostEPL