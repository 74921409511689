import { Box, Grid } from '@mui/material';
import React, { useState } from 'react';

import LoadingSpinner from '../../../../../components/Components/LoadingSpinner';
import TableX from '../../../../../components/Tables/TableX';
import ApiService, { branchId, companyId, countryId, userId, userCode } from '../../../../../services/ApiService';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';
import { Icon, Paginate, PopUp, showToast } from '../../../../../components/ui';
import { defaultDate } from '../../../../../utils/date';
import { handleDataTable } from '../../../../../utils/reusableFunction';

const SelectDataTemporaryPayment = (props) => {
    const param1 = "temporaryPayment/temporaryPayment";
    const param2 = 'temporaryReceipt/temporaryReceipt';
    const [rowsCount, setRowsCount] = useState(50)
    const [numPage, setNumPage] = useState(1)
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [sortArr, setSortArr] = useState([]);
    const [stateX, setStateX] = useState({});
    const [goTo, setGoTo] = useState(1);

    const [isLoading2, setIsLoading2] = useState(false);
    const [rowsCount2, setRowsCount2] = useState(50)
    const [numPage2, setNumPage2] = useState(1)
    const [dataMap2, setDataMap2] = useState([])
    const [dataTable2, setDataTable2] = useState([])
    const [columnData2, setColumnData2] = useState([])
    const [totalRows2, setTotalRows2] = useState(50);
    const [filter2, setFilter2] = useState([]);
    const [filterBy2, setFilterBy2] = useState([]);
    const [SelectedData2, setSelectedData2] = useState({});
    const [stateX2, setStateX2] = useState({});
    const [sortArr2, setSortArr2] = useState([]);
    const [goTo2, setGoTo2] = useState(1);

    const emptyState = () => {
        setNumPage(1);
        setRowsCount(50);
        props.setTotalRows(50);
        setFilter([]);
        setFilterBy([]);
        setGoTo(1);
        setSortArr([]);
        setStateX({});

        setNumPage2(1);
        setSelectedData2({});
        setColumnData2([]);
        setRowsCount2(50);
        setTotalRows2(50);
        setFilter2([]);
        setFilterBy2([]);
        setDataMap2([]);
        setDataTable2([]);
        setStateX({})
        setStateX2({})
    };

    const getData2 = (pageNumber, pageSize, filters, id) => {
        setIsLoading2(true);
        if (props.ReceiptBy === 'T') {
            let params = [
                {
                    "attr": "tpId",
                    "value": '' + localStorage.getItem('dataId')
                },
                {
                    'attr': 'isPivot',
                    "value": 'true'
                },
                {
                    'attr': 'RVIdCI',
                    'value': '0'
                },
                {
                    'attr': 'RVIdCU',
                    'value': '0'
                },
                {
                    'attr': 'RVIdBI',
                    'value': '0'
                },
                {
                    'attr': 'RVIdBU',
                    'value': '0'
                }
            ]
            ApiService.FinAccPostByDetail(param1, pageNumber, pageSize, params, filters, 'SEARV')
                .then((response) => {
                    if (response.data.code === 200) {

                        let temp = response.data.data.temporaryPaymentJob
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })
                        setDataMap2(indexed)

                        handleDataTable(indexed, response.data.data.columns.jobColumns, setDataTable2, 'id');
                        setColumnData2(response.data.data.columns.jobColumns)
                        setTotalRows2(response.data.totalRowCount)

                        setIsLoading2(false)
                    }
                    setIsLoading2(false)
                })
                .catch(function (error) {
                    setIsLoading2(false)
                    console.error('error saat fetch', error)
                    showToast({ type: 'error', message: error.toString() });
                })
        }
        else if (props.ReceiptBy === 'D') {
            let params = [
                {
                    "attr": "trId",
                    "value": '' + localStorage.getItem('dataId')
                },
                {
                    'attr': 'isPivot',
                    "value": 'true'
                },
                {
                    'attr': 'RVIdCI',
                    'value': '0'
                },
                {
                    'attr': 'RVIdCU',
                    'value': '0'
                },
                {
                    'attr': 'RVIdBI',
                    'value': '0'
                },
                {
                    'attr': 'RVIdBU',
                    'value': '0'
                }
            ]
            ApiService.FinAccPostByDetail(param2, pageNumber, pageSize, params, filters, 'SEARV')
                .then((response) => {
                    if (response.data.code === 200) {

                        let temp = response.data.data.temporaryReceiptJob
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })
                        setDataMap2(indexed)

                        handleDataTable(indexed, response.data.data.columns.jobColumns, setDataTable2, 'id');
                        setColumnData2(response.data.data.columns.jobColumns)
                        setTotalRows2(response.data.totalRowCount)

                        setIsLoading2(false)
                    }
                    setIsLoading2(false)
                })
                .catch(function (error) {
                    setIsLoading2(false)
                    console.error('error saat fetch', error)
                    showToast({ type: 'error', message: error.toString() });
                })
        }
    };

    function SelectData() {
        if (SelectedData2.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (!props.DataReceiptVoucherId.includes(SelectedData2.id)) {
                let Sequence = props.detailSequence + 1;
                let rvId = props.receiptVoucherId ? props.receiptVoucherId : 0;
                let amountUSD = SelectedData2?.amountCrr === 0 ? SelectedData2?.amount : 0;
                let amountIDR = SelectedData2?.amountCrr !== 0 ? SelectedData2?.amount : 0;
                let paymentMethod = SelectedData2?.paymentMethod ? SelectedData2?.paymentMethod : '';

                let data = {
                    "countryId": countryId,
                    "companyId": companyId,
                    "branchId": branchId,
                    "userId": userId,
                    "userCode": userCode,

                    "rowStatus": "ACT",
                    "sequence": Sequence,
                    "id": 0,
                    "rvId": rvId,
                    "rvNo": props.RVNo,
                    "description": props.description,
                    "dcNote": 'C',
                    "amountUSD": amountUSD,
                    "amountIDR": amountIDR,
                    "receiptCashUSD": 0,
                    "receiptCashIDR": 0,
                    "receiptBankIDR": 0,
                    "receiptBankUSD": 0,
                    "refId": props.refId,
                    "refDetailId": SelectedData2?.id,
                    "refNo": props.refNo,
                    "coaCode": props.refAccountCode,
                    "coaName": props.refAccountName,
                    "shipmentOrderId": SelectedData2?.shipmentOrderId,
                    "shipmentNo": SelectedData2?.shipmentNo,
                    "customerId": props.refCustomerId,
                    "customerName": props.refCustomerName,
                    "exRateId": props.exRateId,
                    "refRate": props.refRate,
                    "linkDetail": 0,
                    "paidOn": defaultDate,
                    "paymentMethod": paymentMethod,
                    "saveOR": false,
                    "seqDetail": 0,
                    "tandaMigrasi": 0,
                    "isStored": false,

                    "idLama": 0,
                    "receiptVoucherDetailBanks": [],
                }

                let arrayData = props.DataReceiptVoucher;
                arrayData = [...arrayData, data];
                props.setDataReceiptVoucher(arrayData);

                let arrayDataId = props.DataReceiptVoucherId;
                arrayDataId = [...arrayDataId, data.refDetailId];
                props.setDataReceiptVoucherId(arrayDataId);

                props.calculateTotalDebitCredit(arrayData, props.Rate);

                emptyState();
                props.setIsModal(false);
                props.emptyState();
                props.handleClose();
                props.handleClick();
                props.setDetailEdit(false);
                props.setSelectedDetail({});
            }
            else {
                showToast({ type: 'error', message: 'Data Has Been Selected!' });
                emptyState();
                props.setRefId(0);
                props.setExRateId(0);
                props.setRefRate(0);
                
                props.setRefNo('');
                props.setRefAccountCode('');
                props.setRefAccountName('');
                props.setDescription('');
            }
        }
    };

    function Cancel() {
        emptyState();
        props.handleClick();
    };

    function handleGetData(data) {
        props.setRefId(data.id);
        props.setExRateId(data.exRateId);
        props.setRefRate(data.rate);
        if (props.ReceiptBy === 'T') {
            props.setRefNo(data.tpNo2);
            props.setRefAccountCode(data.accountCode);
            props.setRefAccountName(data.accountName);
            props.setDescription('Refund Jaminan TP No. ' + data.tpNo2);
        }
        else if (props.ReceiptBy === 'D') {
            props.setRefNo(data.trNo2);
            props.setRefAccountCode(data.accountCode);
            props.setRefAccountName(data.accountName);
            props.setDescription('Refund Jaminan DP Invoice No. ' + data.trNo2);
        }
        localStorage.setItem('dataId', JSON.stringify(data.id));
        getData2(1, 50, filter2);
    }

    const handleReset = () => {
        emptyState();
        props.getData(1, 50);
    };

    const handleReset2 = () => {
        setFilter2([]);
        setFilterBy2([]);
        getData2(1, 50, []);
    };

    function CellDoubleClick1(row) {
        handleGetData(row);
    }

    function CellDoubleClick2(row) {
        SelectData();
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        props.getData(value, rowsCount, filter, sortArr);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        props.getData(1, value, filter, sortArr);
    }

    const onChangePaging2 = (value) => {
        setNumPage2(value);
        getData2(value, rowsCount, filter);
    }

    const onChangeLimit2 = (value) => {
        setNumPage2(1);
        setRowsCount2(value);
        getData2(1, value, filter);
    }

    const mainContainer = (
        <>
            <Grid item xs={6} style={{ width: "100%", borderRadius: "5px" }}>
                <Box height='55%'>
                    <div>
                        {
                            ButtonComponent.RefreshButton(sortArr, rowsCount, props.columnData,
                                setGoTo, handleReset, setFilterBy,
                                filterBy, setFilter, setNumPage,
                                setRowsCount, props.getData, stateX, setStateX
                            )
                        }
                    </div>

                    <div
                        className="rounded-10 table-responsive"
                        style={{
                            maxHeight: '375px',
                            marginTop: '16px',
                            padding: '2px 16px',
                            borderRadius: '12px',
                            background: '#F2F2F2'
                        }}
                    >
                        {
                            props.isLoading ?
                                (
                                    <LoadingSpinner />
                                )
                                :
                                (
                                    <>
                                        <div className="mt-1">
                                            <TableX
                                                uniqueKey={'id'}
                                                goTo={goTo}
                                                rowsCount={rowsCount}
                                                setNumPage={setNumPage}
                                                setRowsCount={setRowsCount}
                                                getData={props.getData}
                                                columnData={props.columnData}
                                                dataMap={props.dataMap}
                                                dataTable={props.dataTable}
                                                SelectedData={props.SelectedData}
                                                setSelectedData={props.setSelectedData}
                                                setFilter={setFilter}
                                                setFilterBy={setFilterBy}
                                                CellDoubleClick={CellDoubleClick1}
                                                stateX={stateX}
                                                setStateX={setStateX}
                                                sortArr={sortArr}
                                                setSortArr={setSortArr}
                                                setGoTo={setGoTo}
                                                handleGetData={handleGetData}
                                            />
                                        </div>
                                    </>
                                )
                        }
                    </div>

                    <div style={{ marginTop: '16px' }}>
                        <Paginate
                            page={numPage}
                            limit={rowsCount}
                            totalData={props.totalRows}
                            onChange={onChangePaging}
                            onChangeLimit={onChangeLimit}
                            size='small'
                        />
                    </div>
                </Box>
            </Grid>
        </>
    );

    const mainContainer2 = (
        <>
            <Grid item xs={6} style={{ width: "100%", borderRadius: "5px" }}>
                <Box height='55%'>
                    <div>
                        {
                            ButtonComponent.RefreshButton(sortArr, rowsCount, props.columnData2,
                                setGoTo, handleReset2, setFilterBy2,
                                filterBy2, setFilter2, setNumPage2,
                                setRowsCount2, getData2, stateX2, setStateX2
                            )
                        }
                    </div>

                    <div
                        className="rounded-10 table-responsive"
                        style={{
                            maxHeight: '375px',
                            marginTop: '16px',
                            padding: '2px 16px',
                            borderRadius: '12px',
                            background: '#F2F2F2'
                        }}
                    >
                        {
                            isLoading2 ?
                                (
                                    <LoadingSpinner />
                                )
                                :
                                (
                                    <>
                                        <div className="mt-1">
                                            <TableX
                                                uniqueKey={'id'}
                                                goTo={goTo2}
                                                rowsCount={rowsCount2}
                                                setNumPage={setNumPage2}
                                                setRowsCount={setRowsCount2}
                                                getData={getData2}
                                                columnData={columnData2}
                                                dataMap={dataMap2}
                                                dataTable={dataTable2}
                                                SelectedData={SelectedData2}
                                                setSelectedData={setSelectedData2}
                                                setFilter={setFilter2}
                                                setFilterBy={setFilterBy2}
                                                CellDoubleClick={CellDoubleClick2}
                                                stateX={stateX2}
                                                setStateX={setStateX2}
                                                sortArr={sortArr2}
                                                setSortArr={setSortArr2}
                                                setGoTo={setGoTo2}
                                            />
                                        </div>
                                    </>
                                )
                        }
                    </div>

                    <div style={{ marginTop: '16px' }}>
                        <Paginate
                            page={numPage2}
                            limit={rowsCount2}
                            totalData={totalRows2}
                            onChange={onChangePaging2}
                            onChangeLimit={onChangeLimit2}
                            size='small'
                        />
                    </div>
                </Box>
            </Grid>
        </>
    );


    return (
        <PopUp
            id='modal-id'
            icon={
                <Icon
                    iconName={'account'}
                    svgIconProps={{
                        htmlColor: '#0087C2',
                        sx: {
                            height: '24px',
                            width: '24px'
                        }
                    }}
                />
            }
            title={'Select ' + props.titleModal}
            isOpen={props.isModal}
            onClose={Cancel}
            draggable={false}
            shrinkable={false}
            disableScrollLock={false}
            maxWidth={'lg'}
            fullWidth={true}
        >
            <div style={{ padding: '0px 6px' }}>
                <Grid container spacing={'16px'}>
                    {mainContainer}
                    {mainContainer2}
                </Grid>

                <div style={{ padding: '24px 0px 0px' }}>
                    {CustomizeButtonComponent.SelectModalButton(SelectData)}
                </div>
            </div>
        </PopUp>
    )
}

export default SelectDataTemporaryPayment;