import { Grid, Stack, IconButton, InputAdornment } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import ApiService, { isInfossNo } from '../../../../../services/ApiService';
import SelectDataEmployee from '../Selects/SelectDataEmployee';
import SelectDataBankAdvance from '../Selects/SelectDataBankAdvance';
import { CustomDatePicker, Icon, Input, showToast } from '../../../../../components/ui';
import { handleDataTable } from '../../../../../utils/reusableFunction';

const BankAdvSettlementHeader = (props) => {
    const [titleModalBA, setTitleModalBA] = useState('');
    const [isModalBA, setIsModalBA] = useState(false);
    const paramBA = "bankAdvance/bankAdvance";
    const [isLoadingBA, setIsLoadingBA] = useState(false);
    const [dataBA, setDataBA] = useState([]);
    const [SelectedDataBA, setSelectedDataBA] = useState({});
    const [totalRowsBA, setTotalRowsBA] = useState(50);
    const [dataMapBA, setDataMapBA] = useState([]);
    const [dataTableBA, setDataTableBA] = useState([]);
    const [columnDataBA, setColumnDataBA] = useState([]);

    const [titleModalE, setTitleModalE] = useState('');
    const [isModalE, setIsModalE] = useState(false);
    const paramE = "employee/employee";
    const [isLoadingE, setIsLoadingE] = useState(false);
    const [dataE, setDataE] = useState([]);
    const [SelectedDataE, setSelectedDataE] = useState({});
    const [totalRowsE, setTotalRowsE] = useState(50);
    const [dataMapE, setDataMapE] = useState([]);
    const [dataTableE, setDataTableE] = useState([]);
    const [columnDataE, setColumnDataE] = useState([]);

    const handleClickBankAdvance = () => {
        if (isModalBA === false) {
            setIsModalBA(true);
            getDataBankAdvance(1, 50, []);
        }
        else {
            setIsModalBA(false);
        }
    };

    const searchBankAdvance = (data) => {
        if (data === 'search') {
            if (props.EmployeeId !== 0) {
                setTitleModalBA('Bank Advance');
                handleClickBankAdvance();
            }
            else {
                showToast({ type: 'error', message: 'Please Select Data Personal First!' });
            }
        }
        else if (data === 'delete') {
            props.setBankAdvanceId(0);
            props.setBankAdvanceNo('');

            props.setBankId(0);
            props.setBankCode('');
            props.setBankIntName('');
            props.setBankName('');
            props.setACNumber('');

            props.setTotalUSD(0);
            props.setTotalIDR(0);

            props.setBalanceBAIDR(0);
            props.setBalanceBAUSD(0);
            props.setBalanceSettlementIDR(0);
            props.setBalanceSettlementUSD(0);
            props.setBalanceIDR(0);
            props.setBalanceUSD(0);
        }
    };

    const handleClickEmployee = () => {
        if (isModalE === false) {
            setIsModalE(true);
            getDataEmployee(1, 50);
        }
        else {
            setIsModalE(false);
        }
    };

    const searchEmployee = (data) => {
        if (data === 'search') {
            setTitleModalE('Employee');
            handleClickEmployee();
        }
        else if (data === 'delete') {
            props.setEmployeeId(0);
            props.setEmployeeCode('');
            props.setEmployeeName('');
            props.setEmployeeNickname('');

            props.setBankAdvanceId(0);
            props.setBankAdvanceNo('');

            props.setBankId(0);
            props.setBankCode('');
            props.setBankIntName('');
            props.setBankName('');
            props.setACNumber('');

            props.setTotalUSD(0);
            props.setTotalIDR(0);

            props.setBalanceBAIDR(0);
            props.setBalanceBAUSD(0);
            props.setBalanceSettlementIDR(0);
            props.setBalanceSettlementUSD(0);
            props.setBalanceIDR(0);
            props.setBalanceUSD(0);
        }
    };

    const getDataBankAdvance = (pageNumber, pageSize, filters) => {
        setIsLoadingBA(true);
        let param = []
        param = [
            ...param,
            { 'attr': 'employeeId', 'value': '' + props.EmployeeId },
            { 'attr': 'paid', 'value': '' + false },
            { 'attr': 'rowStatus', 'value': 'ACT' },
            { 'attr': 'printing', 'value': '0', 'mathSign': '!=' },
        ];
        // let filter = [{ 'field': 'employeeName', 'data': '' + props.EmployeeNickname}]
        ApiService.FinAccPostByPage(paramBA, 'SEARCH', pageNumber, pageSize, param, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataBA({});
                    setDataBA(response.data.data.bankAdvance);

                    let temp = response.data.data.bankAdvance
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapBA(indexed);
                    handleDataTable(indexed, response.data.data.columns, setDataTableBA, 'id');
                    setColumnDataBA(response.data.data.columns.headerColumns);
                    setTotalRowsBA(response.data.totalRowCount);

                    setIsLoadingBA(false);
                }
                setIsLoadingBA(false);
            })
            .catch(function (error) {
                setIsLoadingBA(false);
                console.error('error saat fetch', error);
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const getDataEmployee = (pageNumber, pageSize, filters) => {
        setIsLoadingE(true);
        let param = [];
        ApiService.DynamicPostByPage(paramE, 'SEARCH', pageNumber, pageSize, param, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataE({})
                    setDataE(response.data.data.employees);

                    let temp = response.data.data.employees
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapE(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTableE, 'id');
                    setColumnDataE(response.data.data.columns)
                    setTotalRowsE(response.data.totalRowCount)

                    setIsLoadingE(false)
                }
                setIsLoadingE(false)
            })
            .catch(function (error) {
                setIsLoadingE(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    };

    return (
        <div>
            <form>
                <Box>
                    <Grid
                        container
                        spacing={'32px'}
                    >
                        <Grid
                            item
                            xl={5}
                            lg={5}
                            sm={5}
                            xs={12}
                        >
                            <Input
                                label={'Infoss Number'}
                                value={props.infossNo}
                                onChange={(e) => props.setInfossNo(e.target.value)}
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginBottom: '16px !important'
                                    }
                                }}
                                disabled={props.Printing > 0}
                                hidden={!isInfossNo}
                                message={props.infossNo !== '' ? '' : 'This Field is Required'}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={'32px'}
                    >
                        <Grid
                            item
                            xl={5}
                            lg={5}
                            sm={5}
                            xs={12}
                        >
                            <Input
                                label="Settlement No"
                                value={props.SettlementBankAdvanceNo2}
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />

                            <Input
                                label="Reference"
                                value={props.Reference}
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />

                            <Stack spacing={'16px'} direction="row" className='mt-3'>
                                <Input
                                    label='Bank Name'
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.BankCode}
                                    type='number'
                                    formControlProps={{
                                        sx: {
                                            width: '50%',
                                        }
                                    }}
                                    disabled
                                />

                                <Input
                                    value={props.BankIntName}
                                    disabled
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '50%',
                                        }
                                    }}
                                    inputBaseProps={{
                                        sx: {
                                            marginTop: '23px !important',
                                        },
                                    }}
                                />

                                <Input
                                    value={props.BankName}
                                    disabled
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                    inputBaseProps={{
                                        sx: {
                                            marginTop: '23px !important',
                                        },
                                    }}
                                />
                            </Stack>
                        </Grid>

                        <Grid
                            item
                            xl={5}
                            lg={5}
                            sm={5}
                            xs={12}
                        >
                            <Stack spacing={'16px'} direction="row">
                                <Input
                                    label="Personal"
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.EmployeeCode}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '50%',
                                        }
                                    }}
                                    disabled
                                />

                                <Input
                                    value={props.EmployeeName}
                                    disabled
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                    inputBaseProps={{
                                        sx: {
                                            marginTop: '23px !important',
                                        },
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => searchEmployee('delete')}
                                                    hidden={props.EmployeeName ? false : true}
                                                >
                                                    <Icon
                                                        iconName={'close'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>

                                                <IconButton
                                                    onClick={() => searchEmployee('search')}
                                                >
                                                    <Icon
                                                        iconName={'search'}
                                                        svgIconProps={{
                                                            fontSize: 'small',
                                                            htmlColor: '#0087C2',
                                                            sx: {
                                                                height: '12px',
                                                                width: '12px'
                                                            }
                                                        }}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Stack>

                            <Input
                                label='For Bank Advance No'
                                value={props.BankAdvanceNo}
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                                inputBaseProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => searchBankAdvance('delete')}
                                                hidden={props.BankAdvanceNo ? false : true}
                                            >
                                                <Icon
                                                    iconName={'close'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>

                                            <IconButton
                                                onClick={() => searchBankAdvance('search')}
                                                hidden={props.isEdit ? true : props.EmployeeId === 0 ? true : false}
                                            >
                                                <Icon
                                                    iconName={'search'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />

                            <Input
                                label="AC. Number"
                                value={props.ACNumber}
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />
                        </Grid>

                        <Grid
                            item
                            xl={2}
                            lg={2}
                            sm={2}
                            xs={12}
                        >
                            <Input
                                label='Rate'
                                value={props.Rate}
                                textAlign='right'
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                            />

                            <CustomDatePicker
                                label='Rate Date'
                                value={props.ExRateDate}
                                type={'date'}
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />

                            <CustomDatePicker
                                label="Print Date"
                                value={props.PrintedOn}
                                type={'date'}
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </form>

            {
                isModalE && (
                    <SelectDataEmployee
                        isModal={isModalE}
                        handleClick={handleClickEmployee}
                        titleModal={titleModalE}
                        setIsModal={setIsModalE}

                        getData={getDataEmployee}
                        isLoading={isLoadingE}
                        totalRows={totalRowsE}
                        setTotalRows={setTotalRowsE}
                        SelectedData={SelectedDataE}
                        setSelectedData={setSelectedDataE}
                        data={dataE}
                        columnData={columnDataE}
                        dataMap={dataMapE}
                        dataTable={dataTableE}

                        setEmployeeId={props.setEmployeeId}
                        setEmployeeCode={props.setEmployeeCode}
                        setEmployeeName={props.setEmployeeName}
                        setEmployeeNickname={props.setEmployeeNickname}
                    />
                )
            }

            {
                isModalBA && (
                    <SelectDataBankAdvance
                        isModal={isModalBA}
                        handleClick={handleClickBankAdvance}
                        titleModal={titleModalBA}
                        setIsModal={setIsModalBA}

                        getData={getDataBankAdvance}
                        isLoading={isLoadingBA}
                        totalRows={totalRowsBA}
                        setTotalRows={setTotalRowsBA}
                        SelectedData={SelectedDataBA}
                        setSelectedData={setSelectedDataBA}
                        data={dataBA}
                        columnData={columnDataBA}
                        dataMap={dataMapBA}
                        dataTable={dataTableBA}

                        setBankAdvanceId={props.setBankAdvanceId}
                        setBankAdvanceNo={props.setBankAdvanceNo}
                        setBankId={props.setBankId}
                        setBankCode={props.setBankCode}
                        setBankIntName={props.setBankIntName}
                        setBankName={props.setBankName}
                        setACNumber={props.setACNumber}

                        TotalIDR={props.TotalIDR}
                        TotalUSD={props.TotalUSD}
                        setBalanceBAIDR={props.setBalanceBAIDR}
                        setBalanceBAUSD={props.setBalanceBAUSD}
                        setBalanceBAIDRReal={props.setBalanceBAIDRReal}
                        setBalanceBAUSDReal={props.setBalanceBAUSDReal}
                        setBalanceSettlementIDR={props.setBalanceSettlementIDR}
                        setBalanceSettlementUSD={props.setBalanceSettlementUSD}
                        setBalanceSettlementIDRReal={props.setBalanceSettlementIDRReal}
                        setBalanceSettlementUSDReal={props.setBalanceSettlementUSDReal}
                        calculateBalance={props.calculateBalance}
                    />
                )
            }
        </div>
    )
}

export default BankAdvSettlementHeader;