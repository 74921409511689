import { MenuItem, IconButton, InputAdornment, Grid, Typography } from '@mui/material';
import { useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Stack } from "@mui/system";

import ApiService from "../../../services/ApiService";
import SelectChartOfAccount from './Select/SelectChartOfAccount';
import CreateEditModalMaster from '../CreateEditModalMaster';
import { Icon, Input, Selection, showToast, TickBox } from '../../../components/ui';
import { handleDataTable } from '../../../utils/reusableFunction';

const CreateChartOfAccountPage = (props) => {
    const { coaId } = useParams()
    const [isModal, setIsModal] = useState(false)
    const [accountGroupList, setAccountGroupList] = useState([])
    const [accountLevelList, setAccountLevelList] = useState([])

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [dataMap, setDataMap] = useState([])
    const [dataTable, setDataTable] = useState([])
    const [columnData, setColumnData] = useState([])
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50)

    const param = "chartOfAccount/chartOfAccount";

    useEffect(() => {
        document.title = 'Chart of Account - Detail - ' + JSON.parse(localStorage.getItem('branchName'));

        fetchAccountGroupList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchAccountGroupList = async (rowsCount = 50, NumPage = 1) => {
        try {
            ApiService.PostByPage('chartOfAccountGroup/chartOfAccountGroup', NumPage, rowsCount, [])
                .then(res => {
                    const data = res.data.data.chartOfAccountGroup;
                    if (res.data.code === 200) {
                        setAccountGroupList(data)

                        fetchAccountLevelList(50, 1)
                    }
                })
        }
        catch (err) {
            console.error(err);
            alert(err)
        }
    }

    const fetchAccountLevelList = async (rowsCount = 50, NumPage = 1) => {
        try {
            ApiService.PostByPage('chartOfAccountLevel/chartOfAccountLevel', NumPage, rowsCount, [])
                .then(res => {
                    const data = res.data.data.chartOfAccountLevel
                    if (res.data.code === 200) {
                        setAccountLevelList(data)
                    }
                })
        }
        catch (err) {
            console.error(err);
        }
    }

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            fetchChartOfAccounts()
        }
        else {
            setIsModal(false);
        }
    }

    const handleLevel = (id) => {
        props.setAccountLevel(id);
        props.setAccountParent('');
        props.setAccountParentTitle('');
    }

    const handleValidation = () => {
        //validation
        let message = ''
        let status = true
        if (!props.accountGroup) {
            message += 'Account Group, '
            status = false;
        }
        if (!props.accountLevel) {
            message += 'Account Level, '
            status = false;
        }
        if (!props.accountParent) {
            message += 'Parent Account Code, '
            status = false;
        }
        if (!props.accountTitle) {
            message += 'Account Title, '
            status = false;
        }
        message = message.slice(0, -2);

        if (!status) {
            showToast({ type: 'error', message: message + " can't be empty!" })
            return false;
        }
        return true;
    }

    const handleSubmit = () => {
        if (!handleValidation()) {
            return false;
        }

        let accountType = "BANK";
        if (!props.bank && props.accountLevel >= 0 && props.accountLevel <= 5) {
            accountType = "GENERAL";
        }
        else if (!props.bank && props.accountLevel === 6) {
            accountType = "DETAILS";
        }

        if (coaId) {
            let payload = {
                chartOfAccount: {
                    rowStatus: props.active ? "ACT" : "DEL",
                    accountCode: props.accountCode,
                    accountTitle: props.accountTitle,
                    accountGroup: props.accountGroup,
                    accountLevel: props.accountLevel,
                    accountType: accountType,
                    accountParent: props.accountParent,
                    legacyCode: props.legacyCode,
                }
            }
            ApiService.updateData('chartOfAccount/chartOfAccount', payload)
                .then(response => {
                    console.log("response", response);
                    if (response.data.code === 200) {
                        showToast({ type: 'success', message: 'Update Data Chart of Account Success' })
                    }
                    else {
                        showToast({ type: 'error', message: 'Update Data Failed, Please Contact The Administrator, ' + response.data.message })
                    }
                })
                .catch(error => {
                    showToast({ type: 'error', message: 'Update Data Failed, Please Contact The Administrator, ' + error })
                    console.error(error)
                })
        }
        else {
            let payload = {
                chartOfAccount: {
                    rowStatus: props.active ? "ACT" : "DEL",
                    accountCode: "",
                    accountTitle: props.accountTitle,
                    accountGroup: props.accountGroup,
                    accountLevel: props.accountLevel,
                    accountType: accountType,
                    accountParent: props.accountParent,
                    legacyCode: "",
                }
            }
            ApiService.createData('chartOfAccount/chartOfAccount', payload)
                .then(response => {
                    if (response.data.code === 200) {
                        showToast({ type: 'success', message: 'Create Data Chart of Account Success' })
                    }
                    else {
                        showToast({ type: 'error', message: 'Create Data Failed, Please Contact The Administrator, ' + response.data.message })
                    }
                })
                .catch(error => {
                    showToast({ type: 'error', message: 'Create Data Failed, Please Contact The Administrator, ' + error })
                    console.error(error)
                })
        }
    }

    const fetchChartOfAccounts = (rowsCount = 50, NumPage = 1) => {
        setIsLoading(true);
        let accLvl = 0;

        if (props.accountLevel > 0) {
            accLvl = props.accountLevel - 1;
        }

        let params = [
            {
                'attr': 'accountLevel',
                'value': '' + accLvl
            }
        ]
        ApiService.DynamicPostByPage(param, 'SEARCH', NumPage, rowsCount, params)
            .then(async res => {
                let temp = res.data.data.chartOfAccount;
                setData(temp)
                let indexed = temp.map((el, index) => {
                    let indexedTemp = {
                        ...el,
                        index
                    }
                    return indexedTemp
                });
                setDataMap(indexed);
                handleDataTable(indexed, res.data.data.columns, setDataTable, 'id');
                setColumnData(res.data.data.columns);
                setTotalRows(res.data.totalRowCount);
                setIsLoading(false)
            })
            .catch(error => {
                setIsLoading(false)
                console.error(error);
            })
    }

    function handleChange(name, value) {
        if (name === 'bank') {
            props.setBank(!value);
        }
        else if (name === 'active') {
            props.setActive(!value);
        }
    }

    const mainContainer = (
        <form onSubmit={handleSubmit} style={{ marginTop: '12px' }}>
            <Stack direction={'row'} spacing={'16px'}>
                <Selection
                    fullWidth={true}
                    value={props.accountGroup}
                    label="Account Group"
                    placeholder={'Select Account Group'}
                    formControlProps={{
                        sx: {
                            width: '100%',
                            // marginTop: '16px'
                        }
                    }}
                    onChange={(e) => props.setAccountGroup(e.target.value)}
                >
                    {
                        accountGroupList.map((el, index) => {
                            return (
                                <MenuItem sx={{ fontSize: '12px' }} id={el.id} key={index} value={el.code}>
                                    {el.name}
                                </MenuItem>
                            )
                        })
                    }
                </Selection>

                <Selection
                    fullWidth={true}
                    value={props.accountLevel}
                    label="Account Level"
                    placeholder={'Select Account Level'}
                    formControlProps={{
                        sx: {
                            width: '100%',
                            // marginTop: '16px'
                        }
                    }}
                    onChange={(e) => handleLevel(e.target.value)}
                >
                    {
                        accountLevelList.map((el, index) => {
                            return (
                                <MenuItem sx={{ fontSize: '12px' }} id={el.id} key={index} value={el.level}>
                                    {el.name}
                                </MenuItem>
                            )
                        })
                    }
                </Selection>
            </Stack>

            <Input
                label="Parent Account Code"
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                value={props.accountParentTitle}
                onChange={(e) => props.setAccountParent(e.target.value)}
                disabled={props.accountLevel > 0 ? false : true}
                inputBaseProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => { handleClick() }}
                                disabled={props.accountLevel > 0 ? false : true}
                            >
                                <Icon
                                    iconName={'search'}
                                    svgIconProps={{
                                        fontSize: 'small',
                                        htmlColor: '#0087C2',
                                        sx: {
                                            height: '12px',
                                            width: '12px'
                                        }
                                    }}
                                />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />

            <Stack direction={'row'} spacing={'16px'}>
                <Input
                    label="Account Code"
                    formControlProps={{
                        sx: {
                            // width: '100%',
                            marginTop: '16px !important'
                        }
                    }}
                    disabled
                    value={props.accountCode}
                    onChange={(e) => props.setAccountCode(e.target.value)}
                />

                <Input
                    label="Account Title"
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '16px !important'
                        }
                    }}
                    value={props.accountTitle}
                    onChange={(e) => props.setAccountTitle(e.target.value)}
                />
            </Stack>

            <Grid container spacing={'16px'}>
                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <div style={{ marginTop: '16px' }}>
                        <TickBox
                            size={'small'}
                            label={
                                <Typography
                                    sx={{
                                        color: 'var(--Blue-Primary-1, #083A50)',
                                        fontFamily: 'Nunito',
                                        fontSize: '12px !important',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: '20px',
                                    }}
                                >
                                    Bank
                                </Typography>
                            }
                            checked={props.bank}
                            onChange={() => handleChange('bank', props.bank)}
                        />
                    </div>
                </Grid>

                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <div style={{ marginTop: '16px' }}>
                        <TickBox
                            size={'small'}
                            label={
                                <Typography
                                    sx={{
                                        color: 'var(--Blue-Primary-1, #083A50)',
                                        fontFamily: 'Nunito',
                                        fontSize: '12px !important',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: '20px',
                                    }}
                                >
                                    Active
                                </Typography>
                            }
                            checked={props.active}
                            onChange={() => handleChange('active', props.active)}
                        />
                    </div>
                </Grid>
            </Grid>
        </form>
    )

    return (
        <>
            {
                props.isModal && (
                    <CreateEditModalMaster
                        isModal={props.isModal}
                        titleModal={props.titleModal}
                        title={props.title}
                        mainContainer={mainContainer}
                        handleClick={props.handleClick}
                        handleSave={handleSubmit}
                    />
                )
            }

            {
                isModal && (
                    <SelectChartOfAccount
                        isModal={isModal}
                        setIsModal={setIsModal}
                        accountLevel={props.accountLevel}
                        setAccountParent={props.setAccountParent}
                        setAccountParentTitle={props.setAccountParentTitle}
                        handleClick={handleClick}
        
                        isLoading={isLoading}
                        data={data}
                        dataMap={dataMap}
                        dataTable={dataTable}
                        columnData={columnData}
                        SelectedData={SelectedData}
                        totalRows={totalRows}
                        setSelectedData={setSelectedData}
                        getData={fetchChartOfAccounts}
                    />
                )
            }
        </>
    )
}

export default CreateChartOfAccountPage
