import { Route, Routes, useNavigate } from "react-router-dom";
import React, { useEffect } from 'react';
import { useBnIdle } from 'use-bn-idle';

import ApiService, { idleTimer, username } from '../../services/ApiService';

//Main Menu
import ToDo from '../../pages/Home/ToDo/ToDo';
import Profile from '../../pages/Account/Profile/Profile';
import MainMenu from '../../pages/Home/MainMenu/MainMenu';
import MasterDesign from "../../pages/Design/MasterDesign";
import DashBoard from '../../pages/Home/Dashboard/DashBoard';
import NotFoundPage from '../../pages/Home/NotFound/NotFoundPage';

//Master Menu
import ListVat from '../../pages/Master/Vat/ListVat';
import ListCity from '../../pages/Master/City/ListCity';
import ListUser from '../../pages/Master/User/ListUser';
import ListBank from '../../pages/Master/Bank/ListBank';
import ListCash from '../../pages/Master/Cash/ListCash';
import ListPort from '../../pages/Master/Port/ListPort';
import ListRegion from '../../pages/Master/Region/ListRegion';
import ListVessel from '../../pages/Master/Vessel/ListVessel';
// import ListRegCoa from '../../pages/Master/RegCOA/ListRegCoa';
import ListBranch from '../../pages/Master/Branch/ListBranch';
import ListNotify from '../../pages/Master/Notify/ListNotify';
import ListModule from '../../pages/Master/Module/ListModule';
import ListApiUrl from '../../pages/Master/ApiUrl/ListApiUrl';
import ListVendor from '../../pages/Master/Vendor/ListVendor';
import ListSeriFP from '../../pages/Master/SeriFP/ListSeriFP';
import ListCountry from '../../pages/Master/Country/ListCountry';
import ListJobType from '../../pages/Master/JobType/ListJobType';
import ListSeaport from '../../pages/Master/Seaport/ListSeaport';
import ListContact from '../../pages/Master/Contact/ListContact';
import ListCompany from '../../pages/Master/Company/ListCompany';
import ListAccount from '../../pages/Master/Account/ListAccount';
import ListKursKMK from '../../pages/Master/KursKMK/ListKursKMK';
import ListStorage from '../../pages/Master/Storage/ListStorage';
import ListAirline from '../../pages/Master/Airline/ListAirline';
import ListAirport from '../../pages/Master/Airport/ListAirport';
import ListJobOwner from '../../pages/Master/JobOwner/ListJobOwner';
import ListSetValue from '../../pages/Master/SetValue/ListSetValue';
import ListEmployee from '../../pages/Master/Employee/ListEmployee';
import ListSetTable from '../../pages/Master/SetTable/ListSetTable';
import ListProvince from '../../pages/Master/Province/ListProvince';
import ListDataType from '../../pages/Master/DataType/ListDataType';
import ListJobStatus from '../../pages/Master/JobStatus/ListJobStatus';
import ListInvoiceTo from '../../pages/Master/InvoiceTo/ListInvoiceTo';
import ListKendaraan from '../../pages/Master/Kendaraan/ListKendaraan';
import ListContinent from '../../pages/Master/Continent/ListContinent';
import ListSetColumn from '../../pages/Master/SetColumn/ListSetColumn';
import ListRegJobType from '../../pages/Master/RegJobType/ListRegJobType';
import ListRegContact from '../../pages/Master/RegContact/ListRegContact';
import ListContactType from '../../pages/Master/ContactType/ListContactType';
import ListServiceType from '../../pages/Master/ServiceType/ListServiceType';
import ListCompanyGroup from '../../pages/Master/CompanyGroup/ListCompanyGroup';
import ListBillOfLading from '../../pages/Master/BillOfLading/ListBillOfLading';
import ListExchangeRate from '../../pages/Master/ExchangeRate/ListExchangeRate';
import ListMemorialType from '../../pages/Master/MemorialType/ListMemorialType';
import ListReportConfig from '../../pages/Master/ReportConfig/ListReportConfig';
import ListSeriFPDetail from '../../pages/Master/SeriFPDetail/ListSeriFPDetail';
import ListContainerType from '../../pages/Master/ContainerType/ListContainerType';
import ListPackagingType from '../../pages/Master/PackagingType/ListPackagingType';
import ListJasaLainPPh23 from '../../pages/Master/JasaLainPPh23/ListJasaLainPPh23';
import ListDeliveryOrder from '../../pages/Master/DeliveryOrder/ListDeliveryOrder';
import ListSelectionData from '../../pages/Master/SelectionData/ListSelectionData';
import ListJenisKendaraan from '../../pages/Master/JenisKendaraan/ListJenisKendaraan';
import ListShipmentStatus from '../../pages/Master/ShipmentStatus/ListShipmentStatus';
import LIstTrackingStatus from '../../pages/Master/TrackingStatus/LIstTrackingStatus';
import ListChartOfAccount from '../../pages/Master/ChartOfAccount/ListChartOfAccount';
import ListRegCOAEntry from '../../pages/Master/RegChartOfAccountEntry/ListRegCOAEntry';
import ListJobOwnerContact from '../../pages/Master/JobOwnerContact/ListJobOwnerContact';
import ListContainerStatus from '../../pages/Master/ContainerStatus/ListContainerStatus';
import ListRegClosingPeriod from '../../pages/Master/RegClosingPeriod/ListRegClosingPeriod';
import ListJenisDokumenPPh23 from '../../pages/Master/JenisDokumenPPh23/ListJenisDokumenPPh23';
import ListChartOfAccountGroup from '../../pages/Master/ChartOfAccountGroup/ListChartOfAccountGroup';
import ListChartOfAccountLevel from '../../pages/Master/ChartOfAccountLevel/ListChartOfAccountLevel';
import ListChartOfAccountBranch from "../../pages/Master/ChartOfAccountBranch/ListChartOfAccountBranch";

//Operation Menu
import ListInvoice from '../../pages/Operation/Invoice/ListInvoice';
import ListEpl from '../../pages/Operation/EstimateProfitLoss/ListEpl';
import ListShipment from '../../pages/Operation/ShipmentOrder/ListShipment';
import ListBooking from '../../pages/Operation/BookingConfirmation/ListBooking';
import ListFakturPajak from "../../pages/Operation/FakturPajak/ListFakturPajak";
import CreateEditInvoice from '../../pages/Operation/Invoice/CreateEditInvoice';
import CreateEditEpl from '../../pages/Operation/EstimateProfitLoss/CreateEditEpl';
import CreateEditShipment from '../../pages/Operation/ShipmentOrder/CreateEditShipment';
import ListPaymentRequest from '../../pages/Operation/PaymentRequest/ListPaymentRequest';
import CreateEditBooking from '../../pages/Operation/BookingConfirmation/CreateEditBooking';
import CustomerServiceList from '../../pages/Operation/CustomerService/CustomerServiceList';
import CreateEditFakturPajak from "../../pages/Operation/FakturPajak/CreateEditFakturPajak";
import CheckListPaymentRequest from '../../pages/Operation/PaymentRequest/CheckListPaymentRequest';
import CreateEditPaymentRequest from '../../pages/Operation/PaymentRequest/CreateEditPaymentRequest';

//Finance Menu
import ListLogTransactions from '../../pages/DataLogs/ListLogTransactions';
import BalancePage from '../../pages/Accounting/Cashier/Balance/BalancePage';
import ListCashAdv from '../../pages/Accounting/Cashier/CashAdvance/ListCashAdv';
import ListBankAdv from '../../pages/Accounting/Cashier/BankAdvance/ListBankAdv';
import ListMemorial from '../../pages/Accounting/Transaction/Memorial/MemorialList';
import CreateEditCashAdv from '../../pages/Accounting/Cashier/CashAdvance/CreateEditCashAdv';
import CreateEditBankAdv from '../../pages/Accounting/Cashier/BankAdvance/CreateEditBankAdv';
import GeneralLedgerList from '../../pages/Accounting/Cashier/GeneralLedger/GeneralLedgerList';
import CreateEditMemorial from '../../pages/Accounting/Transaction/Memorial/CreateEditMemorial';
import ListAdjustmentAP from '../../pages/Accounting/Transaction/AdjustmentAP/AdjustmentAPList';
import ListInvoiceDelete from "../../pages/Accounting/Operation/InvoiceDelete/ListInvoiceDelete";
import ListPaymentVoucher from '../../pages/Accounting/Cashier/PaymentVoucher/PaymentVoucherList';
import DailyClosingProcess from "../../pages/Accounting/Cashier/DailyClosing/DailyClosingProcess";
import ListPaymentPPh23 from '../../pages/Accounting/Cashier/PaymentVoucherPPh23/ListPaymentPPh23';
import ListReceiptVoucher from '../../pages/Accounting/Cashier//ReceiptVoucher/ReceiptVoucherList';
import ListReceiptPPh23 from '../../pages/Accounting/Cashier/OfficialReceiptPPh23/ListReceiptPPh23';
import ListOfficialReceipt from '../../pages/Accounting/Cashier/OfficialReceipt/OfficialReceiptList';
import CreateEditDownPayment from '../../pages/Accounting/Cashier/DownPayment/CreateEditDownPayment';
import ListGeneralInvoice from '../../pages/Accounting/Transaction/GeneralInvoice/GeneralInvoiceList';
import ListTemporaryReceipt from '../../pages/Accounting/Cashier/TemporaryReceipt/TemporaryReceiptList';
import ListTemporaryPayment from '../../pages/Accounting/Cashier/TemporaryPayment/TemporaryPaymentList';
import CreateEditInvoiceDelete from "../../pages/Accounting/Operation/InvoiceDelete/CreateEditInvoiceDelete";
import CreateEditReceiptVoucher from '../../pages/Accounting/Cashier/ReceiptVoucher/CreateEditReceiptVoucher';
import CreateEditPaymentVoucher from '../../pages/Accounting/Cashier/PaymentVoucher/CreateEditPaymentVoucher';
import CreateEditPaymentPPh23 from '../../pages/Accounting/Cashier/PaymentVoucherPPh23/CreateEditPaymentPPh23';
import AdjustmentReceiptPPh23 from '../../pages/Accounting/Cashier/OfficialReceiptPPh23/AdjustmentReceiptPPh23';
import CreateEditReceiptPPh23 from '../../pages/Accounting/Cashier/OfficialReceiptPPh23/CreateEditReceiptPPh23';
import CreateEditOfficialReceipt from '../../pages/Accounting/Cashier/OfficialReceipt/CreateEditOfficialReceipt';
import CreateEditGeneralInvoice from '../../pages/Accounting/Transaction/GeneralInvoice/CreateEditGeneralInvoice';
import CreateEditTemporaryPayment from '../../pages/Accounting/Cashier/TemporaryPayment/CreateEditTemporaryPayment';
import CreateEditTemporaryReceipt from '../../pages/Accounting/Cashier/TemporaryReceipt/CreateEditTemporaryReceipt';
import CostEPLNextPeriod from "../../pages/Accounting/Transaction/Memorial/MemorialAdditionalMenu/CostEPLNextPeriod";
import CashAdvanceSettlementList from '../../pages/Accounting/Cashier/CashAdvanceSettlement/CashAdvanceSettlementList';
import BankAdvanceSettlementList from '../../pages/Accounting/Cashier/BankAdvanceSettlement/BankAdvanceSettlementList';
import OutstandingCostEPL from '../../pages/Accounting/Transaction/Memorial/MemorialAdditionalMenu/OutstandingCostEPL';
import RestoreDailyClosingProcess from "../../pages/Accounting/Cashier/RestoreDailyClosing/RestoreDailyClosingProcess";
import RestoreClosingMonthlyView from "../../pages/Accounting/Process/RestoreClosingMonthly/RestoreClosingMonthlyView";
import IncomeEPLNextPeriod from "../../pages/Accounting/Transaction/Memorial/MemorialAdditionalMenu/IncomeEPLNextPeriod";
import OutstandingIncomeEPL from '../../pages/Accounting/Transaction/Memorial/MemorialAdditionalMenu/OutstandingIncomeEPL';
import CreateEditCashAdvSettlement from '../../pages/Accounting/Cashier/CashAdvanceSettlement/CreateEditCashAdvSettlement';
import CreateEditBankAdvSettlement from '../../pages/Accounting/Cashier/BankAdvanceSettlement/CreateEditBankAdvSettlement';

//Identity Menu
import RoleList from '../../pages/Identity/Role/RoleList';
import AccessList from '../../pages/Identity/Access/AccessList';
import ServerList from '../../pages/Identity/Server/ServerList';
import RegAccessList from '../../pages/Identity/RegAccess/RegAccessList';
import PermissionList from '../../pages/Identity/Permission/PermissionList';
import ServerTypeList from '../../pages/Identity/ServerType/ServerTypeList';
import RegUserRoleList from '../../pages/Identity/RegUserRole/RegUserRoleList';
import RegUserAccessList from '../../pages/Identity/RegUserAccess/RegUserAccessList';
import RegUserEntityList from '../../pages/Identity/RegUserEntity/RegUserEntityList';
import CreateEditRegUserAccess from '../../pages/Identity/RegUserAccess/CreateEditRegUserAccess';
import RegColumnSettingsList from '../../pages/Identity/RegColumnSettings/RegColumnSettingsList';

//Report
import EBupotView from "../../pages/Report/PPh23/EBupot/EBupotView";
import PecahPosView from "../../pages/Report/Operation/PecahPos/PecahPosView";
import LiftingReport from '../../pages/Report/Operation/Lifting/LiftingReport';
import BalanceSheet from '../../pages/Report/Finance/BalanceSheet/BalanceSheet';
import ExportEJKPView from '../../pages/Report/EFaktur/ExportEJKP/ExportEJKPView';
import VerifyEJKPView from '../../pages/Report/EFaktur/VerifyEJKP/VerifyEJKPView';
import GeneralLedger from '../../pages/Report/Finance/GeneralLedger/GeneralLedger';
import DailyReportView from "../../pages/Report/Finance/DailyReport/DailyReportView";
import SalesCostView from '../../pages/Report/ControlReport/SalesCost/SalesCostView';
import RekapPPnReport from '../../pages/Report/Others/RekapPPnMasukan/RekapPPnReport';
import SoaArReport from '../../pages/Report/AccountReceivable/SoaArReport/SoaArReport';
import DailyReportCash from '../../pages/Report/Cashier/DailyReportCash/DailyReportCash';
import RegisterBiayaView from "../../pages/Report/Others/RegisterBiaya/RegisterBiayaView";
import SPTMasaPPh23Report from '../../pages/Report/PPh23/SPTMasaPPh23/SPTMasaPPh23Report';
import TandaTerimaReport from '../../pages/Report/Operation/TandaTerima/TandaTerimaReport';
import ExportEFakturView from '../../pages/Report/EFaktur/ExportEFaktur/ExportEFakturView';
import VerifyEFakturView from '../../pages/Report/EFaktur/VerifyEFaktur/VerifyEFakturView';
import PerformanceReport from '../../pages/Report/Operation/Performance/PerformanceReport';
import LiftingRegionView from "../../pages/Report/Operation/LiftingRegion/LiftingRegionView";
import ArAgingReport from '../../pages/Report/AccountReceivable/ArAgingReport/ArAgingReport';
import CargoManifestView from '../../pages/Report/Operation/CargoManifest/CargoManifestView';
import RekapMemorialReport from '../../pages/Report/Others/RekapMemorial/RekapMemorialReport';
import RegisterSalesReport from '../../pages/Report/Others/RegisterSales/RegisterSalesReport';
import SuratPengantarView from '../../pages/Report/Operation/SuratPengantar/SuratPengantarView';
import DailyReportBankView from '../../pages/Report/Cashier/DailyReportBank/DailyReportBankView';
import OutstandingPPnReport from '../../pages/Report/Others/OutstandingPPn/OutstandingPPnReport';
import NoticeOfArrivalPage from '../../pages/Report/Operation/NoticeOfArrival/NoticeOfArrivalPage';
import PayableAgingReport from '../../pages/Report/AccountPayable/PayableAging/PayableAgingReport';
import IncomeStatementReport from '../../pages/Report/Finance/IncomeStatement/IncomeStatementReport';
import PerformanceRekapView from "../../pages/Report/Operation/PerformanceRekap/PerformanceRekapView";
import UnprintedInvoiceView from '../../pages/Report/Operation/UnprintedInvoice/UnprintedInvoiceView';
import TransferSOAAgentReport from '../../pages/Report/Others/TransferSOAAgent/TransferSOAAgentReport';
import OutstandingARAPView from '../../pages/Report/ControlReport/OutstandingARAP/OutstandingARAPView';
import CustomerPaymentView from '../../pages/Report/ControlReport/CustomerPayment/CustomerPaymentView';
import RekapPaymentPPh23Report from '../../pages/Report/PPh23/RekapPaymentPPh23/RekapPaymentPPh23Report';
import RekapReceiptPPh23Report from '../../pages/Report/PPh23/RekapReceiptPPh23/RekapReceiptPPh23Report';
import BalanceCashAdvanceView from '../../pages/Report/Cashier/BalanceCashAdvance/BalanceCashAdvanceView';
import BalanceBankAdvanceView from '../../pages/Report/Cashier/BalanceBankAdvance/BalanceBankAdvanceView';
import CustomerInvoiceReport from '../../pages/Report/ControlReport/CustomerInvoice/CustomerInvoiceReport';
import BalanceAPDetailReport from '../../pages/Report/AccountPayable/BalanceAPDetail/BalanceAPDetailReport';
import BalanceSheetByBranch from '../../pages/Report/Finance/BalanceSheetByBranch/BalanceSheetByBranchView';
import GeneralTrialBalanceView from "../../pages/Report/Finance/GeneralTrialBalance/GeneralTrialBalanceView";
import DOWithoutOriginalBLView from '../../pages/Report/Operation/DOWithoutOriginalBL/DOWithoutOriginalBLView';
import ShipmentOrderReportView from '../../pages/Report/Operation/ShipmentOrderReport/ShipmentOrderReportView';
import CostOfSalesAndSalesView from '../../pages/Report/ControlReport/CostOfSalesAndSales/CostOfSalesAndSalesView';
import OutstandingInvoiceReport from '../../pages/Report/ControlReport/OutstandingInvoice/OutstandingInvoiceReport';
import IncomeStatementByBranch from '../../pages/Report/Finance/IncomeStatementByBranch/IncomeStatementByBranchView';
import TemporaryReceiptListView from '../../pages/Report/ControlReport/TemporaryReceiptList/TemporaryReceiptListView';
import LiftingDestinationPortView from "../../pages/Report/Operation/LiftingDestinationPort/LiftingDestinationPortView";
import IncomeAndCostVarianceView from '../../pages/Report/ControlReport/IncomeAndCostVariance/IncomeAndCostVarianceView';
import EstimateIncomeStatementView from "../../pages/Report/Finance/EstimateIncomeStatement/EstimateIncomeStatementView";
import OutstandingPaymentPPh23Report from '../../pages/Report/PPh23/OutstandingPaymentPPh23/OutstandingPaymentPPh23Report';
import OutstandingReceiptPPh23Report from '../../pages/Report/PPh23/OutstandingReceiptPPh23/OutstandingReceiptPPh23Report';
import SummaryGeneralJournalReport from '../../pages/Report/ControlReport/SummaryGeneralJournal/SummaryGeneralJournalReport';
import RecapitulationCashAdvanceView from '../../pages/Report/Cashier/RecapitulationCashAdvance/RecapitulationCashAdvanceView';
import ContributionOfSalesCostView from '../../pages/Report/ControlReport/ContributionOfSalesCost/ContributionOfSalesCostView';
import PayableSubsidiaryLedgerReport from '../../pages/Report/AccountPayable/PayableSubsidiaryLedger/PayableSubsidiaryLedgerReport';
import OutstandingTemporaryPaymentView from '../../pages/Report/ControlReport/OutstandingTemporaryPayment/OutstandingTemporaryPaymentView';
import OutstandingTemporaryReceiptView from '../../pages/Report/ControlReport/OutstandingTemporaryReceipt/OutstandingTemporaryReceiptView';
import ActualEstimateProfitMarginReport from '../../pages/Report/ControlReport/ActualEstimateProfitMargin/ActualEstimateProfitMarginReport';
import ShipmentOrderWithoutInvoicesView from '../../pages/Report/ControlReport/ShipmentOrderWithoutInvoices/ShipmentOrderWithoutInvoicesView';
import ReceivableSubsidiaryLedgerReport from '../../pages/Report/AccountReceivable/ReceivableSubsidiaryLedger/ReceivableSubsidiaryLedgerReport';

//Warehouse Master Menu
import SitesList from '../../pages/Master/MasterWarehouse/Sites/SitesList';
import UnitsList from '../../pages/Master/MasterWarehouse/Units/UnitsList';
import ProductList from '../../pages/Master/MasterWarehouse/Product/ProductList';
import WarehouseList from '../../pages/Master/MasterWarehouse/Warehouses/WarehousesList';
import RegProductUnitList from '../../pages/Master/MasterWarehouse/RegProductUnits/RegProductUnitsList';
import ProductCategoryList from '../../pages/Master/MasterWarehouse/ProductCategory/ProductCategoryList';
import WarehouseLocationList from '../../pages/Master/MasterWarehouse/WarehouseLocation/WarehouseLocationList';
import RegSitesWarehousesList from '../../pages/Master/MasterWarehouse/RegSitesWarehouses/RegSitesWarehousesList';

//Warehouse Transaction Menu
import InBoundList from '../../pages/Warehouse/Transaction/InBound/InBoundList';
import OutBoundList from '../../pages/Warehouse/Transaction/OutBound/OutBoundList';
import AdjustmentList from '../../pages/Warehouse/Transaction/Adjustment/AdjustmentList';
import CreateEditInBound from '../../pages/Warehouse/Transaction/InBound/CreateEditInBound';
import CreateEditOutBound from '../../pages/Warehouse/Transaction/OutBound/CreateEditOutBound';
import CreateEditAdjustment from '../../pages/Warehouse/Transaction/Adjustment/CreateEditAdjustment';

//Warehouse Report Menu
import ReportInBound from '../../pages/Report/Warehouse/ReportInBound/ReportInBound';
import ReportOutBound from '../../pages/Report/Warehouse/ReportOutBound/ReportOutBound';
import WarehouseRekapMutasi from '../../pages/Report/Warehouse/RekapMutasi/WarehouseRekapMutasi';
import WarehouseDetailMutasi from '../../pages/Report/Warehouse/DetailMutasi/WarehouseDetailMutasi';

//Dev Tools Menu
import SandboxPage from "../../pages/Dev/Sandbox/SandboxPage";
import RestorePostingClosingPage from "../../pages/Dev/RestorePostingClosing/RestorePostingClosingPage";
import RestorePostingClosingProcess from "../../pages/Dev/RestorePostingClosing/RestorePostingClosingProcess";

const MyRoute = React.memo((props) => {
    const history = useNavigate();
    const [startTimer, stopTimer] = useBnIdle(() => {
        logOut()
        stopTimer()
    })

    useEffect(() => {
        startTimer(idleTimer);
    }, [startTimer])

    const logOut = () => {
        let isLogOut = JSON.parse(localStorage.getItem(username + "-isLogOut"));
        if (isLogOut !== true) {
            if (props?.childRef?.current?.localSave) {
                props.childRef.current.localSave();
            }

            localStorage.setItem(username + "-lastUrl", JSON.stringify(window.location.pathname));

            ApiService.logOut().then((res) => {
                setTimeout(() => {
                    localStorage.setItem(username + "-isLogOut", JSON.stringify(true));
                    history('/', { replace: true });
                }, 500);
            });
        }
        // localStorage.removeItem(username + "-isLogOut");
    }

    return (
        <Routes>
            {/* Main Menu */}
            <Route path="/" element={<><ToDo ref={props.childRef} /></>} />
            <Route path="/Profile" element={<><Profile ref={props.childRef} /></>} />
            <Route path="/MainMenu" element={<><MainMenu ref={props.childRef} /></>} />
            <Route path="/Dashboard" element={<><DashBoard ref={props.childRef} /></>} />
            <Route path="/MasterDesign" element={<><MasterDesign ref={props.childRef} /></>} />

            {/* Master Menu */}
            <Route path="/Airline" element={<><ListAirline ref={props.childRef} /></>} />
            <Route path="/Airport" element={<><ListAirport ref={props.childRef} /></>} />
            <Route path="/City" element={<><ListCity isCari={false} ref={props.childRef} /></>} />
            <Route path="/Continent" element={<><ListContinent ref={props.childRef} /></>} />
            <Route path="/Country" element={<><ListCountry ref={props.childRef} /></>} />
            <Route path="/ExchangeRate" element={<><ListExchangeRate ref={props.childRef} /></>} />
            <Route path="/JobType" element={<><ListJobType ref={props.childRef} /></>} />
            <Route path="/Region" element={<><ListRegion ref={props.childRef} /></>} />
            <Route path="/Seaport" element={<><ListSeaport isCari={false} ref={props.childRef} /></>} />
            <Route path="/Vessel" element={<><ListVessel isCari={false} ref={props.childRef} /></>} />
            <Route path="/Bank" element={<><ListBank ref={props.childRef} /></>} />
            <Route path="/RegChartOfAccount" element={<><ListChartOfAccountBranch ref={props.childRef} /></>} />
            <Route path="/ChartOfAccount" element={<><ListChartOfAccount ref={props.childRef} /></>} />
            <Route path="/Province" element={<><ListProvince ref={props.childRef} /></>} />
            <Route path="/Contact" element={<><ListContact ref={props.childRef} /></>} />
            <Route path="/ContactType" element={<><ListContactType ref={props.childRef} /></>} />
            <Route path="/Company" element={<><ListCompany ref={props.childRef} /></>} />
            <Route path="/VAT" element={<><ListVat ref={props.childRef} /></>} />
            <Route path="/Cash" element={<><ListCash ref={props.childRef} /></>} />
            <Route path="/Account" element={<><ListAccount ref={props.childRef} /></>} />
            <Route path="/BillOfLading" element={<><ListBillOfLading ref={props.childRef} /></>} />
            <Route path="/Branch" element={<><ListBranch ref={props.childRef} /></>} />
            <Route path="/ChartOfAccountGroup" element={<><ListChartOfAccountGroup ref={props.childRef} /></>} />
            <Route path="/ChartOfAccountLevel" element={<><ListChartOfAccountLevel ref={props.childRef} /></>} />
            <Route path="/ContainerStatus" element={<><ListContainerStatus ref={props.childRef} /></>} />
            <Route path="/ContainerType" element={<><ListContainerType ref={props.childRef} /></>} />
            <Route path="/JobStatus" element={<><ListJobStatus ref={props.childRef} /></>} />
            <Route path="/PackagingType" element={<><ListPackagingType ref={props.childRef} /></>} />
            <Route path="/ServiceType" element={<><ListServiceType ref={props.childRef} /></>} />
            <Route path="/JenisKendaraan" element={<><ListJenisKendaraan ref={props.childRef} /></>} />
            <Route path="/InvoicesTo" element={<><ListInvoiceTo ref={props.childRef} /></>} />
            <Route path="/JasaLainPph23" element={<><ListJasaLainPPh23 ref={props.childRef} /></>} />
            <Route path="/JobOwner" element={<><ListJobOwner ref={props.childRef} /></>} />
            <Route path="/Notify" element={<><ListNotify ref={props.childRef} /></>} />
            <Route path="/JenisDokumenPph23" element={<><ListJenisDokumenPPh23 ref={props.childRef} /></>} />
            <Route path="/Kendaraan" element={<><ListKendaraan ref={props.childRef} /></>} />
            <Route path="/Module" element={<><ListModule ref={props.childRef} /></>} />
            <Route path="/Employee" element={<><ListEmployee ref={props.childRef} /></>} />
            <Route path="/JobOwnerContact" element={<><ListJobOwnerContact ref={props.childRef} /></>} />
            <Route path="/KursKMK" element={<><ListKursKMK ref={props.childRef} /></>} />
            <Route path="/MemorialType" element={<><ListMemorialType ref={props.childRef} /></>} />
            <Route path="/ReportConfig" element={<><ListReportConfig ref={props.childRef} /></>} />
            <Route path="/ShipmentStatus" element={<><ListShipmentStatus ref={props.childRef} /></>} />
            <Route path="/Storage" element={<><ListStorage ref={props.childRef} /></>} />
            <Route path="/DeliveryOrder" element={<><ListDeliveryOrder ref={props.childRef} /></>} />
            <Route path="/TrackingStatus" element={<><LIstTrackingStatus ref={props.childRef} /></>} />
            <Route path="/RegContact" element={<><ListRegContact ref={props.childRef} /></>} />
            <Route path="/Vendor" element={<><ListVendor ref={props.childRef} /></>} />
            <Route path="/SeriFP" element={<><ListSeriFP ref={props.childRef} /></>} />
            <Route path="/SeriFPDetail" element={<><ListSeriFPDetail ref={props.childRef} /></>} />
            <Route path="/SetValues" element={<><ListSetValue ref={props.childRef} /></>} />
            <Route path="/SetTable" element={<><ListSetTable ref={props.childRef} /></>} />
            <Route path="/SetColumn" element={<><ListSetColumn ref={props.childRef} /></>} />
            <Route path="/User" element={<><ListUser ref={props.childRef} /></>} />
            <Route path="/RegClosingPeriod" element={<><ListRegClosingPeriod ref={props.childRef} /></>} />
            <Route path="/DataType" element={<><ListDataType ref={props.childRef} /></>} />
            <Route path="/CompanyGroup" element={<><ListCompanyGroup ref={props.childRef} /></>} />
            <Route path="/Port" element={<><ListPort ref={props.childRef} /></>} />
            <Route path="/RegJobType" element={<><ListRegJobType ref={props.childRef} /></>} />
            <Route path="/APIUrl" element={<><ListApiUrl ref={props.childRef} /></>} />
            <Route path="/SelectionData" element={<><ListSelectionData ref={props.childRef} /></>} />
            <Route path="/RegChartOfAccountEntry" element={<><ListRegCOAEntry ref={props.childRef} /></>} />
            {/* <Route path="/ChartOfAccountBranch" element={<><ListChartOfAccountBranch ref={props.childRef} /></>} /> */}

            {/* Operation Menu */}
            <Route path="/BookingConfirmation" element={<><ListBooking ref={props.childRef} /></>} />
            <Route path="/BookingConfirmation/Create" element={<><CreateEditBooking ref={props.childRef} /></>} />
            <Route path="/BookingConfirmation/Create/:isCopy/:bookingId" element={<><CreateEditBooking ref={props.childRef} /></>} />
            <Route path="/BookingConfirmation/:bookingId" element={<><CreateEditBooking ref={props.childRef} /></>} />
            <Route path="/BookingConfirmation/:isToDo/:bookingId/:sJobTypeId/:customerId/:customerTypeId" element={<><CreateEditBooking ref={props.childRef} /></>} />
            <Route path="/ShipmentOrder" element={<> <ListShipment ref={props.childRef} /> </>} />
            <Route path="/ShipmentOrder/Create/:sJobTypeId" element={<> <CreateEditShipment ref={props.childRef} /> </>} />
            <Route path="/ShipmentOrder/Create/:sJobTypeId/:bookingId/:isEPL" element={<> <CreateEditShipment ref={props.childRef} /> </>} />
            <Route path="/ShipmentOrder/Create/:isToDo/:bookingId/:sJobTypeId/:customerId/:customerTypeId" element={<> <CreateEditShipment ref={props.childRef} /> </>} />
            <Route path="/ShipmentOrder/:sJobTypeId/:shipmentId" element={<> <CreateEditShipment ref={props.childRef} /> </>} />
            <Route path="/EstimateProfitLoss" element={<><ListEpl ref={props.childRef} /></>} />
            <Route path="/EstimateProfitLoss/Create/:jobId" element={<><CreateEditEpl ref={props.childRef} /></>} />
            <Route path="/EstimateProfitLoss/Create/:isToDo/:shipmentId/:jobId/:customerId/:customerTypeId" element={<><CreateEditEpl ref={props.childRef} /></>} />
            <Route path="/EstimateProfitLoss/:jobId/:eplId" element={<><CreateEditEpl ref={props.childRef} /></>} />
            <Route path='/Invoice/' element={<><ListInvoice ref={props.childRef} /></>} />
            <Route path='/Invoice/Create/:sJobTypeId' element={<><CreateEditInvoice ref={props.childRef} /></>} />
            <Route path='/Invoice/Create/:isToDo/:eplId/:sJobTypeId/:customerId/:customerTypeId/:dcNote' element={<><CreateEditInvoice ref={props.childRef} /></>} />
            <Route path='/Invoice/:sJobTypeId/:invoiceId' element={<><CreateEditInvoice ref={props.childRef} /></>} />
            <Route path='/Invoice/:fakturType/:sJobTypeId/:invoiceId' element={<><CreateEditInvoice ref={props.childRef} /></>} />
            <Route path='/Invoice/:isToDo/:invoiceId/:sJobTypeId/:customerId/:customerTypeId/:dcNote' element={<><CreateEditInvoice ref={props.childRef} /></>} />
            <Route path='/PaymentRequest/' element={<><ListPaymentRequest ref={props.childRef} /></>} />
            <Route path='/PaymentRequest/:isCheckType' element={<><CheckListPaymentRequest ref={props.childRef} /></>} />
            <Route path='/PaymentRequest/Create/:sJobTypeId' element={<><CreateEditPaymentRequest ref={props.childRef} /></>} />
            <Route path='/PaymentRequest/Create/:isToDo/:eplId/:sJobTypeId/:customerId/:customerTypeId/:dcNote' element={<><CreateEditPaymentRequest ref={props.childRef} /></>} />
            <Route path='/PaymentRequest/:sJobTypeId/:prId' element={<><CreateEditPaymentRequest ref={props.childRef} /></>} />
            <Route path='/PaymentRequest/:isContra/:sJobTypeId/:prId' element={<><CreateEditPaymentRequest ref={props.childRef} /></>} />
            <Route path='/PaymentRequest/:isToDo/:prId/:sJobTypeId/:customerId/:customerTypeId/:dcNote' element={<><CreateEditPaymentRequest ref={props.childRef} /></>} />
            <Route path="/CustomerService" element={<><CustomerServiceList ref={props.childRef} /></>} />
            <Route path="/FakturPajak" element={<><ListFakturPajak ref={props.childRef} /></>} />
            <Route path='/FakturPajak/Create/:sJobTypeId' element={<><CreateEditFakturPajak ref={props.childRef} /></>} />
            <Route path='/FakturPajak/:sJobTypeId/:prId' element={<><CreateEditFakturPajak ref={props.childRef} /></>} />

            {/* Finance Menu */}
            <Route path="/OfficialReceipt" element={<><ListOfficialReceipt ref={props.childRef} /></>} />
            <Route path="/OfficialReceipt/Create" element={<><CreateEditOfficialReceipt ref={props.childRef} /></>} />
            <Route path="/OfficialReceipt/:officialReceiptId" element={<><CreateEditOfficialReceipt ref={props.childRef} /></>} />
            <Route path="/PaymentVoucher" element={<><ListPaymentVoucher ref={props.childRef} /></>} />
            <Route path="/PaymentVoucher/Create" element={<><CreateEditPaymentVoucher ref={props.childRef} /></>} />
            <Route path="/PaymentVoucher/:paymentVoucherId" element={<><CreateEditPaymentVoucher ref={props.childRef} /></>} />
            <Route path="/TemporaryReceipt" element={<><ListTemporaryReceipt ref={props.childRef} /></>} />
            <Route path="/TemporaryReceipt/Create/:sJobTypeId" element={<><CreateEditTemporaryReceipt ref={props.childRef} /></>} />
            <Route path="/TemporaryReceipt/:sJobTypeId/:temporaryReceiptId" element={<><CreateEditTemporaryReceipt ref={props.childRef} /></>} />
            <Route path="/DownPayment/Create" element={<><CreateEditDownPayment ref={props.childRef} /></>} />
            <Route path="/DownPayment/:temporaryReceiptId" element={<><CreateEditDownPayment ref={props.childRef} /></>} />
            <Route path="/ReceiptVoucher" element={<><ListReceiptVoucher ref={props.childRef} /></>} />
            <Route path="/ReceiptVoucher/Create" element={<><CreateEditReceiptVoucher ref={props.childRef} /></>} />
            <Route path="/ReceiptVoucher/:receiptVoucherId" element={<><CreateEditReceiptVoucher ref={props.childRef} /></>} />
            <Route path="/TemporaryPayment" element={<><ListTemporaryPayment ref={props.childRef} /></>} />
            <Route path="/TemporaryPayment/Create/:sJobTypeId" element={<><CreateEditTemporaryPayment ref={props.childRef} /></>} />
            <Route path="/TemporaryPayment/:sJobTypeId/:temporaryPaymentId" element={<><CreateEditTemporaryPayment ref={props.childRef} /></>} />
            <Route path="/GeneralInvoice" element={<><ListGeneralInvoice ref={props.childRef} /></>} />
            <Route path="/GeneralInvoice/Create" element={<><CreateEditGeneralInvoice ref={props.childRef} /></>} />
            <Route path="/GeneralInvoice/:generalInvoiceId" element={<><CreateEditGeneralInvoice ref={props.childRef} /></>} />
            <Route path="/Memorial" element={<><ListMemorial ref={props.childRef} /></>} />
            <Route path="/AdjustmentAP" element={<><ListAdjustmentAP ref={props.childRef} /></>} />
            <Route path="/Memorial/Create" element={<><CreateEditMemorial ref={props.childRef} /></>} />
            <Route path="/Memorial/:memorialId" element={<><CreateEditMemorial ref={props.childRef} /></>} />
            <Route path='/OutstandingIncomeEPL' element={<><OutstandingIncomeEPL ref={props.childRef} /></>} />
            <Route path='/EPLNextIncome' element={<><IncomeEPLNextPeriod ref={props.childRef} /></>} />
            <Route path='/OutstandingCostEPL' element={<><OutstandingCostEPL ref={props.childRef} /></>} />
            <Route path='/EPLNextCost' element={<><CostEPLNextPeriod ref={props.childRef} /></>} />
            <Route path="/CashAdvance" element={<><ListCashAdv ref={props.childRef} /></>} />
            <Route path="/CashAdvance/Create" element={<><CreateEditCashAdv ref={props.childRef} /></>} />
            <Route path="/CashAdvance/:cashAdvId" element={<><CreateEditCashAdv ref={props.childRef} /></>} />
            <Route path="/BankAdvance" element={<><ListBankAdv ref={props.childRef} /></>} />
            <Route path="/BankAdvance/Create" element={<><CreateEditBankAdv ref={props.childRef} /></>} />
            <Route path="/BankAdvance/:bankAdvId" element={<><CreateEditBankAdv ref={props.childRef} /></>} />
            <Route path="/OfficialReceiptPph23" element={<><ListReceiptPPh23 ref={props.childRef} /></>} />
            <Route path="/OfficialReceiptPph23/Create" element={<><CreateEditReceiptPPh23 ref={props.childRef} /></>} />
            <Route path="/OfficialReceiptPph23/Adjustment" element={<><AdjustmentReceiptPPh23 ref={props.childRef} /></>} />
            <Route path="/OfficialReceiptPph23/:orPphId" element={<><CreateEditReceiptPPh23 ref={props.childRef} /></>} />
            <Route path="/PaymentVoucherPph23" element={<><ListPaymentPPh23 ref={props.childRef} /></>} />
            <Route path="/PaymentVoucherPph23/Create" element={<><CreateEditPaymentPPh23 ref={props.childRef} /></>} />
            <Route path="/PaymentVoucherPph23/:pvPphId" element={<><CreateEditPaymentPPh23 ref={props.childRef} /></>} />
            <Route path="/InvoiceDeletionApproval" element={<><ListInvoiceDelete ref={props.childRef} /></>} />
            <Route path="/Balance" element={<><BalancePage ref={props.childRef} /></>} />
            <Route path="/DailyClosing" element={<><DailyClosingProcess ref={props.childRef} /></>} />
            <Route path="/RestoreDailyClosing" element={<><RestoreDailyClosingProcess ref={props.childRef} /></>} />
            <Route path="/RestoreClosingMonthly" element={<><RestoreClosingMonthlyView ref={props.childRef} /></>} />
            <Route path="/GeneralLedger" element={<><GeneralLedgerList ref={props.childRef} /></>} />
            <Route path="/CashAdvanceSettlement" element={<><CashAdvanceSettlementList ref={props.childRef} /></>} />
            <Route path="/CashAdvanceSettlement/Create" element={<><CreateEditCashAdvSettlement ref={props.childRef} /></>} />
            <Route path="/CashAdvanceSettlement/:CashAdvSetId" element={<><CreateEditCashAdvSettlement ref={props.childRef} /></>} />
            <Route path="/BankAdvanceSettlement" element={<><BankAdvanceSettlementList ref={props.childRef} /></>} />
            <Route path="/BankAdvanceSettlement/Create" element={<><CreateEditBankAdvSettlement ref={props.childRef} /></>} />
            <Route path="/BankAdvanceSettlement/:BankAdvSetId" element={<><CreateEditBankAdvSettlement ref={props.childRef} /></>} />
            <Route path="/TransactionLogs/:transactionType/:dataId/:period/:yearPeriod" element={<><ListLogTransactions ref={props.childRef} /></>} />
            <Route path='/Invoice/:sJobTypeId/:invoiceId/:branchId/:companyId/:countryId' element={<><CreateEditInvoiceDelete ref={props.childRef} /></>} />

            {/* Identity Menu */}
            <Route path="/Permission" element={<><PermissionList ref={props.childRef} /></>} />
            <Route path="/Access" element={<><AccessList ref={props.childRef} /></>} />
            <Route path="/RegAccess" element={<><RegAccessList ref={props.childRef} /></>} />
            <Route path="/Role" element={<><RoleList ref={props.childRef} /></>} />
            <Route path="/RegUserAccess" element={<><RegUserAccessList ref={props.childRef} /></>} />
            <Route path="/RegUserAccess/:userId/:roleId" element={<><CreateEditRegUserAccess ref={props.childRef} /></>} />
            <Route path="/RegUserRole" element={<><RegUserRoleList ref={props.childRef} /></>} />
            <Route path="/RegUserEntity" element={<><RegUserEntityList ref={props.childRef} /></>} />
            <Route path="/RegColumnSettings" element={<><RegColumnSettingsList ref={props.childRef} /></>} />
            <Route path="/ServerType" element={<><ServerTypeList ref={props.childRef} /></>} />
            <Route path="/Server" element={<><ServerList ref={props.childRef} /></>} />

            {/* Report Menu */}
            <Route path="/BalanceSheetReport" element={<><BalanceSheet ref={props.childRef} /></>} />
            <Route path="/BalanceSheetBranchReport" element={<><BalanceSheetByBranch ref={props.childRef} /></>} />
            <Route path="/GeneralLedgerReport" element={<><GeneralLedger ref={props.childRef} /></>} />
            <Route path="/IncomeStatementReport" element={<><IncomeStatementReport ref={props.childRef} /></>} />
            <Route path="/IncomeStatementBranchReport" element={<><IncomeStatementByBranch ref={props.childRef} /></>} />
            <Route path="/DailyReportCashReport" element={<><DailyReportCash ref={props.childRef} /></>} />
            <Route path="/DailyReportBankReport" element={<><DailyReportBankView ref={props.childRef} /></>} />
            <Route path="/StatementOfAccountReport" element={<><SoaArReport ref={props.childRef} /></>} />
            <Route path="/BalanceBankAdvanceListReport" element={<><BalanceBankAdvanceView ref={props.childRef} /></>} />
            <Route path="/BalanceCashAdvanceListReport" element={<><BalanceCashAdvanceView ref={props.childRef} /></>} />
            <Route path="/RecapitulationCashAdvanceReport" element={<><RecapitulationCashAdvanceView ref={props.childRef} /></>} />
            <Route path="/NoticeOfArrivalReport" element={<><NoticeOfArrivalPage ref={props.childRef} /></>} />
            <Route path="/PecahPosReport" element={<><PecahPosView ref={props.childRef} /></>} />
            <Route path="/CargoManifestReport" element={<><CargoManifestView ref={props.childRef} /></>} />
            <Route path="/DOWithoutOriginalBLReport" element={<><DOWithoutOriginalBLView ref={props.childRef} /></>} />
            <Route path="/SuratPengantarReport" element={<><SuratPengantarView ref={props.childRef} /></>} />
            <Route path="/LiftingDestinationPortReport" element={<><LiftingDestinationPortView ref={props.childRef} /></>} />
            <Route path="/RekapPerformanceReport" element={<><PerformanceRekapView ref={props.childRef} /></>} />
            <Route path="/ShipmentOrderListReport" element={<><ShipmentOrderReportView ref={props.childRef} /></>} />
            <Route path="/UnprintedInvoiceReport" element={<><UnprintedInvoiceView ref={props.childRef} /></>} />
            <Route path="/PerformanceReport" element={<><PerformanceReport ref={props.childRef} /></>} />
            <Route path="/SummaryGeneralJournalReport" element={<><SummaryGeneralJournalReport ref={props.childRef} /></>} />
            <Route path="/ContributionOfSalesCostReport" element={<><ContributionOfSalesCostView ref={props.childRef} /></>} />
            <Route path="/OutstandingARAPReport" element={<><OutstandingARAPView ref={props.childRef} /></>} />
            <Route path="/SalesCostReport" element={<><SalesCostView ref={props.childRef} /></>} />
            <Route path="/CostOfSalesAndSalesReport" element={<><CostOfSalesAndSalesView ref={props.childRef} /></>} />
            <Route path="/IncomeAndCostVarianceReport" element={<><IncomeAndCostVarianceView ref={props.childRef} /></>} />
            <Route path="/ShipmentOrderWithoutInvoicesReport" element={<><ShipmentOrderWithoutInvoicesView ref={props.childRef} /></>} />
            <Route path="/TemporaryReceiptListReport" element={<><TemporaryReceiptListView ref={props.childRef} /></>} />
            <Route path="/OutstandingTemporaryReceiptReport" element={<><OutstandingTemporaryReceiptView ref={props.childRef} /></>} />
            <Route path="/OutstandingTemporaryPaymentReport" element={<><OutstandingTemporaryPaymentView ref={props.childRef} /></>} />
            <Route path="/ActualEstimateProfitMarginReport" element={<><ActualEstimateProfitMarginReport ref={props.childRef} /></>} />
            <Route path="/OutstandingInvoiceReport" element={<><OutstandingInvoiceReport ref={props.childRef} /></>} />
            <Route path="/CustomerPaymentReport" element={<><CustomerPaymentView ref={props.childRef} /></>} />
            <Route path="/CustomerInvoiceReport" element={<><CustomerInvoiceReport ref={props.childRef} /></>} />
            <Route path="/LiftingReport" element={<><LiftingReport ref={props.childRef} /></>} />
            <Route path="/LiftingRegionReport" element={<><LiftingRegionView ref={props.childRef} /></>} />
            <Route path="/TandaTerimaReport" element={<><TandaTerimaReport ref={props.childRef} /></>} />
            <Route path="/ExportEFaktur" element={<><ExportEFakturView ref={props.childRef} /></>} />
            <Route path="/VerifyEFaktur" element={<><VerifyEFakturView ref={props.childRef} /></>} />
            <Route path="/ExportEJKP" element={<><ExportEJKPView ref={props.childRef} /></>} />
            <Route path="/VerifyEJKP" element={<><VerifyEJKPView ref={props.childRef} /></>} />
            <Route path="/PayableAgingReport" element={<><PayableAgingReport ref={props.childRef} /></>} />
            <Route path="/PayableSubsidiaryLedgerReport" element={<><PayableSubsidiaryLedgerReport ref={props.childRef} /></>} />
            <Route path="/BalanceAPDetailReport" element={<><BalanceAPDetailReport ref={props.childRef} /></>} />
            <Route path="/ReceivableAgingReport" element={<><ArAgingReport ref={props.childRef} /></>} />
            <Route path="/ReceivableSubsidiaryLedgerReport" element={<><ReceivableSubsidiaryLedgerReport ref={props.childRef} /></>} />
            <Route path="/OutstandingPaymentPPh23Report" element={<><OutstandingPaymentPPh23Report ref={props.childRef} /></>} />
            <Route path="/RekapPaymentPPh23Report" element={<><RekapPaymentPPh23Report ref={props.childRef} /></>} />
            <Route path="/SPTMasaPPh23Report" element={<><SPTMasaPPh23Report ref={props.childRef} /></>} />
            <Route path="/EBupotReport" element={<><EBupotView ref={props.childRef} /></>} />
            <Route path="/OutstandingReceiptPPh23Report" element={<><OutstandingReceiptPPh23Report ref={props.childRef} /></>} />
            <Route path="/RekapReceiptPPh23Report" element={<><RekapReceiptPPh23Report ref={props.childRef} /></>} />
            <Route path="/TransferSOAAgentReport" element={<><TransferSOAAgentReport ref={props.childRef} /></>} />
            <Route path="/RegisterSalesReport" element={<><RegisterSalesReport ref={props.childRef} /></>} />
            <Route path="/OutstandingPPnReport" element={<><OutstandingPPnReport ref={props.childRef} /></>} />
            <Route path="/RekapPPnMasukanReport" element={<><RekapPPnReport ref={props.childRef} /></>} />
            <Route path="/RekapMemorialReport" element={<><RekapMemorialReport ref={props.childRef} /></>} />
            <Route path="/GeneralTrialBalanceReport" element={<><GeneralTrialBalanceView ref={props.childRef} /></>} />
            <Route path="/EstimateIncomeStatementReport" element={<><EstimateIncomeStatementView ref={props.childRef} /></>} />
            <Route path="/DailyReport" element={<><DailyReportView ref={props.childRef} /></>} />
            <Route path="/RegisterBiayaReport" element={<><RegisterBiayaView ref={props.childRef} /></>} />

            {/* Warehouse Master Menu */}
            <Route path="/Sites" element={<><SitesList ref={props.childRef} /></>} />
            <Route path="/Warehouses" element={<><WarehouseList ref={props.childRef} /></>} />
            <Route path="/Units" element={<><UnitsList ref={props.childRef} /></>} />
            <Route path="/Product" element={<><ProductList ref={props.childRef} /></>} />
            <Route path="/WarehouseLocation" element={<><WarehouseLocationList ref={props.childRef} /></>} />
            <Route path="/ProductCategory" element={<><ProductCategoryList ref={props.childRef} /></>} />
            <Route path="/RegProductUnits" element={<><RegProductUnitList ref={props.childRef} /></>} />
            <Route path="/RegSitesWarehouses" element={<><RegSitesWarehousesList ref={props.childRef} /></>} />

            {/* Warehouse Transaction Menu */}
            <Route path="/TransactionInbound" element={<><InBoundList ref={props.childRef} /></>} />
            <Route path="/TransactionInbound/Create" element={<><CreateEditInBound ref={props.childRef} /></>} />
            <Route path="/TransactionInbound/:Id" element={<><CreateEditInBound ref={props.childRef} /></>} />
            <Route path="/TransactionOutbound" element={<><OutBoundList ref={props.childRef} /></>} />
            <Route path="/TransactionOutbound/Create" element={<><CreateEditOutBound ref={props.childRef} /></>} />
            <Route path="/TransactionOutbound/:Id" element={<><CreateEditOutBound ref={props.childRef} /></>} />
            <Route path="/Adjustment" element={<><AdjustmentList ref={props.childRef} /></>} />
            <Route path="/Adjustment/Create" element={<><CreateEditAdjustment ref={props.childRef} /></>} />
            <Route path="/Adjustment/:Id" element={<><CreateEditAdjustment ref={props.childRef} /></>} />

            {/* Warehouse Report Menu */}
            <Route path="/ReportInBound" element={<><ReportInBound ref={props.childRef} /></>} />
            <Route path="/ReportOutBound" element={<><ReportOutBound ref={props.childRef} /></>} />
            <Route path="/ReportRekapMutasi" element={<><WarehouseRekapMutasi ref={props.childRef} /></>} />
            <Route path="/ReportDetailMutasi" element={<><WarehouseDetailMutasi ref={props.childRef} /></>} />

            {/* Dev Tools Menu */}
            <Route path="/RestorePostingClosing" element={<><RestorePostingClosingPage ref={props.childRef} /></>} />
            <Route path="/Sandbox" element={<><SandboxPage ref={props.childRef} /></>} />
            <Route path="/RestorePostingClosing/:batchGroupId" element={<><RestorePostingClosingProcess ref={props.childRef} /></>} />

            {/* Not Found Page */}
            <Route path="*" element={<><NotFoundPage ref={undefined} /></>} />
        </Routes>
    )
})

export default MyRoute