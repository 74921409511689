import { Grid, Stack, IconButton, MenuItem, InputAdornment } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import ApiService, { formatCurrency, isInfossNo } from '../../../../services/ApiService';
import DollarInput from '../../../../components/Masks/DollarInput';
import RupiahInput from '../../../../components/Masks/RupiahInput';

import TabsCustomerBillTo from '../Tabs/TabsCustomerBillTo';
import SelectDataEPL from '../Selects/SelectDataEPL';
import SelectDataContact from '../Selects/SelectDataContact';
import SelectRevisedInvoice from '../Selects/SelectRevisedInvoice';
import { CustomDatePicker, Icon, Input, Selection, showToast } from '../../../../components/ui';
import { handleDataTable } from '../../../../utils/reusableFunction';

const InvoiceHeader = (props) => {
    const [CustomerType, setCustomerType] = useState(1);

    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);

    const [isLoadingE, setIsLoadingE] = useState(false);
    const paramE = 'estimateProfitLoss/estimateProfitLoss';
    const [dataE, setDataE] = useState([]);
    const [SelectedDataE, setSelectedDataE] = useState({});
    const [totalRowsE, setTotalRowsE] = useState(50);
    const [dataMapE, setDataMapE] = useState([]);
    const [dataTableE, setDataTableE] = useState([]);
    const [columnDataE, setColumnDataE] = useState([]);
    let countErrE = 1;

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataHeaderEPL(1, 50);
        }
        else {
            setIsModal(false);
        }
    };

    const searchShipment = () => {
        setTitleModal('Shipment Order');
        handleClick();
    }

    const getDataHeaderEPL = (pageNumber, pageSize, filters, orderBy) => {
        setIsLoadingE(true);
        let params = [];

        if (props.PaymentFrom === 0) {
            params = [
                ...params,
                { 'attr': 'jobTypeId', 'value': '' + props.typeJobId },
            ];
            ApiService.OperationDynamicPostByPage(paramE, 'SEARCH', pageNumber, pageSize, params, filters, orderBy)
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedDataE({});
                        setDataE(response.data.data.estimateProfitLoss);

                        let temp = response.data.data.estimateProfitLoss;
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp;
                        })

                        setDataMapE(indexed);
                        handleDataTable(indexed, response.data.data.columns.headerColumns, setDataTableE, 'id');
                        setColumnDataE(response.data.data.columns.headerColumns);
                        setTotalRowsE(response.data.totalRowCount);

                        setIsLoadingE(false);
                    }
                    setIsLoadingE(false);
                })
                .catch(function (error) {
                    setIsLoadingE(false);
                    countErrE++
                    if (countErrE < 3) {
                        getDataHeaderEPL(1, 50);
                    }
                    else {
                        showToast({ type: 'error', message: 'You Don\'t Have Access to This Data!' });
                        setIsModal(false);
                    }
                })
        }
        else {
            params = [...params,
            {
                'attr': "jobTypeId",
                'value': '' + props.typeJobId
            },
            {
                'attr': "jobClosed",
                'value': "false"
            },
            ]
            ApiService.OperationDynamicPostByPage('shipmentOrder/shipmentOrder', 'SEARCH', pageNumber, pageSize, params, filters, orderBy)
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedDataE({})
                        setDataE(response.data.data.shipmentOrder);

                        let temp = response.data.data.shipmentOrder
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataMapE(indexed)
                        handleDataTable(indexed, response.data.data.columns.headerColumns, setDataTableE, 'id');
                        setColumnDataE(response.data.data.columns.headerColumns)
                        setTotalRowsE(response.data.totalRowCount)

                        setIsLoadingE(false)
                    }
                    setIsLoadingE(false)
                })
                .catch(function (error) {
                    setIsLoadingE(false)
                    console.error('error saat fetch', error)
                    showToast({ type: 'error', message: 'You Don\'t Have Access to This Data!' });
                })
        }
    }

    const [titleModal2, setTitleModal2] = useState('');
    const [isModal2, setIsModal2] = useState(false);

    const paramC = 'estimateProfitLoss/estimateProfitLoss';
    const [isLoadingC, setIsLoadingC] = useState(false);
    const [dataC, setDataC] = useState([]);
    const [SelectedDataC, setSelectedDataC] = useState({});
    const [totalRowsC, setTotalRowsC] = useState(50);
    const [dataMapC, setDataMapC] = useState([]);
    const [dataTableC, setDataTableC] = useState([]);
    const [columnDataC, setColumnDataC] = useState([]);

    const paramR = 'invoice/invoice';
    const [isLoadingR, setIsLoadingR] = useState(false);
    const [dataR, setDataR] = useState([]);
    const [SelectedDataR, setSelectedDataR] = useState({});
    const [totalRowsR, setTotalRowsR] = useState(50);
    const [dataMapR, setDataMapR] = useState([]);
    const [dataTableR, setDataTableR] = useState([]);
    const [columnDataR, setColumnDataR] = useState([]);

    const [tabValue, setTabValue] = useState('Customer');

    const handleClick2 = () => {
        if (isModal2 === false) {
            setIsModal2(true);
            getDataContact(1, 50);
        }
        else {
            setIsModal2(false);
        }
    };

    const [titleModal3, setTitleModal3] = useState('');
    const [isModal3, setIsModal3] = useState(false);

    const handleClick3 = () => {
        if (isModal3 === false) {
            setIsModal3(true);
            getDataRevised(1, 50);
        }
        else {
            setIsModal3(false);
        }
    };

    const searchRevisedTaxInvoice = () => {
        setTitleModal3('Revised Tax Invoice');
        handleClick3();
    }

    const paymentFromData = [
        { id: 1, code: 0, name: 'Invoices' },
        { id: 2, code: 1, name: 'General Invoices' }
    ];

    const typeData = [
        { id: 1, code: false, name: 'Non - Cost To Cost' },
        { id: 2, code: true, name: 'Cost To Cost' }
    ];

    const debitCreditData = [
        { id: 1, code: 'D', name: 'Debit' },
        { id: 2, code: 'C', name: 'Credit' }
    ];

    const customerToData = [
        { id: 1, code: '2', name: 'Shipper', hidden: props.isExport },
        { id: 2, code: '3', name: 'Consignee', hidden: props.isImport },
        { id: 3, code: '1', name: 'Agent', hidden: false }
    ];

    const includeData = [
        { id: 1, code: '1', name: 'Yes' },
        { id: 2, code: '0', name: 'No' },
    ];

    function handlePaymentFrom(val) {
        props.setPaymentFrom(val);
        if (val === 0) {
            if (props.Customer !== 'Agent') {
                props.setCustomerId(props.CustomerSCTempId);
                props.setCustomerName(props.CustomerSCTempName);
                props.setCustomerAddress(props.CustomerSCTempAddress);

                props.setDataPayment(props.DataPaymentSC);
                props.setTempDataPayment(props.DataPaymentSC);
                props.calculateTotal(props.DataPaymentSC);
            }
            else {
                props.setCustomerId(props.CustomerATempId);
                props.setCustomerName(props.CustomerATempName);
                props.setCustomerAddress(props.CustomerATempAddress);

                props.setDataPayment(props.DataPaymentA);
                props.setTempDataPayment(props.DataPaymentA);
                props.calculateTotal(props.DataPaymentA);
            }

            props.setIsInvoice(true);
        }
        else {
            props.setIsInvoice(false);
            props.setDataPayment([]);
            props.setTempDataPayment([]);
            props.calculateTotal([]);
        }

        props.setCurrency('All');
    }

    function handleCustomerTo(val) {
        props.setIsLoadingBackDrop(true);
        props.setCustomerTo(val);

        if (val === '2') {
            props.setCustomer('Shipper');
            props.setIsAgent(true);
            props.setInvoiceForAgentNo('');
            props.setPaymentType('D');
            props.setCustomerId(props.CustomerSCId);
            props.setCustomerName(props.CustomerSCName);
            props.setCustomerAddress(props.CustomerSCAddress);
            props.setPaymentFrom(0);

            props.getDataDetail(props.EPLId, props.CustomerSCId, props.Type, true, val);
        }
        else if (val === '3') {
            props.setCustomer('Consignee');
            props.setIsAgent(true);
            props.setInvoiceForAgentNo('');
            props.setPaymentType('D');
            props.setCustomerId(props.CustomerSCId);
            props.setCustomerName(props.CustomerSCName);
            props.setCustomerAddress(props.CustomerSCAddress);
            props.setPaymentFrom(0);

            props.getDataDetail(props.EPLId, props.CustomerSCId, props.Type, true, val);
        }
        else {
            props.setCustomer('Agent');
            props.setIsAgent(false);
            props.setCustomerId(props.CustomerAId);
            props.setCustomerName(props.CustomerAName);
            props.setCustomerAddress(props.CustomerAAddress);
            props.setPaymentFrom(0);

            if (props.PaymentType === 'D') {
                props.getDataDetail(props.EPLId, props.CustomerAId, props.Type, true, val);
            }
            else {
                props.getDataDetail(props.EPLId, props.CustomerAId, props.Type, false, val);
            }
        }

        props.setCurrency('All');
    }

    function handleIsInclude(val) {
        props.setIncludeValue(val);
        props.setAmount(0);
        if (val === '1') {
            props.setInclude(true);
            props.setIsInclude(false);
        }
        else {
            props.setInclude(false);
            props.setIsInclude(true);
        }
    }

    function handlePaymentType(val) {
        props.setPaymentType(val);

        if (props.EPLId !== 0) {
            if (props.BillToId !== 0) {
                props.setIsLoadingBackDrop(true);

                if (val === 'C') {
                    props.getDataDetail(props.EPLId, props.BillToId, props.Type, false);
                }
                else {
                    props.getDataDetail(props.EPLId, props.BillToId, props.Type, true);
                }
            }
            else if (props.CustomerId !== 0) {
                props.setIsLoadingBackDrop(true);

                if (val === 'C') {
                    props.getDataDetail(props.EPLId, props.CustomerId, props.Type, false);
                }
                else {
                    props.getDataDetail(props.EPLId, props.CustomerId, props.Type, true);
                }
            }
        }
    }

    function handleType(value) {
        props.setType(value);

        if (props.EPLId !== 0) {
            if (props.BillToId !== 0) {
                props.setIsLoadingBackDrop(true);

                if (props.PaymentType === 'C') {
                    props.getDataDetail(props.EPLId, props.BillToId, value, false);
                }
                else {
                    props.getDataDetail(props.EPLId, props.BillToId, value, true);
                }
            }
            else if (props.CustomerId !== 0) {
                props.setIsLoadingBackDrop(true);

                if (props.PaymentType === 'C') {
                    props.getDataDetail(props.EPLId, props.CustomerId, value, false);
                }
                else {
                    props.getDataDetail(props.EPLId, props.CustomerId, value, true);
                }
            }
        }
    }

    const getDataContact = (pageNumber, pageSize, filters, orderBy) => {
        setIsLoadingC(true);
        let params = [];

        if (props.PaymentFrom === 0) {
            let isIncome = true;

            if (props.PaymentType === 'C') {
                isIncome = false;
            }
            else {
                isIncome = true;
            }

            params = [
                ...params,
                { 'attr': 'eplId', 'value': '' + props.EPLId, 'isDistinct': true },
                { 'attr': 'customerTypeId', 'value': '' + props.CustomerTo, 'isDistinct': true },
                { 'attr': 'isIncome', 'value': '' + isIncome, 'isDistinct': false },
                { 'attr': 'customerId', 'value': '', 'isDistinct': true }
            ];

            ApiService.OperationPostByDetail(paramC, 'SEARCH', pageNumber, pageSize, params, filters, orderBy)
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedDataC({})
                        setDataC(response.data.data.estimateProfitLossDetail);

                        let temp = response.data.data.estimateProfitLossDetail
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataMapC(indexed)
                        handleDataTable(indexed, response.data.data.columns, setDataTableC, 'id');
                        setColumnDataC(response.data.data.columns.detailColumns)
                        setTotalRowsC(response.data.totalRowCount)

                        setIsLoadingC(false)
                    }
                    setIsLoadingC(false)
                })
                .catch(function (error) {
                    setIsLoadingC(false);
                    showToast({ type: 'error', message: 'You Don\'t Have Access to This Data!, ' + error });
                    setIsModal2(false);
                })
        }
        else {
            ApiService.ContactPostByPage('regContact/regContact', props.CustomerTo, pageNumber, pageSize, filters, orderBy)
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedDataC({})
                        setDataC(response.data.data.contact);

                        let temp = response.data.data.contact
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataMapC(indexed)
                        handleDataTable(indexed, response.data.data.columns, setDataTableC, 'id');
                        setColumnDataC(response.data.data.columns)
                        setTotalRowsC(response.data.totalRowCount)

                        setIsLoadingC(false)
                    }
                    setIsLoadingC(false)
                })
                .catch(function (error) {
                    setIsLoadingC(false);
                    showToast({ type: 'error', message: 'You Don\'t Have Access to This Data!, ' + error });
                    setIsModal2(false);
                })
        }
    };

    const getDataRevised = (pageNumber, pageSize, filters, orderBy) => {
        setIsLoadingR(true);
        let params = [];
        params = [
            ...params,
            { 'attr': 'jobTypeId', 'value': '' + props.typeJobId },
            { 'attr': 'isPosted', 'value': '1' },
            { 'attr': 'replacementInvId', 'value': '0' },
            { 'attr': 'orId', 'value': '0' },
            { 'attr': 'pvId', 'value': '0' },
            { 'attr': 'deleteType', 'value': '3', },
            { 'attr': 'rowStatus', 'value': 'DEL' }
        ];

        if (props.ShipmentId !== 0) {
            params = [
                ...params,
                { 'attr': 'shipmentId', 'value': '' + props.ShipmentId }
            ]
        }
        ApiService.OperationDynamicPostByPage(paramR, 'SEARCH', pageNumber, pageSize, params, filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataR({});
                    setDataR(response.data.data.invoices);

                    let temp = response.data.data.invoices;
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp;
                    })

                    setDataMapR(indexed);
                    handleDataTable(indexed, response.data.data.columns.headerColumns, setDataTableR, 'id');
                    setColumnDataR(response.data.data.columns.headerColumns);
                    setTotalRowsR(response.data.totalRowCount);

                    setIsLoadingR(false);
                }
                setIsLoadingR(false);
            })
            .catch(function (error) {
                setIsLoadingR(false);
                showToast({ type: 'error', message: 'You Don\'t Have Access to This Data!, ' + error });
                setIsModal2(false);
            })
    }

    return (
        <div>
            <form>
                <Box>
                    <Box>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={4}
                                lg={4}
                                sm={4}
                                xs={12}
                            >
                                <Input
                                    label={'Infoss Number'}
                                    value={props.infossNo}
                                    onChange={(e) => props.setInfossNo(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginBottom: '16px !important'
                                        }
                                    }}
                                    disabled={props.isViewOnly}
                                    hidden={!isInfossNo}
                                    message={props.infossNo !== '' ? '' : 'This Field is Required'}
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={4}
                                lg={4}
                                sm={4}
                                xs={12}
                            >
                                <Selection
                                    id='0'
                                    label={'Payment From'}
                                    fullWidth={true}
                                    placeholder={'Select Payment From'}
                                    value={props.PaymentFrom}
                                    onChange={(e) => handlePaymentFrom(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            // marginTop: '16px'
                                        }
                                    }}
                                    disabled={props.isEdit}
                                >
                                    {
                                        paymentFromData.map((data, index) => (
                                            <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                        ))
                                    }
                                </Selection>

                                <Selection
                                    id='0'
                                    label={'Type'}
                                    fullWidth={true}
                                    placeholder={'Select Type'}
                                    value={props.Type}
                                    onChange={(e) => handleType(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            marginTop: '16px'
                                        }
                                    }}
                                    disabled={props.isEdit}
                                >
                                    {
                                        typeData.map((data, index) => (
                                            <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                        ))
                                    }
                                </Selection>

                                <Input
                                    label={'Invoice No'}
                                    value={props.InvoiceNo}
                                    hidden={!props.isEdit}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px'
                                        }
                                    }}
                                />

                                <Input
                                    label={'Invoice For Agent No'}
                                    value={props.InvoiceForAgentNo}
                                    hidden={props.isAgent}
                                    disabled={props.isViewOnly}
                                    onChange={(e) => props.setInvoiceForAgentNo(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px'
                                        }
                                    }}
                                />

                                <Stack spacing={'16px'} direction="row">
                                    <Input
                                        textAlign='right'
                                        label={'Printing'}
                                        value={props.PrintCount}
                                        disabled
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                                marginTop: '16px !important'
                                            }
                                        }}
                                    />

                                    <CustomDatePicker
                                        label={'Printing Date'}
                                        value={props.PrintOn}
                                        type={'date'}
                                        disabled
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                                marginTop: '16px !important'
                                            }
                                        }}
                                    />
                                </Stack>

                                <CustomDatePicker
                                    label={'ETD / ETA'}
                                    value={props.ET}
                                    type={'date'}
                                    disabled
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />

                                <div style={{ marginTop: '16px' }}>
                                    {props.selectInvoiceHeader(props.PrincipleBy, props.setPrincipleBy, true)}
                                </div>

                                <div style={{ marginTop: '16px' }}>
                                    {props.selectInvoiceHeader(props.HeaderInvoice, props.setHeaderInvoice, props.isViewOnly, 'Invoice Header')}
                                </div>
                            </Grid>

                            <Grid
                                item
                                xl={8}
                                lg={8}
                                sm={8}
                                xs={12}
                            >
                                <Input
                                    label="Shipment Order No."
                                    variant="standard"
                                    InputLabelProps={{ shrink: true }}
                                    fullWidth
                                    value={props.ShipmentOrderNo}
                                    type='text'
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                    disabled
                                    inputBaseProps={{
                                        readOnly: true,
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {
                                                    !props.eplId ?
                                                        <IconButton onClick={searchShipment} hidden={props.isEdit}>
                                                            <Icon
                                                                iconName={'search'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                        :
                                                        <IconButton hidden>
                                                            <Icon
                                                                iconName={'search'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                }
                                            </InputAdornment>
                                        )
                                    }}
                                />

                                <Selection
                                    id='0'
                                    label={'Debit/Credit'}
                                    fullWidth={true}
                                    placeholder={'Select Debit/Credit'}
                                    value={props.PaymentType}
                                    onChange={(e) => handlePaymentType(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            marginTop: '16px'
                                        }
                                    }}
                                    disabled={props.isAgent}
                                >
                                    {
                                        debitCreditData.map((data, index) => (
                                            <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                        ))
                                    }
                                </Selection>

                                <Selection
                                    id='0'
                                    label={'Customer'}
                                    fullWidth={true}
                                    placeholder={'Select Customer'}
                                    value={props.CustomerTo}
                                    onChange={(e) => handleCustomerTo(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            marginTop: '16px'
                                        }
                                    }}
                                    disabled={props.isEdit !== true && props.EPLId === 0 ? true : props.isEdit === true ? true : false}
                                >
                                    {
                                        customerToData.map((data, index) => (
                                            <MenuItem sx={{ fontSize: '12px' }} key={index} hidden={data.hidden} value={data.code}>{data.name}</MenuItem>
                                        ))
                                    }
                                </Selection>

                                <Box sx={{ marginTop: '25px' }}>
                                    <TabsCustomerBillTo
                                        setIsLoadingBackDrop={props.setIsLoadingBackDrop}
                                        isEdit={props.isEdit}
                                        tabValue={tabValue}
                                        setTabValue={setTabValue}
                                        Customer={props.Customer}
                                        setTitleModal={setTitleModal2}
                                        handleClick={handleClick2}
                                        CustomerType={CustomerType}
                                        setCustomerType={setCustomerType}
                                        CustomerId={props.CustomerId}
                                        setCustomerId={props.setCustomerId}
                                        CustomerName={props.CustomerName}
                                        setCustomerName={props.setCustomerName}
                                        CustomerAddress={props.CustomerAddress}
                                        setCustomerAddress={props.setCustomerAddress}
                                        BillToId={props.BillToId}
                                        setBillToId={props.setBillToId}
                                        PaymentFrom={props.PaymentFrom}
                                        BillToName={props.BillToName}
                                        setBillToName={props.setBillToName}
                                        BillToAddress={props.BillToAddress}
                                        setBillToAddress={props.setBillToAddress}

                                        CustomerSCTempId={props.CustomerSCTempId}
                                        CustomerSCTempName={props.CustomerSCTempName}
                                        CustomerSCTempAddress={props.CustomerSCTempAddress}
                                        CustomerATempId={props.CustomerATempId}
                                        CustomerATempName={props.CustomerATempName}
                                        CustomerATempAddress={props.CustomerATempAddress}

                                        EPLId={props.EPLId}
                                        PaymentType={props.PaymentType}
                                        getDataDetail={props.getDataDetail}
                                        isRevised={props.isRevised}
                                    />
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={4}
                                lg={4}
                                sm={4}
                                xs={12}
                            >
                                <Input
                                    label={'Packing List'}
                                    value={props.PackingList}
                                    onChange={(e) => props.setPackingList(e.target.value)}
                                    hidden={props.isSea}
                                    disabled={props.isViewOnly}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px'
                                        }
                                    }}
                                />

                                <Input
                                    label={'SI Customer'}
                                    value={props.SICustomer}
                                    onChange={(e) => props.setSICustomer(e.target.value)}
                                    hidden={props.isSea}
                                    disabled={props.isViewOnly}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px'
                                        }
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                xl={8}
                                lg={8}
                                sm={8}
                                xs={12}
                            >
                                <Grid
                                    container
                                    spacing={'24px'}
                                >
                                    <Grid
                                        item
                                        xl={6}
                                        lg={6}
                                        sm={6}
                                        xs={12}
                                    >
                                        <Input
                                            label={'Reference E-Faktur'}
                                            value={props.RefEFaktur}
                                            onChange={(e) => props.setRefEFaktur(e.target.value)}
                                            disabled={props.isViewOnly}
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px'
                                                }
                                            }}
                                        />

                                        <Input
                                            label="Revised Tax Inv. No"
                                            variant="standard"
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                            value={props.RevisedTaxNo}
                                            type='text'
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px'
                                                }
                                            }}
                                            inputBaseProps={{
                                                readOnly: true,
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={searchRevisedTaxInvoice}
                                                            disabled={props.isRevised ? false : (props.isEdit || (props.fakturType !== undefined) === true ? true : false)}
                                                            hidden={props.isRevised ? false : props.isEdit}
                                                        >
                                                            <Icon
                                                                iconName={'search'}
                                                                svgIconProps={{
                                                                    fontSize: 'small',
                                                                    htmlColor: '#0087C2',
                                                                    sx: {
                                                                        height: '12px',
                                                                        width: '12px'
                                                                    }
                                                                }}
                                                            />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                            disabled
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xl={6}
                                        lg={6}
                                        sm={6}
                                        xs={12}
                                    >
                                        <Selection
                                            id='0'
                                            label={'Include Stamp Duty'}
                                            fullWidth={true}
                                            placeholder={'Select Include Stamp Duty'}
                                            value={props.IncludeValue}
                                            onChange={(e) => handleIsInclude(e.target.value)}
                                            formControlProps={{
                                                sx: {
                                                    marginTop: '16px'
                                                }
                                            }}
                                            disabled={props.isViewOnly}
                                        >
                                            {
                                                includeData.map((data, index) => (
                                                    <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                                ))
                                            }
                                        </Selection>

                                        <Input
                                            textAlign='right'
                                            label={'Amount Stamp Duty'}
                                            value={props.Amount}
                                            disabled={props.isInclude}
                                            onChange={(e) => props.setAmount(e.target.value)}
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px !important'
                                                }
                                            }}
                                            inputBaseProps={{
                                                inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </form>

            {
                isModal && (
                    <SelectDataEPL
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}
                        typeJobId={props.typeJobId}
                        PaymentType={props.PaymentType}
                        jobType={props.jobType}
                        Type={props.Type}
                        Customer={props.Customer}
                        setVatValue={props.setVatValue}
                        setCurrency={props.setCurrency}
                        calculateTotal={props.calculateTotal}
                        setPaymentFrom={props.setPaymentFrom}
                        invoiceId={props.invoiceId}
                        PaymentFrom={props.PaymentFrom}
                        setPrincipleBy={props.setPrincipleBy}
                        setIsClosed={props.setIsClosed}

                        getDataHeader={getDataHeaderEPL}
                        isLoading={isLoadingE}
                        setIsLoading={setIsLoadingE}
                        data={dataE}
                        setData={setDataE}
                        dataMap={dataMapE}
                        dataTable={dataTableE}
                        setDataMap={setDataMapE}
                        columnData={columnDataE}
                        setColumnData={setColumnDataE}
                        SelectedData={SelectedDataE}
                        setSelectedData={setSelectedDataE}
                        totalRows={totalRowsE}
                        setTotalRows={setTotalRowsE}

                        volume20={props.volume20}
                        setVolume20={props.setVolume20}
                        volume40={props.volume40}
                        setVolume40={props.setVolume40}
                        volume45={props.volume45}
                        setVolume45={props.setVolume45}
                        volumeCBM={props.volumeCBM}
                        setVolumeCBM={props.setVolumeCBM}
                        isVolume20={props.isVolume20}
                        setIsVolume20={props.setIsVolume20}
                        isVolume40={props.isVolume40}
                        setIsVolume40={props.setIsVolume40}
                        isVolume45={props.isVolume45}
                        setIsVolume45={props.setIsVolume45}
                        isVolumeCBM={props.isVolumeCBM}
                        setIsVolumeCBM={props.setIsVolumeCBM}

                        bookingConfirmationId={props.bookingConfirmationId}
                        setBookingConfirmationId={props.setBookingConfirmationId}
                        bookingConfirmationNumber={props.bookingConfirmationNumber}
                        setBookingConfirmationNumber={props.setBookingConfirmationNumber}

                        setEPLId={props.setEPLId}
                        setShipmentOrderNo={props.setShipmentOrderNo}
                        setShipmentId={props.setShipmentId}
                        setRequester={props.setRequester}
                        setDataPayment={props.setDataPayment}
                        setTempDataPayment={props.setTempDataPayment}
                        setTicketId={props.setTicketId}
                        setDataPaymentSC={props.setDataPaymentSC}
                        setDataPaymentA={props.setDataPaymentA}
                        setCustomerId={props.setCustomerId}
                        setCustomerName={props.setCustomerName}
                        setCustomerAddress={props.setCustomerAddress}
                        setCustomerSCId={props.setCustomerSCId}
                        setCustomerSCName={props.setCustomerSCName}
                        setCustomerSCAddress={props.setCustomerSCAddress}
                        setCustomerAId={props.setCustomerAId}
                        setCustomerAName={props.setCustomerAName}
                        setCustomerAAddress={props.setCustomerAAddress}
                        setAmountCrr={props.setAmountCrr}
                        setET={props.setET}
                        setIsInvoice={props.setIsInvoice}
                        getDataDetail={props.getDataDetail}
                        setIsLoadingBackDrop={props.setIsLoadingBackDrop}

                        setCustomerSCTempId={props.setCustomerSCTempId}
                        setCustomerSCTempName={props.setCustomerSCTempName}
                        setCustomerSCTempAddress={props.setCustomerSCTempAddress}
                        setCustomerATempId={props.setCustomerATempId}
                        setCustomerATempName={props.setCustomerATempName}
                        setCustomerATempAddress={props.setCustomerATempAddress}

                        GetContainerSizeData={props.GetContainerSizeData}
                        emptyState={props.emptyState}
                    />
                )
            }

            {
                isModal2 && (
                    <SelectDataContact
                        isModal={isModal2}
                        handleClick={handleClick2}
                        titleModal={titleModal2}
                        setIsModal={setIsModal2}
                        type={props.CustomerTo}
                        CustomerType={CustomerType}
                        setVatValue={props.setVatValue}
                        setCurrency={props.setCurrency}
                        EPLId={props.EPLId}
                        invoiceId={props.invoiceId}
                        setAmountCrr={props.setAmountCrr}
                        Customer={props.Customer}
                        PaymentFrom={props.PaymentFrom}
                        setPaymentFrom={props.setPaymentFrom}
                        getDataDetail={props.getDataDetail}
                        setIsLoadingBackDrop={props.setIsLoadingBackDrop}
                        PaymentType={props.PaymentType}

                        getData={getDataContact}
                        isLoading={isLoadingC}
                        setIsLoading={setIsLoadingC}
                        data={dataC}
                        setData={setDataC}
                        dataMap={dataMapC}
                        dataTable={dataTableC}
                        setDataMap={setDataMapC}
                        columnData={columnDataC}
                        setColumnData={setColumnDataC}
                        SelectedData={SelectedDataC}
                        setSelectedData={setSelectedDataC}
                        totalRows={totalRowsC}
                        setTotalRows={setTotalRowsC}

                        CustomerId={props.CustomerId}
                        setCustomerId={props.setCustomerId}
                        setCustomerName={props.setCustomerName}
                        setCustomerAddress={props.setCustomerAddress}
                        setBillToId={props.setBillToId}
                        setBillToName={props.setBillToName}
                        setBillToAddress={props.setBillToAddress}
                        isCostToCost={props.Type}

                        setCustomerSCId={props.setCustomerSCId}
                        setCustomerSCName={props.setCustomerSCName}
                        setCustomerSCAddress={props.setCustomerSCAddress}
                        setCustomerAId={props.setCustomerAId}
                        setCustomerAName={props.setCustomerAName}
                        setCustomerAAddress={props.setCustomerAAddress}

                        setDataPayment={props.setDataPayment}
                        setTempDataPayment={props.setTempDataPayment}
                        setDataPaymentSC={props.setDataPaymentSC}
                        setDataPaymentA={props.setDataPaymentA}
                        calculateTotal={props.calculateTotal}
                    />
                )
            }

            {
                isModal3 && (
                    <SelectRevisedInvoice
                        isModal={isModal3}
                        handleClick={handleClick3}
                        titleModal={titleModal3}
                        setIsModal={setIsModal3}
                        typeJobId={props.typeJobId}

                        getData={getDataRevised}
                        isLoading={isLoadingR}
                        setIsLoading={setIsLoadingR}
                        data={dataR}
                        setData={setDataR}
                        dataMap={dataMapR}
                        dataTable={dataTableR}
                        setDataMap={setDataMapR}
                        columnData={columnDataR}
                        setRolumnData={setColumnDataR}
                        SelectedData={SelectedDataR}
                        setSelectedData={setSelectedDataR}
                        totalRows={totalRowsR}
                        setTotalRows={setTotalRowsR}

                        setRevisedTaxNo={props.setRevisedTaxNo}
                        setRevisedTaxId={props.setRevisedTaxId}
                        getDataInv={props.getData}
                        jobType={props.jobType}
                    />
                )
            }
        </div>
    )
}

export default InvoiceHeader;