import { IconButton, InputAdornment, MenuItem, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ApiService, { userCode } from '../../../../services/ApiService';
import ButtonService from '../../ButtonService';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';
import SelectDataMaster from '../SelectDataMaster';
import { Icon, Input, PopUp, Selection, showToast } from '../../../../components/ui';
import { handleDataTable } from '../../../../utils/reusableFunction';

const CreateEditProductCategory = (props) => {
    const paramSite = "warehouse/Sites/Sites";
    const paramWarehouse = "warehouse/RegSitesWarehouses/RegSitesWarehouses";
    const paramWarehouseLocation = "warehouse/warehouselocation/warehouselocation";

    const [isModalSite, setIsModalSite] = useState(false);
    const [isLoadingModalSite, setIsLoadingModalSite] = useState(false);
    const [dataModalSite, setDataModalSite] = useState([]);
    const [dataMapModalSite, setDataMapModalSite] = useState([]);
    const [dataTableSite, setDataTableSite] = useState([]);
    const [columnDataModalSite, setColumnDataModalSite] = useState([]);
    const [SelectedDataModalSite, setSelectedDataModalSite] = useState({});
    const [totalRowsModalSite, setTotalRowsModalSite] = useState(50);

    const [isModalWarehouse, setIsModalWarehouse] = useState(false);
    const [isLoadingModalWarehouse, setIsLoadingModalWarehouse] = useState(false);
    const [dataModalWarehouse, setDataModalWarehouse] = useState([]);
    const [dataMapModalWarehouse, setDataMapModalWarehouse] = useState([]);
    const [dataTableWarehouse, setDataTableWarehouse] = useState([]);
    const [columnDataModalWarehouse, setColumnDataModalWarehouse] = useState([]);
    const [SelectedDataModalWarehouse, setSelectedDataModalWarehouse] = useState({});
    const [totalRowsModalWarehouse, setTotalRowsModalWarehouse] = useState(50);

    const [warehouseLocationTypeList, setWarehouseLocationTypeList] = useState([]);

    const handleClickSite = () => {
        if (isModalSite === false) {
            setIsModalSite(true);
            getDataSite(1, 50);
        }
        else {
            setIsModalSite(false);
        }
    };

    const handleClickWarehouse = () => {
        if (isModalWarehouse === false) {
            setIsModalWarehouse(true);
            getDataWarehouse(1, 50);
        }
        else {
            setIsModalWarehouse(false);
        }
    };

    const getDataSite = (pageNumber, pageSize, filters) => {
        setIsLoadingModalSite(true);
        ApiService.PostByPage(paramSite, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataModalSite({})
                    setDataModalSite(response.data.data.sites);

                    let temp = response.data.data.sites
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapModalSite(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTableSite, 'id');
                    setColumnDataModalSite(response.data.data.columns)
                    setTotalRowsModalSite(response.data.totalRowCount)

                    setIsLoadingModalSite(false)
                }
                setIsLoadingModalSite(false)
            })
            .catch(function (error) {
                setIsLoadingModalSite(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error })
            })
    };

    const getDataWarehouse = (pageNumber, pageSize, filters) => {
        setIsLoadingModalWarehouse(true);
        filters = [{
            "field": "sitesId",
            "data": props.siteId.toString()
        }]
        ApiService.PostByPage(paramWarehouse, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataModalWarehouse({})
                    setDataModalWarehouse(response.data.data.sitesWarehouses);
                    let temp = response.data.data.sitesWarehouses
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapModalWarehouse(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTableWarehouse, 'id');
                    setColumnDataModalWarehouse(response.data.data.columns)
                    setTotalRowsModalWarehouse(response.data.totalRowCount)

                    setIsLoadingModalWarehouse(false)
                }
                setIsLoadingModalWarehouse(false)
            })
            .catch(function (error) {
                setIsLoadingModalWarehouse(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error })
            })
    };

    const searchWarehouse = () => {
        if (!props.siteId || props.siteId === "0") {
            showToast({ type: 'error', message: 'Select Site!' })
            return
        }
        handleClickWarehouse();
    }

    const selectSite = (selectedData) => {
        props.setSiteId(selectedData.id);
        props.setSiteName(selectedData.name);
        props.setSiteCode(selectedData.code);
        props.setWarehouseId('');
        props.setWarehouseName('');
    }

    const selectWarehouse = (selectedData) => {
        props.setWarehouseId(selectedData.warehousesId);
        props.setWarehouseName(selectedData.warehousesName);
        props.setWarehouseCode(selectedData.warehousesCode)
        props.setLocationX("0");
        props.setLocationY("0");
        props.setLocationZ("0");
    }

    const Save = () => {
        var data =
        {
            "productcategory": {
                "rowStatus": "ACT",
                "id": props.id,
                "code": props.code,
                "name": props.name,
                "description": props.description,
                "sitesId": props.siteId,
                "sitesCode": props.siteCode,
                "sitesName": props.siteName,
                "warehousesId": props.warehouseId,
                "warehousesCode": props.warehouseCode,
                "warehousesName": props.warehouseName,
                "warehouseLocationTypeId": props.warehouseLocationTypeId,
                "warehouseLocationTypeName": props.warehouseLocationTypeName,
                "isRack": props.isRack,
                "warehouseLocationX": props.locationX,
                "warehouseLocationY": props.locationY,
                "warehouseLocationZ": props.locationZ,
                "userCode": userCode
            }
        }
        // ButtonService.Save(props.titleModal, props.title, props.param, data);
        ButtonService.Save(props.titleModal, props.title, props.param, data, () => {
            props.getData(1, 50, []);
            props.handleClick();
        });
    }
    const searchWarehouseLocationType = () => {
        ApiService.PostByPageWarehouseLocationType(paramWarehouseLocation, 1, 100, [])
            .then((response) => {
                if (response.data.code === 200) {
                    setWarehouseLocationTypeList(response.data.data.warehouseLocationType)

                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error })
            })
    }

    const warehouseLocationTypeChangeHandler = (value) => {
        props.setWarehouseLocationTypeId(value)
        ApiService.PostByIdWarehouseLocationType(paramWarehouseLocation, value)
            .then((res) => {
                const obj = res.data.data.warehouseLocationType;
                props.setWarehouseLocationTypeName(obj.name);
                props.setIsRack(obj.isRack);
                if (!obj.isRack) {
                    props.setLocationX("0");
                    props.setLocationY("0");
                    props.setLocationZ("0");
                }
            });
    }

    useEffect(() => {
        searchWarehouseLocationType();
    }, []);

    const mainContainer = (
        <form onSubmit={Save}>
            <Input
                label="Code"
                value={props.code}
                onChange={(e) => props.setCode(e.target.value)}
                disabled={props.id === 0 ? false : true}
                formControlProps={{
                    sx: {
                        width: '100%',
                    }
                }}
            />

            <Input
                label="Name"
                value={props.name}
                onChange={(e) => props.setName(e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px !important'
                    }
                }}
            />

            <Input
                label="Description"
                value={props.description}
                onChange={(e) => props.setDescription(e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px !important'
                    }
                }}
            />

            <Input
                label="Site"
                value={props.siteName}
                fullWidth
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                disabled
                inputBaseProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => handleClickSite()}>
                                <Icon
                                    iconName={'search'}
                                    svgIconProps={{
                                        fontSize: 'small',
                                        htmlColor: '#0087C2',
                                        sx: {
                                            height: '12px',
                                            width: '12px'
                                        }
                                    }}
                                />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />

            <Input
                label="Warehouse"
                value={props.warehouseName}
                onChange={(e) => props.setWarehouseName(e.target.value)}
                fullWidth
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                disabled
                inputBaseProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => searchWarehouse()}>
                                <Icon
                                    iconName={'search'}
                                    svgIconProps={{
                                        fontSize: 'small',
                                        htmlColor: '#0087C2',
                                        sx: {
                                            height: '12px',
                                            width: '12px'
                                        }
                                    }}
                                />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />

            <Selection
                id='0'
                label={'Warehouse Location Type'}
                fullWidth={true}
                placeholder={'Select Warehouse Location Type'}
                value={props.warehouseLocationTypeId}
                onOpen={searchWarehouseLocationType}
                onChange={(e) => warehouseLocationTypeChangeHandler(e.target.value)}
                formControlProps={{
                    sx: {
                        marginTop: '16px !important'
                    }
                }}
            >
                {
                    warehouseLocationTypeList.map((data, index) => {
                        return <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.id}>{data.name}</MenuItem>
                    })
                }
            </Selection>

            <Stack spacing={'32px'} direction="row">
                <Selection
                    id='0'
                    label={'Location X'}
                    fullWidth={true}
                    placeholder={'Select Location X'}
                    value={props.locationX}
                    onOpen={() => props.searchLocation("X")}
                    onChange={(e) => {
                        props.setLocationX(e.target.value)
                        props.setLocationY("0");
                        props.setLocationZ("0");
                    }}
                    formControlProps={{
                        sx: {
                            marginTop: '16px !important'
                        }
                    }}
                    disabled={!props.isRack}
                >
                    {
                        props.locationXList.map((data, index) => {
                            return <MenuItem sx={{ fontSize: '12px' }} key={index} value={data}>{data}</MenuItem>
                        })
                    }
                </Selection>

                <Selection
                    id='0'
                    label={'Location Y'}
                    fullWidth={true}
                    placeholder={'Select Location Y'}
                    value={props.locationY}
                    onOpen={() => props.searchLocation("Y")}
                    onChange={(e) => {
                        props.setLocationY(e.target.value);
                        props.setLocationZ("0");
                    }}
                    formControlProps={{
                        sx: {
                            marginTop: '16px !important'
                        }
                    }}
                    disabled={!props.isRack}
                >
                    {
                        props.locationYList.map((data, index) => {
                            return <MenuItem sx={{ fontSize: '12px' }} key={index} value={data}>{data}</MenuItem>
                        })
                    }
                </Selection>

                <Selection
                    id='0'
                    label={'Location Z'}
                    fullWidth={true}
                    placeholder={'Select Location Z'}
                    value={props.locationZ}
                    onOpen={() => props.searchLocation("Z")}
                    onChange={(e) => props.setLocationZ(e.target.value)}
                    formControlProps={{
                        sx: {
                            marginTop: '16px !important'
                        }
                    }}
                    disabled={!props.isRack}
                >
                    {
                        props.locationZList.map((data, index) => {
                            return <MenuItem sx={{ fontSize: '12px' }} key={index} value={data}>{data}</MenuItem>
                        })
                    }
                </Selection>
            </Stack>
        </form>
    )

    return (
        <div>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={props.titleModal + ' ' + props.title}
                isOpen={props.isModal}
                onClose={props.handleClick}
                draggable={true}
                shrinkable={true}
                disableScrollLock={true}
                maxWidth={'sm'}
                fullWidth={true}
                topButton={false}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    {mainContainer}

                    <div style={{ marginTop: '48px' }}>
                        {CustomizeButtonComponent.SaveCancelModalButton(Save, false, false)}
                    </div>
                </div>
            </PopUp>

            {
                isModalSite && (
                    <SelectDataMaster
                        isModal={isModalSite}
                        handleClick={handleClickSite}
                        titleModal="Site"
                        setIsModal={setIsModalSite}

                        getData={getDataSite}
                        isLoading={isLoadingModalSite}
                        totalRows={totalRowsModalSite}
                        setTotalRows={setTotalRowsModalSite}
                        SelectedData={SelectedDataModalSite}
                        setSelectedData={setSelectedDataModalSite}
                        data={dataModalSite}
                        columnData={columnDataModalSite}
                        dataMap={dataMapModalSite}
                        dataTable={dataTableSite}

                        selectHandler={selectSite}
                    />
                )
            }

            {
                isModalWarehouse && (
                    <SelectDataMaster
                        isModal={isModalWarehouse}
                        handleClick={handleClickWarehouse}
                        titleModal="Warehouse"
                        setIsModal={setIsModalWarehouse}

                        getData={getDataWarehouse}
                        isLoading={isLoadingModalWarehouse}
                        totalRows={totalRowsModalWarehouse}
                        setTotalRows={setTotalRowsModalWarehouse}
                        SelectedData={SelectedDataModalWarehouse}
                        setSelectedData={setSelectedDataModalWarehouse}
                        data={dataModalWarehouse}
                        columnData={columnDataModalWarehouse}
                        dataMap={dataMapModalWarehouse}
                        dataTable={dataTableWarehouse}

                        selectHandler={selectWarehouse}
                    />
                )
            }
        </div>
    )
}

export default CreateEditProductCategory