import { Grid, Stack, IconButton, InputAdornment, Typography } from '@mui/material';
import React, { useState } from 'react';

import SelectDataCOA from '../Selects/SelectDataCOA';
import ApiService, { isInfossNo } from '../../../../../services/ApiService';
import SelectDataContact from '../Selects/SelectDataContact';
import { CustomDatePicker, Icon, Input, showToast, TickBox } from '../../../../../components/ui';
import { updateDateFormat } from '../../../../../utils/date';
import { handleDataTable } from '../../../../../utils/reusableFunction';

const TemporaryReceiptHeader = (props) => {
    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);

    const paramC = "regContact/regContact";
    const [isLoadingC, setIsLoadingC] = useState(false);
    const [dataC, setDataC] = useState([]);
    const [dataMapC, setDataMapC] = useState([])
    const [dataTableC, setDataTableC] = useState([])
    const [columnDataC, setColumnDataC] = useState([])
    const [SelectedDataC, setSelectedDataC] = useState({});
    const [totalRowsC, setTotalRowsC] = useState(50);

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataContact(1, 50);
        }
        else {
            setIsModal(false);
        }
    };

    const searchCustomer = (data) => {
        if (data === 'search') {
            if (props.JobOwnerId !== 0) {
                setTitleModal('Customer');
                handleClick();
            }
            else {
                showToast({ type: 'error', message: 'Please Select Principle By First!' });
            }
        }
        else if (data === 'delete') {
            props.setCustomerId(0);
            props.setCustomerCode('');
            props.setCustomerName('');

            props.setShipmentOrderList([]);
            props.setShipmentOrderIdList([]);
            props.setCashPaymentUSD(0);
            props.setCashPaymentIDR(0);
            props.calculateTotalCash([]);
            props.setBankData([]);
            props.calculateTotalBank([]);
        }
    }

    const [titleModal2, setTitleModal2] = useState('');
    const [isModal2, setIsModal2] = useState(false);

    const paramO = "regChartOfAccountEntry/regChartOfAccountEntry";
    const [isLoadingO, setIsLoadingO] = useState(false);
    const [dataO, setDataO] = useState([]);
    const [dataMapO, setDataMapO] = useState([]);
    const [dataTableO, setDataTableO] = useState([]);
    const [columnDataO, setColumnDataO] = useState([]);
    const [SelectedDataO, setSelectedDataO] = useState({});
    const [totalRowsO, setTotalRowsO] = useState(50);

    const handleClick2 = () => {
        if (isModal2 === false) {
            setIsModal2(true);
            getDataCOA(1, 50);
        }
        else {
            setIsModal2(false);
        }
    };

    const searchReceiptType = (data) => {
        if (data === 'search') {
            setTitleModal2('Chart Of Account (COA)');
            handleClick2();
        }
        else if (data === 'delete') {
            props.setCOACode('');
            props.setCOAName('');
        }
    };

    const getDataContact = (pageNumber, pageSize, filters) => {
        setIsLoadingC(true);
        let params = [];
        if (props.jobType.serviceType === 'Export') {
            params = [
                ...params,
                { 'attr': 'contactTypeId', 'value': '2' }
            ];
        }
        else {
            params = [
                ...params,
                { 'attr': 'contactTypeId', 'value': '3' }
            ];
        }
        ApiService.DynamicPostByPage(paramC, 'SEARCH', pageNumber, pageSize, params, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataC({})
                    setDataC(response.data.data.contact);

                    let temp = response.data.data.contact
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapC(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTableC, 'id');
                    setColumnDataC(response.data.data.columns)
                    setTotalRowsC(response.data.totalRowCount)

                    setIsLoadingC(false)
                }
                setIsLoadingC(false)
            })
            .catch(function (error) {
                setIsLoadingC(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const getDataCOA = (pageNumber, pageSize, filters) => {
        setIsLoadingO(true);
        let params = [
            {
                'attr': 'entryFor',
                'value': 'TR'
            }
        ]
        ApiService.DynamicPostByPage(paramO, 'SEARCH', pageNumber, pageSize, params, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataO({})
                    setDataO(response.data.data.chartOfAccountEntry);

                    let temp = response.data.data.chartOfAccountEntry
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapO(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTableO, 'id');
                    setColumnDataO(response.data.data.columns)
                    setTotalRowsO(response.data.totalRowCount)

                    setIsLoadingO(false)
                }
                setIsLoadingO(false)
            })
            .catch(function (error) {
                setIsLoadingO(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    };

    function handleChange() {
        if (props.isDueDate === false) {
            props.getDateNow();
            props.setIsDueDate(true);
        }
        else {
            props.setDueDate('');
            props.setIsDueDate(false);
        }
        props.GetExRate(updateDateFormat(new Date()));
    }

    function handleDueDate(value) {
        props.setDueDate(value);
        if (updateDateFormat(new Date()) > updateDateFormat(value)) {
            props.GetExRate(updateDateFormat(value));
        }
    }

    return (
        <div>
            <form>
                <div>
                    <div>
                        <Grid
                            container
                            spacing={'16px'}
                        >
                            <Grid
                                item
                                xl={3}
                                lg={3}
                                sm={3}
                                xs={12}
                            >
                                <Input
                                    label={'Infoss Number'}
                                    value={props.infossNo}
                                    onChange={(e) => props.setInfossNo(e.target.value)}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginBottom: '16px !important'
                                        }
                                    }}
                                    disabled={props.Printing > 0}
                                    hidden={!isInfossNo}
                                    message={props.infossNo !== '' ? '' : 'This Field is Required'}
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Input
                                    label="TR Number"
                                    textAlign='right'
                                    value={props.TRNumber}
                                    disabled
                                    hidden={!props.isEdit}
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                        }
                                    }}
                                />

                                <div style={{ marginTop: props.isEdit ? '16px' : '0px' }}>
                                    <Grid
                                        container
                                        spacing={'16px'}
                                    >
                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            sm={6}
                                            xs={12}
                                        >
                                            <div>
                                                {props.selectPrincipleBy}
                                            </div>
                                        </Grid>

                                        <Grid
                                            item
                                            xl={6}
                                            lg={6}
                                            sm={6}
                                            xs={12}
                                        >
                                            <div variant='standard' style={{ fullWidth: 'true' }} disabled={props.Printing > 0}>
                                                <Typography
                                                    sx={{
                                                        color: 'var(--Primary-One, #083A50)',
                                                        fontFamily: 'Nunito',
                                                        fontSize: '14px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '600',
                                                        lineHeight: '19.6px',
                                                    }}
                                                >
                                                    Due Date
                                                </Typography>

                                                <Stack direction='row' spacing={'16px'} alignItems={'center'} justifyContent={'center'}>
                                                    <TickBox
                                                        checked={props.isDueDate}
                                                        onChange={() => handleChange()}
                                                        disabled={props.Printing > 0}
                                                    />

                                                    <CustomDatePicker
                                                        type={'date'}
                                                        value={props.DueDate}
                                                        disabled={!props.isDueDate || props.Printing > 0}
                                                        onChange={handleDueDate}
                                                        formControlProps={{
                                                            sx: {
                                                                width: '100%',
                                                                marginTop: '-3px !important'
                                                            }
                                                        }}
                                                    />
                                                </Stack>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>

                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Grid
                                    container
                                    spacing={'16px'}
                                >
                                    <Grid
                                        item
                                        xl={6}
                                        lg={6}
                                        sm={6}
                                        xs={12}
                                    >
                                        <Input
                                            label='Rate'
                                            value={props.Rate}
                                            textAlign='right'
                                            disabled
                                            fullWidth
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                }
                                            }}
                                        />

                                        <CustomDatePicker
                                            label='Verify Acc'
                                            value={props.verifyAccOn}
                                            type={'date'}
                                            disabled
                                            hidden={!props.isEdit}
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px !important'
                                                }
                                            }}
                                        />
                                    </Grid>

                                    <Grid
                                        item
                                        xl={6}
                                        lg={6}
                                        sm={6}
                                        xs={12}
                                    >
                                        <CustomDatePicker
                                            label='Date Rate'
                                            value={props.RateDate}
                                            type={'date'}
                                            disabled
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                }
                                            }}
                                        />

                                        <CustomDatePicker
                                            label='Print Date'
                                            value={props.PrintDate}
                                            type={'date'}
                                            disabled
                                            hidden={!props.isEdit}
                                            formControlProps={{
                                                sx: {
                                                    width: '100%',
                                                    marginTop: '16px !important'
                                                }
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                                    <Input
                                        label='Customer'
                                        value={props.CustomerCode}
                                        disabled
                                        formControlProps={{
                                            sx: {
                                                width: '50%',
                                            }
                                        }}
                                    />

                                    <Input
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        value={props.CustomerName}
                                        type='text'
                                        label={''}
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                            }
                                        }}
                                        inputBaseProps={{
                                            sx: {
                                                marginTop: '23px !important',
                                            },
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => searchCustomer('delete')}
                                                        disabled={props.isEdit}
                                                        hidden={props.isEdit ? true : props.CustomerName ? false : true}
                                                    >
                                                        <Icon
                                                            iconName={'close'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>

                                                    <IconButton
                                                        hidden={props.isEdit}
                                                        onClick={() => searchCustomer('search')}
                                                    >
                                                        <Icon
                                                            iconName={'search'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        disabled
                                    />
                                </Stack>
                            </Grid>

                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Stack spacing={'16px'} direction="row" sx={{ marginTop: '16px' }}>
                                    <Input
                                        label='Receipt Type'
                                        value={props.COACode}
                                        disabled
                                        formControlProps={{
                                            sx: {
                                                width: '50%',
                                            }
                                        }}
                                    />

                                    <Input
                                        variant="standard"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                        value={props.COAName}
                                        type='text'
                                        label={''}
                                        formControlProps={{
                                            sx: {
                                                width: '100%',
                                            }
                                        }}
                                        inputBaseProps={{
                                            sx: {
                                                marginTop: '23px !important',
                                            },
                                            readOnly: true,
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() => searchReceiptType('delete')}
                                                        hidden={props.isEdit ? true : props.COAName ? false : true}
                                                    >
                                                        <Icon
                                                            iconName={'close'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>

                                                    <IconButton
                                                        hidden={props.isEdit}
                                                        onClick={() => searchReceiptType('search')}
                                                    >
                                                        <Icon
                                                            iconName={'search'}
                                                            svgIconProps={{
                                                                fontSize: 'small',
                                                                htmlColor: '#0087C2',
                                                                sx: {
                                                                    height: '12px',
                                                                    width: '12px'
                                                                }
                                                            }}
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                        disabled
                                    />
                                </Stack>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </form>

            {
                isModal && (
                    <SelectDataContact
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}
                        getData={getDataContact}
                        isLoading={isLoadingC}
                        totalRows={totalRowsC}
                        setTotalRows={setTotalRowsC}
                        SelectedData={SelectedDataC}
                        setSelectedData={setSelectedDataC}
                        data={dataC}
                        columnData={columnDataC}
                        dataMap={dataMapC}
                        dataTable={dataTableC}
                        setCustomerId={props.setCustomerId}
                        setCustomerCode={props.setCustomerCode}
                        setCustomerName={props.setCustomerName}
                        setCustomerTypeId={props.setCustomerTypeId}
                        setSelectedShipmentOrder={props.setSelectedShipmentOrder}
                        setShipmentOrderList={props.setShipmentOrderList}
                        setShipmentOrderIdList={props.setShipmentOrderIdList}
                        setCashPaymentUSD={props.setCashPaymentUSD}
                        setCashPaymentIDR={props.setCashPaymentIDR}
                        setBankData={props.setBankData}
                        calculateTotalBank={props.calculateTotalBank} calculateTotalCash={props.calculateTotalCash}
                    />
                )
            }

            {
                isModal2 && (
                    <SelectDataCOA
                        isModal={isModal2}
                        handleClick={handleClick2}
                        titleModal={titleModal2}
                        setIsModal={setIsModal2}
                        getData={getDataCOA}
                        isLoading={isLoadingO}
                        totalRows={totalRowsO}
                        setTotalRows={setTotalRowsO}
                        SelectedData={SelectedDataO}
                        setSelectedData={setSelectedDataO}
                        data={dataO}
                        columnData={columnDataO}
                        dataMap={dataMapO}
                        dataTable={dataTableO}
                        setCOACode={props.setCOACode}
                        setCOAName={props.setCOAName}
                    />
                )
            }
        </div>
    )
}

export default TemporaryReceiptHeader;