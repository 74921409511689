import { Stack } from '@mui/material';
import React from 'react';
import { formatCurrency } from '../../../../../services/ApiService';
import DollarInput, { convertMask } from '../../../../../components/Masks/DollarInput';
import RupiahInput, { convertMaskIDR } from '../../../../../components/Masks/RupiahInput';

import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import { Input, showToast } from '../../../../../components/ui';

const TabCash = (props) => {
    const countTotalPaymentIDR = () => {
        if (props.selectedReceiptVoucher.sequence !== undefined) {
            if (props.checkMethod(props.DataReceiptVoucher) !== 2) {
                let amountUSD = 0;
                let amountIDR = 0;

                if (formatCurrency === 'USD') {
                    amountUSD = parseFloat(convertMask(props.CashPaymentUSD));
                    amountIDR = parseFloat(convertMask(props.CashPaymentIDR));
                }
                else {
                    amountUSD = parseFloat(convertMaskIDR(props.CashPaymentUSD));
                    amountIDR = parseFloat(convertMaskIDR(props.CashPaymentIDR));
                }

                if (amountUSD < 0) {
                    showToast({ type: 'error', message: 'Please Input Cash Payment USD!' });
                }
                else if (amountIDR < 0) {
                    showToast({ type: 'error', message: 'Please Input Cash Payment IDR!' });
                }
                else {
                    const newArray = props.DataReceiptVoucher.slice();
                    newArray.forEach(el => {
                        if (el.sequence === props.selectedReceiptVoucher.sequence) {
                            el.receiptCashUSD = parseFloat(amountUSD);
                            el.receiptCashIDR = parseFloat(amountIDR);
                        }
                    });
                    props.setDataReceiptVoucher(newArray);

                    props.calculateTotalDebitCredit(newArray, props.Rate);
                }
            }
            else {
                showToast({ type: 'error', message: 'Please Input Payment Method on Bank Only!' });
            }
        }
        else {
            showToast({ type: 'error', message: 'Please Select Receipt First!' });
        }
    };

    return (
        <>
            <div>
                <Stack spacing={'32px'} direction="row">
                    <Input
                        label='Cash USD'
                        value={props.CashPaymentUSD}
                        onChange={(e) => props.setCashPaymentUSD(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
                        disabled={props.isApprovedAcc}
                        fullWidth
                        textAlign='right'
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                        inputBaseProps={{
                            inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                        }}
                    />

                    <Input
                        label='Cash IDR'
                        value={props.CashPaymentIDR}
                        onChange={(e) => props.setCashPaymentIDR(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
                        disabled={props.isApprovedAcc}
                        fullWidth
                        textAlign='right'
                        formControlProps={{
                            sx: {
                                width: '100%',
                            }
                        }}
                        inputBaseProps={{
                            inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                        }}
                    />
                </Stack>

                <div style={{ marginTop: '16px', marginLeft: '-12px' }}>
                    {ButtonComponent.AddButton(countTotalPaymentIDR, '', props.isApprovedAcc)}
                </div>
            </div>
        </>
    )
}

export default TabCash;