import React, { forwardRef, useEffect, useState, useRef } from 'react'
import { Backdrop, Box, Grid, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import ButtonComponent from '../../../../components/Buttons/ButtonComponent';

import { useFormik } from 'formik';

import ApiService from '../../../../services/ApiService';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import TableCheck from '../../../../components/Tables/TableCheck';
import freezeContainer from '../../../../Styles/freezeContainer';
import FilterSection from './components/FilterSection';
import ConfirmModal from './components/ConfirmModal';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Paginate, showToast } from '../../../../components/ui';

const VerifyEFakturView = forwardRef(() => {
  const btnPageRef = useRef(null)
  const btnTableRef = useRef(null)

  const history = useNavigate();

  const date = new Date()
  const startDate = new Date(`${date.getMonth() + 1}-01-${date.getFullYear()}`).toLocaleDateString("sv-se").split('T')[0]
  const endDate = date.toLocaleDateString("sv-se").split('T')[0]

  const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [remainingFaktur, setRemainingFaktur] = useState(0)

  const [modalOpen, setModalOpen] = useState('')

  const [dataRegUserAccess, setDataRegUserAccess] = useState([]);

  const [rowsCount, setRowsCount] = useState(50);
  const [numPage, setNumPage] = useState(1);

  const [dataMap, setDataMap] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [SelectedData, setSelectedData] = useState({});
  const [totalRows, setTotalRows] = useState(50);
  const [filter, setFilter] = useState([]);
  const [filterBy, setFilterBy] = useState([]);
  const [stateX, setStateX] = useState({});
  const [sortArr, setSortArr] = useState([]);
  const [goTo, setGoTo] = useState(1);
  const [dataCheck, setDataCheck] = useState([]);

  const BreadcrumbsItems = [
    { label: 'Majura', link: '/Majura', style: '' },
    { label: 'Verify E-Faktur', link: '/Majura/VerifyEFaktur', style: { color: '#0087C2', fontWeight: '700' } },
  ];

  useEffect(() => {
    document.title = 'Verify E-Faktur - ' + JSON.parse(localStorage.getItem('branchName'));

    getDataAccess()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDataAccess = () => {
    let params = [];
    let pId = 0;
    let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
    let hasAccess = false

    const indexMenu = listMenu.findIndex((elm) => elm.code === 'RVFAK')
    if (indexMenu !== -1) {
      pId = listMenu[indexMenu].id
      hasAccess = true
    }

    if (hasAccess) {
      params = [
        ...params,
        { attr: "permissionId", value: "" + pId },
        { attr: "userId", value: "" + JSON.parse(localStorage.getItem("userId")) },
        { attr: "roleId", value: "" + JSON.parse(localStorage.getItem("roleId")) }
      ];

      ApiService.IdentityPostByPage('regUserAccess/regUserAccess', "PAGE", 1, 100, params, [])
        .then((response) => {
          if (response.data.code === 200) {
            setDataRegUserAccess(response.data.data.userAccess);

            getData(0, 0)
          }
        })
        .catch(function (error) {
          console.error("error saat fetch", error);
          history('/Majura');
        });
    } else {
      history('/Majura');
    }
  };

  const getData = async (pageNumber, pageSize, filter, orderBy) => {
    if (!isLoadingBackDrop) setIsLoading(true);

    let params = [
      {
        "attr": "filterBy",
        "value": '' + formik.values.filter
      },
      {
        "attr": "startDate", //FilterBy 1
        "value": formik.values.startDate
      },
      {
        "attr": "endDate", //FilterBy 1
        "value": formik.values.endDate
      },
      {
        "attr": "startInvoiceNo", //FilterBy 2,3
        "value": formik.values.startInvoice
      },
      {
        "attr": "endInvoiceNo", //FilterBy 2,3
        "value": formik.values.endInvoice
      }
    ]
    ApiService.OperationCustomPostByPage('invoice/invoice/PostByPageEFaktur?', 'PAGE', pageNumber, pageSize, params, filter, orderBy)
      .then((response) => {
        if (response.data.code === 200) {
          setSelectedData({})
          setRemainingFaktur(0);

          let temp = response.data.data.invoiceExportFakturs
          let indexed = temp.map((el, index) => {
            let indexedTemp = {
              ...el,
              index
            }
            return indexedTemp
          })

          setDataMap(indexed)
          setColumnData(response.data.data.columns.headerColumns)
          setTotalRows(response.data.totalRowCount)

          setIsLoading(false)
          setIsLoadingBackDrop(false)
        }
        else if (response.data.code === 500) {
          showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message })
          setIsLoading(false)
        }
        setIsLoadingBackDrop(false)
        setIsLoading(false)
        setSelectedData({})
      })
      .catch(function (error) {
        setIsLoading(false)
        setIsLoadingBackDrop(false)

        console.error('error saat fetch', error)
        // Alert.ErrorAlert(error.toString(), true)
        showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + error.toString() })
      })
  }

  const handleVerify = (isConfirm, uploadDate = date) => {
    if (!dataCheck.length) return showToast({ type: 'error', message: 'Please Select Data First!' })
    if (!isConfirm) return setModalOpen('Verify')

    let check = 0;
    setModalOpen('')
    setIsLoadingBackDrop(true)
    dataCheck.forEach(element => {
      if (check === 0 && element.isUploaded === false) {
        let body = {
          "invoice": {
            "id": element.invoiceId,
            "isNonJob": element.isNonJob,
            "approvedOn": uploadDate,
          }
        }

        ApiService.OperationCustomPost('invoice/invoice/ApproveEFaktur', body)
          .then((response) => {
            if (response.data.code === 200) {
              if (dataCheck[dataCheck.length - 1].invoiceId === element.invoiceId) {
                emptyState()
                getData(1, 50, [])
                setIsLoadingBackDrop(false)
                showToast({ type: 'success', message: 'Verified Data Success!' })
              }
            } else {
              check = 1;
              emptyState()
              getData(1, 50, [])
              setIsLoadingBackDrop(false)
              showToast({ type: 'error', message: 'Verified Data Failed!, ' + response.data.message })
            }
          })
          .catch(function (error) {
            check = 1;
            emptyState()
            getData(1, 50, [])
            setIsLoadingBackDrop(false)
            console.error('error saat fetch', error)
            showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + error.toString() })
          })
      }
      else {
        check = 1;
        emptyState()
        getData(1, 50, [])
        setIsLoadingBackDrop(false)
        showToast({ type: 'error', message: 'Verified Data Failed Because Data Already Verified!' })
      }
    });
  }

  const handleUnVerify = (isConfirm) => {
    if (!dataCheck.length) return showToast({ type: 'error', message: 'Please Select Data First!' })
    if (!isConfirm) return setModalOpen('Un-Verify')

    let check = 0;
    setModalOpen('')
    setIsLoadingBackDrop(true)
    dataCheck.forEach(element => {
      if (check === 0 && element.isUploaded === true) {
        let body = {
          "invoice": {
            "id": element.invoiceId,
            "isNonJob": element.isNonJob
          }
        }

        ApiService.OperationCustomPost('invoice/invoice/UnApproveEFaktur', body)
          .then((response) => {
            if (response.data.code === 200) {
              if (dataCheck[dataCheck.length - 1].invoiceId === element.invoiceId) {
                emptyState()
                getData(1, 50, [])
                setIsLoadingBackDrop(false)
                showToast({ type: 'success', message: 'Un Verified Data Success!' })
              }
            } else {
              check = 1;
              emptyState()
              getData(1, 50, [])
              setIsLoadingBackDrop(false)
              showToast({ type: 'error', message: 'Un Verified Data Failed!, ' + response.data.message })
            }
          })
          .catch(function (error) {
            check = 1;
            emptyState()
            getData(1, 50, [])
            setIsLoadingBackDrop(false)
            console.error('error saat fetch', error)
            showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + error.toString() })
          })
      }
      else {
        check = 1;
        emptyState()
        getData(1, 50, [])
        setIsLoadingBackDrop(false)
        showToast({ type: 'error', message: 'Un Verified Data Failed Because Data Hasn\'t Been Verified!' })
      }
    });
  }

  const formik = useFormik({
    initialValues: {
      filter: '1',
      startDate: startDate,
      endDate: endDate,
      startInvoice: '0',
      endInvoice: '0',
    },
  })

  const emptyState = () => {
    setNumPage(1);
    setRowsCount(50);
    setTotalRows(50);
    setFilter([]);
    setGoTo(1);
    setFilterBy([]);
    setStateX({});
    setSortArr([]);
    setDataCheck([]);
  };

  const handleReset = () => {
    getData(1, 50, []);
    emptyState();
  };

  const ReloadData = () => {
    showToast({ type: 'success', message: 'Reload Data' })
    emptyState();
    getData(1, 50, []);
  }

  const getContainerHeight = () => {
    const hTitle = 64
    const hHeader = 116
    const hPadding = 10 * 2
    const hBtnPage = btnPageRef?.current?.clientHeight || 28
    const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

    const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable

    return `${totalHeight}px`
  }

  const onChangePaging = (value) => {
    setNumPage(value);
    getData(value, rowsCount, filter, sortArr);
  }

  const onChangeLimit = (value) => {
    setNumPage(1);
    setRowsCount(value);
    getData(1, value, filter, sortArr);
  }

  const ButtonComponents = () => {
    return (
      <>
        <div className="row">
          <div className='col-12'>
            <Grid container item spacing={1} direction="row" style={{ 'maxWidth': '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
              {ButtonComponent.ReloadButton(() => ReloadData())}
              {dataRegUserAccess.map((elm) => (
                <>
                  {elm.accessCode === 'APP' && ButtonComponent.ApproveButton(() => handleVerify(), 'Verify')}
                  {elm.accessCode === 'UPP' && ButtonComponent.UnVerifyButton(() => handleUnVerify(), 'Un-Verify')}
                </>
              ))}
            </Grid>
          </div>
        </div>

        <Grid item className='mt-1' xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
          <Grid container item direction="row" alignItems={'center'} justifyContent={'center'}>
            <Grid item xs={9}>
              <div ref={btnTableRef}>
                <div className="mt-2">
                  <FilterSection formik={formik} />
                </div>
              </div>
            </Grid>

            <Grid item xs={3} className='text-end'>
              <div>
                <Typography
                  sx={{
                    color: 'var(--Primary-One, #083A50)',
                    fontFamily: 'Nunito',
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    lineHeight: '19.6px',
                    marginTop: '24px !important'
                  }}
                >
                  Sisa E-NoFa / No. Faktur: {remainingFaktur}
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <>
      <Box className='w-100' sx={{ width: '100%', p: 3, ...freezeContainer }}>
        <CustomToastContainer />
        <Grid container spacing={0} direction="column">
          <HeaderInfo
            btnPageRef={btnPageRef}
            title={'Verify E-Faktur'}
            isJobType={false}
            BreadcrumbsItems={BreadcrumbsItems}
            ButtonComponents={ButtonComponents}
          />

          <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
            <div ref={btnTableRef}>
              {
                ButtonComponent.RefreshButton(
                  sortArr,
                  rowsCount,
                  columnData,
                  setGoTo,
                  handleReset,
                  setFilterBy,
                  filterBy,
                  setFilter,
                  setNumPage,
                  setRowsCount,
                  getData,
                  stateX,
                  setStateX
                )
              }
            </div>

            <div
              className="rounded-10 table-responsive"
              style={{
                maxHeight: `calc(100vh - ${getContainerHeight()})`,
                marginTop: '16px',
                padding: '2px 16px',
                borderRadius: '12px',
                background: '#F2F2F2'
              }}
            >
              {
                isLoading ?
                  <LoadingSpinner />
                  :
                  <>
                    <div className='mt-1'>
                      <TableCheck
                        uniqueKey={'invoiceId'}
                        rowsCount={rowsCount}
                        setNumPage={setNumPage}
                        setRowsCount={setRowsCount}
                        getData={getData}
                        columnData={columnData}
                        dataMap={dataMap}
                        SelectedData={SelectedData}
                        setSelectedData={setSelectedData}
                        setFilter={setFilter}
                        setFilterBy={setFilterBy}
                        dataCheck={dataCheck}
                        setDataCheck={setDataCheck}
                        stateX={stateX}
                        setStateX={setStateX}
                        sortArr={sortArr}
                        setSortArr={setSortArr}
                        setGoTo={setGoTo}
                        goTo={goTo}
                      />
                    </div>
                  </>
              }
            </div>

            <div style={{ marginTop: '16px' }}>
              <Paginate
                page={numPage}
                limit={rowsCount}
                totalData={totalRows}
                onChange={onChangePaging}
                onChangeLimit={onChangeLimit}
              />
            </div>
          </Grid>
        </Grid>
      </Box>

      {Boolean(modalOpen) && (
        <ConfirmModal
          isModal={Boolean(modalOpen)}
          activeModal={modalOpen}
          handleClick={() => setModalOpen('')}
          handleConfirm={modalOpen === 'Verify' ? handleVerify : handleUnVerify}
        />
      )}

      {
        isLoadingBackDrop && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoadingBackDrop}
          >
            <LoadingSpinner />
          </Backdrop>
        )
      }
    </>
  )
})

export default VerifyEFakturView