import React, { useState, useEffect } from 'react';
import "jspdf-autotable";
// import { useNavigate } from 'react-router-dom';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';

import TempListMaster from '../../TempListMaster';
import ApiService, { userCode, username } from '../../../../services/ApiService';
import CreateEditRegProductUnits from './CreateEditRegProductUnits';
import { Backdrop } from '@mui/material';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import CustomToastContainer, { showToast } from '../../../../components/ui';
import { handleDataTable } from '../../../../utils/reusableFunction';

const RegProductUnitList = forwardRef((props, ref) => {
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const [data, setData] = useState([]);
    const [titleModal, setTitleModal] = useState('');
    const title = 'Reg Product Units'
    const [isModal, setIsModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dataMap, setDataMap] = useState([])
    const [dataTable, setDataTable] = useState([])
    const [columnData, setColumnData] = useState([])
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50)
    // const history = useNavigate()
    const [rowsCount, setRowsCount] = useState(50)
    const [numPage, setNumPage] = useState(1)
    const [filter, setFilter] = useState([])
    const [filterBy, setFilterBy] = useState([])

    const param = "warehouse/regProductUnits/regProductUnits";
    const paramProduct = "warehouse/product/product";
    const paramUnit = "warehouse/units/units";
    const [id, setId] = useState(0);
    const [productId, setProductId] = useState('');
    const [productName, setProductName] = useState('');
    const [unitId, setUnitId] = useState('');
    const [unitName, setUnitName] = useState('');
    const [qty, setQty] = useState('');
    const [isSmallest, setIsSmallest] = useState('');
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([])

    let countErr = 0

    const dataLocal = JSON.parse(localStorage.getItem(username + "-DataProductUnits"));

    const simpanLocal = () => {
        if (isModal) {
            var data;
            data =
            {
                "productUnits": {
                    "rowStatus": "ACT",
                    id,
                    productId,
                    productName,
                    unitId,
                    unitName,
                    userCode,
                    qty,
                    isSmallest,
                    titleModal
                }
            }
            localStorage.setItem(username + "-DataProductUnits", JSON.stringify(data));
        }
    }

    useImperativeHandle(ref, () => ({

        localSave() {
            simpanLocal()
        }

    }));

    const fetchDataLocal = () => {
        var result = dataLocal.product;
        setIsModal(true);
        setProductId(result.productId);
        setProductName(result.productName);
        setUnitId(result.unitId);
        setUnitName(result.unitName);
        setQty(result.qty);
        setIsSmallest(result.isSmallest);
        setId(result.id);
        setTitleModal(result.titleModal);
        localStorage.removeItem(username + '-DataProductUnits')
    }

    const toggleAdd = () => {
        setId(0);
        setProductId('');
        setProductName('');
        setUnitId('');
        setUnitName('');
        setQty('');
        setIsSmallest('');
        handleClick();
        setTitleModal('Add');
    }

    const toggleEdit = () => {
        if (SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' })
        } else {
            setId(SelectedData.id);
            setProductId(SelectedData.productId);
            setUnitId(SelectedData.unitId);
            setQty(SelectedData.qty);
            setIsSmallest(SelectedData.isSmallest);
            setIsModal(true);
            setTitleModal('Edit');
            ApiService.PostById(paramProduct, SelectedData.productId)
                .then((res) => {
                    setProductName(res.data.data.product.name);
                })
            ApiService.PostById(paramUnit, SelectedData.unitId)
                .then((res) => {
                    setUnitName(res.data.data.units.name);
                })
        }
    }

    let countRefresh = 0;

    useEffect(() => {
        if (countRefresh === 0) {
            ApiService.refreshToken();
            countRefresh++;
            if (dataLocal) {
                setTimeout(function () { fetchDataLocal(); }, 2000);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = (pageNumber, pageSize, filters, orderBy) => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        } else {
            setIsLoadingBackDrop(true);
        }

        ApiService.ListMasterDynamicPostByPage(param, 'PAGE', pageNumber, pageSize, [], filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({})
                    setData(response.data.data.productUnits);

                    let temp = response.data.data.productUnits
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })
                    setDataMap(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTable, 'id');
                    setColumnData(response.data.data.columns)
                    setTotalRows(response.data.totalRowCount)

                    setIsLoading(false)
                    setIsLoadingBackDrop(false)
                } else {
                    setIsLoadingBackDrop(false)
                    // ApiService.logOut();
                    // history('/')
                }
                setIsLoading(false)
            })
            .catch(function (error) {
                setIsLoading(false)
                setIsLoadingBackDrop(false)
                console.error('error saat fetch', error)
                countErr++
                if (countErr < 3) {
                    getData(1, 50)
                } else {
                    // ApiService.logOut()
                    // history('/')
                }
            })
    }

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
        } else {
            setIsModal(false);
            setNumPage(1)
            setRowsCount(50)
            setId(0);
            setProductId('');
            setUnitId('');
            setQty('');
            setIsSmallest('');
        }
    };

    function CellDoubleClick(row) {
        toggleEdit()
    }

    return (
        <>
            <CustomToastContainer />
            <TempListMaster
                dataTable={dataTable}
                SelectedData={SelectedData}
                param={param}
                data={data}
                title={title}
                toggleAdd={toggleAdd}
                toggleEdit={toggleEdit}
                isLoading={isLoading}
                getData={getData}
                columnData={columnData}
                dataMap={dataMap}
                setSelectedData={setSelectedData}
                CellDoubleClick={CellDoubleClick}
                totalRows={totalRows}
                rowsCount={rowsCount}
                setRowsCount={setRowsCount}
                numPage={numPage}
                setNumPage={setNumPage}
                filter={filter}
                setFilter={setFilter}
                filterBy={filterBy}
                setFilterBy={setFilterBy}
                stateX={stateX}
                setStateX={setStateX}
                menuCode={'MWHRPU'}
                sortArr={sortArr}
                setSortArr={setSortArr}
            />

            {
                isModal && (
                    <CreateEditRegProductUnits
                        isModal={isModal}
                        titleModal={titleModal}
                        handleClick={handleClick}
                        title={title}
                        param={param}

                        id={id}
                        productId={productId}
                        setProductId={setProductId}
                        unitId={unitId}
                        setUnitId={setUnitId}
                        productName={productName}
                        setProductName={setProductName}
                        unitName={unitName}
                        setUnitName={setUnitName}
                        qty={qty}
                        setQty={setQty}
                        isSmallest={isSmallest}
                        setIsSmallest={setIsSmallest}

                        getData={getData}
                    />
                )
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
})

export default RegProductUnitList