import { Grid } from "@mui/material";
import React, { useState } from "react";

import TableX from "../../../../components/Tables/TableX";
import ButtonComponent from "../../../../components/Buttons/ButtonComponent";
import LoadingSpinner from "../../../../components/Components/LoadingSpinner";
import CustomizeButtonComponent from "../../../../components/Buttons/CustomizeButtonComponent";
import { Icon, Paginate, PopUp, showToast } from "../../../../components/ui";

const SelectChartOfAccount = (props) => {
  const [rowsCount, setRowsCount] = useState(50);
  const [numPage, setNumPage] = useState(1);
  const [filter, setFilter] = useState([]);
  const [filterBy, setFilterBy] = useState([]);
  const [stateX, setStateX] = useState({});
  const [sortArr, setSortArr] = useState([])
  const [goTo, setGoTo] = useState(1);

  const handleClose = () => {
    props.handleClick();
  };

  const handleSelected = () => {
    if (props.SelectedData.id === undefined) {
      showToast({ type: 'error', message: 'Please Select Data!' });
    } else {
      props.setAccountParent(props.SelectedData.accountCode);
      props.setAccountParentTitle(props.SelectedData.accountCode + ' - ' + props.SelectedData.accountTitle);
      props.handleClick();
    }

    handleClose();
  };

  const handleReset = () => {
    setGoTo(1);
    setSortArr([])
    setFilter([]);
    setFilterBy([]);
    props.getData(1, 50);
    setStateX({});
  };

  function CellDoubleClick(row) {
    handleSelected();
  }

  const onChangePaging = (value) => {
    setNumPage(value);
    props.getData(value, rowsCount, filter, sortArr);
  }

  const onChangeLimit = (value) => {
    setNumPage(1);
    setRowsCount(value);
    props.getData(1, value, filter, sortArr);
  }

  const mainContainer = (
    <>
      <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
        <div>
          {
            ButtonComponent.RefreshButton(
              sortArr,
              rowsCount,
              props.columnData,
              setGoTo,
              handleReset,
              setFilterBy,
              filterBy,
              setFilter,
              setNumPage,
              setRowsCount,
              props.getData,
              stateX,
              setStateX
            )
          }
        </div>

        <div
          className="rounded-10 table-responsive"
          style={{
            maxHeight: '375px',
            marginTop: '16px',
            padding: '2px 16px',
            borderRadius: '12px',
            background: '#F2F2F2'
          }}
        >
          {
            props.isLoading ?
              (
                <LoadingSpinner />
              )
              :
              (
                <>
                  <div className="mt-1">
                    <TableX
                      uniqueKey={'id'}
                      goTo={goTo}
                      rowsCount={rowsCount}
                      setNumPage={setNumPage}
                      setRowsCount={setRowsCount}
                      getData={props.getData}
                      columnData={props.columnData}
                      dataMap={props.dataMap}
                      dataTable={props.dataTable}
                      SelectedData={props.SelectedData}
                      setSelectedData={props.setSelectedData}
                      setFilter={setFilter}
                      setFilterBy={setFilterBy}
                      CellDoubleClick={CellDoubleClick}
                      stateX={stateX}
                      setStateX={setStateX}
                      sortArr={sortArr}
                      setSortArr={setSortArr}
                      setGoTo={setGoTo}
                    />
                  </div>
                </>
              )
          }
        </div>

        <div style={{ marginTop: '16px' }}>
          <Paginate
            page={numPage}
            limit={rowsCount}
            totalData={props.totalRows}
            onChange={onChangePaging}
            onChangeLimit={onChangeLimit}
          />
        </div>
      </Grid>
    </>
  );

  return (
    <PopUp
      id='modal-id'
      icon={
        <Icon
          iconName={'account'}
          svgIconProps={{
            htmlColor: '#0087C2',
            sx: {
              height: '24px',
              width: '24px'
            }
          }}
        />
      }
      title={'Select Master of Account'}
      isOpen={props.isModal}
      onClose={handleClose}
      draggable={false}
      shrinkable={false}
      disableScrollLock={false}
      maxWidth={'lg'}
      fullWidth={true}
    >
      <div style={{ padding: '0px 6px' }}>
        {mainContainer}

        <div style={{ padding: '24px 0px 0px' }}>
          {CustomizeButtonComponent.SelectModalButton(handleSelected)}
        </div>
      </div>
    </PopUp>
  );
};

export default SelectChartOfAccount;
