import {
    IconButton, MenuItem,
    Stack, InputAdornment,
    Grid, Backdrop,
} from '@mui/material';
import React, { useState } from 'react'
import { companyId, userCode, formatCurrency } from '../../../../services/ApiService';
import ModalAccount from './ModalAccount';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';
import ApiService from '../../../../services/ApiService';
import DollarInput from '../../../../components/Masks/DollarInput';
import RupiahInput from '../../../../components/Masks/RupiahInput';
import ModalConfirmationNominal from '../../../../components/Modals/ModalConfirmationNominal.jsx';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import { Icon, Input, Selection, showToast, PopUp } from '../../../../components/ui';
import { convertToNumeric, maxAmountIDR, maxAmountUSD } from '../../../../utils/masks.jsx';
import { handleDataTable } from '../../../../utils/reusableFunction.jsx';

const ModalIncomeCost = (props) => {
    document.onkeyup = escapeFunction;
    function escapeFunction(e) {
        var keyCode = e.keyCode;
        var valid = (keyCode === 27 || e.key === 'Escape');

        if (valid) {
            if (e.keyCode === 27 || e.key === 'Escape') {
                props.EmptyState();
                handleClose();
            }
        }
    }

    const [isLoading, setIsLoading] = useState(false);
    const paramAccount = "account/account";
    const [isModalAccount, setIsModalAccount] = useState(false);
    const [titleModalAccount, setTitleModalAccount] = useState('');

    const [isLoadingAccount, setIsLoadingAccount] = useState(false);
    const [dataAccount, setDataAccount] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [dataMapAccount, setDataMapAccount] = useState([])
    const [columnDataAccount, setColumnDataAccount] = useState([])
    const [SelectedDataAccount, setSelectedDataAccount] = useState({});
    const [totalRowsAccount, setTotalRowsAccount] = useState(50);

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [currency, setCurrency] = useState('IDR');

    const BankTypeData = [
        { id: 1, code: 0, name: 'USD' },
        { id: 2, code: 1, name: 'IDR' },
    ];

    const CostToCostData = [
        { id: 1, code: false, name: 'No' },
        { id: 2, code: true, name: 'Yes' },
    ];

    const signData = [
        { id: 1, code: true, name: '+' },
        { id: 2, code: false, name: '-' }
    ];

    const searchCustomer = (data) => {
        if (data === 'search') {
            if (props.tipe) {
                props.setTitleModalContact('Customer/Vendor');
                props.handleClickContact();
            } else {
                showToast({ type: 'error', message: "Invalid Type!" });
            }
        }
        else if (data === 'delete') {
            props.setContactId(0);
            props.setTempCustName('');
        }
    }

    const searchAccount = (data) => {
        if (data === 'search') {
            if (props.tipe) {
                setTitleModalAccount('Account');
                handleClickAccount();
            } else {
                showToast({ type: 'error', message: "Invalid Type!" });
            }
        }
        else if (data === 'delete') {
            props.setAccountId(0)
            props.setAccountCode('');
            props.setCOACode('');
            props.setCOAName('');
            props.setTempAccountName('')
            props.setDescription('')
            props.setIsContainer(false)
            props.setContainerSize('All')
            props.setContainerFormat('All')
            props.setQuantity(0)
            props.setAmount(0)
            props.setIsOthers(false)
        }
    }

    const handleClickAccount = () => {
        if (isModalAccount === false) {
            setIsModalAccount(true);
            getDataAccount(1, 50)
        } else {
            setIsModalAccount(false);
        }
    };

    const getDataAccount = (pageNumber, pageSize, filters, orderBy) => {
        setIsLoadingAccount(true);
        let params = [];
        if (props.portType === 'SEA') {
            params = [...params, { 'attr': 'Type', 'value': props.portType }];
        }
        else if (props.portType === 'AIR') {
            params = [...params, { 'attr': 'Type', 'value': props.portType }];
        }
        ApiService.DynamicPostByPage(paramAccount, 'SEARCH', pageNumber, pageSize, params, filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataAccount({})
                    setDataAccount(response.data.data.account);

                    let temp = response.data.data.account
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapAccount(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTable, 'id');
                    setColumnDataAccount(response.data.data.columns)
                    setTotalRowsAccount(response.data.totalRowCount)

                    setIsLoadingAccount(false)
                }
                setIsLoadingAccount(false)
            })
            .catch(function (error) {
                setIsLoadingAccount(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'You Don\'t Have Access to This Data!' });
                setIsModalAccount(false);
            })
    }

    const handleType = (e, c) => {
        props.setTipeName(e.target.value);
        props.setTipe(c.props.id)
        localStorage.setItem("ContactType", c.props.id);
        let value = e.target.value;

        if (value === 'Agent') {
            props.setContactId(props.idAgent);
            props.setTempCustName(props.agentName);
        }
        else if (value === 'Shipper' || value === 'Consignee') {
            props.setContactId(props.idCustomer);
            props.setTempCustName(props.CustomerName);
        }
        else if (value === 'SSLine') {
            if (props.portType !== 'AIR') {
                props.setContactId(props.idCarrier);
                props.setTempCustName(props.carrierName);
            }
        }
        else if (value === 'IATA') {
            if (props.portType === 'AIR') {
                props.setContactId(props.idCarrier);
                props.setTempCustName(props.carrierName);
            }
        }
        else if (value === 'EMKL') {
            props.setContactId(props.idEMKL);
            props.setTempCustName(props.EMKLName);
        }
        else if (value === 'Depo') {
            props.setContactId(props.idDepo);
            props.setTempCustName(props.depoName);
        }
        else if (value === 'DiscountShipper' || value === 'DiscountConsignee') {
            props.setContactId(props.idCustomer);
            props.setTempCustName(props.CustomerName);
        }

        if (c.props.id === 1) {
            props.setIsPlusMin(false);
        }
        else {
            props.setIsPlusMin(true);
            props.setSign(true);
        }
    }

    const handleClose = () => {
        props.setSign(true);
        props.setIsCostToCost(false);
        props.setContainerSize('All');
        props.setIsContainer(false);
        props.handleClick2();
    };

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    const handleCheckNominal = () => {
        if (props.detailEdit) {
            let data = {};
            let Sequence = 0;
            if (props.isIncome) {
                Sequence = props.selectedDetailIncome.sequence
                data = props.dataIncome.filter((el) => el.sequence === Sequence)
            }
            else {
                Sequence = props.selectedDetailCost.sequence
                data = props.dataCost.filter((el) => el.sequence === Sequence)
            }

            if (convertToNumeric(data[0].amount) === convertToNumeric(props.amount)) {
                handleSave();
            }
            else {
                handleValidationCurrency();
            }
        }
        else {
            handleValidationCurrency();
        }
    }

    const handleValidationCurrency = () => {
        if (props.amountCrr === 0) {
            setCurrency('USD');
            if (parseFloat(convertToNumeric(props.amount)) >= maxAmountUSD) {
                handleClickConfirmation();
            }
            else {
                handleSave();
            }
        }
        else {
            setCurrency('IDR');
            if (parseFloat(convertToNumeric(props.amount)) >= maxAmountIDR) {
                handleClickConfirmation();
            }
            else {
                handleSave();
            }
        }
    }

    const handleConfirmation = () => {
        handleSave();
        handleClickConfirmation();
    }

    const handleSave = () => {
        setIsLoading(true);
        if (!props.contactId) {
            showToast({ type: 'error', message: 'Please Input Contact!' });
            setIsLoading(false);
        }
        else {
            if (!props.accountId) {
                showToast({ type: 'error', message: 'Please Input Account!' });
                setIsLoading(false);
            }
            else {
                if (props.amount === 0) {
                    showToast({ type: 'error', message: 'Please Input Amount!' });
                    setIsLoading(false);
                }
                else {
                    let Sequence = 1
                    let boolSign = true;
                    let bookId = 0;
                    if (props.detailEdit) {
                        if (props.isIncome) {
                            Sequence = props.selectedDetailIncome.sequence
                        }
                        else {
                            Sequence = props.selectedDetailCost.sequence
                        }
                    }
                    else {
                        if (props.detailSequence > 0) Sequence = props.detailSequence + 1
                    }

                    if (props.sign === 'false' || props.sign === false) {
                        boolSign = false;
                    }
                    else if (props.sign === 'true' || props.sign === true) {
                        boolSign = true;
                    }

                    if (props.bookingId) {
                        bookId = parseInt(props.bookingId);
                    }

                    let data = {
                        "rowStatus": "ACT",
                        "bookingConfirmationId": bookId,
                        "sequence": Sequence,
                        "companyId": companyId,
                        "accountId": props.accountId,
                        "accountCode": props.accountCode,
                        "accountName": props.tempAccountName,
                        "coaCode": props.COACode,
                        "coaName": props.COAName,
                        "description": props.description,
                        "quantity": props.quantity ? parseFloat(props.quantity) : parseFloat(0),
                        "perQty": convertToNumeric(props.perQty),
                        "amount": convertToNumeric(props.amount),
                        "amountCrr": parseInt(props.amountCrr),
                        "isIncome": props.isIncome,
                        "isContainer": props.isContainer,
                        "containerSize": props.containerSize,
                        "formatContainer": props.containerFormat,
                        "containerTypeId": props.containerTypeId,
                        "containerTypeCode": props.containerTypeCode,
                        "containerTypeName": props.containerTypeName,
                        "isCostToCost": props.isCostToCost,
                        "contactId": props.contactId,
                        "contactName": props.tempCustName,
                        "customerTypeId": props.tipe,
                        "customerTypeName": props.tipeName,
                        "codingQuantity": true,
                        "sign": boolSign,
                        "vatId": 0,
                        "originalUsd": convertToNumeric(props.originalUsd),
                        "originalRate": convertToNumeric(props.originalRate),
                        "user": userCode,
                    }

                    if (data.formatContainer === 'All') {
                        data.containerTypeCode = '';
                        data.containerTypeName = '';
                    }

                    if (props.isIncome) {
                        if (!props.detailEdit) {
                            props.setDataIncome([...props.dataIncome, data])
                            let arrDetail = [...props.dataIncome, data]
                            let arrDetailCost = [...props.dataCost]
                            let mergeArray = [...arrDetail, ...arrDetailCost];
                            props.calculateBookingData(mergeArray, props.exRate);
                            showToast({ type: 'success', message: 'Add Income Success' });
                        }
                        else {
                            const newArr = props.dataIncome.slice()
                            newArr.forEach(el => {
                                if (el.sequence === data.sequence) {
                                    el.accountId = data.accountId
                                    el.accountCode = data.accountCode;
                                    el.accountName = data.accountName
                                    el.description = data.description
                                    el.type = data.type
                                    el.coaCode = data.coaCode
                                    el.coaName = data.coaName
                                    el.isContainer = data.isContainer
                                    el.containerSize = data.containerSize
                                    el.formatContainer = data.formatContainer
                                    el.containerTypeId = data.containerTypeId
                                    el.containerTypeCode = data.containerTypeCode
                                    el.containerTypeName = data.containerTypeName
                                    el.isCostToCost = data.isCostToCost
                                    el.sign = data.sign
                                    el.quantity = data.quantity
                                    el.perQty = data.perQty
                                    el.originalRate = data.originalRate
                                    el.amount = data.amount
                                    el.originalUsd = data.originalUsd
                                    el.amountCrr = data.amountCrr
                                    el.contactId = data.contactId;
                                    el.contactName = data.contactName;
                                    el.customerTypeId = data.customerTypeId;
                                    el.customerTypeName = data.customerTypeName;
                                }
                            })
                            props.setDataIncome(newArr)
                            let arrDetailCost = [...props.dataCost]
                            let mergeArray = [...newArr, ...arrDetailCost];
                            props.calculateBookingData(mergeArray, props.exRate);
                            showToast({ type: 'success', message: 'Update Income Success' });
                        }
                    }
                    else {
                        if (!props.detailEdit) {
                            props.setDataCost([...props.dataCost, data])
                            let arrDetail = [...props.dataCost, data]
                            let arrDetailIncome = [...props.dataIncome]
                            let mergeArray = [...arrDetail, ...arrDetailIncome];
                            props.calculateBookingData(mergeArray, props.exRate);
                            showToast({ type: 'success', message: 'Add Cost Success' });
                        }
                        else {
                            const newArr = props.dataCost.slice()
                            newArr.forEach(el => {
                                if (el.sequence === data.sequence) {
                                    el.accountId = data.accountId
                                    el.accountCode = data.accountCode;
                                    el.accountName = data.accountName
                                    el.description = data.description
                                    el.type = data.type
                                    el.coaCode = data.coaCode
                                    el.coaName = data.coaName
                                    el.isContainer = data.isContainer
                                    el.containerSize = data.containerSize
                                    el.formatContainer = data.formatContainer
                                    el.containerTypeId = data.containerTypeId
                                    el.containerTypeCode = data.containerTypeCode
                                    el.containerTypeName = data.containerTypeName
                                    el.isCostToCost = data.isCostToCost
                                    el.sign = data.sign
                                    el.quantity = data.quantity
                                    el.perQty = data.perQty
                                    el.originalRate = data.originalRate
                                    el.amount = data.amount
                                    el.originalUsd = data.originalUsd
                                    el.amountCrr = data.amountCrr
                                    el.contactId = data.contactId;
                                    el.contactName = data.contactName;
                                    el.customerTypeId = data.customerTypeId;
                                    el.customerTypeName = data.customerTypeName;
                                }
                            })
                            props.setDataCost(newArr)
                            let arrDetailIncome = [...props.dataIncome]
                            let mergeArray = [...newArr, ...arrDetailIncome];
                            props.calculateBookingData(mergeArray, props.exRate);
                            showToast({ type: 'success', message: 'Update Cost Success' });
                        }
                    }

                    props.setDetailEdit(false)
                    props.setSelectedDetailIncome({})
                    props.setSelectedDetailCost({})
                    props.setIsPlusMin(true);
                    props.EmptyState();
                    props.setSign(true);
                    props.setIsCostToCost(false);
                    props.setContainerSize('All');
                    props.setIsContainer(false);
                    setIsLoading(false);

                    if (props.detailEdit) {
                        handleClose();
                    }
                    else {
                        props.setDetailSequence(Sequence)
                    }
                }
            }
        }
    }

    function handleCurrency(value) {
        props.setAmountCrr(value);
    }

    const renderType = () => {
        const defaultOptionCost = [
            { id: 4, value: 'SSLine' },
            { id: 5, value: 'IATA' },
        ]

        if (props.isIncome) {
            return (
                <Selection
                    id='0'
                    label={'Type'}
                    value={props.tipeName}
                    fullWidth={true}
                    disabled={props.isApproved}
                    helperText={props.tipeName ? '' : 'This Field is Required'}
                    placeholder={'Select Contact Type'}
                    onChange={(e, c) => handleType(e, c)}
                >
                    <MenuItem sx={{ fontSize: '12px' }} id={1} value='Agent'>Agent</MenuItem>
                    {
                        props.jobServiceType !== 'Import' ?
                            <MenuItem sx={{ fontSize: '12px' }} id={2} value='Shipper'>Shipper</MenuItem>
                            :
                            <MenuItem sx={{ fontSize: '12px' }} id={3} value='Consignee'>Consignee</MenuItem>
                    }
                </Selection>
            )

        } else {
            return (
                <Selection
                    id='0'
                    label={'Type'}
                    value={props.tipeName}
                    fullWidth={true}
                    disabled={props.isApproved}
                    helperText={props.tipeName ? '' : 'This Field is Required'}
                    placeholder={'Select Contact Type'}
                    onChange={(e, c) => handleType(e, c)}
                >
                    <MenuItem sx={{ fontSize: '12px' }} id={1} value='Agent'>Agent</MenuItem>
                    {
                        props?.portType?.trim() ?
                            props.portType !== 'AIR' ?
                                <MenuItem sx={{ fontSize: '12px' }} id={4} value='SSLine'>SSLine</MenuItem>
                                :
                                <MenuItem sx={{ fontSize: '12px' }} id={5} value='IATA'>IATA</MenuItem>
                            :
                            defaultOptionCost.map((opt) => {
                                return <MenuItem sx={{ fontSize: '12px' }} id={opt.id} key={opt.id} value={opt.value}>{opt.value}</MenuItem>
                            })
                    }
                    <MenuItem sx={{ fontSize: '12px' }} id={6} value='EMKL'>EMKL</MenuItem>
                    <MenuItem sx={{ fontSize: '12px' }} id={7} value='Depo'>Depo</MenuItem>
                    {
                        props.jobServiceType !== 'Import' ?
                            <MenuItem sx={{ fontSize: '12px' }} id={8} value='DiscountShipper'>Discount Shipper</MenuItem>
                            :
                            <MenuItem sx={{ fontSize: '12px' }} id={9} value='DiscountConsignee'>Discount Consignee</MenuItem>
                    }
                    {
                        props.contactTypeId === 10 ?
                            <MenuItem sx={{ fontSize: '12px' }} id={10} value='Trucking'>Trucking</MenuItem>
                            :
                            <></>
                    }
                </Selection>
            )
        }
    }

    function handleOriginalUSD(value) {
        if (value !== null && value !== undefined && value !== '') {
            props.setOriginalUsd(value);

            props.setPerQty(parseFloat((convertToNumeric(value) * convertToNumeric(props.originalRate)).toFixed(5)));
            props.setAmount(parseFloat((parseFloat((convertToNumeric(value) * convertToNumeric(props.originalRate)).toFixed(5)) * convertToNumeric(props.quantity)).toFixed(5)));
        }
    }

    function handleOriginalRate(value) {
        if (value !== null && value !== undefined && value !== '') {
            props.setOriginalRate(value);

            props.setPerQty(parseFloat((convertToNumeric(value) * convertToNumeric(props.originalUsd)).toFixed(5)));
            props.setAmount(parseFloat((parseFloat((convertToNumeric(value) * convertToNumeric(props.originalUsd)).toFixed(5)) * convertToNumeric(props.quantity)).toFixed(5)));
        }
    }

    function handleQuantityUnit(value) {
        if (value !== null && value !== undefined && value !== '') {
            props.setQuantity(value);

            props.setAmount(parseFloat((convertToNumeric(value) * convertToNumeric(props.perQty)).toFixed(5)));
        }
    }

    function handlePerQty(value) {
        if (value !== null && value !== undefined && value !== '') {
            props.setPerQty(value);

            props.setAmount(parseFloat((convertToNumeric(value) * convertToNumeric(props.quantity)).toFixed(5)));
        }
    }

    function handleContainerFormat(value) {
        props.setContainerFormat(value);
        if (props.isContainer) {
            props.containerData.forEach(element => {
                if (element.formatContainer === value) {
                    handleQuantityUnit(element.amount);
                    props.setContainerSize(element.containerSize);
                    props.setContainerTypeId(element.containerTypeId);
                    props.setContainerTypeCode(element.containerTypeCode);
                    props.setContainerTypeName(element.containerTypeName);
                }
            });
        }
        else {
            handleQuantityUnit(0);
            props.setContainerSize('All');
            props.setContainerTypeId(0);
            props.setContainerTypeCode('');
            props.setContainerTypeName('');
        }
    }

    const mainContainer = (
        <div style={{ padding: '12px 8px 0px' }}>
            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={3}
                    lg={3}
                    sm={3}
                    xs={12}
                >
                    {renderType()}

                    {
                        props.isApproved ?
                            <Selection
                                id='0'
                                label={'Container Size'}
                                value={props.containerFormat}
                                fullWidth={true}
                                disabled
                                placeholder={'Select Container Size'}
                                onChange={(e) => handleContainerFormat(e.target.value)}
                                formControlProps={{
                                    sx: {
                                        marginTop: props.tipeName ? '24px' : '1px'
                                    }
                                }}
                            >
                                <MenuItem sx={{ fontSize: '12px' }} key={0} value={'All'}>All</MenuItem>
                                {
                                    props.isContainer === true && props.isVolumeCBM === false ?
                                        props.containerData.map((data, index) => (
                                            <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.formatContainer}>{data.formatContainer}</MenuItem>
                                        ))
                                        :
                                        <></>
                                }
                            </Selection>
                            :
                            <>
                                {
                                    props.isContainer === true && props.isVolumeCBM === false ?
                                        <Selection
                                            id='0'
                                            label={'Container Size'}
                                            value={props.containerFormat}
                                            fullWidth={true}
                                            placeholder={'Select Container Size'}
                                            onChange={(e) => handleContainerFormat(e.target.value)}
                                            formControlProps={{
                                                sx: {
                                                    marginTop: props.tipeName ? '24px' : '1px'
                                                }
                                            }}
                                        >
                                            <MenuItem sx={{ fontSize: '12px' }} key={0} value={'All'}>All</MenuItem>
                                            {
                                                props.isContainer === true && props.isVolumeCBM === false ?
                                                    props.containerData.map((data, index) => (
                                                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.formatContainer}>{data.formatContainer}</MenuItem>
                                                    ))
                                                    :
                                                    <></>
                                            }
                                        </Selection>
                                        :
                                        <Selection
                                            id='0'
                                            label={'Container Size'}
                                            value={props.containerFormat}
                                            fullWidth={true}
                                            placeholder={'Select Container Size'}
                                            onChange={(e) => handleContainerFormat(e.target.value)}
                                            formControlProps={{
                                                sx: {
                                                    marginTop: props.tipeName ? '24px' : '1px'
                                                }
                                            }}
                                        >
                                            <MenuItem sx={{ fontSize: '12px' }} key={0} value={'All'}>All</MenuItem>
                                            {
                                                props.isContainer === true && props.isVolumeCBM === false ?
                                                    props.containerData.map((data, index) => (
                                                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.formatContainer}>{data.formatContainer}</MenuItem>
                                                    ))
                                                    :
                                                    <></>
                                            }
                                        </Selection>
                                }
                            </>
                    }
                </Grid>

                <Grid
                    item
                    xl={9}
                    lg={9}
                    sm={9}
                    xs={12}
                >
                    <Stack spacing={'32px'} direction="row">
                        <Input
                            label={'Customer Code'}
                            value={props.contactId}
                            formControlProps={{
                                sx: {
                                    marginTop: '24px'
                                }
                            }}
                            inputBaseProps={{
                                readOnly: true
                            }}
                            disabled
                        />

                        <Input
                            label="Customer Name"
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={props.tempCustName}
                            type='text'
                            disabled
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '24px'
                                }
                            }}
                            inputBaseProps={{
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => searchCustomer('delete')}
                                            disabled={props.isApproved}
                                            hidden={props.tempCustName && !props.isApproved ? false : true}
                                        >
                                            <Icon
                                                iconName={'close'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>

                                        <IconButton
                                            onClick={() => searchCustomer('search')}
                                            disabled={props.isApproved}
                                            hidden={props.isApproved}
                                        >
                                            <Icon
                                                iconName={'search'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Stack>

                    <Stack spacing={'32px'} direction="row" sx={{ marginTop: '24px' }}>
                        <Input
                            label={'Account Code'}
                            value={props.accountCode}
                            formControlProps={{
                                sx: {
                                    marginTop: '24px'
                                }
                            }}
                            inputBaseProps={{
                                readOnly: true,
                            }}
                            disabled
                        />

                        <Input
                            label="Account Name"
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={props.tempAccountName}
                            type='text'
                            disabled
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '24px'
                                }
                            }}
                            inputBaseProps={{
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => searchAccount('delete')}
                                            disabled={props.isApproved}
                                            hidden={props.tempAccountName && !props.isApproved ? false : true}
                                        >
                                            <Icon
                                                iconName={'close'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>

                                        <IconButton
                                            onClick={() => searchAccount('search')}
                                            disabled={props.isApproved}
                                            hidden={props.isApproved}
                                        >
                                            <Icon
                                                iconName={'search'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Stack>
                </Grid>
            </Grid>

            <Input
                label={'Description'}
                value={props.description}
                onChange={(e) => props.setDescription(e.target.value)}
                multiline
                fullWidth
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '24px'
                    }
                }}
                inputBaseProps={{
                    rows: 4,
                    sx: {
                        padding: '8px',
                    },
                }}
                disabled={props.isApproved}
            />

            <div style={{ marginTop: "24px" }}>
                <Grid
                    container
                    spacing={'28px'}
                >
                    <Grid
                        item
                        xl={3}
                        lg={3}
                        sm={3}
                        xs={12}
                    >
                        <Selection
                            id='0'
                            label={'Currency'}
                            value={props.amountCrr}
                            fullWidth={true}
                            disabled={props.isApproved}
                            placeholder={'Select Currency'}
                            onChange={(e) => handleCurrency(e.target.value)}
                        >
                            {
                                BankTypeData.map((data, index) => (
                                    <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                ))
                            }
                        </Selection>
                    </Grid>

                    <Grid
                        item
                        xl={3}
                        lg={3}
                        sm={3}
                        xs={12}
                    >
                        <Selection
                            id='0'
                            label={'Cost To Cost'}
                            value={props.isCostToCost}
                            fullWidth={true}
                            disabled={props.isApproved}
                            placeholder={'Select Cost To Cost'}
                            onChange={(e) => props.setIsCostToCost(e.target.value)}
                        >
                            {
                                CostToCostData.map((data, index) => (
                                    <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                ))
                            }
                        </Selection>
                    </Grid>

                    <Grid
                        item
                        xl={3}
                        lg={3}
                        sm={3}
                        xs={12}
                    >
                        <Input
                            label={'Original USD'}
                            textAlign='right'
                            fullWidth
                            value={props.originalUsd}
                            disabled={props.isApproved}
                            onChange={(e) => handleOriginalUSD(e.target.value)}
                            formControlProps={{
                                sx: {
                                    width: '100%'
                                }
                            }}
                            inputBaseProps={{
                                inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                            }}
                        />
                    </Grid>

                    <Grid
                        item
                        xl={3}
                        lg={3}
                        sm={3}
                        xs={12}
                    >
                        <Input
                            label={'Original Rate'}
                            textAlign='right'
                            fullWidth
                            value={props.originalRate}
                            disabled={props.isApproved}
                            onChange={(e) => handleOriginalRate(e.target.value)}
                            formControlProps={{
                                sx: {
                                    width: '100%'
                                }
                            }}
                            inputBaseProps={{
                                inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                            }}
                        />
                    </Grid>
                </Grid>
            </div>

            <div style={{ marginTop: "24px" }}>
                <Grid
                    container
                    spacing={'24px'}
                >
                    <Grid
                        item
                        xl={3}
                        lg={3}
                        sm={3}
                        xs={12}
                    >
                        <Input
                            label={'Quantity Unit'}
                            textAlign='right'
                            fullWidth
                            value={props.quantity}
                            disabled={props.isApproved}
                            onChange={(e) => handleQuantityUnit(e.target.value)}
                            formControlProps={{
                                sx: {
                                    width: '100%'
                                }
                            }}
                            inputBaseProps={{
                                inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                            }}
                        />
                    </Grid>

                    <Grid
                        item
                        xl={3}
                        lg={3}
                        sm={3}
                        xs={12}
                    >
                        <Input
                            label={'Per Unit Cost'}
                            textAlign='right'
                            fullWidth
                            value={props.perQty}
                            disabled={props.isApproved}
                            onChange={(e) => handlePerQty(e.target.value)}
                            formControlProps={{
                                sx: {
                                    width: '100%'
                                }
                            }}
                            inputBaseProps={{
                                inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                            }}
                        />
                    </Grid>

                    <Grid
                        item
                        xl={3}
                        lg={3}
                        sm={3}
                        xs={12}
                    >
                        <Input
                            label={'Amount'}
                            textAlign='right'
                            fullWidth
                            value={props.amount}
                            disabled={props.isApproved}
                            onChange={(e) => props.setAmount(e.target.value)}
                            formControlProps={{
                                sx: {
                                    width: '100%'
                                }
                            }}
                            inputBaseProps={{
                                inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                            }}
                        />
                    </Grid>

                    <Grid
                        item
                        xl={3}
                        lg={3}
                        sm={3}
                        xs={12}
                    >
                        {
                            props.isApproved ?
                                <Selection
                                    id='0'
                                    label={'Sign'}
                                    value={props.sign}
                                    fullWidth={true}
                                    disabled
                                    placeholder={'Select Sign'}
                                    onChange={(e) => props.setSign(e.target.value)}
                                >
                                    {
                                        signData.map((data, index) => (
                                            <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                        ))
                                    }
                                </Selection>
                                :
                                <Selection
                                    id='0'
                                    label={'Sign'}
                                    value={props.sign}
                                    fullWidth={true}
                                    disabled={props.isPlusMin}
                                    placeholder={'Select Sign'}
                                    onChange={(e) => props.setSign(e.target.value)}
                                >
                                    {
                                        signData.map((data, index) => (
                                            <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                        ))
                                    }
                                </Selection>
                        }
                    </Grid>
                </Grid>
            </div>
        </div>
    )

    const buttonComponent = (
        <div style={{ padding: '6px 24px 20px' }}>
            {CustomizeButtonComponent.SaveCancelModalButton(handleCheckNominal, false, props.isApproved)}
        </div>
    )

    return (
        <>
            <PopUp
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px',
                            },
                        }}
                    />
                }
                id='modal-id'
                maxWidth={'lg'}
                draggable={true}
                fullWidth={true}
                shrinkable={true}
                topButton={false}
                onClose={handleClose}
                isOpen={props.isModal2}
                disableScrollLock={true}
                children={mainContainer}
                title={props.titleModal2}
                actions={buttonComponent}
            />

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
            >
                <LoadingSpinner />
            </Backdrop>

            {
                isModalAccount && (
                    <ModalAccount
                        isModal={isModalAccount}
                        handleClick={handleClickAccount}
                        titleModal={titleModalAccount}
                        setIsModal={setIsModalAccount}
                        setTempAccountId={props.setAccountId}
                        setTempAccountCode={props.setAccountCode}
                        setTempAccountName={props.setTempAccountName}
                        setDescription={props.setDescription}
                        setIsOthers={props.setIsOthers}
                        setCOACode={props.setCOACode}
                        setCOAName={props.setCOAName}

                        isContainer={props.isContainer}
                        setIsContainer={props.setIsContainer}
                        isVolume20={props.isVolume20}
                        setIsVolume20={props.setIsVolume20}
                        isVolume40={props.isVolume40}
                        setIsVolume40={props.setIsVolume40}
                        isVolume45={props.isVolume45}
                        setIsVolume45={props.setIsVolume45}
                        isVolumeCBM={props.isVolumeCBM}
                        setIsVolumeCBM={props.setIsVolumeCBM}
                        shipmentVolumeCBM={props.shipmentVolumeCBM}
                        containerSize={props.containerSize}
                        setContainerSize={props.setContainerSize}
                        containerTypeId={props.containerTypeId}
                        setContainerTypeId={props.setContainerTypeId}
                        containerTypeCode={props.containerTypeCode}
                        setContainerTypeCode={props.setContainerTypeCode}
                        containerTypeName={props.containerTypeName}
                        setContainerTypeName={props.setContainerTypeName}
                        containerFormat={props.containerFormat}
                        setContainerFormat={props.setContainerFormat}

                        isLoading={isLoadingAccount}
                        data={dataAccount}
                        dataMap={dataMapAccount}
                        dataTable={dataTable}
                        columnData={columnDataAccount}
                        SelectedData={SelectedDataAccount}
                        totalRows={totalRowsAccount}
                        setSelectedData={setSelectedDataAccount}
                        getData={getDataAccount}
                        containerData={props.containerData}
                        quantity={props.quantity}
                        setQuantity={props.setQuantity}
                        detailEdit={props.detailEdit}
                        handleQuantityUnit={handleQuantityUnit}
                    />
                )
            }

            {
                isModalConfirmation && (
                    <ModalConfirmationNominal
                        isModal={isModalConfirmation}
                        handleClick={handleClickConfirmation}

                        nominal={props.amount}
                        currency={currency}
                        ConfirmationData={handleConfirmation}
                    />
                )
            }
        </>
    )
}

export default ModalIncomeCost