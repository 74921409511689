import React, { useState } from 'react';
import { Grid } from '@mui/material';

import TableX from '../../../../../components/Tables/TableX';
import { Icon, Paginate, PopUp, showToast } from '../../../../../components/ui';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import LoadingSpinner from '../../../../../components/Components/LoadingSpinner';
import ModalConfirmation from '../../../../../components/Modals/ModalConfirmation';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';

const SelectDataCOA = (props) => {
    const [goTo, setGoTo] = useState(1);
    const [stateX, setStateX] = useState({});
    const [filter, setFilter] = useState([]);
    const [numPage, setNumPage] = useState(1);
    const [sortArr, setSortArr] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [rowsCount, setRowsCount] = useState(50);

    const [titleConfirmation, setTitleConfirmation] = useState('');
    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');

    const emptyState = () => {
        setNumPage(1);
        setRowsCount(50);
        props.setTotalRows(50);
        setFilter([]);
        setFilterBy([]);
        setGoTo(1);
        setSortArr([]);
        setStateX({});
    };

    function Select() {
        if (props.SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            props.setAccountCode(props.SelectedData.accountCode);
            props.setAccountName(props.SelectedData.accountTitle);

            if (props.isAccount === 0) {
                setTitleConfirmation('Select')
                setTitleModalConfirmation('All Acount');
                handleClickConfirmation();
            }

            emptyState();
            props.handleClick();

            props.setIsModal(false);
        }
    };

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    function ConfirmationAllAccount() {
        if (titleConfirmation === 'Select') {
            let i = props.isAccount + 1;
            props.setIsAccount(i);
        }
        setIsModalConfirmation(false);
    }

    function Cancel() {
        emptyState();
        props.handleClick();
    };

    const handleReset = () => {
        emptyState();
        props.getData(1, 50);
    };

    function CellDoubleClick(row) {
        Select();
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        props.getData(value, rowsCount, filter, sortArr);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        props.getData(1, value, filter, sortArr);
    }

    const mainContainer = (
        <>
            <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
                <div>
                    {
                        ButtonComponent.RefreshButton(
                            sortArr,
                            rowsCount,
                            props.columnData,
                            setGoTo,
                            handleReset,
                            setFilterBy,
                            filterBy,
                            setFilter,
                            setNumPage,
                            setRowsCount,
                            props.getData,
                            stateX,
                            setStateX
                        )
                    }
                </div>

                <div
                    className="rounded-10 table-responsive"
                    style={{
                        maxHeight: '375px',
                        marginTop: '16px',
                        padding: '2px 16px',
                        borderRadius: '12px',
                        background: '#F2F2F2'
                    }}
                >
                    {
                        props.isLoading ?
                            (
                                <LoadingSpinner />
                            )
                            :
                            (
                                <>
                                    <div className="mt-1">
                                        <TableX
                                            uniqueKey={'id'}
                                            goTo={goTo}
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={props.getData}
                                            columnData={props.columnData}
                                            dataMap={props.dataMap}
                                            dataTable={props.dataTable}
                                            SelectedData={props.SelectedData}
                                            setSelectedData={props.setSelectedData}
                                            setFilter={setFilter}
                                            setFilterBy={setFilterBy}
                                            CellDoubleClick={CellDoubleClick}
                                            stateX={stateX}
                                            setStateX={setStateX}
                                            sortArr={sortArr}
                                            setSortArr={setSortArr}
                                            setGoTo={setGoTo}
                                        />
                                    </div>
                                </>
                            )
                    }
                </div>

                <div style={{ marginTop: '16px' }}>
                    <Paginate
                        page={numPage}
                        limit={rowsCount}
                        totalData={props.totalRows}
                        onChange={onChangePaging}
                        onChangeLimit={onChangeLimit}
                    />
                </div>
            </Grid>
        </>
    );

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'account'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={'Select ' + props.titleModal}
                isOpen={props.isModal}
                onClose={Cancel}
                draggable={false}
                shrinkable={false}
                disableScrollLock={false}
                maxWidth={'lg'}
                fullWidth={true}
            >
                <div style={{ padding: '0px 6px' }}>
                    {mainContainer}

                    <div style={{ padding: '24px 0px 0px' }}>
                        {CustomizeButtonComponent.SelectModalButton(Select)}
                    </div>
                </div>
            </PopUp>

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationAllAccount}
                    />
                )
            }
        </>
    )
}

export default SelectDataCOA;