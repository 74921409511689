import { Grid, IconButton, MenuItem, InputAdornment, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';

import ApiService, { formatCurrency } from '../../../../services/ApiService';
import TabsContact from '../Tabs/TabsContact';
import SelectDataNonContact from '../Selects/SelectDataNonContact';
import { CustomDatePicker, Icon, Input, Selection, showToast } from '../../../../components/ui';
import DollarInput from '../../../../components/Masks/DollarInput';
import RupiahInput from '../../../../components/Masks/RupiahInput';
import { handleDataTable } from '../../../../utils/reusableFunction';

const BillOfLading = (props) => {
    const dataNoOfBL = [
        { id: 1, code: 'N', name: 'None' },
        { id: 2, code: 'T', name: '3 (Three)' },
        { id: 3, code: 'S', name: 'Seawaybill' },
    ];

    const dataCargoInsurance = [
        { id: 1, name: 'Not Covered' },
        { id: 2, name: 'Covered' },
    ];

    const [isModal, setIsModal] = useState(false);
    const [titleModal, setTitleModal] = useState('');

    const [isLoadingN, setIsLoadingN] = useState(false);
    const [dataN, setDataN] = useState([]);
    const [dataMapN, setDataMapN] = useState([]);
    const [dataTableN, setDataTableN] = useState([]);
    const [columnDataN, setColumnDataN] = useState([]);
    const [SelectedDataN, setSelectedDataN] = useState({});
    const [totalRowsN, setTotalRowsN] = useState(50);
    let countErrN = 1;

    const [tabValue, setTabValue] = useState('Agent');

    const handleClick = (title) => {
        if (isModal === false) {
            setIsModal(true);
            localStorage.setItem("title", JSON.stringify(title));
            getDataNonContact(1, 50);
        }
        else {
            setIsModal(false);
            localStorage.removeItem("title");
        }
    };

    const AddBL = (data) => {
        if (data === 'search') {
            setTitleModal('BL');
            handleClick('BL');
        }
        else if (data === 'delete') {
            props.setBLFormId(0);
            props.setBLFormName('');
        }
    };

    const getDataNonContact = (pageNumber, pageSize, filters) => {
        setIsLoadingN(true);
        let param = '';

        let title = JSON.parse(localStorage.getItem("title"));

        if (title === 'BL') {
            param = 'billOfLading/billOfLading';
        }

        if (param !== '') {
            ApiService.DynamicPostByPage(param, 'SEARCH', pageNumber, pageSize, [], filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedDataN({})
                        let temp = [];
                        switch (title) {
                            case 'BL':
                                setDataN(response.data.data.billOfLading);
                                temp = response.data.data.billOfLading;
                                break;

                            default:
                        }

                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataMapN(indexed)
                        handleDataTable(indexed, response.data.data.columns, setDataTableN, 'id');
                        setColumnDataN(response.data.data.columns)
                        setTotalRowsN(response.data.totalRowCount)

                        setIsLoadingN(false)
                    }
                    setIsLoadingN(false)
                })
                .catch(function (error) {
                    setIsLoadingN(false);
                    // console.error('error saat fetch', error);

                    countErrN++
                    if (countErrN < 3) {
                        getDataNonContact(1, 50);
                    }
                    else {
                        showToast({ type: 'error', message: 'Failed to Fetch Data, ' + error });
                        setIsModal(false);
                    }
                })
        }
    };

    return (
        <div>
            <form>
                <Box>
                    <Typography
                        sx={{
                            color: 'var(--Primary-One, #083A50)',
                            fontFamily: 'Nunito',
                            fontSize: '18px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '19.6px',
                            marginTop: '8px'
                        }}
                    >
                        Bill of Lading
                    </Typography>

                    <Box>
                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={9}
                                lg={9}
                                sm={9}
                                xs={18}
                            >
                                <Input
                                    label="BL Number"
                                    value={props.BLNumber}
                                    onChange={(e) => props.setBLNumber(e.target.value)}
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '24px !important'
                                        }
                                    }}
                                />

                                <CustomDatePicker
                                    label="Place of Date Issue"
                                    value={props.placeDateIssue}
                                    onChange={props.setPlaceDateIssue}
                                    type={'date'}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />

                                <Selection
                                    id='0'
                                    label={'No Of B(s)/L'}
                                    fullWidth={true}
                                    placeholder={'Select No Of B(s)/L'}
                                    value={props.noBLData}
                                    onChange={(e) => props.setNoBLData(e.target.value)}
                                    hidden={props.jobType.portType === 'SEA' ? false : true}
                                    formControlProps={{
                                        sx: {
                                            marginTop: '16px !important'
                                        }
                                    }}
                                >
                                    {
                                        dataNoOfBL.map((data, index) => (
                                            <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                        ))
                                    }
                                </Selection>
                            </Grid>

                            <Grid
                                item
                                xl={3}
                                lg={3}
                                sm={3}
                                xs={6}
                            >
                                <Box component="div" sx={{ marginTop: '59px' }}>
                                    <Typography
                                        sx={{
                                            color: 'var(--Blue-Primary-1, #083A50)',
                                            fontFamily: 'Nunito',
                                            fontSize: '16px',
                                            fontStyle: 'normal',
                                            fontWeight: '600',
                                            lineHeight: '19.6px',
                                        }}
                                    >
                                        Print Status
                                    </Typography>

                                    <Grid container item>
                                        <Box>
                                            <Input
                                                label="Print Draft"
                                                value={props.printDraft}
                                                textAlign='right'
                                                disabled
                                                fullWidth
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                        marginTop: '16px !important'
                                                    }
                                                }}
                                            />

                                            <Input
                                                label="Print Fixed"
                                                value={props.printFixed}
                                                textAlign='right'
                                                disabled
                                                fullWidth
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                        marginTop: '16px !important'
                                                    }
                                                }}
                                            />
                                        </Box>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>

                        <Stack spacing={'16px'} direction="row" sx={{ marginTop: '12px' }}>
                            <Input
                                label="BL Form"
                                value={props.BLFormId}
                                formControlProps={{
                                    sx: {
                                        marginTop: '24px'
                                    }
                                }}
                                inputBaseProps={{
                                    readOnly: true
                                }}
                                disabled
                            />

                            <Input
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                                fullWidth
                                value={props.BLFormName}
                                type='text'
                                label={''}
                                disabled
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                                inputBaseProps={{
                                    sx: {
                                        marginTop: '23px !important',
                                    },
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => AddBL('delete')}
                                                hidden={props.BLFormName ? false : true}
                                            >
                                                <Icon
                                                    iconName={'close'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>

                                            <IconButton onClick={() => AddBL('search')}>
                                                <Icon
                                                    iconName={'search'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Stack>

                        <br></br>
                        <TabsContact
                            tabValue={tabValue}
                            setTabValue={setTabValue}
                            contactTypeId={props.contactTypeId}
                            setContactTypeId={props.setContactTypeId}
                            contactType={'BillOfLading'}

                            AgentId={props.AgentId}
                            AgentName={props.AgentName}
                            AgentAddress={props.AgentAddress}
                            setAgentId={props.setAgentId}
                            setAgentName={props.setAgentName}
                            setAgentAddress={props.setAgentAddress}
                            RefAgentId={props.RefAgentId}
                            RefAgentName={props.RefAgentName}
                            RefAgentAddress={props.RefAgentAddress}

                            ShipperId={props.ShipperId}
                            ShipperName={props.ShipperName}
                            ShipperAddress={props.ShipperAddress}
                            setShipperId={props.setShipperId}
                            setShipperName={props.setShipperName}
                            setShipperAddress={props.setShipperAddress}
                            RefShipperId={props.RefShipperId}
                            RefShipperName={props.RefShipperName}
                            RefShipperAddress={props.RefShipperAddress}

                            ConsigneeId={props.ConsigneeId}
                            ConsigneeName={props.ConsigneeName}
                            ConsigneeAddress={props.ConsigneeAddress}
                            setConsigneeId={props.setConsigneeId}
                            setConsigneeName={props.setConsigneeName}
                            setConsigneeAddress={props.setConsigneeAddress}
                            RefConsigneeId={props.RefConsigneeId}
                            RefConsigneeName={props.RefConsigneeName}
                            RefConsigneeAddress={props.RefConsigneeAddress}

                            NPartyId={props.NPartyId}
                            NPartyName={props.NPartyName}
                            NPartyAddress={props.NPartyAddress}
                            setNPartyId={props.setNPartyId}
                            setNPartyName={props.setNPartyName}
                            setNPartyAddress={props.setNPartyAddress}
                            RefNPartyId={props.RefNPartyId}
                            RefNPartyName={props.RefNPartyName}
                            RefNPartyAddress={props.RefNPartyAddress}

                            SNPartyId={props.SNPartyId}
                            setSNPartyId={props.setSNPartyId}
                            SNPartyName={props.SNPartyName}
                            setSNPartyName={props.setSNPartyName}
                            SNPartyAddress={props.SNPartyAddress}
                            setSNPartyAddress={props.setSNPartyAddress}
                        />

                        <Grid
                            container
                            spacing={'32px'}
                        >
                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <CustomDatePicker
                                    label="Shipment On Board"
                                    value={props.shipmentOnBoard}
                                    onChange={props.setShipmentOnBoard}
                                    type={'date'}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />

                                {
                                    props.jobType.portType === 'SEA' ?
                                        <>
                                            <Input
                                                label="Total Number Of Container (In Word)"
                                                value={props.totalNumberCont}
                                                onChange={(e) => props.setTotalNumberCont(e.target.value)}
                                                fullWidth
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                        marginTop: '16px !important'
                                                    }
                                                }}
                                            />

                                            <Selection
                                                id='0'
                                                label={'Cargo Insurance'}
                                                fullWidth={true}
                                                placeholder={'Select Cargo Insurance'}
                                                value={props.cargoInsurance}
                                                onChange={(e) => props.setCargoInsurance(e.target.value)}
                                                formControlProps={{
                                                    sx: {
                                                        marginTop: '16px !important'
                                                    }
                                                }}
                                            >
                                                {
                                                    dataCargoInsurance.map((data, index) => (
                                                        <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.name}>{data.name}</MenuItem>
                                                    ))
                                                }
                                            </Selection>
                                        </>
                                        :
                                        <>
                                            <Input
                                                label="HAWB Fee (USD)"
                                                textAlign='right'
                                                value={props.HAWBFee}
                                                onChange={(e) => props.setHAWBFee(e.target.value)}
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                        marginTop: '16px !important'
                                                    }
                                                }}
                                                inputBaseProps={{
                                                    inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                                                }}
                                            />

                                            <Input
                                                label="Amount of Insurance"
                                                value={props.AmountInsurance}
                                                onChange={(e) => props.setAmountInsurance(e.target.value)}
                                                fullWidth
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                        marginTop: '16px !important'
                                                    }
                                                }}
                                            />
                                        </>

                                }

                            </Grid>

                            <Grid
                                item
                                xl={6}
                                lg={6}
                                sm={6}
                                xs={12}
                            >
                                <Input
                                    label="Freight Amount"
                                    value={props.freightAmount}
                                    onChange={(e) => props.setFreightAmount(e.target.value)}
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />

                                <Input
                                    label="Freight Payable At"
                                    value={props.freightPayable}
                                    onChange={(e) => props.setFreightPayable(e.target.value)}
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                />

                                <Input
                                    label="Description (Add Clause)"
                                    value={props.descriptionBoL}
                                    onChange={(e) => props.setDescriptionBoL(e.target.value)}
                                    multiline
                                    fullWidth
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px !important'
                                        }
                                    }}
                                    inputBaseProps={{
                                        rows: 4,
                                        sx: {
                                            padding: '8px',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </form>

            {
                isModal && (
                    <SelectDataNonContact
                        jobType={props.jobType}
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}
                        setTitleModal={setTitleModal}

                        getData={getDataNonContact}
                        isLoading={isLoadingN}
                        setIsLoading={setIsLoadingN}
                        data={dataN}
                        setData={setDataN}
                        dataMap={dataMapN}
                        dataTable={dataTableN}
                        setDataMap={setDataMapN}
                        columnData={columnDataN}
                        setColumnData={setColumnDataN}
                        SelectedData={SelectedDataN}
                        setSelectedData={setSelectedDataN}
                        totalRows={totalRowsN}
                        setTotalRows={setTotalRowsN}

                        setBLFormId={props.setBLFormId}
                        setBLFormName={props.setBLFormName}
                    />
                )
            }
        </div>
    )
}

export default BillOfLading;