import { Grid, Typography } from '@mui/material';
import React, { memo, useState } from 'react';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import TableX from '../../../../components/Tables/TableX';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';
import { CustomRadioGroup, Icon, Paginate, PopUp, showToast } from '../../../../components/ui';
import { newWindowConfig } from '../../../../utils/masks';

const TableContainer = memo((props) => {
    return (
        <div>
            <Typography
                sx={{
                    color: 'var(--Primary-One, #083A50)',
                    fontFamily: 'Nunito',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '700',
                    lineHeight: '19.6px',
                    marginBottom: '-8px',
                    marginTop: '16px'
                }}
            >
                Booking Confirmation
            </Typography>

            <div>
                {
                    ButtonComponent.RefreshButton(
                        props.sortArr,
                        props.rowsCount,
                        props.columnData,
                        props.setGoTo,
                        props.handleReset,
                        props.setFilterBy,
                        props.filterBy,
                        props.setFilter,
                        props.setNumPage,
                        props.setRowsCount,
                        props.getData,
                        props.stateX,
                        props.setStateX
                    )
                }
            </div>

            <div
                className="rounded-10 table-responsive"
                style={{
                    maxHeight: '375px',
                    marginTop: '16px',
                    padding: '2px 16px',
                    borderRadius: '12px',
                    background: '#F2F2F2'
                }}
            >
                {
                    props.isLoading ?
                        (
                            <LoadingSpinner />
                        )
                        :
                        (
                            <>
                                <div className="mt-1">
                                    <TableX
                                        uniqueKey={'id'}
                                        goTo={props.goTo}
                                        rowsCount={props.rowsCount}
                                        setNumPage={props.setNumPage}
                                        setRowsCount={props.setRowsCount}
                                        getData={props.getData}
                                        dataTable={props.dataTable}
                                        columnData={props.columnData}
                                        dataMap={props.dataMap}
                                        SelectedData={props.SelectedData}
                                        setSelectedData={props.setSelectedData}
                                        setFilter={props.setFilter}
                                        setFilterBy={props.setFilterBy}
                                        CellDoubleClick={props.CellDoubleClick}
                                        stateX={props.stateX}
                                        setStateX={props.setStateX}
                                        sortArr={props.sortArr}
                                        setSortArr={props.setSortArr}
                                        setGoTo={props.setGoTo}
                                    />
                                </div>
                            </>
                        )
                }
            </div>

            <div style={{ marginTop: '16px' }}>
                <Paginate
                    page={props.numPage}
                    limit={props.rowsCount}
                    totalData={props.totalRows}
                    onChange={props.onChangePaging}
                    onChangeLimit={props.onChangeLimit}
                />
            </div>
        </div>
    )
})

const SelectDataBooking = memo((props) => {
    const [rowsCount, setRowsCount] = useState(50);
    const [numPage, setNumPage] = useState(1);
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);
    const [isEPL, setIsEPL] = useState(false);

    const createSelection = [
        { 'id': 1, 'name': 'Yes', 'code': true },
        { 'id': 2, 'name': 'No', 'code': false }
    ]

    const emptyState = () => {
        setNumPage(1);
        setRowsCount(50);
        props.setTotalRows(50);
        setGoTo(1);
        setSortArr([]);
        setFilter([]);
        setFilterBy([]);
        setStateX({});
    };

    function Select() {
        if (props.SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            window.open('/Majura/ShipmentOrder/Create/' + props.jobType + '/' + props.SelectedData.id + '/' + isEPL, newWindowConfig);
        }

        emptyState();

        props.setIsModal(false);
    }

    function Cancel() {
        emptyState();
        props.handleClick();
    };

    const handleReset = () => {
        emptyState();
        props.getData(1, 50);
    };

    function CellDoubleClick(row) {
        Select();
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        props.getData(value, rowsCount, filter, sortArr);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        props.getData(1, value, filter, sortArr);
    }

    const mainContainer = (
        <>
            <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
                <div style={{ marginTop: '4px' }}>
                    <CustomRadioGroup
                        isTitle={true}
                        title={'Create Auto EPL from Booking Confirmation'}
                        isChecked={isEPL}
                        setIsChecked={setIsEPL}
                        dataGroup={createSelection}
                    />
                </div>

                <TableContainer
                    sortArr={sortArr}
                    rowsCount={rowsCount}
                    setRowsCount={setRowsCount}
                    props={props}
                    setNumPage={setNumPage}
                    setFilterBy={setFilterBy}
                    filterBy={filterBy}
                    setFilter={setFilter}
                    stateX={stateX}
                    setStateX={setStateX}
                    setSortArr={setSortArr}
                    setGoTo={setGoTo}
                    goTo={goTo}
                    handleReset={handleReset}
                    CellDoubleClick={CellDoubleClick}
                    onChangePaging={onChangePaging}
                    onChangeLimit={onChangeLimit}
                    numPage={numPage}

                    isLoading={props.isLoading}
                    columnData={props.columnData}
                    getData={props.getData}
                    dataTable={props.dataTable}
                    dataMap={props.dataMap}
                    SelectedData={props.SelectedData}
                    setSelectedData={props.setSelectedData}
                    totalRows={props.totalRows}
                />
            </Grid>
        </>
    );

    return (
        <PopUp
            id='modal-id'
            icon={
                <Icon
                    iconName={'approve-doc'}
                    svgIconProps={{
                        htmlColor: '#0087C2',
                        sx: {
                            height: '24px',
                            width: '24px'
                        }
                    }}
                />
            }
            title={'Create Shipment Order'}
            isOpen={props.isModal}
            onClose={Cancel}
            draggable={false}
            shrinkable={false}
            disableScrollLock={false}
            maxWidth={'lg'}
            fullWidth={true}
        >
            <div style={{ padding: '0px 6px' }}>
                {mainContainer}

                <div style={{ padding: '24px 0px 0px' }}>
                    {CustomizeButtonComponent.SelectModalButton(Select)}
                </div>
            </div >
        </PopUp >
    )
})

export default SelectDataBooking;