import React, { useImperativeHandle } from 'react'
import { Box, CardContent, Grid, Card } from '@mui/material';
import { useState } from 'react'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import ApiService, { branchId, companyId, countryId, userCode, userId, username } from '../../../../services/ApiService'
import Backdrop from '@mui/material/Backdrop';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import ReceiptPPh23Detail from './Content/ReceiptPPh23Detail'
import ReceiptPPh23Header from './Content/ReceiptPPh23Header'
import ReceiptPPh23Total from './Content/ReceiptPPh23Total'
import { forwardRef } from 'react';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import ButtonInfo from '../../../../components/Components/ButtonInfo';
import { defaultDate, getDefaultDateFormat } from '../../../../utils/date';
import { convertToNominal } from '../../../../utils/masks.jsx';
import CustomToastContainer, { showToast } from '../../../../components/ui';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation.jsx';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent.jsx';
import ReceiptPPh23AdjustmentForm from './Content/ReceiptPPh23AdjustmentForm.jsx';

const CreateEditReceiptPPh23 = forwardRef((props, ref) => {
    const dataLocal = JSON.parse(localStorage.getItem(username + "-DataRPPH23"));

    const { orPphId } = useParams()

    const history = useNavigate()
    const param = 'officialReceiptPph23/officialReceiptPph23';
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);

    const [dataDetail, setDataDetail] = useState([])
    const [selectedDetail, setSelectedDetail] = useState({})
    const [dataJobOwner, setDataJobOwner] = useState([])

    const [jobOwnerId, setJobOwnerId] = useState(0)
    const [jobOwnerCode, setJobOwnerCode] = useState('')
    const [jobOwnerName, setJobOwnerName] = useState('')
    const [customerId, setCustomerId] = useState(0)
    const [customerCode, setCustomerCode] = useState('')
    const [customerName, setCustomerName] = useState('')
    const [customerType, setCustomerType] = useState('SH')
    const [totalIDR, setTotalIDR] = useState(0)
    const [totalUSD, setTotalUSD] = useState(0)
    const [totalPPh23, setTotalPPh23] = useState(0)
    const [adjustmentDate, setAdjustmentDate] = useState(getDefaultDateFormat(new Date()))
    const [debitAccountCode, setDebitAccountCode] = useState('')
    const [debitAccountName, setDebitAccountName] = useState('')
    const [creditAccountCode, setCreditAccountCode] = useState('')
    const [creditAccountName, setCreditAccountName] = useState('')

    const [totalIDRReal, setTotalIDRReal] = useState(0)
    const [totalUSDReal, setTotalUSDReal] = useState(0)
    const [totalPPh23Real, setTotalPPh23Real] = useState(0)

    const paramRegUserAccess = 'regUserAccess/regUserAccess';

    const [isLoading, setIsLoading] = useState(true);
    const [dataMap, setDataMap] = useState([])
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50);
    const [columnData, setColumnData] = useState([])
    const [dataCheck, setDataCheck] = useState([]);

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Receipt PPh23', link: '/Majura/OfficialReceiptPph23', style: '' },
        { label: 'Adjustment Receipt PPh23', link: '/Majura/OfficialReceiptPph23/Adjustment', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useEffect(() => {
        localStorage.setItem('customerId', 0);
        document.title = 'Adjustment Receipt Pph23 - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orPphId]);

    useImperativeHandle(ref, () => ({
        localSave() {
            saveLocalData();
        }
    }));

    const saveLocalData = () => {
        let detailData = [];
        let sequence = 0;
        dataCheck.forEach((el) => {
            sequence += 1;
            let detail = {
                "rowStatus": "ACT",
                "countryId": el.countryId,
                "companyId": el.companyId,
                "branchId": el.branchId,
                "sequence": sequence,
                "orpPh23Id": el.id,
                "orId": el.orInvoiceId,
                "orNo": el.orNo,
                "shipmentId": 0,
                "shipmentNo": "",
                "invoiceId": el.invoiceId,
                "invoiceNo": el.invoicesNo,
                "amountIDR": el.invoiceIDR,
                "amountUSD": el.invoiceUSD,
                "pph23Amount": el.pph23Amount,
                "idLama": 0,
                "userId": el.userId,
                "userCode": el.userCode
            }

            detailData.push(detail);
        });

        let data = {
            "officialReceiptPph23": {
                "rowStatus": "ACT",
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "id": 0,
                "no": 0,
                "no2": "string",
                "reference": "",
                "buktiPotong": "",
                "jobOwnerId": jobOwnerId,
                "jobOwnerCode": "" + jobOwnerCode,
                "jobOwnerName": "" + jobOwnerName,
                "customerId": customerId,
                "customerCode": "" + customerCode,
                "customerName": "" + customerName,
                "customerType": "" + customerType,
                "isSettle": false,
                "settledOn": defaultDate,
                "remarks": "",
                "printing": 0,
                "printedOn": defaultDate,
                "idLama": 0,
                "receivedOn": adjustmentDate,
                "differentAmount": 0,
                "masaPajak": 0,
                "tahunPajak": 0,
                "deleted": true,
                "deletedby": "string",
                "deletedOn": defaultDate,
                "userId": userId,
                "userCode": userCode,
                "accountDebitCode": "" + debitAccountCode,
                "accountDebitName": "" + debitAccountName,
                "accountCreditCode": "" + creditAccountCode,
                "accountCreditName": "" + creditAccountName,
                "isAdjustment": true
            },
            "officialReceiptPph23Details": detailData
        }

        localStorage.setItem(username + "-DataRPPH23", JSON.stringify(data));
    }

    const getLocalData = () => {
        let officialReceiptPph23Data = dataLocal.officialReceiptPph23;
        let officialReceiptPph23DetailData = dataLocal.officialReceiptPph23Details;

        setJobOwnerId(officialReceiptPph23Data.jobOwnerId)
        setJobOwnerCode(officialReceiptPph23Data.jobOwnerCode)
        setJobOwnerName(officialReceiptPph23Data.jobOwnerName)
        setCustomerId(officialReceiptPph23Data.customerId)
        localStorage.setItem('customerId', officialReceiptPph23Data.customerId);
        setCustomerCode(officialReceiptPph23Data.customerCode)
        setCustomerName(officialReceiptPph23Data.customerName)
        setCustomerType(officialReceiptPph23Data.customerType)
        setAdjustmentDate(officialReceiptPph23Data.receivedOn)
        setDebitAccountCode(officialReceiptPph23Data.accountDebitCode)
        setDebitAccountName(officialReceiptPph23Data.accountDebitName)
        setCreditAccountCode(officialReceiptPph23Data.accountCreditCode)
        setCreditAccountName(officialReceiptPph23Data.accountCreditName)
        setDataCheck(officialReceiptPph23DetailData)
        getData(1, 50)

        SumTotal(officialReceiptPph23DetailData)

        localStorage.removeItem(username + '-DataRPPH23');
        setTimeout(function () { setIsLoadingBackDrop(false); }, 1250);
    }

    const getData = (pageNumber = 1, pageSize = 50, filters, orderBy) => {
        setIsLoadingBackDrop(true)
        setIsLoading(true)
        let params = [
            {
                "attr": "customerId",
                "value": '' + localStorage.getItem('customerId')
            },
        ]
        ApiService.FinAccCustomPostByPage('PostByDetailRetrieve', param, 'PAGE', pageNumber, pageSize, params, filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setDataDetail(response.data.data.officialReceiptPph23DetailRetrieve);

                    let temp = response.data.data.officialReceiptPph23DetailRetrieve
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMap(indexed)
                    let headerData = response.data.data.columns.headerColumns;
                    headerData.forEach(element => {
                        element.hidden = false;
                    });
                    setColumnData(headerData)
                    setTotalRows(response.data.totalRowCount)

                }
                setIsLoading(false)
                setIsLoadingBackDrop(false)
            })
            .catch(function (error) {
                setIsLoading(false)
                setIsLoadingBackDrop(false)
                console.error('error saat fetch', error)
            })
    }

    const getDataAccess = () => {
        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'FORP')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        getJobOwner(1, 50);
                    }
                    else {
                        handleBack();
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    history('/Majura');
                })
        }
        else {
            history('/Majura');
        }
    }

    const getDataCredit = () => {
        const paramC = "regChartOfAccount/regChartOfAccount";
        let params = [
            {
                'attr': 'accountCode',
                'value': '17020101001'
            }
        ];
        ApiService.COAPostBySearch(paramC, 1, 1, params, [])
            .then((response) => {
                if (response.data.code === 200) {
                    let data = response.data.data.chartOfAccount;
                    setCreditAccountCode(data[0].accountCode);
                    setCreditAccountName(data[0].accountTitle);
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const getJobOwner = (pageNumber, pageSize) => {
        if (!dataLocal) {
            setIsLoadingBackDrop(true);
        }

        ApiService.PostByPage('jobOwner/jobOwner', pageNumber, pageSize)
            .then((response) => {
                if (response.data.code === 200) {
                    let data = response.data.data.jobOwner;
                    setDataJobOwner(data);

                    if (data.length === 1) {
                        setJobOwnerId(data[0].id);
                        setJobOwnerCode(data[0].code);
                        setJobOwnerName(data[0].name);
                    }

                    if (dataLocal) {
                        setIsLoadingBackDrop(true);
                        setTimeout(function () { getLocalData(); }, 2000);
                    }
                    else {
                        GetFormatCurrency()
                        getDataCredit();
                        getData();
                    }
                }
                else {
                    handleBack();
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error })
            })
    }

    const handleBack = () => {
        history('/Majura/officialReceiptPph23')
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        }
        else {
            setIsModalConfirmation(false);
        }
    }

    const handleMemo = () => {
        setTitleModalConfirmation('Receipt PPh23');
        setTitleConfirmation('Memorial');
        handleClickConfirmation();
    }

    function ConfirmationRP() {
        if (titleConfirmation === 'Memorial') {
            Memorial();
        }
        setIsModalConfirmation(false);
    }

    const Memorial = () => {
        setIsLoadingBackDrop(true);

        let detailData = [];
        let sequence = 0;
        
        dataCheck.forEach((el) => {
            sequence += 1;
            let detail = {
                "orId": el.orId,
                "orNo": el.orNo,
                "sequence": sequence,
                "invoiceId": el.invoiceId,
                "invoiceNo": el.invoicesNo,
                "pph23Amount": el.pph23Amount,
            }

            detailData.push(detail);
        });

        let data = {
            "officialReceiptPph23": {
                "rowStatus": "ACT",
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "buktiPotong": "",
                "jobOwnerId": jobOwnerId,
                "jobOwnerCode": "" + jobOwnerCode,
                "jobOwnerName": "" + jobOwnerName,
                "customerId": customerId,
                "customerCode": "" + customerCode,
                "customerName": "" + customerName,
                "customerType": "" + customerType,
                "settledOn": defaultDate,
                "printedOn": defaultDate,
                "receivedOn": adjustmentDate,
                "deletedOn": defaultDate,
                "approveAccOn": defaultDate,
                "userId": userId,
                "userCode": userCode,
                "accountDebitCode": "" + debitAccountCode,
                "accountDebitName": "" + debitAccountName,
                "accountCreditCode": "" + creditAccountCode,
                "accountCreditName": "" + creditAccountName,
                "isAdjustment": true
            },
            "officialReceiptPph23Details": detailData
        }

        let errMsg = undefined;

        if (data.officialReceiptPph23.jobOwnerId === 0) {
            errMsg = 'Job Owner is Required!';
        }
        else if (data.officialReceiptPph23.customerId === 0) {
            errMsg = 'Customer is Required!';
        }
        else if (data.officialReceiptPph23.accountDebitCode === '') {
            errMsg = 'Debit Account is Required!';
        }
        else if (data.officialReceiptPph23Details.length === 0) {
            errMsg = 'Detail is Required!';
        }

        if (errMsg === undefined) {
            ApiService.HelpCreateData(data, param, 0)
                .then((res) => {
                    if (res.data.code === 200) {
                        let message = ''
                        ApiService.FinAccCustomPost(param + '/CreateAdjustment', data)
                            .then((res) => {
                                if (res.data.code === 200) {
                                    message = 'Success create data';
                                    showToast({ type: 'success', message: message });
                                    setIsLoadingBackDrop(false);
                                    setTimeout(
                                        function () {
                                            localStorage.setItem('tempId', JSON.stringify(res.data.data.MemorialId));
                                            history('/Majura/Memorial/' + JSON.parse(localStorage.getItem("tempId")), { replace: true });
                                            window.location.reload();
                                            localStorage.removeItem('tempId');
                                        },
                                        1250);
                                }
                                else {
                                    message = 'Failed create data';
                                    setIsLoadingBackDrop(false);
                                    showToast({ type: 'error', message: message });
                                }
                            })
                            .catch(function (error) {
                                setIsLoadingBackDrop(false);
                                showToast({ type: 'error', message: 'You Don\'t Have Access, Please Contact The Administrator, ' + error });
                            })
                    }
                    else {
                        setIsLoadingBackDrop(false);
                        showToast({ type: 'error', message: 'Failed to Create Help Data' });
                    }
                })
                .catch(function (error) {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'You Don\'t Have Access, Please Contact The Administrator, ' + error });
                })
        }
        else {
            setIsLoadingBackDrop(false);
            showToast({ type: 'error', message: errMsg });
        }
    }

    const GetFormatCurrency = () => {
        setTotalUSD(convertToNominal(0))
        setTotalIDR(convertToNominal(0))
        setTotalPPh23(convertToNominal(0))

        if (!dataLocal) {
            setIsLoadingBackDrop(false);
        }
    }

    const SumTotal = (data) => {
        let sumIDR = 0
        let sumUSD = 0
        let sumPPh23 = 0
        data.forEach(el => {
            sumUSD += parseFloat(el.invoiceUSD)
            sumIDR += parseFloat(el.invoiceIDR)
            sumPPh23 += parseFloat(el.pph23Amount)
        });
        setTotalUSDReal(sumUSD)
        setTotalIDRReal(sumIDR)
        setTotalPPh23Real(sumPPh23)
        setTotalUSD(convertToNominal(`${sumUSD}`))
        setTotalIDR(convertToNominal(`${sumIDR}`))
        setTotalPPh23(convertToNominal(`${sumPPh23}`))
    }

    const ButtonComponents = () => {
        return (
            <div className="row" style={{ marginTop: '16px' }}>
                <div className="col-12">
                    <Grid
                        container
                        item
                        justifyContent="right"
                        direction="row"
                        style={{ maxWidth: "100vw", gap: "12px" }}
                    >
                        {ButtonComponent.MemorialButton(handleMemo, 'Memorial', true)}
                    </Grid>
                </div>
            </div>
        )
    }

    return (
        <>
            <Box sx={{ backgroundColor: '#E4E8EC', width: '100%', p: '24px 32px' }}>
                <CustomToastContainer />
                <Grid container spacing={0} direction="column">
                    <HeaderInfo
                        title={'Adjustment Receipt PPh 23'}
                        BreadcrumbsItems={BreadcrumbsItems}
                        type={'Form'}
                        ticketId={0}
                        BackFunction={handleBack}
                    />

                    <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                        <CardContent>
                            <ReceiptPPh23Header
                                isAdjustment={true}
                                jobOwnerId={jobOwnerId}
                                setJobOwnerId={setJobOwnerId}
                                customerId={customerId}
                                setCustomerId={setCustomerId}
                                customerCode={customerCode}
                                setCustomerCode={setCustomerCode}
                                customerName={customerName}
                                setCustomerName={setCustomerName}
                                customerType={customerType}
                                setCustomerType={setCustomerType}
                                jobOwnerCode={jobOwnerCode}
                                setJobOwnerCode={setJobOwnerCode}
                                jobOwnerName={jobOwnerName}
                                setJobOwnerName={setJobOwnerName}
                                dataJobOwner={dataJobOwner}
                                getDataOR={getData}
                                setDataCheck={setDataCheck}
                                SumTotal={SumTotal}
                            />
                        </CardContent>
                    </Card>

                    <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                        <CardContent>
                            <ReceiptPPh23Detail
                                isAdjustment={true}
                                dataDetail={dataDetail}
                                setDataDetail={setDataDetail}
                                selectedDetail={selectedDetail}
                                setSelectedDetail={setSelectedDetail}
                                customerId={customerId}
                                totalIDR={totalIDR}
                                totalUSD={totalUSD}
                                totalPPh23={totalPPh23}
                                SumTotal={SumTotal}

                                dataCheck={dataCheck}
                                setDataCheck={setDataCheck}
                                isLoading={isLoading}
                                setIsLoading={setIsLoading}
                                dataMap={dataMap}
                                setDataMap={setDataMap}
                                SelectedData={SelectedData}
                                setSelectedData={setSelectedData}
                                totalRows={totalRows}
                                setTotalRows={setTotalRows}
                                columnData={columnData}
                                setColumnData={setColumnData}
                                getDataOR={getData}

                                totalIDRReal={totalIDRReal}
                                setTotalIDRReal={setTotalIDRReal}
                                totalUSDReal={totalUSDReal}
                                setTotalUSDReal={setTotalUSDReal}
                                totalPPh23Real={totalPPh23Real}
                                setTotalPPh23Real={setTotalPPh23Real}
                            />

                            <ReceiptPPh23Total
                                isAdjustment={true}
                                totalIDR={totalIDR}
                                totalUSD={totalUSD}
                                totalPPh23={totalPPh23}
                            />
                        </CardContent>
                    </Card>

                    <Card component="div" style={{ marginTop: '24px', borderRadius: '12px', backgroundColor: '#F2F2F2', padding: '0px 24px' }}>
                        <CardContent>
                            <ReceiptPPh23AdjustmentForm
                                adjustmentDate={adjustmentDate}
                                setAdjustmentDate={setAdjustmentDate}
                                debitAccountCode={debitAccountCode}
                                setDebitAccountCode={setDebitAccountCode}
                                debitAccountName={debitAccountName}
                                setDebitAccountName={setDebitAccountName}
                                creditAccountCode={creditAccountCode}
                                creditAccountName={creditAccountName}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Box>

            <ButtonInfo
                ButtonComponents={ButtonComponents}
            />

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationRP}
                    />
                )
            }
        </>
    )
})

export default CreateEditReceiptPPh23