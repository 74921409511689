import {
    TableContainer, Table,
    TableHead, TableRow,
    TableCell, TableBody, Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

import ApiService from '../../../../../services/ApiService';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';
import SelectDataShipment from '../Selects/SelectDataShipment';
import ModalDelete from '../../../../../components/Modals/ModalDelete';
import { showToast } from '../../../../../components/ui';
import { handleDataTable } from '../../../../../utils/reusableFunction';

const TemporaryPaymentListJob = (props) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F2FAFF',
            color: 'var(--Primary-One, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '14px',
            fontstyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            padding: '8px 16px !important',
        },
    }));

    const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            padding: '0px 16px !important',
            height: '35px !important'
        },
    }));

    const [isModal, setIsModal] = useState(false);
    const [titleModal, setTitleModal] = useState('');
    const [selectedDetail, setSelectedDetail] = useState({});
    const [detailSequence, setDetailSequence] = useState(0);
    const selectedStyle = { bgcolor: '#CCCCCC', "&.MuiTableRow-hover:hover": { backgroundColor: "#CCCCCC" } };
    const deletedDetailStyle = { bgcolor: (theme) => theme.palette.text.disabled };

    const paramS = "shipmentOrder/shipmentOrder";
    const [isLoadingS, setIsLoadingS] = useState(false);
    const [dataS, setDataS] = useState([]);
    const [dataMapS, setDataMapS] = useState([]);
    const [dataTableS, setDataTableS] = useState([]);
    const [columnDataS, setColumnDataS] = useState([]);
    const [SelectedDataS, setSelectedDataS] = useState({});
    const [totalRowsS, setTotalRowsS] = useState(50);

    const [isModalDelete, setIsModalDelete] = useState(false);
    const [titleModalDelete, setTitleModalDelete] = useState('');

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataShipment(1, 50);
        }
        else {
            setIsModal(false);
        }
    };

    const ShowAddShipmentOrder = () => {
        if (props.CustomerId !== 0) {
            setTitleModal('Shipment Order');
            if (props.ShipmentOrderList.length > 0) {
                let data = props.ShipmentOrderList.reduce((prev, current) => (prev && prev.sequence > current.sequence) ? prev : current);
                setDetailSequence(data.sequence);
            }
            else {
                setDetailSequence(0);
            }
            handleClick();
        }
        else {
            showToast({ type: 'error', message: 'Please Select Customer First!' });
        }
    };

    const DeleteShipmentOrder = () => {
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            setTitleModalDelete('Temporary Payment Job');
            handleClickDelete();
        }
    }

    const DeleteJob = () => {
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            let tempSequence = selectedDetail.sequence;
            let tempShipmentOrderId = selectedDetail.shipmentOrderId;

            const deleteFunction = (data) => {
                return data.sequence !== tempSequence;
            }

            const deleteIdFunction = (data) => {
                return data !== tempShipmentOrderId;
            }

            const result = props.ShipmentOrderList.filter(deleteFunction);

            const resultId = props.ShipmentOrderIdList.filter(deleteIdFunction);

            if (result.length > 0) {
                tempSequence = 0;

                result.forEach((el) => {
                    tempSequence += 1;
                    el.sequence = tempSequence;
                })

                setDetailSequence(tempSequence);
            }
            else {
                setDetailSequence(0);
            }

            props.setShipmentOrderList(result);

            setSelectedDetail({});

            props.setBankData([]);
            props.calculateTotalBank(result);
            props.calculateTotalCash(result);
            props.setCashPaymentUSD(0);
            props.setCashPaymentIDR(0);
            props.setShipmentOrderIdList(resultId);
            handleClickDelete();
        }
    }

    function handleSelectedDetail(val) {
        setSelectedDetail(val);
        props.setSelectedShipmentOrder(val);
        props.setBankData(val.temporaryPaymentJobBanks);
        props.setCashPaymentUSD(val.cashUSD);
        props.setCashPaymentIDR(val.cashIDR);
    }

    const getDataShipment = (pageNumber, pageSize, filters, orderBy) => {
        setIsLoadingS(true);
        let params = [];
        params = [
            ...params,
            { 'attr': 'jobTypeId', 'value': '' + props.jobType.jobTypeId },
            { 'attr': 'jobClosed', 'value': 'false' },
        ];

        if (props.PaymentTo === 1) {
            params = [
                ...params,
                { 'attr': 'agentId', 'value': '' + props.CustomerId }
            ]
        }
        else if (props.PaymentTo === 8) {
            params = [
                ...params,
                { 'attr': 'shipperId', 'value': '' + props.CustomerId }
            ]
        }
        else if (props.PaymentTo === 9) {
            params = [
                ...params,
                { 'attr': 'consigneeId', 'value': '' + props.CustomerId }
            ]
        }
        else if (props.PaymentTo === 4) {
            params = [
                ...params,
                { 'attr': 'ssLineId', 'value': '' + props.CustomerId }
            ]
        }
        else if (props.PaymentTo === 5) {
            params = [
                ...params,
                { 'attr': 'iataId', 'value': '' + props.CustomerId }
            ]
        }
        else if (props.PaymentTo === 6) {
            params = [
                ...params,
                { 'attr': 'emklId', 'value': '' + props.CustomerId }
            ]
        }
        else if (props.PaymentTo === 7) {
            params = [
                ...params,
                { 'attr': 'depoId', 'value': '' + props.CustomerId }
            ]
        }
        else if (props.PaymentTo === 10) {
            params = [
                ...params,
                { 'attr': 'truckId', 'value': '' + props.CustomerId }
            ]
        }
        else if (props.PaymentTo === 12) {
            params = [...params]
        }

        if (props.jobType.jobTypeId !== undefined) {
            let arrayData = [];
            ApiService.OperationDynamicPostByPage(paramS, 'SEATP', pageNumber, pageSize, params, filters, orderBy)
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedDataS({})
                        arrayData = response.data.data.shipmentOrder;

                        setDataS(arrayData);

                        let temp = arrayData
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataMapS(indexed)
                        handleDataTable(indexed, response.data.data.columns.headerColumns, setDataTableS, 'id');
                        setColumnDataS(response.data.data.columns.headerColumns)
                        setTotalRowsS(response.data.totalRowCount);

                        setIsLoadingS(false)
                    }
                    setIsLoadingS(false)
                })
                .catch(function (error) {
                    setIsLoadingS(false)
                    console.error('error saat fetch', error)
                    showToast({ type: 'error', message: error.toString() });
                })
        }
    };

    const handleClickDelete = () => {
        if (isModalDelete === false) {
            setIsModalDelete(true);
        }
        else {
            setIsModalDelete(false);
        }
    }

    const handleDoubleClick = () => {

    }

    return (
        <div>
            <form>
                <div>
                    <Typography
                        sx={{
                            color: 'var(--Blue-Primary-1, #083A50)',
                            fontFamily: 'Nunito',
                            fontSize: '18px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                        }}
                    >
                        Job List
                    </Typography>

                    <TableContainer sx={{ maxHeight: 235, background: '#FFF', borderRadius: '12px', marginTop: '16px' }}>
                        <Table sx={{ minWidth: 650 }} stickyHeader>
                            <TableHead
                                sx={{
                                    '& th:first-of-type': {
                                        borderRadius: '12px 0 0'
                                    },
                                    '& th:last-child': {
                                        borderRadius: '0 12px 0 0'
                                    }
                                }}
                            >
                                <TableRow>
                                    <StyledTableCell sx={{ textAlign: 'center' }}>Shipment No</StyledTableCell>
                                    <StyledTableCell>Payment To Name</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    props.ShipmentOrderList.length > 0 ?
                                        props.ShipmentOrderList.map((el) => {
                                            return (
                                                <TableRow
                                                    key={el.sequence}
                                                    onClick={() => handleSelectedDetail(el)}
                                                    onDoubleClick={() => handleDoubleClick()}
                                                    sx={selectedDetail.sequence === el.sequence ? selectedStyle : el.rowStatus === 'DEL' ? deletedDetailStyle : {}}
                                                    hover={true}
                                                >
                                                    <StyledTableBodyCell sx={{ textAlign: 'center' }}>{el.shipmentNo}</StyledTableBodyCell>
                                                    <StyledTableBodyCell>{el.customerName}</StyledTableBodyCell>
                                                </TableRow>
                                            )
                                        })
                                        :
                                        <TableRow sx={{ backgroundColor: '#FFFFFF' }}>
                                            <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                                <Typography
                                                    sx={{
                                                        color: 'var(--Primary-One, #083A50)',
                                                        fontFamily: 'Nunito',
                                                        fontSize: '12px',
                                                        fontstyle: 'normal',
                                                        fontWeight: '500',
                                                        lineHeight: 'normal'
                                                    }}
                                                >
                                                    Data Empty
                                                </Typography>
                                            </StyledTableBodyCell>
                                        </TableRow>
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <div style={{ marginTop: '16px' }}>
                        {CustomizeButtonComponent.AddDeleteTabsButton(ShowAddShipmentOrder, DeleteShipmentOrder, props.Printing > 0, props.Printing > 0)}
                    </div>
                </div>
            </form>

            {
                isModal && (
                    <SelectDataShipment
                        isModal={isModal}
                        setIsModal={setIsModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        selectedDetail={selectedDetail}
                        detailSequence={detailSequence}
                        setDetailSequence={setDetailSequence}
                        getData={getDataShipment}
                        isLoading={isLoadingS}
                        totalRows={totalRowsS}
                        setTotalRows={setTotalRowsS}
                        SelectedData={SelectedDataS}
                        setSelectedData={setSelectedDataS}
                        data={dataS}
                        columnData={columnDataS}
                        dataMap={dataMapS}
                        dataTable={dataTableS}
                        jobType={props.jobType}
                        temporaryReceiptId={props.temporaryReceiptId}
                        Rate={props.Rate}
                        RateId={props.RateId}
                        ShipmentOrderList={props.ShipmentOrderList}
                        setShipmentOrderList={props.setShipmentOrderList}
                        ShipmentOrderIdList={props.ShipmentOrderIdList}
                        setShipmentOrderIdList={props.setShipmentOrderIdList}
                    />
                )
            }

            {
                isModalDelete && (
                    <ModalDelete
                        isModal={isModalDelete}
                        titleModal={titleModalDelete}
                        handleClick={handleClickDelete}
                        DeleteData={DeleteJob}
                    />
                )
            }
        </div>
    )
}

export default TemporaryPaymentListJob;