import React from 'react';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';
import Grid from '@mui/material/Grid';

const ButtonMemorial = (props) => {
    const {
        isApprove, isUnApprove, DataRegUserAccess, ReloadData, AddM, EditM,
        DeleteData, DropDownTitle, Print, PrintExcel, handleApprove,
        handleUnApprove, HandleStatus, DropDownList, Approve, UnApprove
    } = props;

    const renderButtonByAccessCode = (data) => {
        switch (data.accessCode) {
            case 'CRE':
                return ButtonComponent.AddButton(AddM, 'New');
            case 'REI':
                return ButtonComponent.EditButton(EditM, 'Data');
            case 'DEL':
                return DropDownTitle !== 'Deleted' && ButtonComponent.DeleteButton(DeleteData, 'Data');
            case 'PRI':
                return DropDownTitle !== 'Deleted' && (
                    <>
                        {ButtonComponent.PrintButton(Print)}
                        {ButtonComponent.ExcelButton(PrintExcel, 'Print Excel')}
                    </>
                );
            case 'APP':
                return (DropDownTitle === 'Active' || DropDownTitle === 'All') && ButtonComponent.ApproveButton(handleApprove, 'Approve');
            case 'UPP':
                return (DropDownTitle === 'Active' || DropDownTitle === 'All') && ButtonComponent.UnApproveButton(handleUnApprove, 'Un-Approve');
            default:
                return null;
        }
    };

    return (
        <Grid container spacing={1} style={{ maxWidth: '100vw', margin: 0, columnGap: '8px', rowGap: '10px' }}>
            {!isApprove && !isUnApprove ? (
                <>
                    {ButtonComponent.ReloadButton(ReloadData)}
                    {DataRegUserAccess.map(data => (
                        <React.Fragment key={data.accessId}>
                            {renderButtonByAccessCode(data)}
                        </React.Fragment>
                    ))}
                    {CustomizeButtonComponent.DropDownButtonStatus(HandleStatus, DropDownTitle, DropDownList)}
                </>
            ) : isApprove ? (
                <>
                    {ButtonComponent.BackButton(handleApprove)}
                    {ButtonComponent.ReloadButton(ReloadData)}
                    {DataRegUserAccess.filter(data => data.accessCode === 'APP').map(data => (
                        <>
                            {ButtonComponent.ApproveButton(Approve, 'Approve Data')}
                        </>
                    ))}
                </>
            ) : (
                <>
                    {ButtonComponent.BackButton(handleUnApprove)}
                    {ButtonComponent.ReloadButton(ReloadData)}
                    {DataRegUserAccess.filter(data => data.accessCode === 'APP').map(data => (
                        <>
                            {ButtonComponent.UnApproveButton(UnApprove, 'Un-Approve Data')}
                        </>
                    ))}
                </>
            )}
        </Grid>
    );
};

export default ButtonMemorial;