import { Grid, IconButton, MenuItem, Stack, InputAdornment, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { formatCurrency } from '../../../../../services/ApiService';
import DollarInput, { convertMask } from '../../../../../components/Masks/DollarInput';
import RupiahInput, { convertMaskIDR } from '../../../../../components/Masks/RupiahInput';

import ApiService, { branchId, companyId, countryId, userId, userCode } from '../../../../../services/ApiService';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';
import SelectDataCOA from '../Selects/SelectDataCOA';
import SelectDataPaymentVoucher from '../Selects/SelectDataPaymentVoucher';
import { Icon, Input, PopUp, Selection, showToast } from '../../../../../components/ui';
import { convertToNominal } from '../../../../../utils/masks';
import { handleDataTable } from '../../../../../utils/reusableFunction';

const ModalPaymentDetail = (props) => {
    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);

    const paramC = "regChartOfAccount/regChartOfAccount";
    const [isAccount, setIsAccount] = useState(0);
    const [isLoadingC, setIsLoadingC] = useState(false);
    const [dataC, setDataC] = useState([]);
    const [dataMapC, setDataMapC] = useState([]);
    const [dataTableC, setDataTableC] = useState([]);
    const [columnDataC, setColumnDataC] = useState([]);
    const [SelectedDataC, setSelectedDataC] = useState({});
    const [totalRowsC, setTotalRowsC] = useState(50);

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataCOA(1, 50);
        }
        else {
            setIsModal(false);
        }
    };

    const searchAccount = (data) => {
        if (data === 'search') {
            setTitleModal('Chart of Account (COA)');
            handleClick();
        }
        else if (data === 'delete') {
            props.setAccountCode('');
            props.setAccountName('');
        }
    };

    const [titleModal2, setTitleModal2] = useState('');
    const [isModal2, setIsModal2] = useState(false);

    const [isLoadingP, setIsLoadingP] = useState(false);
    const [paramP, setParamP] = useState('');
    const [dataP, setDataP] = useState([]);
    const [dataMapP, setDataMapP] = useState([])
    const [dataTableP, setDataTableP] = useState([]);
    const [SelectedDataP, setSelectedDataP] = useState({});
    const [SelectedDataP2, setSelectedDataP2] = useState({});
    const [totalRowsP, setTotalRowsP] = useState(50);
    const [columnDataP, setColumnDataP] = useState([])

    const handleClick2 = () => {
        if (isModal2 === false) {
            setIsModal2(true);
            getDataPayment(1, 50);
        }
        else {
            emptyState();
            setIsModal2(false);
        }
    };

    const searchData = (data) => {
        if (data === 'search') {
            setTitleModal2(props.SettlementTo);
            handleClick2();
        }
        else if (data === 'delete') {

        }
    };

    const handleClose = () => {
        emptyState();
        props.handleClick();
    };

    const emptyState = () => {
        props.setRefId(0);
        props.setRefNo('');
        props.setShipmentId(0);
        props.setShipmentNo('');
        props.setExRateId(0);
        props.setRefRate(0);

        if (isAccount > 1) {
            props.setAccountCode('');
            props.setAccountName('');
        }

        props.setDescription('');
        props.setDCNote('D');
        props.setAmountUSD(0);
        props.setAmountIDR(0);
        props.setDifferentUSD(0);
        props.setDifferentIDR(0);

        props.setIsDisabled(false);
        props.setIsAccount(false);
    };

    const TransactionTypeData = [
        { id: 1, code: 'D', name: 'Debit' },
        { id: 2, code: 'C', name: 'Credit' },
    ];

    const handleSave = () => {
        if (props.AmountUSD <= 0 && props.AmountIDR <= 0) {
            showToast({ type: 'error', message: 'Please Input Amount!' });
        }
        else {
            if (props.Description !== '') {
                let Sequence = 1;
                let pvId = 0;
                let rate = 0;
                let amountUSD = 0;
                let amountIDR = 0;

                if (props.detailEdit) {
                    Sequence = props.selectedDetail.sequence;
                }
                else {
                    if (props.detailSequence > 0) Sequence = props.detailSequence + 1;
                }

                if (props.paymentVoucherId) {
                    pvId = props.paymentVoucherId;
                }

                if (props.RefId !== 0) {
                    if (props.refRate > 0) {
                        rate = props.refRate;
                    }
                    else {
                        if (formatCurrency === 'USD') {
                            rate = parseFloat(convertMask(props.RefRate));
                        }
                        else {
                            rate = parseFloat(convertMaskIDR(props.RefRate));
                        }
                    }
                }
                else {
                    rate = props.RateReal;
                }

                amountUSD = props.AmountUSD - props.AmountTaxUSD;
                amountIDR = props.AmountIDR - props.AmountTaxIDR;

                let data = {
                    "countryId": countryId,
                    "companyId": companyId,
                    "branchId": branchId,
                    "userId": userId,
                    "userCode": userCode,

                    "rowStatus": "ACT",
                    "pvId": pvId,
                    "id": 0,
                    "sequence": Sequence,
                    "paymentBy": props.PaymentBy,
                    "bankId": 0,
                    "description": props.Description,
                    "dcNote": props.DCNote,
                    "amountUSD": formatCurrency === 'USD' ? parseFloat(convertMask(amountUSD)) : parseFloat(convertMaskIDR(amountUSD)),
                    "amountIDR": formatCurrency === 'USD' ? parseFloat(convertMask(amountIDR)) : parseFloat(convertMaskIDR(amountIDR)),
                    "amountTaxUSD": props.AmountTaxUSD,
                    "amountTaxIDR": props.AmountTaxIDR,
                    "paymentCash": 0,
                    "refId": props.RefId,
                    "refNo": props.RefNo,
                    "shipmentOrderId": props.ShipmentId,
                    "shipmentNo": props.ShipmentNo,
                    "refRate": rate,
                    "refDetailId": props.RefDetailId,
                    "refAccountId": props.refAccountId,
                    "coaCode": props.AccountCode,
                    "coaName": props.AccountName,
                    "exRateId": props.ExRateId,
                    "isPPh23": false,
                    "isStored": false,
                    "paymentVoucherDetailBanks": [],

                    "idLama": 0,
                }

                if (!props.detailEdit) {
                    let newArr = props.DataPaymentDetail;
                    newArr = [...props.DataPaymentDetail, data];
                    props.setDataPaymentDetail(newArr);

                    props.calculateTotalDebitCredit(newArr, props.Rate, props.PaymentBy, props.PPh23Amount);
                }
                else {
                    let tempPPh23 = 0;
                    let tempPPh23Amount = 0;
                    let tempAmount = 0;

                    const newArr = props.DataPaymentDetail.slice();
                    newArr.forEach(el => {
                        if (el.sequence === data.sequence) {
                            el.refId = data.refId;
                            el.refNo = data.refNo;
                            el.shipmentOrderId = data.shipmentOrderId;
                            el.shipmentNo = data.shipmentNo;
                            el.exRateId = data.exRateId;
                            el.refRate = data.refRate;

                            el.refAccountId = data.refAccountId;
                            el.coaCode = data.coaCode;
                            el.coaName = data.coaName;
                            el.description = data.description;
                            el.dcNote = data.dcNote;
                            el.amountUSD = data.amountUSD;
                            el.amountIDR = data.amountIDR;
                            el.amountTaxUSD = data.amountTaxUSD;
                            el.amountTaxIDR = data.amountTaxIDR;

                            if (el.isPPh23 === true) {
                                if (props.PaymentBy === 'CU' || props.PaymentBy === 'BU') {
                                    tempPPh23Amount = parseFloat(data.amountUSD) * parseFloat(props.PPh23Percent) / parseFloat(100);
                                    tempAmount = parseFloat(data.amountUSD) + parseFloat(data.amountTaxUSD) - parseFloat(tempPPh23Amount);
                                }
                                else {
                                    tempPPh23Amount = parseFloat(data.amountIDR) * parseFloat(props.PPh23Percent) / parseFloat(100);
                                    tempAmount = parseFloat(data.amountIDR) + parseFloat(data.amountTaxIDR) - parseFloat(tempPPh23Amount);
                                }

                                if (props.PaymentBy === 'CU' || props.PaymentBy === 'CI') {
                                    el.paymentCash = tempAmount;
                                    props.setCashPayment(el.paymentCash);
                                }
                            }
                        }

                        if (el.isPPh23 === true) {
                            if (props.PaymentBy === 'CU' || props.PaymentBy === 'BU') {
                                tempPPh23 = parseFloat(tempPPh23) + (parseFloat(el.amountUSD) * parseFloat(props.PPh23Percent) / parseFloat(100));
                            }
                            else {
                                tempPPh23 = parseFloat(tempPPh23) + (parseFloat(el.amountIDR) * parseFloat(props.PPh23Percent) / parseFloat(100));
                            }
                        }
                    })
                    props.setPPh23Amount(convertToNominal(tempPPh23));
                    props.setPPh23AmountReal(tempPPh23);
                    props.setDataPaymentDetail(newArr);

                    props.calculateTotalDebitCredit(newArr, props.Rate, props.PaymentBy, tempPPh23);
                }

                emptyState();
                handleClose();
                props.setDetailEdit(false);
                props.setSelectedDetail({});
            }
            else {
                showToast({ type: 'error', message: 'Please Input Description!' });
            }
        }
    }

    const getDataCOA = (pageNumber, pageSize, filters) => {
        setIsLoadingC(true);
        ApiService.COAPostBySearch(paramC, pageNumber, pageSize, [], filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataC({})
                    setDataC(response.data.data.chartOfAccount);

                    let temp = response.data.data.chartOfAccount
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapC(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTableC, 'id');
                    setColumnDataC(response.data.data.columns)
                    setTotalRowsC(response.data.totalRowCount)

                    setIsLoadingC(false)
                }
                setIsLoadingC(false)
            })
            .catch(function (error) {
                setIsLoadingC(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    };

    const getDataPayment = (pageNumber, pageSize, filters) => {
        setIsLoadingP(true);
        let params = [];
        let param = '';
        let arrayData = [];
        if (props.SettlementTo === 'I') {
            param = 'invoice/invoice';
            setParamP(param);
            params = [
                ...params,
                { 'attr': 'customerId', 'value': '' + props.ContactId },
                { 'attr': 'isAllPV', 'value': 'false' },
                { 'attr': 'debetCredit', 'value': 'C' },
                { 'attr': 'isPosted', 'value': 'true' },
                { 'attr': props.PaymentBy === 'BU' || props.PaymentBy === 'CU' ? 'totalPaymentUSD' : 'totalPaymentIDR', 'value': '0', 'mathSign': '>' }
            ];
            ApiService.OperationDynamicPostByPage(param, 'SEARCH', pageNumber, pageSize, params, filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        arrayData = response.data.data.invoices;
                        setDataP(arrayData);
                        let temp = arrayData
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })
                        setDataMapP(indexed)

                        handleDataTable(indexed, response.data.data.columns.headerColumns, setDataTableP, 'id');
                        setColumnDataP(response.data.data.columns.headerColumns)
                        setTotalRowsP(response.data.totalRowCount);
                        setIsLoadingP(false)
                    }
                    setIsLoadingP(false)
                })
                .catch(function (error) {
                    setIsLoadingP(false)
                    console.error('error saat fetch', error)
                    showToast({ type: 'error', message: error.toString() });
                })
        }
        else if (props.SettlementTo === 'P') {
            param = 'paymentRequest/paymentRequest';
            setParamP(param);
            params = [
                ...params,
                { 'attr': 'customerId', 'value': '' + props.ContactId },
                { 'attr': 'isAllPV', 'value': 'false' },
                { 'attr': 'isPosted', 'value': 'true' },
                { 'attr': 'approveAcc', 'value': 'true' },
                { 'attr': 'prContraStatus', 'value': '' },
                { 'attr': props.PaymentBy === 'BU' || props.PaymentBy === 'CU' ? 'paymentUSD' : 'paymentIDR', 'value': '0', 'mathSign': '>' }
            ];
            ApiService.OperationDynamicPostByPage(param, 'SEARCH', pageNumber, pageSize, params, filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        arrayData = response.data.data.paymentRequests;
                        setDataP(arrayData);
                        let temp = arrayData
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })
                        setDataMapP(indexed)

                        handleDataTable(indexed, response.data.data.columns.headerColumns, setDataTableP, 'id');
                        setColumnDataP(response.data.data.columns.headerColumns)
                        setTotalRowsP(response.data.totalRowCount);

                        setIsLoadingP(false)

                    }
                    setIsLoadingP(false)
                })
                .catch(function (error) {
                    setIsLoadingP(false)
                    console.error('error saat fetch', error)
                    showToast({ type: 'error', message: error.toString() });
                })
        }
        else if (props.SettlementTo === 'T') {
            param = 'temporaryReceipt/temporaryReceipt';
            setParamP(param);
            params = [
                ...params,
                { 'attr': 'contactId', 'value': '' + props.ContactId },
                { 'attr': 'isDP', 'value': 'false' },
                { 'attr': 'isAllPV', 'value': 'false' },
                { 'attr': 'isApprovedAcc', 'value': 'true' }
            ];
            ApiService.FinAccPostByPage(param, 'SEARCH', pageNumber, pageSize, params, filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataP(response.data.data.temporaryReceipts);

                        let temp = response.data.data.temporaryReceipts
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })
                        setDataMapP(indexed)

                        handleDataTable(indexed, response.data.data.columns.headerColumns, setDataTableP, 'id');
                        setColumnDataP(response.data.data.columns.headerColumns)
                        setTotalRowsP(response.data.totalRowCount)

                        setIsLoadingP(false)
                    }
                    setIsLoadingP(false)
                })
                .catch(function (error) {
                    setIsLoadingP(false)
                    console.error('error saat fetch', error)
                    showToast({ type: 'error', message: error.toString() });
                })
        }
        else if (props.SettlementTo === 'R') {
            param = 'receiptVoucher/receiptVoucher';
            setParamP(param);
            params = [
                ...params,
                { 'attr': 'contactId', 'value': '' + props.ContactId },
                { 'attr': 'isAllPV', 'value': 'false' },
                { 'attr': 'isApprovedAcc', 'value': 'true' },
                { 'attr': 'receiptByCode', 'value': 'D', 'mathSign': '!=' },
            ];
            ApiService.FinAccPostByPage(param, 'SEARCH', pageNumber, pageSize, params, filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataP(response.data.data.receiptVouchers);

                        let temp = response.data.data.receiptVouchers
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })
                        setDataMapP(indexed)

                        handleDataTable(indexed, response.data.data.columns.headerColumns, setDataTableP, 'id');
                        setColumnDataP(response.data.data.columns.headerColumns)
                        setTotalRowsP(response.data.totalRowCount)

                        setIsLoadingP(false)
                    }
                    setIsLoadingP(false)
                })
                .catch(function (error) {
                    setIsLoadingP(false)
                    console.error('error saat fetch', error)
                    showToast({ type: 'error', message: error.toString() });
                })
        }
        else if (props.SettlementTo === 'M') {

        }
    };

    const mainContainer = (
        <>
            <div hidden={props.SettlementTo !== 'G' ? false : true}>
                <Typography
                    sx={{
                        color: 'var(--Blue-Primary-1, #083A50)',
                        fontFamily: 'Nunito',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: 'normal',
                    }}
                >
                    Settlement For
                </Typography>

                <Grid
                    container
                    spacing={'32px'}
                >
                    <Grid
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}
                    >
                        <Input
                            label='Reference No'
                            value={props.RefNo}
                            disabled
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '12px !important'
                                }
                            }}
                            inputBaseProps={{
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => searchData('search')}
                                            hidden={props.detailEdit}
                                        >
                                            <Icon
                                                iconName={'search'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />

                        <Input
                            label='Ref Shipment No'
                            value={props.ShipmentNo}
                            disabled
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '16px !important'
                                }
                            }}
                        />
                    </Grid>

                    <Grid
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}
                    >
                        <Input
                            label="Ref Account Id"
                            textAlign='right'
                            fullWidth
                            value={props.RefAccountId}
                            disabled
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '12px !important'
                                }
                            }}
                        />

                        <Input
                            label='Ref Rate'
                            textAlign='right'
                            fullWidth
                            value={props.RefRate}
                            disabled
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '16px !important'
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </div>

            <div style={{ marginTop: props.SettlementTo !== 'G' ? '16px' : '12px' }}>
                <Grid
                    container
                    spacing={'32px'}
                >
                    <Grid
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}
                    >
                        <Stack direction={'row'} spacing={'16px'}>
                            <Input
                                label={'Account Code'}
                                value={props.AccountCode}
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '50%',
                                    }
                                }}
                                readOnly
                            />

                            <Input
                                value={props.AccountName}
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                    }
                                }}
                                inputBaseProps={{
                                    sx: {
                                        marginTop: '23px !important',
                                    },
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => { searchAccount('delete'); setIsAccount(isAccount + 1); }}
                                                disabled={props.isAccount || props.isApprovedAcc}
                                                hidden={props.AccountName ? props.SettlementTo === 'G' ? !props.isPosted ? false : true : props.printing === 0 ? false : true : true}
                                            >
                                                <Icon
                                                    iconName={'close'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>

                                            <IconButton
                                                onClick={() => searchAccount('search')}
                                                disabled={props.isAccount || props.isApprovedAcc}
                                                hidden={props.SettlementTo === 'G' ? props.isPosted : props.printing > 0}
                                            >
                                                <Icon
                                                    iconName={'search'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Stack>

                        <Selection
                            id='0'
                            label={'Transaction'}
                            fullWidth={true}
                            placeholder={'Select Transaction'}
                            value={props.DCNote}
                            onChange={(e) => props.setDCNote(e.target.value)}
                            disabled
                            formControlProps={{
                                sx: {
                                    marginTop: '16px !important'
                                }
                            }}
                        >
                            {
                                TransactionTypeData.map((data, index) => (
                                    <MenuItem sx={{ fontSize: '12px' }} key={index} value={data.code}>{data.name}</MenuItem>
                                ))
                            }
                        </Selection>
                    </Grid>

                    <Grid
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}
                    >
                        <Input
                            label={'Description'}
                            value={props.Description}
                            disabled={props.printing > 0}
                            onChange={(e) => props.setDescription(e.target.value)}
                            multiline
                            fullWidth
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                }
                            }}
                            inputBaseProps={{
                                rows: 5,
                                sx: {
                                    padding: '8px',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
            </div>

            <div style={{ marginTop: '16px' }}>
                <Grid
                    container
                    spacing={'32px'}
                >
                    <Grid
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}
                    >
                        <Typography
                            sx={{
                                color: 'var(--Blue-Primary-1, #083A50)',
                                fontFamily: 'Nunito',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: 'normal',
                            }}
                        >
                            Amount
                        </Typography>

                        {
                            !props.isAmountCrr0 ?
                                <Input
                                    label="USD"
                                    textAlign='right'
                                    fullWidth
                                    value={props.AmountUSD}
                                    disabled={props.SettlementTo === 'G' ? props.isPosted : props.printing > 0}
                                    onChange={(e) => props.setAmountUSD(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px'
                                        }
                                    }}
                                    inputBaseProps={{
                                        inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                                    }}
                                />
                                :
                                <Input
                                    label="USD"
                                    textAlign='right'
                                    fullWidth
                                    value={props.AmountUSD}
                                    disabled
                                    onChange={(e) => props.setAmountUSD(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px'
                                        }
                                    }}
                                    inputBaseProps={{
                                        inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                                    }}
                                />
                        }

                        {
                            !props.isAmountCrr1 ?
                                <Input
                                    label="IDR"
                                    textAlign='right'
                                    fullWidth
                                    value={props.AmountIDR}
                                    disabled={props.SettlementTo === 'G' ? props.isPosted : props.printing > 0}
                                    onChange={(e) => props.setAmountIDR(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px'
                                        }
                                    }}
                                    inputBaseProps={{
                                        inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                                    }}
                                />
                                :
                                <Input
                                    label="IDR"
                                    textAlign='right'
                                    fullWidth
                                    value={props.AmountIDR}
                                    disabled
                                    onChange={(e) => props.setAmountIDR(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
                                    formControlProps={{
                                        sx: {
                                            width: '100%',
                                            marginTop: '16px'
                                        }
                                    }}
                                    inputBaseProps={{
                                        inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                                    }}
                                />
                        }
                    </Grid>

                    <Grid
                        item
                        xl={6}
                        lg={6}
                        sm={6}
                        xs={12}
                    >
                        <Typography
                            sx={{
                                color: 'var(--Blue-Primary-1, #083A50)',
                                fontFamily: 'Nunito',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: '600',
                                lineHeight: 'normal',
                            }}
                        >
                            Different Cost
                        </Typography>

                        <Input
                            label="USD"
                            textAlign='right'
                            fullWidth
                            value={props.DifferentUSD}
                            disabled
                            onChange={(e) => props.setDifferentUSD(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '16px'
                                }
                            }}
                            inputBaseProps={{
                                inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                            }}
                        />

                        <Input
                            label="IDR"
                            textAlign='right'
                            fullWidth
                            value={props.DifferentIDR}
                            disabled
                            onChange={(e) => props.setDifferentIDR(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
                            formControlProps={{
                                sx: {
                                    width: '100%',
                                    marginTop: '16px'
                                }
                            }}
                            inputBaseProps={{
                                inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                            }}
                        />
                    </Grid>
                </Grid>
            </div>
        </>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={props.titleModal}
                isOpen={props.isModal}
                onClose={handleClose}
                draggable={true}
                shrinkable={true}
                disableScrollLock={true}
                maxWidth={'lg'}
                fullWidth={true}
                topButton={false}
            >
                <div style={{ padding: '12px 6px 0px' }}>
                    {mainContainer}

                    <div style={{ marginTop: '48px' }}>
                        {CustomizeButtonComponent.SaveCancelModalButton(handleSave, false, props.SettlementTo === 'G' ? props.isPosted : props.printing > 0 ? true : false)}
                    </div>
                </div>
            </PopUp>

            {
                isModal && (
                    <SelectDataCOA
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}
                        getData={getDataCOA}
                        isLoading={isLoadingC}
                        totalRows={totalRowsC}
                        setTotalRows={setTotalRowsC}
                        SelectedData={SelectedDataC}
                        setSelectedData={setSelectedDataC}
                        data={dataC}
                        columnData={columnDataC}
                        dataMap={dataMapC}
                        dataTable={dataTableC}
                        isAccount={isAccount}
                        setIsAccount={setIsAccount}
                        setAccountCode={props.setAccountCode}
                        setAccountName={props.setAccountName}
                    />
                )
            }

            {
                isModal2 && (
                    <SelectDataPaymentVoucher
                        isModal={isModal2}
                        handleClick={handleClick2}
                        titleModal={titleModal2}
                        setIsModal={setIsModal2}
                        handleClose={handleClose}
                        IsPPh23={props.IsPPh23}
                        detailSequence={props.detailSequence}
                        CustomerTo={props.CustomerTo}
                        SettlementTo={props.SettlementTo}
                        SettlementToName={props.SettlementToName}
                        PPh23Amount={props.PPh23Amount}
                        selectedDetail={props.selectedDetail}
                        PaymentBy={props.PaymentBy}
                        Rate={props.Rate}
                        emptyState={emptyState}
                        getData={getDataPayment}
                        isLoading={isLoadingP}
                        totalRows={totalRowsP}
                        setTotalRows={setTotalRowsP}
                        param={paramP}
                        setParam={setParamP}
                        SelectedData={SelectedDataP}
                        setSelectedData={setSelectedDataP}
                        SelectedData2={SelectedDataP2}
                        setSelectedData2={setSelectedDataP2}
                        data={dataP}
                        columnData={columnDataP}
                        dataMap={dataMapP}
                        dataTable={dataTableP}
                        DataPaymentDetail={props.DataPaymentDetail}
                        setDataPaymentDetail={props.setDataPaymentDetail}
                        DataPaymentDetailId={props.DataPaymentDetailId}
                        setDataPaymentDetailId={props.setDataPaymentDetailId}
                        RefId={props.RefId}
                        setRefId={props.setRefId}
                        RefNo={props.RefNo}
                        setRefNo={props.setRefNo}
                        ShipmentId={props.ShipmentId}
                        setShipmentId={props.setShipmentId}
                        ShipmentNo={props.ShipmentNo}
                        setShipmentNo={props.setShipmentNo}
                        ExRateId={props.ExRateId}
                        setExRateId={props.setExRateId}
                        RefRate={props.RefRate}
                        setRefRate={props.setRefRate}
                        RefAccountId={props.RefAccountId}
                        setRefAccountId={props.setRefAccountId}
                        AccountCode={props.AccountCode}
                        setAccountCode={props.setAccountCode}
                        AccountName={props.AccountName}
                        setAccountName={props.setAccountName}
                        isDisabled={props.isDisabled}
                        calculateTotalDebitCredit={props.calculateTotalDebitCredit}
                        setIsLoadingBackDrop={props.setIsLoadingBackDrop}
                    />
                )
            }
        </>
    )
}

export default ModalPaymentDetail;