import { Grid } from '@mui/material';
import React, { useState } from 'react'
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import TableX from '../../../../components/Tables/TableX';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';
import { Icon, Paginate, PopUp, showToast } from '../../../../components/ui';

const SelectDataAccount = (props) => {
    const [rowsCount, setRowsCount] = useState(50);
    const [numPage, setNumPage] = useState(1);
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);

    const emptyState = () => {
        setNumPage(1);
        setRowsCount(50);
        props.setTotalRows(50);
        setGoTo(1);
        setSortArr([]);
        setFilter([]);
        setFilterBy([]);
        setStateX({});
    };

    function Select() {
        if (props.SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            let accountId = 0;
            let accountCode = '';
            let accountName = '';
            let coaCode = '';
            let coaName = '';
            let description = '';
            let isOthers = false;
            let isContainer = false;
            let containerType = 'All';
            let containerTypeId = 0;
            let containerTypeCode = '';
            let containerTypeName = '';
            let containerFormat = 'All';
            let quantityUnit = 0;

            if (props.PaymentFrom === 1) {
                accountId = props.SelectedData.id;
                accountCode = props.SelectedData.masterCode;
                accountName = props.SelectedData.name;
                coaCode = props.SelectedData.coaCode;
                coaName = props.SelectedData.coaName;
                description = props.SelectedData.name;

                if (accountName === 'OTHER') {
                    isOthers = true;
                }

                if (props.SelectedData.quantity === true) {
                    isContainer = true;

                    if (props.containerSizeData.length > 0) {
                        containerType = props.containerSizeData[0].containerSize;
                        containerTypeId = props.containerSizeData[0].containerTypeId;
                        containerTypeCode = props.containerSizeData[0].containerTypeCode;
                        containerTypeName = props.containerSizeData[0].containerTypeName;
                        containerFormat = props.containerSizeData[0].formatContainer;
                        quantityUnit = props.containerSizeData[0].totalContainer;
                    }
                }

                props.handleQuantityUnit(quantityUnit);
            }
            else {
                accountId = props.SelectedData.accountId;
                accountCode = props.SelectedData.accountCode;
                accountName = props.SelectedData.accountName;
                coaCode = props.SelectedData.coaCode;
                coaName = props.SelectedData.coaName;
                description = props.SelectedData.description;
                quantityUnit = props.SelectedData.quantity;

                if (accountName === 'OTHER') {
                    isOthers = true;
                }

                if (props.SelectedData.type !== 'All') {
                    isContainer = true;

                    containerType = props.SelectedData.containerSize;
                    containerTypeId = props.SelectedData.containerTypeId;
                    containerTypeCode = props.SelectedData.containerTypeCode;
                    containerTypeName = props.SelectedData.containerTypeName;
                    containerFormat = props.SelectedData.formatContainer;
                }
                let amount = props.SelectedData.amountCrr === 0? props.SelectedData.amountUSD : props.SelectedData.amountIDR;
                props.setUnitCost(props.SelectedData.perQty);
                props.setCostToCost(props.SelectedData.isCostToCost);
                props.setOriginalRate(props.SelectedData.originalRate);
                props.setOriginalUSD(props.SelectedData.originalUsd);
                props.setAmountCrr(props.SelectedData.amountCrr);
                props.setAmount(amount);
                props.setQuantity(quantityUnit);
                props.setEplDetailId(props.SelectedData.id);
            }

            props.setAccountId(accountId);
            props.setAccountCode(accountCode);
            props.setAccountName(accountName);
            props.setCOACode(coaCode);
            props.setCOAName(coaName);
            props.setDescription(description);
            props.setIsOthers(isOthers);
            props.setIsContainer(isContainer);
            props.setContainerType(containerType);
            props.setContainerTypeId(containerTypeId);
            props.setContainerTypeCode(containerTypeCode);
            props.setContainerTypeName(containerTypeName);
            props.setContainerFormat(containerFormat);

            emptyState();
            props.handleClick();

            props.setIsModal(false);
        }
    };

    function Cancel() {
        emptyState();
        props.handleClick();
    };

    const handleReset = () => {
        emptyState();
        props.getData(1, 50);
        setStateX({})
    };

    function CellDoubleClick(row) {
        Select();
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        props.getData(value, rowsCount, filter, sortArr);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        props.getData(1, value, filter, sortArr);
    }

    const mainContainer = (
        <>
            <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
                <div>
                    {
                        ButtonComponent.RefreshButton(
                            sortArr,
                            rowsCount,
                            props.columnData,
                            setGoTo,
                            handleReset,
                            setFilterBy,
                            filterBy,
                            setFilter,
                            setNumPage,
                            setRowsCount,
                            props.getData,
                            stateX,
                            setStateX
                        )
                    }
                </div>

                <div
                    className="rounded-10 table-responsive"
                    style={{
                        maxHeight: '375px',
                        marginTop: '16px',
                        padding: '2px 16px',
                        borderRadius: '12px',
                        background: '#F2F2F2'
                    }}
                >
                    {
                        props.isLoading ?
                            (
                                <LoadingSpinner />
                            )
                            :
                            (
                                <>
                                    <div className="mt-1">
                                        <TableX
                                            uniqueKey={'id'}
                                            goTo={goTo}
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={props.getData}
                                            columnData={props.columnData}
                                            dataMap={props.dataMap}
                                            dataTable={props.dataTable}
                                            SelectedData={props.SelectedData}
                                            setSelectedData={props.setSelectedData}
                                            setFilter={setFilter}
                                            setFilterBy={setFilterBy}
                                            CellDoubleClick={CellDoubleClick}
                                            stateX={stateX}
                                            setStateX={setStateX}
                                            sortArr={sortArr}
                                            setSortArr={setSortArr}
                                            setGoTo={setGoTo}
                                        />
                                    </div>
                                </>
                            )
                    }
                </div>

                <div style={{ marginTop: '16px' }}>
                    <Paginate
                        page={numPage}
                        limit={rowsCount}
                        totalData={props.totalRows}
                        onChange={onChangePaging}
                        onChangeLimit={onChangeLimit}
                    />
                </div>
            </Grid>
        </>
    );

    return (
        <PopUp
            id='modal-id'
            icon={
                <Icon
                    iconName={'account'}
                    svgIconProps={{
                        htmlColor: '#0087C2',
                        sx: {
                            height: '24px',
                            width: '24px'
                        }
                    }}
                />
            }
            title={'Select ' + props.titleModal}
            isOpen={props.isModal}
            onClose={Cancel}
            draggable={false}
            shrinkable={false}
            disableScrollLock={false}
            maxWidth={'lg'}
            fullWidth={true}
        >
            <div style={{ padding: '0px 6px' }}>
                {mainContainer}

                <div style={{ padding: '24px 0px 0px' }}>
                    {CustomizeButtonComponent.SelectModalButton(Select)}
                </div>
            </div>
        </PopUp>
    )
}

export default SelectDataAccount;