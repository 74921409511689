import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import { Grid, Box, InputAdornment, IconButton } from "@mui/material";
import Backdrop from '@mui/material/Backdrop';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';

import ApiService from '../../../../services/ApiService';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import TableX from '../../../../components/Tables/TableX';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import SelectDataMaster from "../../../Master/MasterWarehouse/SelectDataMaster";
import ButtonAdjustmentList from './Buttons/ButtonAdjustmentList';
import ModalDelete from '../../../../components/Modals/ModalDelete';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import freezeContainer from "../../../../Styles/freezeContainer";
import CustomToastContainer, { Icon, Input, Paginate, showToast } from '../../../../components/ui';
import { getDefaultDateFormat } from '../../../../utils/date';
import { handleDataTable } from '../../../../utils/reusableFunction';

const AdjustmentList = forwardRef((props, ref) => {
    const btnPageRef = useRef(null)
    const btnTableRef = useRef(null)

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);

    const paramAdjustment = 'Adjustment/Adjustment';
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const paramProductCategory = "warehouse/productCategory/productCategory";
    const paramProduct = "warehouse/product/product";

    let selectParam, selectKey;
    const [selectName, setSelectName] = useState("");
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    const history = useNavigate();

    const [dataMap, setDataMap] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [data, setData] = useState([]);
    const [columnData, setColumnData] = useState([]);
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50);
    const [rowsCount, setRowsCount] = useState(50);
    const [numPage, setNumPage] = useState(1);
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);

    const [isModalDelete, setIsModalDelete] = useState(false);
    const [titleModalDelete, setTitleModalDelete] = useState('');

    const [titleModal, setTitleModal] = useState("");
    const [isModal, setIsModal] = useState(false);
    const [isLoadingModal, setIsLoadingModal] = useState(false);
    const [dataModal, setDataModal] = useState([]);
    const [dataMapModal, setDataMapModal] = useState([]);
    const [columnDataModal, setColumnDataModal] = useState([]);
    const [SelectedDataModal, setSelectedDataModal] = useState({});
    const [totalRowsModal, setTotalRowsModal] = useState(50);

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState("");
    const [titleConfirmation, setTitleConfirmation] = useState("");

    const [dtFrom, setDtFrom] = useState("");
    const [dtTo, setDtTo] = useState("");
    const [productCategoryId, setProductCategoryId] = useState("");
    const [productCategoryName, setProductCategoryName] = useState("");
    const [productId, setProductId] = useState("");
    const [productName, setProductName] = useState("");

    const [DropDownTitle, setDropDownTitle] = useState('Active');

    let countErr = 0;

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'Adjustment', link: '/Majura/Adjustment', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'Adjustment - ' + JSON.parse(localStorage.getItem('branchName'));
        getDateNow();
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function getDateNow() {
        var formatedDateNow = getDefaultDateFormat(new Date());
        setDtFrom(formatedDateNow);
        setDtTo(formatedDateNow);
    }

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
        } else {
            setIsModalConfirmation(false);
        }
    };

    function ConfirmationDRB() {
        if (titleConfirmation === "Print PDF") {
            PrintPDF();
        } else if (titleConfirmation === "Print Excel") {
            PrintExl();
        }
        setIsModalConfirmation(false);
    }

    const ReloadData = () => {
        showToast({ type: 'success', message: 'Reload Data' })
        emptyState();
        getData(1, 50, []);
    }

    const PrintData = () => {
        setTitleConfirmation("Print PDF");
        setTitleModalConfirmation("Report InBound");
        handleClickConfirmation();
    };

    const PrintExcel = () => {
        setTitleConfirmation("Print Excel");
        setTitleModalConfirmation("Report InBound");
        handleClickConfirmation();
    };

    const getData = (pageNumber, pageSize, filters, orderBy) => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        } else {
            setIsLoadingBackDrop(true);
        }

        let filter = [];
        if (data) {
            if (data === 'Deleted') {
                filter = [...filters, { 'field': 'rowStatus', 'data': 'DEL' }];
            }
            else {
                filter = [...filters, { 'field': 'rowStatus', 'data': 'ACT' }];
            }
        }
        else {
            if (DropDownTitle === 'Deleted') {
                filter = [...filters, { 'field': 'rowStatus', 'data': 'DEL' }];
            }
            else {
                filter = [...filters, { 'field': 'rowStatus', 'data': 'ACT' }];
            }
        }

        ApiService.DynamicPostByPageWarehouse(paramAdjustment, 'PAGE', pageNumber, pageSize, [], filter, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({});
                    setData(response.data.data.adjustment);

                    let temp = response.data.data.adjustment;
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index,
                        };
                        return indexedTemp;
                    });

                    setDataMap(indexed);
                    handleDataTable(indexed, response.data.data.columns.headerColumns, setDataTable, 'id');
                    setColumnData(response.data.data.columns.headerColumns);
                    setTotalRows(response.data.totalRowCount);

                    if (isLoadingBackDrop === false) {
                        setIsLoading(false);
                    }
                    setIsLoadingBackDrop(false);
                } else {
                    showToast({ type: 'error', message: response.data.error })
                }

                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                setIsLoadingBackDrop(false);
            })
            .catch(function (error) {
                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: error })
            });
    };

    function PrintPDF() {
        setIsLoadingBackDrop(true);
        ApiService.ReportWarehouseAdjustment(paramAdjustment, "PDF", [], getParams(dtFrom, dtTo, productCategoryId, productId))
            .then((response) => {
                const blob = new Blob([response.data], { type: 'application/pdf' });

                // Buat URL objek untuk blob
                const blobUrl = window.URL.createObjectURL(blob);

                // Buat tautan unduhan
                const a = document.createElement('a');
                a.href = blobUrl;
                a.download = 'Report_InBound.pdf';
                a.style.display = 'none';
                document.body.appendChild(a);
                a.click();

                // Hapus elemen tautan setelah pengunduhan selesai
                window.URL.revokeObjectURL(blobUrl);
                document.body.removeChild(a);

                setIsLoadingBackDrop(false);
            })
            .catch((error) => {
                setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: error })
            });
    }

    function PrintExl() {
        setIsLoadingBackDrop(true);
        ApiService.ReportWarehouseAdjustment(paramAdjustment, "Excel", [], getParams(dtFrom, dtTo, productCategoryId, productId))
            .then((response) => {
                if (response.status === 200) {
                    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                    const blobUrl = window.URL.createObjectURL(blob);

                    const a = document.createElement('a');
                    a.href = blobUrl;
                    a.download = 'Report_InBound.xlsx';
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();

                    window.URL.revokeObjectURL(blobUrl);
                    document.body.removeChild(a);

                    setIsLoadingBackDrop(false);
                } else {
                    setIsLoadingBackDrop(false);
                    throw new Error("Failed, " + response.status);
                }
            })
            .catch((error) => {
                setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: error })
            });
    }

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataSelect(1, 50);
        } else {
            setIsModal(false);
        }
    };

    const getDataAccess = () => {
        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'TWHADJ')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);

                        if (hasAccess) {
                            getData(1, 50, []);
                        }
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    countErr++
                    if (countErr < 3) {
                        getDataAccess()
                    }
                    else {
                        ApiService.logOut()
                        history('/')
                    }
                })
        }
        else {
            history('/Majura');
        }
    }

    const emptyState = () => {
        setNumPage(1);
        setRowsCount(50);
        setTotalRows(50);
        setFilter([]);
        setGoTo(1);
        setFilterBy([]);
        setStateX({});
        setSortArr([]);
    };

    const handleReset = () => {
        emptyState();
        getData(1, 50, []);
    };

    const getDataSelect = (pageNumber, pageSize, filters) => {
        setIsLoadingModal(true);
        ApiService.PostByPage(selectParam, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataModal({});
                    setDataModal(response.data.data[selectKey]);
                    let temp = response.data.data[selectKey];
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index,
                        };
                        return indexedTemp;
                    });

                    setDataMapModal(indexed);
                    setColumnDataModal(response.data.data.columns);
                    setTotalRowsModal(response.data.totalRowCount);

                    setIsLoadingModal(false);
                }
                setIsLoadingModal(false);
            })
            .catch(function (error) {
                setIsLoadingModal(false);
                console.error("error saat fetch", error);
                showToast({ type: 'error', message: error })
            });
    };

    const selectHandler = (selectedData, name) => {
        if (name === "product") {
            setProductId(selectedData.id);
            setProductName(selectedData.name);
        } else {
            if (selectedData.id !== props.productCategoryId) {
                setProductId("");
                setProductName("");
            }
            setProductCategoryId(selectedData.id);
            setProductCategoryName(selectedData.name);
        }
    };

    const searchProductCategory = () => {
        setTitleModal("Product Category");
        selectParam = paramProductCategory;
        selectKey = "productCategory";
        setSelectName("productCategory");
        handleClick();
    };

    const searchProduct = () => {
        setTitleModal("Product");
        selectParam = paramProduct;
        selectKey = "product";
        setSelectName("product");
        handleClick();
    };

    const getParams = (dtFrom, dtTo, productCategoryId, productId) => {
        let fromDate = getDefaultDateFormat(new Date());
        let toDate = getDefaultDateFormat(new Date());
        let prodCategoryId = '';
        let prodId = '';

        if (dtFrom) {
            fromDate = dtFrom;
        }

        if (dtTo) {
            toDate = dtTo;
        }

        if (productCategoryId) {
            prodCategoryId = productCategoryId;
        }

        if (productId) {
            prodId = productId;
        }

        let params = [
            {
                "attr": "StartDate",
                "value": getDefaultDateFormat(fromDate),
                "mathSign": ">="
            },
            {
                "attr": "EndDate",
                "value": getDefaultDateFormat(toDate),
                "mathSign": "<="
            }
        ];
        if (prodCategoryId !== "") {
            params = [
                ...params,
                {
                    "attr": "productCategoryId",
                    "value": prodCategoryId.toString(),
                    "mathSign": "="
                }
            ]
        }
        if (prodId !== "") {
            params = [
                ...params,
                {
                    "attr": "productId",
                    "value": prodId.toString(),
                    "mathSign": "="
                }
            ]
        }

        return params;
    }

    const AddAdjustment = () => {
        history('/Majura/Adjustment/Create');
    }

    const EditAdjustment = () => {
        if (SelectedData.id === undefined && Object.keys(SelectedData).length === 0) {
            showToast({ type: 'error', message: 'Please Select Data!' })
        }
        else {
            history('/Majura/Adjustment/' + SelectedData.id);
        }
    }

    const DeleteData = () => {
        if (SelectedData.id === undefined && Object.keys(SelectedData).length === 0) {
            showToast({ type: 'error', message: 'Please Select Data!' })
        }
        else {
            setTitleModalDelete('Adjustment');
            handleClickDelete();
        }
    }

    const handleClickDelete = () => {
        if (isModalDelete === false) {
            setIsModalDelete(true);
        }
        else {
            setIsModalDelete(false);
        }
    }

    const DeleteAdjustment = () => {
        ApiService.DeleteDataWarehouse(paramAdjustment, SelectedData.id)
            .then((res) => {
                if (res.status === 200) {
                    emptyState();
                    getData(1, 50, filter);
                    showToast({ type: 'success', message: 'Delete Data Success' })
                    setIsModalDelete(false);
                }
                else {
                    showToast({ type: 'error', message: 'Delete Data Failed' })
                    setIsModalDelete(false);
                }
            });
    }

    function CellDoubleClick(row) {
        EditAdjustment();
    };

    function HandleStatus(data) {
        setDropDownTitle(data);
        getData(numPage, rowsCount, filter, sortArr, data);
    };

    const getContainerHeight = () => {
        const hTitle = 64
        const hHeader = 116
        const hPadding = 10 * 2
        const hBtnPage = btnPageRef?.current?.clientHeight || 28
        const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

        const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable

        return `${totalHeight}px`
    }

    const ButtonComponents = () => {
        return (
            <>
                <ButtonAdjustmentList
                    HandleStatus={HandleStatus}
                    AddData={AddAdjustment}
                    EditData={EditAdjustment}
                    ReloadData={ReloadData}
                    DeleteData={DeleteData}
                    PrintData={PrintData}
                    PrintExcel={PrintExcel}
                    DataRegUserAccess={DataRegUserAccess}
                    DropDownTitle={DropDownTitle}
                />

                <Grid
                    container
                    columnSpacing={{ xs: 0, md: 3 }}
                    rowSpacing={{ xs: 2, md: 0 }}
                >
                    <Grid
                        item
                        lg={3}
                        md={3}
                        sm={12}
                        xs={12}
                    >
                        <Input
                            fullWidth
                            type='date'
                            value={dtFrom}
                            label="Period"
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => { setDtFrom(e.target.value); }}
                            formControlProps={{ sx: { width: '100%', marginTop: '16px !important' } }}
                        />
                    </Grid>

                    <Grid
                        item
                        lg={3}
                        md={3}
                        sm={12}
                        xs={12}
                    >
                        <Input
                            fullWidth
                            label="To"
                            type='date'
                            value={dtTo}
                            variant="standard"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) => { setDtTo(e.target.value); }}
                            formControlProps={{ sx: { width: '100%', marginTop: '16px !important' } }}
                        />
                    </Grid>

                    <Grid
                        item
                        lg={3}
                        md={3}
                        sm={12}
                        xs={12}
                    >
                        <Input
                            fullWidth
                            variant="standard"
                            label="Product Category"
                            value={productCategoryName}
                            InputLabelProps={{ shrink: true }}
                            formControlProps={{ sx: { width: '100%', marginTop: '16px' } }}
                            inputBaseProps={{
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            hidden={productCategoryId ? false : true}
                                            onClick={() => {
                                                setProductCategoryId("");
                                                setProductCategoryName("");
                                            }}
                                        >
                                            <Icon
                                                iconName={'close'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>

                                        <IconButton onClick={() => searchProductCategory()}>
                                            <Icon
                                                iconName={'search'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>

                    <Grid
                        item
                        lg={3}
                        md={3}
                        sm={12}
                        xs={12}
                    >
                        <Input
                            fullWidth
                            label="Product"
                            variant="standard"
                            value={productName}
                            InputLabelProps={{ shrink: true }}
                            formControlProps={{ sx: { width: '100%', marginTop: '16px' } }}
                            inputBaseProps={{
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            hidden={productId ? false : true}
                                            onClick={() => {
                                                setProductId("");
                                                setProductName("");
                                            }}
                                        >
                                            <Icon
                                                iconName={'close'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>

                                        <IconButton onClick={() => searchProduct()}>
                                            <Icon
                                                iconName={'search'}
                                                svgIconProps={{
                                                    fontSize: 'small',
                                                    htmlColor: '#0087C2',
                                                    sx: {
                                                        height: '12px',
                                                        width: '12px'
                                                    }
                                                }}
                                            />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>
                </Grid>
            </>
        )
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        getData(value, rowsCount, filter, sortArr);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        getData(1, value, filter, sortArr);
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: '24px 32px', ...freezeContainer }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo
                    btnPageRef={btnPageRef}
                    title={'Adjustment'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
                    <div ref={btnTableRef}>
                        {
                            ButtonComponent.RefreshButton(
                                sortArr,
                                rowsCount,
                                columnData,
                                setGoTo,
                                handleReset,
                                setFilterBy,
                                filterBy,
                                setFilter,
                                setNumPage,
                                setRowsCount,
                                getData,
                                stateX,
                                setStateX
                            )
                        }
                    </div>

                    <div
                        className="rounded-10 table-responsive"
                        style={{
                            maxHeight: `calc(100vh - ${getContainerHeight()})`,
                            marginTop: '16px',
                            padding: '2px 16px',
                            borderRadius: '12px',
                            background: '#F2F2F2'
                        }}
                    >
                        {
                            isLoading ?
                                <LoadingSpinner />
                                :
                                <>
                                    <div className='mt-1'>
                                        <TableX
                                            uniqueKey={'id'}
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={getData}
                                            columnData={columnData}
                                            dataMap={dataMap}
                                            dataTable={dataTable}
                                            SelectedData={SelectedData}
                                            setSelectedData={setSelectedData}
                                            setFilter={setFilter}
                                            setFilterBy={setFilterBy}
                                            stateX={stateX}
                                            setStateX={setStateX}
                                            sortArr={sortArr}
                                            setSortArr={setSortArr}
                                            goTo={goTo}
                                            CellDoubleClick={CellDoubleClick}
                                            setGoTo={setGoTo}
                                        />
                                    </div>
                                </>
                        }
                    </div>

                    <div style={{ marginTop: '16px' }}>
                        <Paginate
                            page={numPage}
                            limit={rowsCount}
                            totalData={totalRows}
                            onChange={onChangePaging}
                            onChangeLimit={onChangeLimit}
                        />
                    </div>
                </Grid>
            </Grid>

            {
                isModal && (
                    <SelectDataMaster
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}
                        getData={getDataSelect}
                        isLoading={isLoadingModal}
                        totalRows={totalRowsModal}
                        setTotalRows={setTotalRowsModal}
                        SelectedData={SelectedDataModal}
                        setSelectedData={setSelectedDataModal}
                        data={dataModal}
                        columnData={columnDataModal}
                        dataMap={dataMapModal}
                        selectHandler={selectHandler}
                        selectName={selectName}
                    />
                )
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{
                            color: "#fff",
                            zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationDRB}
                    />
                )
            }

            {
                isModalDelete && (
                    <ModalDelete
                        isModal={isModalDelete}
                        titleModal={titleModalDelete}
                        handleClick={handleClickDelete}
                        DeleteData={DeleteAdjustment}
                    />
                )
            }
        </Box>
    )
})

export default AdjustmentList