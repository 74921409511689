import { IconButton, InputAdornment, MenuItem } from '@mui/material';
import React, { useEffect } from 'react';
import ApiService, { userCode } from '../../../../services/ApiService';
import ButtonService from '../../ButtonService';
import { useState } from 'react';
import SelectDataMaster from '../SelectDataMaster';
import { Icon, Input, Selection, showToast } from '../../../../components/ui';
import CreateEditModalMaster from '../../CreateEditModalMaster';
import { handleDataTable } from '../../../../utils/reusableFunction';

const CreateEditProduct = (props) => {
    const paramProductCategory = "warehouse/productCategory/productCategory";
    const paramProduct = "warehouse/product/product";
    // const paramUnit = "warehouse/units/units";

    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);
    const [isLoadingModal, setIsLoadingModal] = useState(false);
    const [dataModal, setDataModal] = useState([]);
    const [dataMapModal, setDataMapModal] = useState([]);
    const [dataTableModal, setDataTableModal] = useState([]);
    const [columnDataModal, setColumnDataModal] = useState([]);
    const [SelectedDataModal, setSelectedDataModal] = useState({});
    const [totalRowsModal, setTotalRowsModal] = useState(50);

    // const [titleModalUnit, setTitleModalUnit] = useState('');
    // const [isModalUnit, setIsModalUnit] = useState(false);
    // const [isLoadingModalUnit, setIsLoadingModalUnit] = useState(false);
    // const [dataModalUnit, setDataModalUnit] = useState([]);
    // const [dataMapModalUnit, setDataMapModalUnit] = useState([]);
    // const [columnDataModalUnit, setColumnDataModalUnit] = useState([]);
    // const [SelectedDataModalUnit, setSelectedDataModalUnit] = useState({});
    // const [totalRowsModalUnit, setTotalRowsModalUnit] = useState(50);

    const [pickingStrategyList, setPickingStrategyList] = useState([]);

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataProductCategory(1, 50);
        }
        else {
            setIsModal(false);
        }
    };

    // const handleClickUnit = () => {
    //     if (isModalUnit === false) {
    //         setIsModalUnit(true);
    //         getdataUnit(1, 50);
    //     }
    //     else {
    //         setIsModalUnit(false);
    //     }
    // };

    const getDataProductCategory = (pageNumber, pageSize, filters) => {
        setIsLoadingModal(true);
        ApiService.PostByPage(paramProductCategory, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataModal({})
                    setDataModal(response.data.data.productCategory);

                    let temp = response.data.data.productCategory
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapModal(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTableModal, 'id');
                    setColumnDataModal(response.data.data.columns)
                    setTotalRowsModal(response.data.totalRowCount)

                    setIsLoadingModal(false)
                }
                setIsLoadingModal(false)
            })
            .catch(function (error) {
                setIsLoadingModal(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error })
            })
    };

    // const getdataUnit = (pageNumber, pageSize, filters) => {
    //     setIsLoadingModalUnit(true);
    //     ApiService.PostByPage(paramUnit, pageNumber, pageSize, filters)
    //         .then((response) => {
    //             if (response.data.code === 200) 
    //             {
    //                 setSelectedDataModalUnit({})
    //                 setDataModalUnit(response.data.data.units);

    //                 let temp = response.data.data.units
    //                 let indexed = temp.map((el, index) => {
    //                     let indexedTemp = {
    //                         ...el,
    //                         index
    //                     }
    //                     return indexedTemp
    //                 })

    //                 setDataMapModalUnit(indexed)
    //                 setColumnDataModalUnit(response.data.data.columns)
    //                 setTotalRowsModalUnit(response.data.totalRowCount)

    //                 setIsLoadingModalUnit(false)
    //             }
    //             setIsLoadingModalUnit(false)
    //         })
    //         .catch(function (error) {
    //             setIsLoadingModalUnit(false)
    //             console.error('error saat fetch', error)
    //         })
    // };

    const searchProductCategory = () => {
        setTitleModal("Product Category");
        handleClick();
    }

    const searchPickingStrategy = () => {
        ApiService.PostByPagePickingStrategy(paramProduct, 1, 100, [])
            .then((response) => {
                if (response.data.code === 200) {
                    setPickingStrategyList(response.data.data.pickingStrategy)
                }
            })
            .catch(function (error) {
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error })
            })
    }

    // const searchUnit = () => {
    //     setTitleModalUnit("Unit");
    //     handleClickUnit();
    // }

    const pickingStrategySelectHandler = (value) => {
        props.setPickingStrategyId(value);
        ApiService.PostByIdPickingStrategy(paramProduct, value)
            .then((res) => {
                const obj = res.data.data.pickingStrategy;
                props.setPickingStrategyName(obj.name);
            });
    }

    const Save = () => {
        var data =
        {
            "product": {
                "rowStatus": "ACT",
                "id": props.id,
                "code": props.code,
                "name": props.name,
                "description": props.description,
                "productCategoryId": props.productCategoryId,
                "productCategoryName": props.productCategoryName,
                "productCategoryCode": props.productCategoryCode,
                // "unitId": props.unitId,
                // "unitName": props.unitName,
                "pickingStrategyId": props.pickingStrategyId,
                "pickingStrategyName": props.pickingStrategyName,
                "userCode": userCode
            }
        }
        ButtonService.Save(props.titleModal, props.title, props.param, data, () => {
            props.getData(1, 50, []);
            props.handleClick();
        });
    }

    useEffect(() => {
        searchPickingStrategy()
    }, [])

    const mainContainer = (
        <form onSubmit={Save} style={{ marginTop: '12px' }}>
            <Input
                label="Code"
                value={props.code}
                onChange={(e) => props.setCode(e.target.value)}
                disabled={props.id === 0 ? false : true}
                formControlProps={{
                    sx: {
                        width: '100%',
                    }
                }}
            />

            <Input
                label="Name"
                value={props.name}
                onChange={(e) => props.setName(e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px !important'
                    }
                }}
            />

            <Input
                label="Description"
                value={props.description}
                onChange={(e) => props.setDescription(e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px !important'
                    }
                }}
            />

            <Input
                label="Product Category"
                value={props.productCategoryName}
                fullWidth
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                disabled
                inputBaseProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => searchProductCategory()}>
                                <Icon
                                    iconName={'search'}
                                    svgIconProps={{
                                        fontSize: 'small',
                                        htmlColor: '#0087C2',
                                        sx: {
                                            height: '12px',
                                            width: '12px'
                                        }
                                    }}
                                />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />

            <Selection
                id='0'
                label={'Picking Strategy'}
                fullWidth={true}
                placeholder={'Select Picking Strategy'}
                value={props.pickingStrategyId}
                onOpen={searchPickingStrategy}
                onChange={(e) => pickingStrategySelectHandler(e.target.value)}
                formControlProps={{
                    sx: {
                        marginTop: '16px !important'
                    }
                }}
            >
                {
                    pickingStrategyList.map((data, index) => {
                        return <MenuItem sx={{ fontSize: '12px' }} key={index} name={data.name} value={data.id}>{data.name}</MenuItem>
                    })
                }
            </Selection>
        </form>
    )

    return (
        <>
            {
                props.isModal && (
                    <CreateEditModalMaster
                        isModal={props.isModal}
                        titleModal={props.titleModal}
                        title={props.title}
                        mainContainer={mainContainer}
                        handleClick={props.handleClick}
                        handleSave={Save}
                    />
                )
            }

            {
                isModal && (
                    <SelectDataMaster
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}

                        getData={getDataProductCategory}
                        isLoading={isLoadingModal}
                        totalRows={totalRowsModal}
                        setTotalRows={setTotalRowsModal}
                        SelectedData={SelectedDataModal}
                        setSelectedData={setSelectedDataModal}
                        data={dataModal}
                        columnData={columnDataModal}
                        dataMap={dataMapModal}
                        dataTable={dataTableModal}

                        setId={props.setProductCategoryId}
                        setCode={props.setProductCategoryCode}
                        setName={props.setProductCategoryName}
                    />
                )
            }
        </>
    )
}

export default CreateEditProduct