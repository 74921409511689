import { Avatar, Button, CssBaseline, Backdrop, Grid, Stack, Typography, Box, InputAdornment, IconButton, useMediaQuery, Link, } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { keyframes } from '@mui/system';
import jwtDecode from 'jwt-decode';

import axios from 'axios';
import ModalLogin from './Login/Modals/ModalLogin';
import ApiService, { username } from '../../services/ApiService';
import majuraIconBlue from '../../Assets/Icon/majuraIconBlue.svg';
import LoadingSpinner from '../../components/Components/LoadingSpinner';
import backgroundLanding from '../../Assets/Background/backgroundLanding2.png';
import backgroundLandingShip from '../../Assets/Background/backgroundLanding3.png';
import CustomToastContainer, { Icon, Input, showToast } from '../../components/ui';

export const urlAuth = process.env.REACT_APP_API_URL_AUTHENTIFICATION;

const newData = (data) => {
    let newArray = [...data];
    return newArray.map((ele, i) => {
        return { ...ele, id: i, index: i };
    })
}

const theme = createTheme();

export const getDataMenu = (dataMenu) => {
    var menu = []
    let menuCode = []
    var mainMenu = dataMenu.filter(el => el.parentId === 0)
    menu = insertMenu(dataMenu, mainMenu, true);
    menuCode = insertMenuCode(dataMenu);
    console.log(menu)
    localStorage.setItem("dataMenu", JSON.stringify(menu));
    localStorage.setItem("dataMenuCode", JSON.stringify(menuCode));
}

const insertMenu = (data, dataParent, isParent = false) => {
    var menu = [];
    dataParent.forEach((el) => {
        let childData = data.filter(elm => elm.parentId === el.permissionId);
        var children = [];
        if (childData.length > 0) {
            children = insertMenu(data, childData);
        }

        let permissionName = el.permissionName;

        if (isParent) {
            permissionName = permissionName.toUpperCase();
        }

        let modifiedUrl = el.url.replace('/Infoss/', '/Majura/');

        menu = [
            ...menu,
            {
                id: '' + el.permissionId,
                name: '' + permissionName,
                icon: el.permissionId === 219 ? 'config' : el?.iconName || '',
                children: children,
                url: '' + modifiedUrl,
                code: '' + el.permissionCode,
                hidden: '' + el.hidden
            }
        ];
    });

    return menu;
};

const insertMenuCode = (data) => {
    var menuCode = []
    data.forEach((el) => {
        if (el?.url) {
            menuCode.push({
                id: String(el?.permissionId || 0),
                code: (el?.permissionCode || ''),
            })
        }
    })

    return menuCode;
}

export default function Login() {
    const [isLoading, setIsLoading] = useState(false);
    const [isLogin, setIsLogin] = useState(true);
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [isPassword, setIsPassword] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const history = useNavigate();

    const isXS = useMediaQuery('(max-width:600px)');
    const isSM = useMediaQuery('(max-width:960px)');
    const isMD = useMediaQuery('(max-width:1280px)');
    const isLG = useMediaQuery('(min-width:1281px)');

    useEffect(() => {
        document.title = 'Majura - Login'
        checkLogin();
        window.ResizeObserver = null;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function checkLogin() {
        setIsLoading(true);
        let expired = JSON.parse(localStorage.getItem("expiredToken"));
        let currentDate = new Date();
        let userNames = JSON.parse(localStorage.getItem("username"));
        let userUrl = userNames + '-lastUrl';
        if (expired * 1000 > currentDate) {
            if (userNames) {
                setIsLoading(false);
                let lastUrl = JSON.parse(localStorage.getItem(userUrl));

                if (lastUrl === '/' || !lastUrl) {
                    history('/Majura', { replace: true });
                }
                else {
                    history(lastUrl, { replace: true })
                }

                window.location.reload();
                ApiService.refreshToken();
            }
            else {
                setIsLogin(false);
                setIsLoading(false);
            }
        }
        else {
            setIsLogin(false);
            setIsLoading(false);
        }
    }

    function removeItemsByPattern(pattern) {
        const keys = Object.keys(localStorage);

        keys.forEach(key => {
            if (key.startsWith(pattern)) {
                localStorage.removeItem(key);
            }
        });
    }

    const handleModal = () => {
        if (isModal) {
            setIsModal(false);
            setUserName('');
            setPassword('');
            setIsPassword(false);
        }
        else {
            setIsModal(true);
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        let pointer = 0;
        let message = '';

        if (username !== '' && password !== '') {
            const loginPayload = {
                authentication:
                {
                    username: userName,
                    password: password
                }
            }
            const loginHeader = {
                headers: {
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            }

            let userUrl = '';
            await axios.post(urlAuth + 'Login', loginPayload, loginHeader)
                .then((res) => {
                    if (res.data.code === 200) {
                        const data = res.data.data;
                        let dataLogin = data.authentication;
                        let jobTypeId;
                        let currency = 'USD';
                        let parentCompanyId = 0;

                        if (dataLogin.companyIdPusat !== undefined) {
                            parentCompanyId = dataLogin.companyIdPusat;
                        }

                        if (dataLogin.jobTypeId === 0) {
                            jobTypeId = 1;
                        }
                        else {
                            jobTypeId = dataLogin.jobTypeId;
                        }

                        let decode = jwtDecode(dataLogin.token);
                        localStorage.setItem("page", JSON.stringify(1));
                        localStorage.setItem("rows", JSON.stringify(50));
                        localStorage.setItem("dataUser", JSON.stringify(data));
                        localStorage.setItem('dashboardMenu', JSON.stringify(0));
                        localStorage.setItem("isFixedPpn", JSON.stringify(false));
                        localStorage.setItem("pinStatus", JSON.stringify('false'));
                        localStorage.setItem("isInfossNo", JSON.stringify('false'));
                        localStorage.setItem("isInfossNoList", JSON.stringify('true'));
                        localStorage.setItem("jobTypeId", JSON.stringify(jobTypeId));
                        localStorage.setItem("bsPeriod", JSON.stringify(new Date()));
                        localStorage.setItem("token", JSON.stringify(dataLogin.token));
                        localStorage.setItem("sidebarStatus", JSON.stringify('false'));
                        localStorage.setItem("expiredToken", JSON.stringify(decode.exp));
                        localStorage.setItem("userId", JSON.stringify(dataLogin.userId));
                        localStorage.setItem("roleId", JSON.stringify(dataLogin.roleId));
                        localStorage.setItem("username", JSON.stringify(dataLogin.code));
                        localStorage.setItem("userCode", JSON.stringify(dataLogin.code));
                        localStorage.setItem("groupId", JSON.stringify(dataLogin.groupId));
                        localStorage.setItem('roleInt', JSON.stringify(dataLogin.roleCode));
                        localStorage.setItem("roleName", JSON.stringify(dataLogin.roleName));
                        localStorage.setItem("branchId", JSON.stringify(dataLogin.branchId));
                        localStorage.setItem("roleLevel", JSON.stringify(dataLogin.roleLevel));
                        localStorage.setItem("companyId", JSON.stringify(dataLogin.companyId));
                        localStorage.setItem("idleTimer", JSON.stringify(dataLogin.idleTimer));
                        localStorage.setItem("countryId", JSON.stringify(dataLogin.countryId));
                        localStorage.setItem("roleCode", JSON.stringify(dataLogin.department));
                        localStorage.setItem("employeeId", JSON.stringify(dataLogin.employeeId));
                        localStorage.setItem('parentCompanyId', JSON.stringify(parentCompanyId));
                        localStorage.setItem("branchName", JSON.stringify(dataLogin.branchName));
                        localStorage.setItem(dataLogin.code + "-isLogOut", JSON.stringify(false));
                        localStorage.setItem("companyName", JSON.stringify(dataLogin.companyName));
                        localStorage.setItem("countryName", JSON.stringify(dataLogin.countryName));
                        localStorage.setItem("refreshToken", JSON.stringify(dataLogin.refreshToken));

                        if (currency === 'USD') {
                            localStorage.setItem('currency', JSON.stringify('US-en'));
                            localStorage.setItem('formatCurrency', JSON.stringify('USD'));
                        }
                        else if (currency === 'IDR') {
                            localStorage.setItem('currency', JSON.stringify('ID-id'));
                            localStorage.setItem('formatCurrency', JSON.stringify('IDR'));
                        }

                        var dataNew = newData(data.user.userProfiles);
                        localStorage.setItem("dataProfile", JSON.stringify(dataNew));

                        var dataMenu = dataNew.filter(el => el.companyId === dataLogin.companyId && el.countryId === dataLogin.countryId && el.branchId === dataLogin.branchId && el.roleId === dataLogin.roleId);

                        if (dataMenu) {
                            dataMenu = dataMenu[0].permissions;
                        }

                        getDataMenu(dataMenu)

                        pointer = 1;
                        userUrl = dataLogin.code + '-lastUrl'
                    }
                    else {
                        message = res.data.message;
                    }
                }).then(() => {
                    if (pointer === 1) {
                        let params = [
                            { attr: 'companyId', value: localStorage.getItem('companyId') },
                            { attr: 'branchId', value: localStorage.getItem('branchId') },
                        ];

                        ApiService.IdentityPostByPage('regUserEntity/regUserEntity', 'PAGE', 1, 999, params, [])
                            .then((response) => {
                                if (response.data.code === 200) {
                                    let tempData = response.data.data.userEntity;

                                    var userList = []
                                    tempData.forEach((el) => {
                                        userList.push({
                                            id: el?.userId || 0,
                                            name: el?.userName || '',
                                        })
                                    })
                                    localStorage.setItem('userList', JSON.stringify(userList));
                                }

                                let lastUrl = JSON.parse(localStorage.getItem(userUrl));
                                if (lastUrl === '/' || !lastUrl || lastUrl === '/Majura') {
                                    let userNames = JSON.parse(localStorage.getItem("username"));
                                    removeItemsByPattern(userNames + '-Data');
                                    setIsLoading(false);
                                    history('/Majura', { replace: true });
                                }
                                else {
                                    setTimeout(() => {
                                        setIsLoading(false);
                                        history(lastUrl, { replace: true });
                                        localStorage.removeItem(userUrl)
                                        window.location.reload()
                                    }, 1250);
                                }
                                window.location.reload()
                            })
                            .catch(function (error) {
                                let lastUrl = JSON.parse(localStorage.getItem(userUrl));
                                if (lastUrl === '/' || !lastUrl) {
                                    setIsLoading(false);
                                    history('/Majura', { replace: true });
                                }
                                else {
                                    setTimeout(() => {
                                        setIsLoading(false);
                                        history(lastUrl, { replace: true });
                                        localStorage.removeItem(userUrl)
                                        window.location.reload()
                                    }, 1250);
                                }
                                window.location.reload()
                            })
                    }
                    else {
                        setIsLoading(false);
                        showToast({ type: 'error', message: message });
                    }
                })
                .catch(function (error) {
                    setIsLoading(false);
                    console.log(error)
                    showToast({ type: 'error', message: 'Wrong Username or Password!' });
                })
        }
        else {
            setIsLoading(false);
            showToast({ type: 'error', message: 'Please Input Username & Password' });
        }
    };

    const zoomAnimation = keyframes`
        0% {
            transform: scale(1);
        }
        25% {
            transform: scale(1.04);
        }
        50% {
            transform: scale(1.02);
        }
        75% {
            transform: scale(1.04);
        }
        100% {
            transform: scale(1);
        }
    `;

    return (
        <>
            <CustomToastContainer />
            {
                isLogin === true ?
                    <></>
                    :
                    <ThemeProvider theme={theme}>
                        <div style={{ overflowX: 'hidden', overflowY: 'hidden' }}>
                            <div
                                style={{
                                    width: '100vw',
                                    justifyContent: 'center',
                                    overflowX: 'hidden',
                                    overflowY: 'hidden'
                                }}
                            >
                                <Grid
                                    container
                                    component="main"
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        overflowX: 'hidden',
                                        overflowY: 'hidden'
                                    }}
                                >
                                    <CssBaseline />
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        sx={{
                                            position: 'relative',
                                            width: '100vw',
                                            height: '100vh',
                                            overflow: 'hidden',
                                            '&::before': {
                                                content: '""',
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                backgroundImage: `url(${backgroundLanding})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'cover',
                                                zIndex: -1,
                                                animation: `${zoomAnimation} 12.5s infinite ease-in-out`,
                                            },
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: '100%',
                                                height: '100vh',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    padding: '32px',
                                                    height: '100%',
                                                    alignItems: 'center',
                                                    justifyContent: 'center'
                                                }}
                                            >
                                                <Stack
                                                    direction={'row'}
                                                    spacing={'32px'}
                                                    sx={{
                                                        height: '100%',
                                                        width: '100%',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            width: '55%',
                                                            height: '100%',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                        }}
                                                        hidden={isXS || isSM ? true : false}
                                                    >
                                                        <Box
                                                            sx={{
                                                                gap: '40px',
                                                                width: 'inherit',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                flexDirection: 'column',
                                                                justifyContent: 'center',
                                                                position: 'relative',
                                                                borderRadius: '12px',
                                                                overflow: 'hidden',
                                                                boxShadow: '0px 30px 60px 0px rgba(0, 0, 0, 0.15)',
                                                                '&::before': {
                                                                    content: '""',
                                                                    position: 'absolute',
                                                                    top: 0,
                                                                    left: 0,
                                                                    right: 0,
                                                                    bottom: 0,
                                                                    borderRadius: '12px',
                                                                    padding: '2px',
                                                                    background: 'linear-gradient(208deg, #788A9F -0.04%, #9AC8FF 55.46%, #E8F9FF 100.07%)',
                                                                    'WebkitMask': 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                                                                    'WebkitMaskComposite': 'destination-out',
                                                                    maskComposite: 'exclude',
                                                                },
                                                            }}
                                                        >
                                                            <form
                                                                onSubmit={handleSubmit}
                                                                style={{
                                                                    padding: '32px',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                }}
                                                            >
                                                                <Stack
                                                                    direction={'column'}
                                                                    sx={{
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center'
                                                                    }}
                                                                >
                                                                    <Avatar
                                                                        src={majuraIconBlue}
                                                                        sx={{
                                                                            width: '15vw',
                                                                            height: 'fit-content',
                                                                        }}
                                                                        variant='square'
                                                                        alt='.'
                                                                    />

                                                                    <Box sx={{ width: '100%', position: 'relative', zIndex: 1 }}>
                                                                        <Typography
                                                                            style={{
                                                                                background: "-webkit-linear-gradient(220deg, #00F0FF -3.72%, #171717 109.9%)",
                                                                                'WebkitBackgroundClip': 'text',
                                                                                'WebkitTextFillColor': 'transparent',
                                                                                textAlign: 'left !important',
                                                                                fontFamily: 'Nunito',
                                                                                fontSize: '26px',
                                                                                fontStyle: 'normal',
                                                                                fontWeight: '600',
                                                                                lineHeight: '100%',
                                                                                letterSpacing: '0.3px',
                                                                                marginLeft: '10px',
                                                                                marginTop: '32px',
                                                                                cursor: 'default',
                                                                                userSelect: 'none',
                                                                            }}
                                                                        >
                                                                            Welcome Back
                                                                        </Typography>

                                                                        <Input
                                                                            formLabelProps={{
                                                                                sx: {
                                                                                    color: 'var(--Blue-Primary-1, #083A50)',
                                                                                    fontFamily: 'Nunito',
                                                                                    fontSize: isMD ? '1.25vh' : '1.75vh',
                                                                                    fontStyle: 'normal',
                                                                                    fontWeight: '700',
                                                                                    lineHeight: 'normal',
                                                                                    userSelect: 'none',
                                                                                }
                                                                            }}
                                                                            formControlProps={{
                                                                                sx: {
                                                                                    marginTop: '12px',
                                                                                    width: '100%',
                                                                                    padding: '1vh'
                                                                                }
                                                                            }}
                                                                            inputBaseProps={{
                                                                                sx: {
                                                                                    fontSize: isMD ? '1vh' : '1.5vh',
                                                                                    minHeight: isMD ? '2.5vh !important' : '4.5vh !important',
                                                                                    borderRadius: '1vh',
                                                                                    marginTop: '0.75vh !important',
                                                                                    padding: '1vh 3vh 1vh 2vh',
                                                                                    border: '0.15vh solid var(--Blue-Primary-1, #083A50)',
                                                                                    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.18) 0.01%, rgba(255, 255, 255, 0.18) 0.02%)',
                                                                                    '&.Mui-focused': {
                                                                                        display: 'flex',
                                                                                        justifyContent: 'flex-end',
                                                                                        alignItems: 'center',
                                                                                        border: '0.25vh solid var(--Blue-Primary-1, #083A50)',
                                                                                        boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.50)',
                                                                                    },
                                                                                    "& input::placeholder": {
                                                                                        fontSize: isMD ? '1vh' : '1.5vh',
                                                                                    }
                                                                                },
                                                                            }}
                                                                            label={'Username'}
                                                                            value={userName}
                                                                            placeholder='Enter your username'
                                                                            onChange={(e) => setUserName(e.target.value)}
                                                                        />

                                                                        <Input
                                                                            name="password"
                                                                            label={'Password'}
                                                                            placeholder='Enter your password'
                                                                            type={isPassword ? 'text' : 'password'}
                                                                            value={password}
                                                                            onChange={(e) => setPassword(e.target.value)}
                                                                            formControlProps={{
                                                                                sx: {
                                                                                    width: '100%',
                                                                                    padding: '1vh'
                                                                                }
                                                                            }}
                                                                            formLabelProps={{
                                                                                sx: {
                                                                                    color: 'var(--Blue-Primary-1, #083A50)',
                                                                                    fontFamily: 'Nunito',
                                                                                    fontSize: isMD ? '1.25vh' : '1.75vh',
                                                                                    fontStyle: 'normal',
                                                                                    fontWeight: '700',
                                                                                    lineHeight: 'normal',
                                                                                    userSelect: 'none',
                                                                                }
                                                                            }}
                                                                            inputBaseProps={{
                                                                                sx: {
                                                                                    minHeight: isMD ? '2.5vh !important' : '4.5vh !important',
                                                                                    fontSize: isMD ? '1vh' : '1.5vh',
                                                                                    border: '0.15vh solid var(--Blue-Primary-1, #083A50)',
                                                                                    borderRadius: '1vh',
                                                                                    marginTop: '0.75vh !important',
                                                                                    padding: '1vh 2vh',
                                                                                    background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.18) 0.01%, rgba(255, 255, 255, 0.18) 0.02%)',
                                                                                    '&.Mui-focused': {
                                                                                        display: 'flex',
                                                                                        justifyContent: 'flex-end',
                                                                                        alignItems: 'center',
                                                                                        border: '0.25vh solid var(--Blue-Primary-1, #083A50)',
                                                                                        boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.50)',
                                                                                    },
                                                                                    "& input::placeholder": {
                                                                                        fontSize: isMD ? '1vh' : '1.5vh',
                                                                                    }
                                                                                },
                                                                                endAdornment: (
                                                                                    <InputAdornment position="end">
                                                                                        <IconButton
                                                                                            onClick={() => setIsPassword(!isPassword)}
                                                                                            size='small'
                                                                                            sx={{ padding: '0px' }}
                                                                                        >
                                                                                            {
                                                                                                isPassword ?
                                                                                                    <Icon
                                                                                                        iconName={'eye-on'}
                                                                                                        svgIconProps={{
                                                                                                            sx: {
                                                                                                                width: '2.5vh',
                                                                                                                height: '2.5vh'
                                                                                                            }
                                                                                                        }}
                                                                                                    />
                                                                                                    :
                                                                                                    <Icon
                                                                                                        iconName={'eye-off'}
                                                                                                        svgIconProps={{
                                                                                                            sx: {
                                                                                                                width: '2.5vh',
                                                                                                                height: '2.5vh'
                                                                                                            }
                                                                                                        }}
                                                                                                    />
                                                                                            }
                                                                                        </IconButton>
                                                                                    </InputAdornment>
                                                                                )
                                                                            }}
                                                                        />

                                                                        <Link
                                                                            href=""
                                                                            underline="none"
                                                                            sx={{
                                                                                color: 'var(--Blue-Button-Primary-1, #0087C2)',
                                                                                textDecoration: 'underline',
                                                                                '&:hover': {
                                                                                    textDecoration: 'underline',
                                                                                    cursor: 'pointer',
                                                                                },
                                                                            }}
                                                                        >
                                                                            <Typography
                                                                                sx={{
                                                                                    color: 'inherit',
                                                                                    textAlign: 'right',
                                                                                    fontFamily: 'Nunito',
                                                                                    fontSize: '14px',
                                                                                    fontStyle: 'normal',
                                                                                    fontWeight: '600',
                                                                                    lineHeight: 'normal',
                                                                                    marginTop: '10px',
                                                                                    marginRight: '12px',
                                                                                    userSelect: 'none',
                                                                                }}
                                                                            >
                                                                                Forgot Password?
                                                                            </Typography>
                                                                        </Link>

                                                                        <div style={{ padding: '0vh 1vh', width: '100%' }}>
                                                                            <Button
                                                                                type='submit'
                                                                                variant="contained"
                                                                                fullWidth
                                                                                onClick={handleSubmit}
                                                                                sx={{
                                                                                    justifyContent: 'center',
                                                                                    alignItems: 'center',
                                                                                    height: isMD || isLG ? '4.25vh' : '5vh',
                                                                                    width: "100%",
                                                                                    marginTop: isMD ? '2.25vh !important' : '2.5vh !important',
                                                                                    borderRadius: '10px',
                                                                                    border: '1px solid var(--Linear-Line, #788A9F)',
                                                                                    background: 'var(--Blue-Linear-Logo, linear-gradient(90deg, #229ACE 0%, #005E92 100%))',
                                                                                    boxShadow: '0px 19.2px 19.2px 0px rgba(0, 0, 0, 0.30) inset',
                                                                                    '&:hover': {
                                                                                        boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.30)'
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <Typography
                                                                                    sx={{
                                                                                        justifyContent: 'center',
                                                                                        alignItems: 'center',
                                                                                        color: 'var(--Disable-Grey-1, #EAEAEA)',
                                                                                        textAlign: 'center',
                                                                                        fontFamily: 'Nunito',
                                                                                        fontSize: isMD ? '1.25vh' : '1.5vh',
                                                                                        fontStyle: 'normal',
                                                                                        fontWeight: '700',
                                                                                        lineHeight: '5vh',
                                                                                        userSelect: 'none',
                                                                                    }}
                                                                                >
                                                                                    Sign In
                                                                                </Typography>
                                                                            </Button>
                                                                        </div>

                                                                        <Stack direction={'column'} sx={{ alignItems: 'center', justifyContent: 'center' }}>
                                                                            <Typography
                                                                                sx={{
                                                                                    color: 'var(--Blue-Button-Primary-1, #171717)',
                                                                                    textAlign: 'center',
                                                                                    fontFamily: 'Nunito',
                                                                                    fontSize: '13.333px',
                                                                                    fontStyle: 'normal',
                                                                                    fontWeight: '400',
                                                                                    lineHeight: 'normal',
                                                                                    marginTop: '32px',
                                                                                    userSelect: 'none',
                                                                                }}
                                                                            >
                                                                                By using MAJURA, you are agreeing to our
                                                                            </Typography>

                                                                            <Stack direction={'row'} columnGap={'6px'} sx={{ alignItems: 'center', justifyContent: 'center' }}>
                                                                                <Link href=''>
                                                                                    <Typography
                                                                                        sx={{
                                                                                            color: 'var(--Blue-Button-Primary-1, #0087C2)',
                                                                                            fontFamily: 'Nunito',
                                                                                            fontSize: '13.333px',
                                                                                            justifyContent: 'center',
                                                                                            alignItems: 'center',
                                                                                            fontStyle: 'normal',
                                                                                            fontWeight: '700',
                                                                                            lineHeight: 'normal',
                                                                                            textDecorationLine: 'underline',
                                                                                            marginTop: '6px',
                                                                                            userSelect: 'none',
                                                                                        }}
                                                                                    >
                                                                                        Terms of Service
                                                                                    </Typography>
                                                                                </Link>


                                                                                <Typography
                                                                                    sx={{
                                                                                        color: 'var(--Blue-Button-Primary-1, #0087C2)',
                                                                                        fontFamily: 'Nunito',
                                                                                        fontSize: '13.333px',
                                                                                        justifyContent: 'center',
                                                                                        alignItems: 'center',
                                                                                        fontStyle: 'normal',
                                                                                        fontWeight: '700',
                                                                                        lineHeight: 'normal',
                                                                                        marginTop: '6px',
                                                                                        userSelect: 'none',
                                                                                    }}
                                                                                >
                                                                                    -
                                                                                </Typography>

                                                                                <Link href='/Privacy'>
                                                                                    <Typography
                                                                                        sx={{
                                                                                            color: 'var(--Blue-Button-Primary-1, #0087C2)',
                                                                                            fontFamily: 'Nunito',
                                                                                            fontSize: '13.333px',
                                                                                            justifyContent: 'center',
                                                                                            alignItems: 'center',
                                                                                            fontStyle: 'normal',
                                                                                            fontWeight: '700',
                                                                                            lineHeight: 'normal',
                                                                                            textDecorationLine: 'underline',
                                                                                            marginTop: '6px',
                                                                                            userSelect: 'none',
                                                                                        }}
                                                                                    >
                                                                                        Privacy Policy
                                                                                    </Typography>
                                                                                </Link>
                                                                            </Stack>
                                                                        </Stack>
                                                                    </Box>
                                                                </Stack>
                                                            </form>
                                                        </Box>
                                                    </div>

                                                    <div
                                                        style={{
                                                            height: '100%',
                                                            width: '45%',
                                                            backgroundImage: `
                                                                    linear-gradient(206deg, rgba(255, 255, 255, 0.15) 0%, rgba(219, 219, 219, 0.15) 100.02%), 
                                                                    url(${backgroundLandingShip})
                                                                `,
                                                            backgroundRepeat: 'no-repeat',
                                                            backgroundSize: 'cover',
                                                            backgroundPosition: 'center',
                                                            borderRadius: '12px',
                                                        }}
                                                    />
                                                </Stack>
                                            </Box>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </ThemeProvider>
            }

            {
                isLoading && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: 10000 }}
                        open={isLoading}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }

            {
                isModal && (
                    <ModalLogin
                        isModal={isModal}
                        handleClick={handleModal}
                        setIsModal={setIsModal}

                        isPassword={isPassword}
                        setIsPassword={setIsPassword}
                        userName={userName}
                        setUserName={setUserName}
                        password={password}
                        setPassword={setPassword}
                        handleSubmit={handleSubmit}
                        isLoading={isLoading}
                    />
                )
            }
        </>
    );
}
