import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Grid, Card, CardContent, Stack, Typography, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

import ApiService from '../../../../../services/ApiService';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';
import ModalMemorialDetail from '../Modals/ModalMemorialDetail';
import SelectDataMemorialCost from '../Selects/SelectDataMemorialCost';
import { Input, showToast } from '../../../../../components/ui';
import ModalCostMemorialDetail from '../Modals/ModalCostMemorialDetail';
import { convertToNominal } from '../../../../../utils/masks.jsx';
import { handleDataTable } from '../../../../../utils/reusableFunction.jsx';

const MemorialDetail = (props) => {
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F2FAFF',
            color: 'var(--Primary-One, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '14px',
            fontstyle: 'normal',
            fontWeight: '600',
            lineHeight: 'normal',
            padding: '8px 16px !important',
        },
    }));

    const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.body}`]: {
            // backgroundColor: '#FFFFFF',
            color: 'var(--Blue-Primary-1, #083A50)',
            fontFamily: 'Nunito',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: 'normal',
            padding: '0px 16px !important',
            height: '35px !important'
        },
    }));

    const selectedStyle = { bgcolor: '#CCCCCC', "&.MuiTableRow-hover:hover": { backgroundColor: "#CCCCCC" } };
    const deletedDetailStyle = { bgcolor: (theme) => theme.palette.text.disabled };
    const [selectedDetail, setSelectedDetail] = useState({});
    const [detailEdit, setDetailEdit] = useState(false);
    const [detailSequence, setDetailSequence] = useState(0);

    const [RefId, setRefId] = useState(0);
    const [RefNo, setRefNo] = useState('');
    const [ShipmentId, setShipmentId] = useState(0);
    const [ShipmentNo, setShipmentNo] = useState('');
    const [RefExRateId, setRefExRateId] = useState(0);
    const [RefRate, setRefRate] = useState(0);
    const [AccountCode, setAccountCode] = useState('');
    const [AccountName, setAccountName] = useState('');
    const [Description, setDescription] = useState('');
    const [RefDetailId, setRefDetailId] = useState(0);
    const [DCNote, setDCNote] = useState('D');
    const [AmountUSD, setAmountUSD] = useState(0);
    const [AmountIDR, setAmountIDR] = useState(0);
    const [isModal, setIsModal] = useState(false);
    const [titleModal, setTitleModal] = useState('');

    const [isModal2, setIsModal2] = useState(false);
    const [titleModal2, setTitleModal2] = useState('');

    const paramC = "memorial/memorial";
    const [isLoadingC, setIsLoadingC] = useState(false);
    const [dataC, setDataC] = useState([]);
    const [dataMapC, setDataMapC] = useState([]);
    const [dataTableC, setDataTableC] = useState([]);
    const [columnDataC, setColumnDataC] = useState([]);
    const [SelectedDataC, setSelectedDataC] = useState({});
    const [totalRowsC, setTotalRowsC] = useState(50);
    const [MemorialCostDetail, setMemorialCostDetail] = useState([]);

    const [isModal3, setIsModal3] = useState(false);

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
        }
        else {
            setIsModal(false);
        }
    };

    const handleClick2 = () => {
        if (isModal2 === false) {
            setIsModal2(true);
            getDataCost(1, 50);
        }
        else {
            setIsModal2(false);
        }
    };

    const handleClick3 = () => {
        if (isModal3 === false) {
            setIsModal3(true);
        }
        else {
            setIsModal3(false);
        }
    }

    const ShowAddMemorialDetail = () => {
        let check = 0;
        if (props.Type === 3 && props.costMemorialRef === '') {
            check = 1;
        }

        if (check === 0) {
            if (props.checkInput() === true) {
                setDetailEdit(false);
                setTitleModal('Memorial Detail');
                setRefExRateId(props.ExRateId);
                setRefRate(props.Rate);
                if (props.MemorialDetailList.length > 0) {
                    let data = props.MemorialDetailList.reduce((prev, current) => (prev && prev.sequence > current.sequence) ? prev : current);
                    setDetailSequence(data.sequence);
                }
                else {
                    setDetailSequence(0);
                }
                handleClick();
            }
        }
        else {
            showToast({ type: 'error', message: 'Please Select The Memorial Cost First!' });
        }
    };

    const ShowEditMemorialDetail = () => {
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (selectedDetail.rowStatus !== 'DEL') {
                setDetailEdit(true);
                setTitleModal('Memorial Detail');

                setRefId(selectedDetail.refId);
                setRefNo(selectedDetail.refNo);
                setShipmentId(selectedDetail.refShipmentId);
                setShipmentNo(selectedDetail.refShipmentNo);
                setRefExRateId(selectedDetail.exRateId);
                setRefRate(selectedDetail.refRate);
                setRefDetailId(selectedDetail.refDetailId);

                setAccountCode(selectedDetail.coaCode);
                setAccountName(selectedDetail.coaName);
                setDescription(selectedDetail.description);
                setDCNote(selectedDetail.dcNote);
                setAmountUSD(selectedDetail.amountUSD);
                setAmountIDR(selectedDetail.amountIDR);

                handleClick();
            }
            else {
                showToast({ type: 'error', message: 'Data Has Been Deleted!' });
            }
        }
    };

    const DeleteMemorialDetail = () => {
        if (!selectedDetail.sequence) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            let tempSequence = selectedDetail.sequence;

            const deleteFunction = (data) => {
                return data.sequence !== tempSequence;
            }

            const result = props.MemorialDetailList.filter(deleteFunction);

            if (result.length > 0) {
                tempSequence = 0;

                result.forEach((el) => {
                    tempSequence += 1;
                    el.sequence = tempSequence;
                })

                setDetailSequence(tempSequence);
            }
            else {
                setDetailSequence(0);
            }

            props.setMemorialDetailList(result);
            props.CalculateTotal(result);

            setSelectedDetail({});
        }
    }

    const AddCostMemorial = () => {
        if (props.CustomerId !== 0) {
            setTitleModal2('List Cost Memorial');
            handleClick2();
        }
        else {
            showToast({ type: 'error', message: 'Please Select Customer First!' });
        }
    }

    const getDataCost = (pageNumber, pageSize, filters) => {
        setIsLoadingC(true);
        let params = [];
        if (props.Type === 3) {
            params = [
                ...params,
                { 'attr': 'isCost', 'value': '1' },
                { 'attr': 'verifyAcc', 'value': 'true' },
                { 'attr': 'customerTo', 'value': '' + props.CustomerToInt },
                { 'attr': 'memorialTypeId', 'value': '2' },
                { 'attr': 'customerId', 'value': '' + props.CustomerId },
            ];
            ApiService.FinAccPostByPage(paramC, 'SEARCH', pageNumber, pageSize, params, filters)
                .then((response) => {
                    if (response.data.code === 200) {
                        setSelectedDataC({})
                        setDataC(response.data.data.memorial);

                        let temp = response.data.data.memorial
                        let indexed = temp.map((el, index) => {
                            let indexedTemp = {
                                ...el,
                                index
                            }
                            return indexedTemp
                        })

                        setDataMapC(indexed)
                        handleDataTable(indexed, response.data.data.columns.headerColumns, setDataTableC, 'id');
                        setColumnDataC(response.data.data.columns.headerColumns)
                        setTotalRowsC(response.data.totalRowCount)

                        setIsLoadingC(false)
                    }
                    setIsLoadingC(false)
                })
                .catch(function (error) {
                    setIsLoadingC(false)
                    console.error('error saat fetch', error)
                    showToast({ type: 'error', message: error.toString() });
                })
        }
    };

    const handleDoubleClick = () => {
        ShowEditMemorialDetail();
    }

    return (
        <div>
            <form>
                <Box>
                    <Box>
                        <Grid container item spacing={2} direction="row">
                            <Grid item xs={10}>
                                <Typography
                                    sx={{
                                        color: 'var(--Blue-Primary-1, #083A50)',
                                        fontFamily: 'Nunito',
                                        fontSize: '18px',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: 'normal',
                                    }}
                                >
                                    Memorial Detail
                                </Typography>
                            </Grid>
                        </Grid>

                        <div className='mt-2'>
                            <Grid hidden={props.isCostSettle}>
                                <Box width='35%' className='mt-2 mb-4'>
                                    <Card
                                        component="div"
                                        className='p-1'
                                        style={{
                                            display: 'flex',
                                            padding: '16px 32px',
                                            flexDirection: 'column',
                                            borderRadius: '12px',
                                            background: '#FAFAFA',
                                            marginTop: '16px'
                                        }}
                                    >
                                        <CardContent>
                                            <Typography
                                                sx={{
                                                    color: 'var(--Blue-Primary-1, #083A50)',
                                                    fontFamily: 'Nunito',
                                                    fontSize: '16px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '700',
                                                    lineHeight: 'normal',
                                                }}
                                            >
                                                Cost Memorial
                                            </Typography>

                                            <Input
                                                label='Memorial No'
                                                value={props.costMemorialNo}
                                                disabled
                                                fullWidth
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                        marginTop: '16px !important'
                                                    }
                                                }}
                                            />

                                            <Input
                                                label='Reference'
                                                value={props.costMemorialRef}
                                                disabled
                                                fullWidth
                                                formControlProps={{
                                                    sx: {
                                                        width: '100%',
                                                        marginTop: '16px !important'
                                                    }
                                                }}
                                            />

                                            <Stack direction={'row'} sx={{ marginTop: '24px', columnGap: '10px' }} className='text-end' display={'flex'} justifyContent={'flex-end'} hidden={props.isEdit}>
                                                {ButtonComponent.AddButton(AddCostMemorial, 'Cost Memorial')}
                                                {ButtonComponent.DetailButton(handleClick3, 'Detail')}
                                            </Stack>
                                        </CardContent>
                                    </Card>
                                </Box>
                            </Grid>
                        </div>

                        <TableContainer sx={{ maxHeight: 235, marginTop: '8px', background: '#FFF', borderRadius: '12px' }}>
                            <Table sx={{ minWidth: 650 }} stickyHeader>
                                <TableHead
                                    sx={{
                                        '& th:first-of-type': {
                                            borderRadius: '12px 0 0'
                                        },
                                        '& th:last-child': {
                                            borderRadius: '0 12px 0 0'
                                        }
                                    }}
                                >
                                    <TableRow>
                                        <StyledTableCell sx={{ textAlign: 'center', fontWeight: 'bold', }}>Account Code</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'left', fontWeight: 'bold', }}>Description</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'center', fontWeight: 'bold', }}>DC Note</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'right', fontWeight: 'bold', }}>Amount USD</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'right', fontWeight: 'bold', }}>Amount IDR</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'center', fontWeight: 'bold', }}>Ref. No</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'center', fontWeight: 'bold', }}>Ref. Shipment</StyledTableCell>
                                        <StyledTableCell sx={{ textAlign: 'right', fontWeight: 'bold', }}>Ref. Rate</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        props.MemorialDetailList.length > 0 ?
                                            props.MemorialDetailList.map((el) => {
                                                return (
                                                    <TableRow
                                                        key={el.sequence}
                                                        onClick={() => setSelectedDetail(el)}
                                                        onDoubleClick={() => handleDoubleClick()}
                                                        sx={selectedDetail.sequence === el.sequence ? selectedStyle : el.rowStatus === 'DEL' ? deletedDetailStyle : {}}
                                                        hover={true}
                                                    >
                                                        <StyledTableBodyCell sx={{ textAlign: 'center', }}>
                                                            <Tooltip title={el.coaName}>
                                                                <span>{el.coaCode}</span>
                                                            </Tooltip>
                                                        </StyledTableBodyCell>
                                                        <StyledTableBodyCell>{el.description}</StyledTableBodyCell>
                                                        <StyledTableBodyCell sx={{ textAlign: 'center', }}>{el.dcNote === 'D' ? 'Debit' : 'Credit'}</StyledTableBodyCell>
                                                        <StyledTableBodyCell sx={{ textAlign: 'right', }}>{convertToNominal(el.amountUSD)}</StyledTableBodyCell>
                                                        <StyledTableBodyCell sx={{ textAlign: 'right', }}>{convertToNominal(el.amountIDR)}</StyledTableBodyCell>
                                                        <StyledTableBodyCell sx={{ textAlign: 'center', }}>{el.refNo !== '' ? el.refNo : '-'}</StyledTableBodyCell>
                                                        <StyledTableBodyCell sx={{ textAlign: 'center', }}>{el.refShipmentNo !== '' ? el.refShipmentNo : '-'}</StyledTableBodyCell>
                                                        <StyledTableBodyCell sx={{ textAlign: 'right', }}>{convertToNominal(el.refRate)}</StyledTableBodyCell>
                                                    </TableRow>
                                                )
                                            })
                                            :
                                            <TableRow sx={{ backgroundColor: '#FFFFFF' }}>
                                                <StyledTableBodyCell colSpan='100%' sx={{ textAlign: 'center' }}>
                                                    <Typography
                                                        sx={{
                                                            color: 'var(--Primary-One, #083A50)',
                                                            fontFamily: 'Nunito',
                                                            fontSize: '12px',
                                                            fontstyle: 'normal',
                                                            fontWeight: '500',
                                                            lineHeight: 'normal'
                                                        }}
                                                    >
                                                        Data Empty
                                                    </Typography>
                                                </StyledTableBodyCell>
                                            </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <div className='mt-3'>
                            {
                                CustomizeButtonComponent.AddEditDeleteTabsButton(ShowAddMemorialDetail, ShowEditMemorialDetail, DeleteMemorialDetail, props.verifyAcc)
                            }
                        </div>
                    </Box>

                    <Grid
                        container
                        spacing={'24px'}
                    >
                        <Grid
                            item
                            xl={4}
                            lg={4}
                            sm={4}
                            xs={12}
                        >
                            <Input
                                label='Total Debit USD'
                                value={props.TotalDebitUSD}
                                textAlign='right'
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />

                            <Input
                                label='Total Credit USD'
                                value={props.TotalCreditUSD}
                                textAlign='right'
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />
                        </Grid>

                        <Grid
                            item
                            xl={4}
                            lg={4}
                            sm={4}
                            xs={12}
                        >
                            <Input
                                label='Total Debit IDR'
                                value={props.TotalDebitIDR}
                                textAlign='right'
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />

                            <Input
                                label='Total Credit IDR'
                                value={props.TotalCreditIDR}
                                textAlign='right'
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />
                        </Grid>

                        <Grid
                            item
                            xl={4}
                            lg={4}
                            sm={4}
                            xs={12}
                        >
                            <Input
                                label='Total Debit In IDR'
                                value={props.TotalDebitInIDR}
                                textAlign='right'
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />

                            <Input
                                label='Total Credit In IDR'
                                value={props.TotalCreditInIDR}
                                textAlign='right'
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />

                            <Input
                                label={props.LabelDifference}
                                value={props.TotalDifference}
                                textAlign='right'
                                disabled
                                fullWidth
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </form>

            {
                isModal && (
                    <ModalMemorialDetail
                        isModal={isModal}
                        setIsModal={setIsModal}
                        handleClick={handleClick}
                        detailEdit={detailEdit}
                        setDetailEdit={setDetailEdit}
                        setSelectedDetail={setSelectedDetail}
                        titleModal={titleModal}
                        selectedDetail={selectedDetail}
                        setIsLoadingBackDrop={props.setIsLoadingBackDrop}
                        detailSequence={detailSequence}
                        setDetailSequence={setDetailSequence}
                        RefId={RefId}
                        setRefId={setRefId}
                        Type={props.Type}
                        RefNo={RefNo}
                        setRefNo={setRefNo}
                        ShipmentId={ShipmentId}
                        setShipmentId={setShipmentId}
                        ShipmentNo={ShipmentNo}
                        setShipmentNo={setShipmentNo}
                        RefExRateId={RefExRateId}
                        setRefExRateId={setRefExRateId}
                        RefRate={RefRate}
                        setRefRate={setRefRate}
                        AccountCode={AccountCode}
                        setAccountCode={setAccountCode}
                        AccountName={AccountName}
                        setAccountName={setAccountName}
                        Description={Description}
                        setDescription={setDescription}
                        DCNote={DCNote}
                        setDCNote={setDCNote}
                        AmountUSD={AmountUSD}
                        setAmountUSD={setAmountUSD}
                        AmountIDR={AmountIDR}
                        setAmountIDR={setAmountIDR}
                        RefDetailId={RefDetailId}
                        setRefDetailId={setRefDetailId}
                        MemorialDetailList={props.MemorialDetailList}
                        setMemorialDetailList={props.setMemorialDetailList}
                        SettlementTo={props.SettlementTo}
                        Rate={props.Rate}
                        ExRateId={props.ExRateId}
                        PrincipleBy={props.PrincipleBy}
                        CalculateTotal={props.CalculateTotal}
                        verifyAcc={props.verifyAcc}
                        CustomerTo={props.CustomerTo}
                        CustomerId={props.CustomerId}
                    />
                )
            }

            {
                isModal3 && (
                    <ModalCostMemorialDetail
                        isModal={isModal3}
                        setIsModal={setIsModal3}
                        handleClick={handleClick3}
                        MemorialCostDetail={MemorialCostDetail}
                    />
                )
            }

            {
                isModal2 && (
                    <SelectDataMemorialCost
                        isModal={isModal2}
                        setIsModal={setIsModal2}
                        handleClick={handleClick2}
                        titleModal={titleModal2}
                        getData={getDataCost}
                        isLoading={isLoadingC}
                        totalRows={totalRowsC}
                        setTotalRows={setTotalRowsC}
                        SelectedData={SelectedDataC}
                        setSelectedData={setSelectedDataC}
                        data={dataC}
                        columnData={columnDataC}
                        dataMap={dataMapC}
                        dataTable={dataTableC}
                        Type={props.Type}
                        setCostMemorialRef={props.setCostMemorialRef}
                        setCostMemorialNo={props.setCostMemorialNo}
                        setCostMemorialId={props.setCostMemorialId}
                        setMemorialCostDetail={setMemorialCostDetail}
                        setMemorialDetailList={props.setMemorialDetailList}
                        setRefId={setRefId}
                        setRefNo={setRefNo}
                        setShipmentId={setShipmentId}
                        setShipmentNo={setShipmentNo}
                        setRefExRateId={setRefExRateId}
                        setRefRate={setRefRate}
                        CalculateTotal={props.CalculateTotal}
                    />
                )
            }
        </div>
    )
}

export default MemorialDetail;