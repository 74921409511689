import { Grid } from '@mui/material';
import React, { useState } from 'react';

import LoadingSpinner from '../../../../../components/Components/LoadingSpinner';
import TableX from '../../../../../components/Tables/TableX';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';
import { Icon, Paginate, PopUp, showToast } from '../../../../../components/ui';
import ApiService from '../../../../../services/ApiService';
import { convertToNominal } from '../../../../../utils/masks.jsx';

const ModalPv = (props) => {
    const [rowsCount, setRowsCount] = useState(50)
    const [numPage, setNumPage] = useState(1)
    const [filter, setFilter] = useState([])
    const [filterBy, setFilterBy] = useState([])
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([])
    const [goTo, setGoTo] = useState(1);

    const selectData = () => {
        if (props.SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' })
        }
        else {
            props.setIsLoadingBackDrop(true);
            props.setPvId(props.SelectedData.id);
            props.setPvNo(props.SelectedData.pvNo2);
            props.setPvRef(props.SelectedData.reference);
            props.setNameWP(props.SelectedData.contactName);

            let params = [
                { 'attr': 'contactId', 'value': '' + props.SelectedData.contactId },
                { 'attr': 'contactTypeId', 'value': '' + props.SelectedData.customerToId }
            ];

            ApiService.DynamicPostByPage("regContact/regContact", 'SEARCH', 1, 1, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        props.setNpwp(response.data.data.contact[0].npwp);

                        ApiService.FinAccPostById('paymentVoucher/paymentVoucher', props.SelectedData.pvId)
                            .then((response) => {
                                if (response.data.code === 200) {
                                    let count = 0;
                                    let totalPph23 = 0;
                                    let jumlahPenghasilan = 0;
                                    let data = response.data.data.paymentVoucher;
                                    let detailData = response.data.data.paymentVoucher.paymentVoucherDetails;

                                    props.setPph23Amount(data.pPh23Amount);

                                    detailData.forEach(element => {
                                        if (element.isPPh23 === true) {
                                            count = parseFloat(count) + parseFloat(1);
                                            totalPph23 = parseFloat(totalPph23) + parseFloat(element.amountIDR) + parseFloat(element.amountTaxIDR) + parseFloat((parseFloat(element.amountUSD) + parseFloat(element.amountTaxUSD)) * parseFloat(element.refRate));
                                        }
                                    });

                                    props.setTotalPph23Amount(totalPph23);

                                    jumlahPenghasilan = parseFloat(totalPph23) / 15;
                                    jumlahPenghasilan = convertToNominal(jumlahPenghasilan);

                                    props.setTarif(15);
                                    props.setJasaLain('');
                                    props.setJasaLainId(0);
                                    props.setJasaLainAmount(0);
                                    props.setTarifTambahan(count);
                                    props.setJenisPenghasilan('D');
                                    props.setJumlahPenghasilan(jumlahPenghasilan);
                                    props.setPPhPotongan(convertToNominal(data.pPh23Amount));

                                    props.setIsLoadingBackDrop(false);
                                }
                                else {
                                    props.setIsLoadingBackDrop(false);
                                }
                            })
                            .catch(function (error) {
                                props.setIsLoadingBackDrop(false);
                                console.error('error saat fetch', error);
                                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
                            });
                    }
                })
                .catch(function (error) {
                    props.setIsLoadingBackDrop(false);
                    console.error('error saat fetch', error);
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
                })

            props.handleClick('search')
        }
    }

    const handleReset = () => {
        setFilter([])
        setFilterBy([])
        props.getData(1, 50)
        setGoTo(1);
        setSortArr([]);
        setStateX({});
    }

    function CellDoubleClick(row) {
        selectData()
    }

    function Cancel() {
        props.handleClick('search');
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        props.getData(value, rowsCount, filter, sortArr);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        props.getData(1, value, filter, sortArr);
    }

    const mainContainer = (
        <>
            <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
                <div>
                    {
                        ButtonComponent.RefreshButton(
                            sortArr,
                            rowsCount,
                            props.columnData,
                            setGoTo,
                            handleReset,
                            setFilterBy,
                            filterBy,
                            setFilter,
                            setNumPage,
                            setRowsCount,
                            props.getData,
                            stateX,
                            setStateX
                        )
                    }
                </div>

                <div
                    className="rounded-10 table-responsive"
                    style={{
                        maxHeight: '375px',
                        marginTop: '16px',
                        padding: '2px 16px',
                        borderRadius: '12px',
                        background: '#F2F2F2'
                    }}
                >
                    {
                        props.isLoading ?
                            (
                                <LoadingSpinner />
                            )
                            :
                            (
                                <>
                                    <div className="mt-1">
                                        <TableX
                                            uniqueKey={'id'}
                                            goTo={goTo}
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={props.getData}
                                            columnData={props.columnData}
                                            dataMap={props.dataMap}
                                            dataTable={props.dataTable}
                                            SelectedData={props.SelectedData}
                                            setSelectedData={props.setSelectedData}
                                            setFilter={setFilter}
                                            setFilterBy={setFilterBy}
                                            CellDoubleClick={CellDoubleClick}
                                            stateX={stateX}
                                            setStateX={setStateX}
                                            sortArr={sortArr}
                                            setSortArr={setSortArr}
                                            setGoTo={setGoTo}
                                        />
                                    </div>
                                </>
                            )
                    }
                </div>

                <div style={{ marginTop: '16px' }}>
                    <Paginate
                        page={numPage}
                        limit={rowsCount}
                        totalData={props.totalRows}
                        onChange={onChangePaging}
                        onChangeLimit={onChangeLimit}
                    />
                </div>
            </Grid>
        </>
    );

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'approve-doc'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={'Select ' + props.titleModal}
                isOpen={props.isModal}
                onClose={Cancel}
                draggable={false}
                shrinkable={false}
                disableScrollLock={false}
                maxWidth={'lg'}
                fullWidth={true}
            >
                <div style={{ padding: '0px 6px' }}>
                    {mainContainer}

                    <div style={{ padding: '24px 0px 0px' }}>
                        {CustomizeButtonComponent.SelectModalButton(selectData)}
                    </div>
                </div>
            </PopUp>
        </>
    )
}

export default ModalPv