import { Grid } from '@mui/material';
import React from 'react';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';

const ButtonListReceiptPPh23 = ({
  ReloadData,
  DataRegUserAccess,
  Add,
  Edit,
  DeleteData,
  DropDownTitle,
  PrintData,
  Adjustment,
  HandleStatus,
  handleApprove,
  handleUnApprove,
  isApprove,
  isUnApprove,
  Approve,
  UnApprove
}) => {

  const renderButton = (data) => {
    switch (data.accessCode) {
      case 'CRE':
        return ButtonComponent.AddButton(Add, 'New');
      case 'REI':
        return ButtonComponent.EditButton(Edit, 'Data');
      case 'DEL':
        return DropDownTitle !== 'Deleted' ? ButtonComponent.DeleteButton(DeleteData, 'Data') : null;
      case 'PRI':
        return DropDownTitle !== 'Deleted' ? ButtonComponent.PrintButton(PrintData, 'Rekap') : null;
      case 'APP':
        return (DropDownTitle === 'Active' || DropDownTitle === 'All') ?
          ButtonComponent.ApproveButton(handleApprove, 'Approve') : null;
      case 'UPP':
        return (DropDownTitle === 'All' || DropDownTitle === 'Posted') ?
          ButtonComponent.UnApproveButton(handleUnApprove, 'Un Approve') : null;
      default:
        return null;
    }
  };

  return (
    <div className="row">
      <div className='col-12'>
        <Grid container item spacing={1} direction="row" style={{ 'maxWidth': '100vw', margin: '0px', columnGap: '8px', rowGap: '10px' }}>
          {isApprove === false && isUnApprove === false ? (
            <>
              {ButtonComponent.ReloadButton(ReloadData)}

              {DataRegUserAccess.map((data) => (
                <React.Fragment key={data.accessId}>
                  {renderButton(data)}
                </React.Fragment>
              ))}

              {ButtonComponent.AdjustButton(Adjustment, 'Adjustment')}
              {CustomizeButtonComponent.DropDownButtonStatus(HandleStatus, DropDownTitle)}
            </>
          ) : isApprove === true ? (
            <>
              {ButtonComponent.BackButton(handleApprove)}
              {ButtonComponent.ReloadButton(ReloadData)}
              {DataRegUserAccess.map(data => (
                <React.Fragment key={data.accessId}>
                  {data.accessCode === 'APP' && ButtonComponent.ApproveButton(Approve, 'Approve Data')}
                </React.Fragment>
              ))}
            </>
          ) : (
            <>
              {ButtonComponent.BackButton(handleUnApprove)}
              {ButtonComponent.ReloadButton(ReloadData)}
              {DataRegUserAccess.map(data => (
                <React.Fragment key={data.accessId}>
                  {data.accessCode === 'UPP' && ButtonComponent.UnApproveButton(UnApprove, 'Un Approve Data')}
                </React.Fragment>
              ))}
            </>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default ButtonListReceiptPPh23;