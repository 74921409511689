import { Grid, MenuItem, IconButton, InputAdornment } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ApiService from '../../../../services/ApiService';
import CustomizeButtonComponent from '../../../../components/Buttons/CustomizeButtonComponent';
import { useState } from 'react';
import SelectDataBooking from './SelectDataBooking';
import { CustomDatePicker, Icon, Input, PopUp, Selection, showToast } from '../../../../components/ui';
import { handleDataTable } from '../../../../utils/reusableFunction';

const ModalCopyBC = (props) => {
    const [jobTypeId, setJobTypeId] = useState(localStorage.getItem('jobTypeId') ? JSON.parse(localStorage.getItem('jobTypeId')) : 0);
    const [bookingNo, setBookingNo] = useState('');
    const [bookingDate, setBookingDate] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [picName, setPicName] = useState('');
    const [receiptName, setReceiptName] = useState('');
    const [deliveryPlace, setDeliveryPlace] = useState('');

    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);

    const paramB = 'bookingConfirmation/bookingConfirmation';
    const [isLoadingB, setIsLoadingB] = useState(false);
    const [dataB, setDataB] = useState([]);
    const [dataMapB, setDataMapB] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [columnDataB, setColumnDataB] = useState([]);
    const [SelectedDataB, setSelectedDataB] = useState({});
    const [totalRowsB, setTotalRowsB] = useState(50);
    const historyB = useNavigate();
    let countErrB = 1;

    const handleSelect = (e, c) => {
        setJobTypeId(e.target.value);
        localStorage.setItem('jobTypeId', JSON.stringify(e.target.value));
    };

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataBooking(1, 50, []);
        }
        else {
            setIsModal(false);
        }
    };

    const handleClose = () => {
        emptyState();
        props.handleClick();
    };

    const emptyState = () => {
        props.setCopyId(0);
        setBookingNo('');
        setBookingDate('');
        setCustomerName('');
        setPicName('');
        setReceiptName('');
        setDeliveryPlace('');
    };

    function handleSave() {
        props.CopyData();
        emptyState();
        props.handleClick();
    }

    function searchBooking(data) {
        if (data === 'search') {
            setTitleModal('Booking Confirmation');
            handleClick();
        }
        else if (data === 'delete') {
            emptyState();
        }
    }

    const getDataBooking = (pageNumber, pageSize, filters, orderBy) => {
        setIsLoadingB(true);
        let params = [];
        let filter;
        params = [...params,
        { 'attr': 'jobTypeId', 'value': '' + jobTypeId },
        ];
        filter = [...filters, { 'field': 'rowStatus', 'data': 'ACT' }]
        ApiService.OperationDynamicPostByPage(paramB, 'SEACBC', pageNumber, pageSize, params, filter, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataB({});
                    setDataB(response.data.data.bookingConfirmation);

                    let temp = response.data.data.bookingConfirmation;
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp;
                    })

                    setDataMapB(indexed);
                    handleDataTable(indexed, response.data.data.columns.headerColumns, setDataTable, 'id');
                    setColumnDataB(response.data.data.columns.headerColumns);
                    setTotalRowsB(response.data.totalRowCount);

                    setIsLoadingB(false);
                }
                setIsLoadingB(false);
            })
            .catch(function (error) {
                setIsLoadingB(false);
                countErrB++
                if (countErrB < 3) {
                    getDataBooking(1, 50, []);
                }
                else {
                    countErrB = 0;
                    ApiService.logOut();
                    historyB('/');
                }

                showToast({ type: 'error', message: error.toString() });
            })
    };

    const mainContainer = (
        <div style={{ padding: '12px 8px 0px' }}>
            <Selection
                id='0'
                label={'Job Type'}
                value={jobTypeId}
                fullWidth={true}
                placeholder={'Select Job Type'}
                onChange={(e, c) => { handleSelect(e, c); emptyState() }}
            >
                {
                    props.dataJobType.map((data) => (
                        <MenuItem sx={{ fontSize: '12px', 'textAlign': 'center' }} id={data.id} key={data.code} value={data.id}>
                            {data.name}
                        </MenuItem>
                    ))
                }
            </Selection>

            <Grid
                container
                spacing={'32px'}
            >
                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <Input
                        label="Booking Confirmation No."
                        variant="standard"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        value={bookingNo}
                        type='text'
                        disabled
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                        inputBaseProps={{
                            readOnly: true,
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => searchBooking('delete')}
                                        hidden={bookingNo ? false : true}
                                    >
                                        <Icon
                                            iconName={'close'}
                                            svgIconProps={{
                                                fontSize: 'small',
                                                htmlColor: '#0087C2',
                                                sx: {
                                                    height: '12px',
                                                    width: '12px'
                                                }
                                            }}
                                        />
                                    </IconButton>

                                    <IconButton
                                        onClick={() => searchBooking('search')}
                                    >
                                        <Icon
                                            iconName={'search'}
                                            svgIconProps={{
                                                fontSize: 'small',
                                                htmlColor: '#0087C2',
                                                sx: {
                                                    height: '12px',
                                                    width: '12px'
                                                }
                                            }}
                                        />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    />

                    <Input
                        label={'Customer Name'}
                        value={customerName}
                        disabled
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                    />

                    <Input
                        label={'Receipt Place'}
                        value={receiptName}
                        disabled
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                    />
                </Grid>

                <Grid
                    item
                    xl={6}
                    lg={6}
                    sm={6}
                    xs={12}
                >
                    <CustomDatePicker
                        label={'Booking Date'}
                        value={bookingDate}
                        disabled
                        type={'date'}
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                    />

                    <Input
                        label={'PIC Name'}
                        value={picName}
                        disabled
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                    />

                    <Input
                        label={'Delivery Place'}
                        value={deliveryPlace}
                        disabled
                        formControlProps={{
                            sx: {
                                width: '100%',
                                marginTop: '16px'
                            }
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    )

    const buttonComponent = (
        <div style={{ padding: '6px 24px 20px' }}>
            {CustomizeButtonComponent.SelectModalButton(handleSave)}
        </div>
    )

    return (
        <>
            <PopUp
                id='modal-id'
                icon={
                    <Icon
                        iconName={'copy'}
                        svgIconProps={{
                            htmlColor: '#0087C2',
                            sx: {
                                height: '24px',
                                width: '24px'
                            }
                        }}
                    />
                }
                title={'Copy ' + props.titleModal}
                isOpen={props.isModal}
                onClose={handleClose}
                draggable={false}
                shrinkable={false}
                disableScrollLock={false}
                maxWidth={'lg'}
                fullWidth={true}
                children={mainContainer}
                actions={buttonComponent}
            />

            {
                isModal && (
                    <SelectDataBooking
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}

                        getData={getDataBooking}
                        isLoading={isLoadingB}
                        setIsLoading={setIsLoadingB}
                        data={dataB}
                        setData={setDataB}
                        dataMap={dataMapB}
                        dataTable={dataTable}
                        setDataMap={setDataMapB}
                        columnData={columnDataB}
                        setColumnData={setColumnDataB}
                        SelectedData={SelectedDataB}
                        setSelectedData={setSelectedDataB}
                        totalRows={totalRowsB}
                        setTotalRows={setTotalRowsB}

                        setBookingId={props.setCopyId}
                        setBookingNo={setBookingNo}
                        setBookingDate={setBookingDate}
                        setCustomerName={setCustomerName}
                        setPicName={setPicName}
                        setReceiptName={setReceiptName}
                        setDeliveryPlace={setDeliveryPlace}
                    />
                )
            }
        </>
    )
}

export default ModalCopyBC;