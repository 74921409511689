import { Box, Typography } from "@mui/material";

const MemorialStatus = ({ documentStatus }) => {
    const statusStyles = {
        APPROVED: {
            borderColor: '#007330',
            backgroundColor: '#B1FF81',
            color: '#007330',
        },
        UNAPPROVED: {
            borderColor: '#585858',
            backgroundColor: 'rgba(88, 88, 88, 0.15)',
            color: '#585858',
        },
    };

    const { borderColor, backgroundColor, color } = statusStyles[documentStatus] || statusStyles.DEFAULT;

    return (
        <Box
            sx={{
                display: 'flex',
                height: '24px',
                padding: '0px 16px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
                borderRadius: '8px',
                border: `1.5px solid ${borderColor}`,
                backgroundColor,
            }}
        >
            <Typography
                sx={{
                    fontFamily: 'Nunito',
                    fontSize: '12px',
                    fontWeight: '800',
                    lineHeight: 'normal',
                    color,
                    userSelect: 'none',
                    cursor: 'default',
                }}
            >
                {documentStatus}
            </Typography>
        </Box>
    );
}

export default MemorialStatus;