import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import ApiService, { branchId, companyId, countryId, parentCompanyId, userCode, userId } from '../../../../services/ApiService';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import TableX from '../../../../components/Tables/TableX';
import { Grid, Box } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import ButtonComponent from '../../../../components/Buttons/ButtonComponent';
import ButtonGeneralInvoice from './Buttons/ButtonGeneralInvoice';
import ModalDelete from '../../../../components/Modals/ModalDelete';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import ModalConfirmation from '../../../../components/Modals/ModalConfirmation';
import freezeContainer from '../../../../Styles/freezeContainer';
import HeaderInfo from '../../../../components/Components/HeaderInfo';
import CustomToastContainer, { Paginate, showToast } from '../../../../components/ui';
import ModalPrintRecap from './Modals/ModalPrintRecap';
import { setDateFormat, updateDateFormat } from '../../../../utils/date';
import { newWindowConfig } from '../../../../utils/masks.jsx';
import { handleDataTable, PrintExcel, PrintPDF } from '../../../../utils/reusableFunction.jsx';

const GeneralInvoiceList = forwardRef((props, ref) => {
    const btnPageRef = useRef(null)
    const btnTableRef = useRef(null)

    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const history = useNavigate();
    const paramGeneralInvoice = 'generalInvoice/generalInvoice';
    const paramRegUserAccess = 'regUserAccess/regUserAccess';
    const paramRegClosingPeriod = 'regClosingPeriod/regClosingPeriod';
    const [rowsCount, setRowsCount] = useState(50);
    const [numPage, setNumPage] = useState(0);
    const [typeDelete, setTypeDelete] = useState('H');
    const [isPrinting, setIsPrinting] = useState(false);
    const [isCustomNote, setIsCustomNote] = useState(false);
    const [CustomNote, setCustomNote] = useState('');
    const [DeleteRemarks, setDeleteRemarks] = useState('');

    const [dataMap, setDataMap] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [columnData, setColumnData] = useState([]);
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);

    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [DataRegUserAccess, setDataRegUserAccess] = useState([]);

    const [isModalDelete, setIsModalDelete] = useState(false);
    const [titleModalDelete, setTitleModalDelete] = useState('');

    const [isModalConfirmation, setIsModalConfirmation] = useState(false);
    const [titleModalConfirmation, setTitleModalConfirmation] = useState('');
    const [titleConfirmation, setTitleConfirmation] = useState('');
    const [dataSelected, setDataSelected] = useState({});

    const [isModalRecap, setIsModalRecap] = useState(false);
    const [Period, setPeriod] = useState(new Date());
    const [isDraft, setIsDraft] = useState(true);
    const [isDueDate, setIsDueDate] = useState(false);
    const [dueDate, setDueDate] = useState('');
    const [Printing, setPrinting] = useState(0);

    const [DropDownTitle, setDropDownTitle] = useState('All');

    const [DataEditType, setDataEditType] = useState([
        { id: 1, code: 'H', name: 'Hard -> No Faktur tidak bisa dipakai lagi' },
        { id: 2, code: 'S', name: 'Soft -> No Faktur masih bisa dipakai lagi' },
        { id: 3, code: 'P', name: 'Pengganti -> No Faktur akan menjadi Faktur Pengganti' }
    ]);

    const BreadcrumbsItems = [
        { label: 'Majura', link: '/Majura', style: '' },
        { label: 'General Invoice', link: '/Majura/GeneralInvoice', style: { color: '#0087C2', fontWeight: '700' } },
    ];

    const dropDownList = [
        { label: 'All', icon: 'search-doc' },
        { label: 'Active', icon: 'documents' },
        { label: 'Posted', icon: 'posted' },
        { label: 'Deleted', icon: 'status-deleted' },
    ]

    useImperativeHandle(ref, () => ({
        localSave() {

        }
    }));

    useEffect(() => {
        document.title = 'General Invoice - ' + JSON.parse(localStorage.getItem('branchName'));
        getDataAccess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = (pageNumber, pageSize, filters, orderBy, data) => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        }
        else {
            setIsLoadingBackDrop(true);
        }

        let filter = [];
        if (data) {
            if (data === 'All') {
                filter = [...filters]
            }
            else if (data === 'Deleted') {
                filter = [...filters, { 'field': 'rowStatus', 'data': 'DEL' }];
            }
            else if (data === 'Active') {
                filter = [
                    ...filters,
                    { 'field': 'rowStatus', 'data': 'ACT' },
                    // { 'field': 'isPosted', 'data': 'false' },
                ];
            }
            else if (data === 'Posted') {
                filter = [
                    ...filters,
                    { 'field': 'rowStatus', 'data': 'ACT' },
                    // { 'field': 'isPosted', 'data': 'true' }
                ];
            }
        }
        else {
            if (DropDownTitle === 'All') {
                filter = [...filters]
            }
            else if (DropDownTitle === 'Deleted') {
                filter = [...filters, { 'field': 'rowStatus', 'data': 'DEL' }];
            }
            else if (DropDownTitle === 'Active') {
                filter = [
                    ...filters,
                    { 'field': 'rowStatus', 'data': 'ACT' },
                    // { 'field': 'isPosted', 'data': 'false' },
                ];
            }
            else if (DropDownTitle === 'Posted') {
                filter = [
                    ...filters,
                    { 'field': 'rowStatus', 'data': 'ACT' },
                    // { 'field': 'isPosted', 'data': 'true' }
                ];
            }
        }

        ApiService.ListFinAccPostByPage(paramGeneralInvoice, 'PAGE', pageNumber, pageSize, [], filter, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({})

                    let temp = response.data.data.generalInvoice
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMap(indexed)
                    handleDataTable(indexed, response.data.data.columns.headerColumns, setDataTable, 'id');
                    setColumnData(response.data.data.columns.headerColumns)
                    setTotalRows(response.data.totalRowCount)

                    if (pageNumber === 0) {
                        setNumPage(Math.ceil(response.data.totalRowCount / pageSize));
                    }

                    if (isLoadingBackDrop === false) {
                        setIsLoading(false);
                    }
                    else {
                        setIsLoadingBackDrop(false);
                    }
                }
                else if (response.data.code === 500) {
                    showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });
                    if (isLoadingBackDrop === false) {
                        setIsLoading(false)
                    }
                    else {
                        setIsLoadingBackDrop(false);
                    }
                }

                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                else {
                    setIsLoadingBackDrop(false);
                }
            })
            .catch(function (error) {
                if (isLoadingBackDrop === false) {
                    setIsLoading(false);
                }
                else {
                    setIsLoadingBackDrop(false);
                }

                console.error('error saat fetch', error)
                showToast({ type: 'error', message: error.toString() });
            })
    }

    const getDataAccess = () => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        }
        else {
            setIsLoadingBackDrop(true);
        }

        let params = [];
        let pId = 0;
        let listMenu = JSON.parse(localStorage.getItem("dataMenuCode")) || [];
        let hasAccess = false

        const indexMenu = listMenu.findIndex((elm) => elm.code === 'FGIN')
        if (indexMenu !== -1) {
            pId = listMenu[indexMenu].id
            hasAccess = true
        }

        if (hasAccess) {
            params = [
                ...params,
                { 'attr': 'permissionId', 'value': '' + pId },
                { 'attr': 'userId', 'value': '' + JSON.parse(localStorage.getItem("userId")) },
                { 'attr': 'roleId', 'value': '' + JSON.parse(localStorage.getItem("roleId")) }
            ]
            ApiService.IdentityPostByPage(paramRegUserAccess, 'PAGE', 1, 100, params, [])
                .then((response) => {
                    if (response.data.code === 200) {
                        setDataRegUserAccess(response.data.data.userAccess);

                        getData(0, 50, []);
                    }
                    else if (response.data.code === 500) {
                        showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message });
                        if (isLoadingBackDrop === false) {
                            setIsLoading(false)
                        }
                        else {
                            setIsLoadingBackDrop(false);
                        }
                    }
                })
                .catch(function (error) {
                    console.error('error saat fetch', error)
                    history('/Majura');
                })
        }
        else {
            history('/Majura');
        }
    }

    const handleClick = () => {
        if (isModalDelete === false) {
            setIsModalDelete(true);
            if (SelectedData.sfpId !== 0) {
                setIsPrinting(true);
            }
            else {
                setIsPrinting(false);
            }
            setDataSelected(SelectedData);
        }
        else {
            setIsModalDelete(false);
        }
    }

    const handleReset = () => {
        emptyState();
        getData(0, 50, []);
    };

    const handleClickConfirmation = () => {
        if (isModalConfirmation === false) {
            setIsModalConfirmation(true);
            setDataSelected(SelectedData);
        }
        else {
            setIsModalConfirmation(false);
            setIsDueDate(false);
            setIsDraft(true);
        }
    }

    function ConfirmationGI() {
        if (titleConfirmation === 'Print') {
            if (isDraft) {
                let reportLink = 'Transactions/Finance/GeneralInvoiceReport/GeneralInvoiceReport/PrintGeneralInvoice?Id=' + dataSelected.id + '&isDraft=true';
                PrintPDF(setIsLoadingBackDrop, 'General Invoice', reportLink);
            }
            else {
                Print();
            }
        }
        else if (titleConfirmation === 'Request Delete') {
            DeleteGI(DeleteRemarks);
        }
        handleClickConfirmation();
    }

    const AddGI = () => {
        window.open('/Majura/GeneralInvoice/Create', newWindowConfig);
    }

    const EditGI = () => {
        if (!SelectedData?.id) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            window.open('/Majura/GeneralInvoice/' + SelectedData.id, newWindowConfig);
        }
    }

    const ReloadData = () => {
        showToast({ type: 'success', message: 'Reload Data' });
        emptyState();
        getData(0, 50, []);
    }

    const DeleteData = () => {
        if (!SelectedData?.id) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (SelectedData.rowStatus === 'ACT') {
                if (SelectedData.sfpId === 0) {
                    setTitleModalDelete('General Invoice');
                    handleClick();
                }
                else {
                    if (parentCompanyId !== 0 && SelectedData.statusDC !== 'C' && SelectedData.sfpId !== 0) {
                        setTypeDelete('R');
                        setDataEditType([{ id: 4, code: 'R', name: 'Request Delete' },]);
                    }
                    else {
                        setDataEditType([
                            { id: 1, code: 'H', name: 'Hard -> No Faktur tidak bisa dipakai lagi' },
                            { id: 2, code: 'S', name: 'Soft -> No Faktur masih bisa dipakai lagi' },
                            { id: 3, code: 'P', name: 'Pengganti -> No Faktur akan menjadi Faktur Pengganti' }
                        ]);
                        setTypeDelete('H');
                    }

                    setTitleModalDelete('General Invoice');
                    handleClick();
                }
            }
            else {
                showToast({ type: 'error', message: 'Data Already Deleted!' });
            }
        }
    }

    const CheckRequestGenInv = (DeleteRemarks = '') => {
        setIsLoadingBackDrop(true);
        handleClick();

        if (typeDelete === 'R') {
            let params = [
                { 'attr': 'invoiceId', 'value': '' + SelectedData.id },
            ]
            ApiService.OperationCustomPostByPage('invoice/invoice/PostByPageInvReqDel?', 'PAGE', 1, 1, params)
                .then((response) => {
                    if (response.data.code === 200) {
                        let data = response.data.data.invoiceReqDels;

                        if (data.length > 0 && data[0].approvedStatusCode === 'DCL') {
                            setDeleteRemarks(DeleteRemarks);
                            setTitleConfirmation('Request Delete');
                            setTitleModalConfirmation('General Invoice');
                            setIsCustomNote(true);
                            setCustomNote('Are You Sure Want To Request Delete This Invoice? This General Invoice Already Being Denied Before Because "' + data[0].approvedRemarks + '"!');
                            handleClickConfirmation();
                        }
                        else {
                            DeleteGI(DeleteRemarks);
                        }
                    }
                    else {
                        setIsLoadingBackDrop(false);
                        showToast({ type: 'error', message: 'Request Delete Failed!, ' + response.data.message });
                        setIsModalDelete(false);
                    }
                })
                .catch(function (error) {
                    setIsLoadingBackDrop(false);
                    showToast({ type: 'error', message: 'Request Delete Failed!, ' + error });
                    setIsModalDelete(false);
                })
        }
        else {
            DeleteGI(DeleteRemarks);
        }
    }

    const DeleteGI = (DeleteRemarks) => {
        setDataEditType([
            { id: 1, code: 'H', name: 'Hard -> No Faktur tidak bisa dipakai lagi' },
            { id: 2, code: 'S', name: 'Soft -> No Faktur masih bisa dipakai lagi' },
            { id: 3, code: 'P', name: 'Pengganti -> No Faktur akan menjadi Faktur Pengganti' }
        ]);

        var oldData = {};
        let deleteTypes = 0;
        let rowStatus = 'DEL';

        if (dataSelected.sfpId !== 0) {
            if (typeDelete === 'H') {
                deleteTypes = 1;
            }
            else if (typeDelete === 'S') {
                deleteTypes = 2;
            }
            else if (typeDelete === 'P') {
                deleteTypes = 3;
            }

            if (typeDelete === 'R') {
                rowStatus = 'ACT';
            }
        }

        ApiService.FinAccPostById(paramGeneralInvoice, dataSelected.id)
            .then((response) => {
                if (response.status === 200) {
                    let dateExRate = updateDateFormat(response.data.data.generalInvoice.exRateDate, true);
                    let dateVerify = updateDateFormat(response.data.data.generalInvoice.verifyAccDate, true);
                    let datePrint = updateDateFormat(response.data.data.generalInvoice.printedOn, true);
                    let paidDateUSD = updateDateFormat(response.data.data.generalInvoice.datePaidUSD, true);
                    let paidDateIDR = updateDateFormat(response.data.data.generalInvoice.datePaidIDR, true);
                    let dateBadDebt = updateDateFormat(response.data.data.generalInvoice.badDebtOn, true);
                    let dateDeleted = updateDateFormat(response.data.data.generalInvoice.deletedOn, true);
                    let dateCreated = updateDateFormat(response.data.data.generalInvoice.createdOn, true);

                    oldData = {
                        "generalInvoice": {
                            "countryId": countryId,
                            "companyId": companyId,
                            "branchId": branchId,
                            "userId": userId,
                            "userCode": userCode,

                            "rowStatus": rowStatus,
                            'noInfoss': response.data.data.generalInvoice.infossNo,
                            'createdOn': dateCreated,
                            "id": response.data.data.generalInvoice.id,
                            "ticketId": response.data.data.generalInvoice.ticketId,
                            "reference": response.data.data.generalInvoice.reference,
                            "statusDC": response.data.data.generalInvoice.statusDC,
                            "generalTo": response.data.data.generalInvoice.generalTo,
                            "GeneralNo2": response.data.data.generalInvoice.generalNo2,
                            "customerId": response.data.data.generalInvoice.customerId,
                            "customerCode": response.data.data.generalInvoice.customerCode,
                            "customerName": response.data.data.generalInvoice.customerName,
                            "isStampDuty": response.data.data.generalInvoice.isStampDuty,
                            "stampDutyAmount": response.data.data.generalInvoice.stampDutyAmount,
                            "cityId": response.data.data.generalInvoice.cityId,
                            "cityInt": response.data.data.generalInvoice.cityInt,
                            "cityName": response.data.data.generalInvoice.cityName,
                            "address": response.data.data.generalInvoice.address,
                            "creditTerm": response.data.data.generalInvoice.creditTerm,
                            "exRateId": response.data.data.generalInvoice.exRateId,
                            "rate": response.data.data.generalInvoice.rate,
                            "exRateDate": dateExRate,
                            "verifyAcc": response.data.data.generalInvoice.verifyAcc,
                            "verifyAccDate": dateVerify,
                            "printing": response.data.data.generalInvoice.printing,
                            "printedOn": datePrint,
                            "totalUSD": response.data.data.generalInvoice.totalUSD,
                            "totalIDR": response.data.data.generalInvoice.totalIDR,
                            "shipmentOrderId": response.data.data.generalInvoice.shipmentOrderId,
                            "shipmentNo": response.data.data.generalInvoice.shipmentNo,
                            "paidUSD": response.data.data.generalInvoice.paidUSD,
                            "datePaidUSD": paidDateUSD,
                            "paidIDR": response.data.data.generalInvoice.paidIDR,
                            "datePaidIDR": paidDateIDR,
                            "badDebt": response.data.data.generalInvoice.badDebt,
                            "badDebtOn": dateBadDebt,
                            "period": response.data.data.generalInvoice.period,
                            "yearPeriod": response.data.data.generalInvoice.yearPeriod,
                            "saveOR": response.data.data.generalInvoice.saveOR,
                            "deleteType": deleteTypes,
                            "deleted": true,
                            "deletedRemarks": DeleteRemarks,
                            "deletedOn": dateDeleted,
                            "jobOwnerId": response.data.data.generalInvoice.jobOwnerId,
                            "sfpId": response.data.data.generalInvoice.sfpId,
                            "sfpNoFormat": response.data.data.generalInvoice.sfpNoFormat,
                            "sfpDetailId": response.data.data.generalInvoice.sfpDetailId,
                            "uniqueKeySFP": response.data.data.generalInvoice.uniqueKeySFP,
                            "uniqueKeyInvoice": response.data.data.generalInvoice.uniqueKeyInvoice,
                            "isUseFakturPajak": response.data.data.generalInvoice.isUseFakturPajak,
                            "vatId": response.data.data.generalInvoice.vatId,
                            "npwp": response.data.data.generalInvoice.npwp,
                            "alamatNPWP": response.data.data.generalInvoice.alamatNPWP,
                            "totalVatIdr": response.data.data.generalInvoice.totalVatIdr,
                            "totalVatUsd": response.data.data.generalInvoice.totalVatUsd,
                            "sfpReference": response.data.data.generalInvoice.sfpReference,
                            "internalRef": response.data.data.generalInvoice.internalRef,
                        },
                        "generalInvoiceDetails": response.data.data.generalInvoice.generalInvoiceDetails,
                    };

                    ApiService.EditFinAcc(paramGeneralInvoice, oldData).then((res) => {
                        if (res.data.code === 200) {
                            setIsLoadingBackDrop(false);
                            getData(numPage, rowsCount, filter);
                            showToast({ type: 'success', message: oldData.generalInvoice.rowStatus === 'DEL' ? 'Delete Data Success!' : res.data.message });
                            setIsModalDelete(false);
                        }
                        else {
                            setIsLoadingBackDrop(false);
                            showToast({ type: 'error', message: 'Delete Data Failed!, ' + res.data.message });
                            setIsModalDelete(false);
                        }
                    })
                        .catch(function (error) {
                            setIsLoadingBackDrop(false);
                            showToast({ type: 'error', message: 'Delete Data Failed!, ' + error });
                            // console.error('error saat fetch', error);
                        });
                }
            })
            .catch(function (error) {
                setIsLoadingBackDrop(false);
                showToast({ type: 'error', message: 'Delete Data Failed!, ' + error });
                // console.error('error saat fetch', error);
            })
    }

    const OpenLogData = () => {
        if (!SelectedData?.id) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            setIsLoadingBackDrop(true);

            let date = new Date(SelectedData.printedOn);
            let formatDate = date.toLocaleDateString("en-US");
            ApiService.RegClosingPeriodPostById(paramRegClosingPeriod, formatDate)
                .then((response) => {
                    let data = response.data.data.closingPeriod;
                    history('/Majura/TransactionLogs/GeneralInvoice/' + SelectedData.id + '/' + data.period + '/' + data.yearPeriod);
                })
                .catch(function (error) {
                    setIsLoadingBackDrop(false);
                    // console.error('error saat fetch', error);
                })
        }
    }

    const PrintData = () => {
        if (!SelectedData?.id) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            if (SelectedData.rowStatus === "ACT") {
                setTitleConfirmation('Print')
                setTitleModalConfirmation('General Invoice');
                handleClickConfirmation();
                setPrinting(SelectedData.printing);
                if (SelectedData.printing > 0) {
                    setDateFormat(SelectedData?.printedOn, setDueDate);
                }
            }
            else {
                showToast({ type: 'error', message: 'Data Already Deleted!' });
            }
        }
    }

    function getDateNow() {
        setDateFormat(new Date(), setDueDate);
    };

    const Print = () => {
        setIsLoadingBackDrop(true);
        let data = {
            'generalInvoice': {
                'id': dataSelected.id,
                'printing': dataSelected.printing,
                'statusDC': dataSelected.statusDC,
                'vatId': dataSelected.vatId,
                'printedOn': dueDate,
            }
        };
        ApiService.FinAccUpdateStatusPrint(paramGeneralInvoice, dataSelected.id, data)
            .then((res) => {
                let message = '';
                message = res.data.message;
                if (res.data.code === 200) {
                    let reportLink = 'Transactions/Finance/GeneralInvoiceReport/GeneralInvoiceReport/PrintGeneralInvoice?Id=' + dataSelected.id + '&isDraft=false';
                    PrintPDF(setIsLoadingBackDrop, 'General Invoice', reportLink);

                    getData(numPage, rowsCount, filter);
                }
                else {
                    showToast({ type: 'error', message: message });
                }
            });
    };

    const handleClickRecap = () => {
        if (isModalRecap === false) {
            setIsModalRecap(true);
            getDefaultMonth();
        }
        else {
            setIsModalRecap(false);
        }
    }

    const getDefaultMonth = () => {
        const date = new Date();
        const year = date.getFullYear()
        let month = date.getMonth() + 1

        if (month < 10) {
            month = `0${month}`
        }

        setPeriod(`${year}-${month}`);
    }

    const PrintRecap = (type) => {
        setIsLoadingBackDrop(true);
        handleClickRecap();
        let params = [
            {
                "attr": "period",
                "value": '' + (new Date(Period).getMonth() + 1),
            },
            {
                "attr": "yearPeriod",
                "value": '' + new Date(Period).getFullYear(),
            }
        ]
        let reportLink = 'Transactions/Finance/GeneralInvoiceReport/GeneralInvoiceReport/PrintGeneralInvoiceRecap?typeReport=' + type;
        if (type !== 'xls') {
            PrintPDF(setIsLoadingBackDrop, 'Rekap General Invoice', reportLink, params);
        }
        else {
            PrintExcel(setIsLoadingBackDrop, 'Rekap General Invoice', reportLink, params);
        }
    }

    const emptyState = () => {
        setNumPage(0);
        setRowsCount(50);
        setGoTo(1);
        setSortArr([]);
        setStateX({});
        setTotalRows(50);
        setFilter([]);
        setFilterBy([]);
    };

    function CellDoubleClick(row) {
        EditGI();
    };

    function HandleStatus(data) {
        setDropDownTitle(data);
        getData(numPage, rowsCount, filter, sortArr, data);
    };

    const getContainerHeight = () => {
        const hTitle = 64
        const hHeader = 116
        const hPadding = 10 * 2
        const hBtnPage = btnPageRef?.current?.clientHeight || 28
        const hBtnTable = btnTableRef?.current?.clientHeight ? (btnTableRef?.current?.clientHeight + 60) : 44

        const totalHeight = hPadding + hTitle + hHeader + hBtnPage + hBtnTable

        return `${totalHeight}px`
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        getData(value, rowsCount, filter, sortArr);
    }

    const onChangeLimit = (value) => {
        setNumPage(0);
        setRowsCount(value);
        getData(0, value, filter);
    }

    const ButtonComponents = () => {
        return (
            <ButtonGeneralInvoice
                AddGI={AddGI}
                EditGI={EditGI}
                ReloadData={ReloadData}
                DeleteData={DeleteData}
                Print={PrintData}
                PrintRecap={handleClickRecap}
                DataRegUserAccess={DataRegUserAccess}
                OpenLogData={OpenLogData}
                DropDownTitle={DropDownTitle}
                HandleStatus={HandleStatus}
                DropDownList={dropDownList}
            />
        )
    }

    return (
        <Box className='w-100' sx={{ width: '100%', p: '24px 32px', ...freezeContainer }}>
            <CustomToastContainer />
            <Grid container spacing={0} direction="column">
                <HeaderInfo btnPageRef={btnPageRef}
                    title={'General Invoice'}
                    isJobType={false}
                    BreadcrumbsItems={BreadcrumbsItems}
                    ButtonComponents={ButtonComponents}
                />

                <Grid item xs={12} style={{ 'width': '100%', 'borderRadius': '5px' }}>
                    <div ref={btnTableRef}>
                        {
                            ButtonComponent.RefreshButton(
                                sortArr,
                                rowsCount,
                                columnData,
                                setGoTo,
                                handleReset,
                                setFilterBy,
                                filterBy,
                                setFilter,
                                setNumPage,
                                setRowsCount,
                                getData,
                                stateX,
                                setStateX
                            )
                        }
                    </div>

                    <div
                        className="rounded-10 table-responsive"
                        style={{
                            maxHeight: `calc(100vh - ${getContainerHeight()})`,
                            marginTop: '16px',
                            padding: '2px 16px',
                            borderRadius: '12px',
                            background: '#F2F2F2'
                        }}
                    >
                        {
                            isLoading ?
                                <LoadingSpinner />
                                :
                                <>
                                    <div className='mt-1'>
                                        <TableX
                                            uniqueKey={'id'}
                                            goTo={goTo}
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={getData}
                                            columnData={columnData}
                                            dataMap={dataMap}
                                            dataTable={dataTable}
                                            SelectedData={SelectedData}
                                            setSelectedData={setSelectedData}
                                            setFilter={setFilter}
                                            setFilterBy={setFilterBy}
                                            CellDoubleClick={CellDoubleClick}
                                            stateX={stateX}
                                            setStateX={setStateX}
                                            sortArr={sortArr}
                                            setSortArr={setSortArr}
                                            setGoTo={setGoTo}
                                        />
                                    </div>
                                </>
                        }
                    </div>

                    <div style={{ marginTop: '16px' }}>
                        <Paginate
                            page={numPage}
                            limit={rowsCount}
                            totalData={totalRows}
                            onChange={onChangePaging}
                            onChangeLimit={onChangeLimit}
                        />
                    </div>
                </Grid>
            </Grid>

            {
                isModalDelete && (
                    <ModalDelete
                        isModal={isModalDelete}
                        titleModal={titleModalDelete}
                        handleClick={handleClick}
                        DeleteData={CheckRequestGenInv}
                        typeDelete={typeDelete}
                        setTypeDelete={setTypeDelete}
                        isRemarks={isPrinting}
                        isPrinting={isPrinting}
                        DataEditType={DataEditType}
                    />
                )
            }

            {
                isModalConfirmation && (
                    <ModalConfirmation
                        isModal={isModalConfirmation}
                        titleModal={titleModalConfirmation}
                        title={titleConfirmation}
                        handleClick={handleClickConfirmation}
                        ConfirmationData={ConfirmationGI}
                        isDraft={isDraft}
                        setIsDraft={setIsDraft}

                        isDueDate={isDueDate}
                        setIsDueDate={setIsDueDate}
                        PrintCount={Printing}
                        dueDate={dueDate}
                        setDueDate={setDueDate}
                        getDateNow={getDateNow}
                        isCustomNote={isCustomNote}
                        CustomNote={CustomNote}
                    />
                )
            }

            {
                isModalRecap && (
                    <ModalPrintRecap
                        isModal={isModalRecap}
                        handleClick={handleClickRecap}
                        PrintRecap={PrintRecap}
                        Period={Period}
                        setPeriod={setPeriod}
                    />
                )
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </Box>
    )
})

export default GeneralInvoiceList