import { Grid } from '@mui/material';
import React, { useState } from 'react';

import LoadingSpinner from '../../../../../components/Components/LoadingSpinner';
import TableX from '../../../../../components/Tables/TableX';
import { branchId, companyId, countryId, userId, userCode } from '../../../../../services/ApiService';
import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import CustomizeButtonComponent from '../../../../../components/Buttons/CustomizeButtonComponent';
import { Icon, Paginate, PopUp, showToast } from '../../../../../components/ui';

const SelectDataTRAndRV = (props) => {
    const [rowsCount, setRowsCount] = useState(50)
    const [numPage, setNumPage] = useState(1)
    const [filter, setFilter] = useState([]);
    const [filterBy, setFilterBy] = useState([]);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([]);
    const [goTo, setGoTo] = useState(1);

    const emptyState = () => {
        setNumPage(1);
        setRowsCount(50);
        props.setTotalRows(50);
        setFilter([]);
        setFilterBy([]);
        setGoTo(1);
        setSortArr([]);
        setStateX({});
    };

    function Select() {
        if (props.SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        }
        else {
            let check = 0;
            let tempArr = [];

            if (props.titleModal === 'Temporary Receipt') {
                tempArr = props.TRIdList;

                tempArr.forEach(element => {
                    if (element === props.SelectedData.id) {
                        check = 1;
                    }
                });
            }
            else {
                tempArr = props.RVIdList;

                tempArr.forEach(element => {
                    if (element === props.SelectedData.id) {
                        check = 2;
                    }
                });
            }

            if (check === 0) {
                let Sequence = 1;
                let orId = 0;

                if (props.detailSequence > 0) {
                    Sequence = props.detailSequence + 1;
                }

                if (!props.officialReceiptId) {
                    orId = props.officialReceiptId;

                    if (orId === undefined) {
                        orId = 0;
                    }
                }
                else {
                    orId = 0;
                }

                if (props.titleModal === 'Temporary Receipt') {
                    let data = {
                        "countryId": countryId,
                        "companyId": companyId,
                        "branchId": branchId,
                        "userId": userId,
                        "userCode": userCode,

                        "rowStatus": "ACT",
                        "orId": orId,
                        "sequence": Sequence,
                        "orInvoiceId": props.checkSelectedInvoice.id,
                        "invoiceId": props.checkSelectedInvoice.invoiceId,
                        "invoiceNo": props.checkSelectedInvoice.invoiceNo,
                        "trId": props.SelectedData.trId,
                        "trDetailId": props.SelectedData.id,
                        "trNo": props.SelectedData.trNo,
                        "exRateId": props.SelectedData.exRateId,
                        "rate": props.SelectedData.rate,
                        "paymentUSD": props.SelectedData.totalPaymentUSD,
                        "paymentIDR": props.SelectedData.totalPaymentIDR,
                        "shipmentNo": props.checkSelectedInvoice.shipmentNo,
                        "shipmentOrderId": props.checkSelectedInvoice.shipmentId,
                        "idLama": 0,

                    };

                    props.setTRList([...props.TRList, data]);
                    props.setTRIdList([...props.TRIdList, data.trDetailId]);

                    const newArray = props.InvoiceList.slice();
                    newArray.forEach(el => {
                        if (el.invoiceId === props.checkSelectedInvoice.invoiceId) {
                            el.officialReceiptInvoiceTRs = [...props.TRList, data];
                        }
                    });
                    props.setInvoiceList(newArray);

                    props.calculateTotalTR(newArray);
                    props.calculateTotalDifferent(newArray);
                }
                else {
                    let data = {
                        "countryId": countryId,
                        "companyId": companyId,
                        "branchId": branchId,
                        "userId": userId,
                        "userCode": userCode,

                        "rowStatus": "ACT",
                        "orId": orId,
                        "sequence": Sequence,
                        "orInvoiceId": props.checkSelectedInvoice.id,
                        "invoiceId": props.checkSelectedInvoice.invoiceId,
                        "invoiceNo": props.checkSelectedInvoice.invoiceNo,
                        "rvId": props.SelectedData.rvId,
                        "rvNo": props.SelectedData.rvNo,
                        "exRateId": props.SelectedData.exRateId,
                        "rate": props.SelectedData.refRate,
                        "rvDetailId": props.SelectedData.id,
                        "paymentUSD": props.SelectedData.amountUSD,
                        "paymentIDR": props.SelectedData.amountIDR,
                        "idLama": 0,

                    };
                    props.setRVList([...props.RVList, data]);
                    props.setRVIdList([...props.RVIdList, data.rvDetailId]);

                    const newArray = props.InvoiceList.slice();
                    newArray.forEach(el => {
                        if (el.invoiceId === props.checkSelectedInvoice.invoiceId) {
                            el.officialReceiptInvoiceRVs = [...props.RVList, data];
                        }
                    });
                    props.setInvoiceList(newArray);

                    props.calculateTotalRV(newArray);
                    props.calculateTotalDifferent(newArray);
                }

                emptyState();
                props.handleClick();
            }
            else {
                if (check === 1) {
                    showToast({ type: 'error', message: 'Data Temporary Receipt Already Selected!' });
                }
                else {
                    showToast({ type: 'error', message: 'Data Receipt Voucher Already Selected!' });
                }
            }
        }
    };

    function Cancel() {
        emptyState();
        props.handleClick();
    };

    const handleReset = () => {
        emptyState();
        props.getData(1, 50);
    };

    function CellDoubleClick(row) {
        Select();
    }

    const onChangePaging = (value) => {
        setNumPage(value);
        props.getData(value, rowsCount, filter, sortArr);
    }

    const onChangeLimit = (value) => {
        setNumPage(1);
        setRowsCount(value);
        props.getData(1, value, filter, sortArr);
    }

    const mainContainer = (
        <>
            <Grid item xs={12} style={{ width: "100%", borderRadius: "5px" }}>
                <div>
                    {
                        ButtonComponent.RefreshButton(
                            sortArr,
                            rowsCount,
                            props.columnData,
                            setGoTo,
                            handleReset,
                            setFilterBy,
                            filterBy,
                            setFilter,
                            setNumPage,
                            setRowsCount,
                            props.getData,
                            stateX,
                            setStateX
                        )
                    }
                </div>

                <div
                    className="rounded-10 table-responsive"
                    style={{
                        maxHeight: '375px',
                        marginTop: '16px',
                        padding: '2px 16px',
                        borderRadius: '12px',
                        background: '#F2F2F2'
                    }}
                >
                    {
                        props.isLoading ?
                            (
                                <LoadingSpinner />
                            )
                            :
                            (
                                <>
                                    <div className="mt-1">
                                        <TableX 
                                            uniqueKey={'id'}
                                            goTo={goTo}
                                            rowsCount={rowsCount}
                                            setNumPage={setNumPage}
                                            setRowsCount={setRowsCount}
                                            getData={props.getData}
                                            columnData={props.columnData}
                                            dataMap={props.dataMap}
                                            dataTable={props.dataTable}
                                            SelectedData={props.SelectedData}
                                            setSelectedData={props.setSelectedData}
                                            setFilter={setFilter}
                                            setFilterBy={setFilterBy}
                                            CellDoubleClick={CellDoubleClick}
                                            stateX={stateX}
                                            setStateX={setStateX}
                                            sortArr={sortArr}
                                            setSortArr={setSortArr}
                                            setGoTo={setGoTo}
                                        />
                                    </div>
                                </>
                            )
                    }
                </div>

                <div style={{ marginTop: '16px' }}>
                    <Paginate
                        page={numPage}
                        limit={rowsCount}
                        totalData={props.totalRows}
                        onChange={onChangePaging}
                        onChangeLimit={onChangeLimit}
                    />
                </div>
            </Grid>
        </>
    );

    return (
        <PopUp
            id='modal-id'
            icon={
                <Icon
                    iconName={'approve-doc'}
                    svgIconProps={{
                        htmlColor: '#0087C2',
                        sx: {
                            height: '24px',
                            width: '24px'
                        }
                    }}
                />
            }
            title={'Select ' + props.titleModal}
            isOpen={props.isModal}
            onClose={Cancel}
            draggable={false}
            shrinkable={false}
            disableScrollLock={false}
            maxWidth={'lg'}
            fullWidth={true}
        >
            <div style={{ padding: '0px 6px' }}>
                {mainContainer}

                <div style={{ padding: '24px 0px 0px' }}>
                    {CustomizeButtonComponent.SelectModalButton(Select)}
                </div>
            </div>
        </PopUp>
    )
}

export default SelectDataTRAndRV;