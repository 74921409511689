import React, { useState, useEffect } from 'react';
import "jspdf-autotable";
import { useNavigate } from 'react-router-dom';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';

import TempListMaster from '../../TempListMaster';
import CreateEditProductCategory from './CreateEditProductCategory';
import ApiService, { userCode, username } from '../../../../services/ApiService';
import { Backdrop } from '@mui/material';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import CustomToastContainer, { showToast } from '../../../../components/ui';
import { handleDataTable } from '../../../../utils/reusableFunction';

const ProductCategoryList = forwardRef((props, ref) => {
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const [data, setData] = useState([]);
    const [titleModal, setTitleModal] = useState('');
    const title = 'Product Category'
    const [isModal, setIsModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dataMap, setDataMap] = useState([])
    const [dataTable, setDataTable] = useState([])
    const [columnData, setColumnData] = useState([])
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50)
    const history = useNavigate()
    const [rowsCount, setRowsCount] = useState(50)
    const [numPage, setNumPage] = useState(1)
    const [filter, setFilter] = useState([])
    const [filterBy, setFilterBy] = useState([])

    const param = "warehouse/ProductCategory/ProductCategory";
    const paramWarehouseLocation = "warehouse/warehouselocation/warehouselocation";

    const [id, setId] = useState(0);
    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [siteId, setSiteId] = useState("0");
    const [siteCode, setSiteCode] = useState("");
    const [siteName, setSiteName] = useState("");
    const [warehouseId, setWarehouseId] = useState("0");
    const [warehouseCode, setWarehouseCode] = useState("");
    const [warehouseName, setWarehouseName] = useState("");
    const [warehouseLocationTypeId, setWarehouseLocationTypeId] = useState("0");
    const [warehouseLocationTypeName, setWarehouseLocationTypeName] = useState("");
    const [locationX, setLocationX] = useState('0');
    const [locationY, setLocationY] = useState('0');
    const [locationZ, setLocationZ] = useState('0');
    const [isRack, setIsRack] = useState(false);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([])

    const [locationXList, setLocationXList] = useState(["0"]);
    const [locationYList, setLocationYList] = useState(["0"]);
    const [locationZList, setLocationZList] = useState(["0"]);

    let countErr = 0

    const dataLocal = JSON.parse(localStorage.getItem(username + "-DataProductCategory"));

    const simpanLocal = () => {
        if (isModal) {
            var data;
            data =
            {
                "productcategory": {
                    "rowStatus": "ACT",
                    "id": id,
                    "code": code,
                    "name": name,
                    "description": description,
                    "userCode": userCode,
                    "titleModal": titleModal,
                    "siteId": siteId,
                    "siteCode": siteCode,
                    "siteName": siteName,
                    "warehouseId": warehouseId,
                    "warehouseCode": warehouseCode,
                    "warehouseName": warehouseName,
                    "warehouseLocationTypeId": warehouseLocationTypeId,
                    "warehouseLocationTypeName": warehouseLocationTypeName,
                    "isRack": isRack,
                    "locationX": locationX,
                    "locationY": locationY,
                    "locationZ": locationZ
                }
            }
            localStorage.setItem(username + "-DataProductCategory", JSON.stringify(data));
        }
    }

    useImperativeHandle(ref, () => ({

        localSave() {
            simpanLocal()
        }

    }));

    const fetchDataLocal = () => {
        var result = dataLocal.productcategory;
        setIsModal(true);
        setCode(result.code);
        setName(result.name);
        setDescription(result.description);
        setId(result.id);
        setTitleModal(result.titleModal);
        setSiteId(result.siteId);
        setSiteCode(result.siteCode);
        setSiteName(result.siteName);
        setWarehouseId(result.warehouseId);
        setWarehouseCode(result.warehouseCode);
        setWarehouseName(result.warehouseName);
        setWarehouseLocationTypeId(result.warehouseLocationTyId);
        setWarehouseLocationTypeName(result.warehouseLocationTyName);
        setIsRack(result.isRack);
        setLocationX(result.locationX);
        setLocationY(result.locationY);
        setLocationZ(result.locationZ);
        localStorage.removeItem(username + '-DataProductCategory')
    }


    const toggleAdd = () => {
        setId(0);
        setCode('');
        setName('');
        setDescription('');
        setSiteId('');
        setSiteCode('');
        setSiteName('');
        setWarehouseId('');
        setWarehouseCode('');
        setWarehouseName('');
        setWarehouseLocationTypeId('');
        setWarehouseLocationTypeName('');
        setIsRack(false);
        setLocationX('');
        setLocationY('');
        setLocationZ('');
        handleClick();
        setTitleModal('Add');
    }

    const toggleEdit = () => {
        if (SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' })
        } else {
            setId(SelectedData.id);
            setCode(SelectedData.code);
            setName(SelectedData.name);
            setDescription(SelectedData.description);
            setSiteId(SelectedData.sitesId);
            setSiteCode(SelectedData.sitesCode);
            setSiteName(SelectedData.sitesName);
            setWarehouseId(SelectedData.warehousesId);
            setWarehouseCode(SelectedData.warehousesCode);
            setWarehouseName(SelectedData.warehousesName);
            setWarehouseLocationTypeId(SelectedData.warehouseLocationTypeId);
            setWarehouseLocationTypeName(SelectedData.warehouseLocationTypeName);
            setIsRack(SelectedData.isRack);
            if (!SelectedData.isRack) {
                setLocationX("0");
                setLocationY("0");
                setLocationZ("0");
            } else {
                setLocationX(SelectedData.warehouseLocationX);
                setLocationY(SelectedData.warehouseLocationY);
                setLocationZ(SelectedData.warehouseLocationZ);
            }
            setIsModal(true);
            setTitleModal('Edit');
        }
    }

    const searchLocation = (location) => {
        if (!warehouseId) {
            return
        }
        const params = [
            {
                "attr": "warehouseId",
                "value": warehouseId.toString(),
                "mathSign": "="
            }
        ]
        const LocationListX = ["0"]
        const LocationListY = ["0"]
        const LocationListZ = ["0"]
        ApiService.DynamicPostByPage(paramWarehouseLocation, "SEARCH", 1, 9999, params, [])
            .then((res) => {
                const result = res.data.data.warehouseLocation;
                if (location === "X") {
                    result.map((el) => {
                        if (!LocationListX.includes(el.locationX) && el.locationX !== "0") {
                            LocationListX.push(el.locationX);
                        }
                        return null
                    })
                    setLocationXList(LocationListX.sort());
                }

                if (location === "Y") {
                    result.map((el) => {
                        if (el.locationX === locationX) {
                            if (!LocationListY.includes(el.locationY) && el.locationY !== "0") {
                                LocationListY.push(el.locationY);
                            }
                        }
                        return null
                    })
                    setLocationYList(LocationListY.sort());
                }

                if (location === "Z") {
                    result.map((el) => {
                        if (el.locationX === locationX && el.locationY === locationY) {
                            if (!LocationListZ.includes(el.locationZ) && el.locationZ !== "0") {
                                LocationListZ.push(el.locationZ);
                            }
                        }
                        return null
                    })
                    setLocationZList(LocationListZ.sort());
                }
            })
    }

    let countRefresh = 0;

    useEffect(() => {
        if (countRefresh === 0) {
            ApiService.refreshToken();
            countRefresh++;
            if (dataLocal) {
                setTimeout(function () { fetchDataLocal(); }, 2000);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        searchLocation("X");
        searchLocation("Y");
        searchLocation("Z");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warehouseId])

    useEffect(() => {
        searchLocation("Y");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationX])

    useEffect(() => {
        searchLocation("Z");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locationY])

    const getData = (pageNumber, pageSize, filters, orderBy) => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        } else {
            setIsLoadingBackDrop(true);
        }
        ApiService.DynamicPostByPage(param, 'PAGE', pageNumber, pageSize, [], filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({})
                    setData(response.data.data.productCategory);

                    let temp = response.data.data.productCategory
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMap(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTable, 'id');
                    setColumnData(response.data.data.columns)
                    setTotalRows(response.data.totalRowCount)

                    setIsLoading(false)
                    setIsLoadingBackDrop(false)
                } else {
                    setIsLoading(false)
                    setIsLoadingBackDrop(false)
                }
                setIsLoading(false)
            })
            .catch(function (error) {
                setIsLoading(false)
                setIsLoadingBackDrop(false)
                console.error('error saat fetch', error)
                countErr++
                if (countErr < 3) {
                    getData(1, 50)
                } else {
                    ApiService.logOut()
                    history('/')
                }
            })
    }

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
        } else {
            setIsModal(false);
            setNumPage(1)
            setRowsCount(50)
            setId(0);
            setCode('');
            setName('');
            setDescription('');
            setSiteId('');
            setSiteCode('');
            setSiteName('');
            setWarehouseId('');
            setWarehouseCode('');
            setWarehouseName('');
            setWarehouseLocationTypeId('');
            setWarehouseLocationTypeName('');
            setIsRack(false);
            setLocationX('');
            setLocationY('');
            setLocationZ('');
        }
    };

    function CellDoubleClick(row) {
        toggleEdit()
    }

    return (
        <>
            <CustomToastContainer />
            <TempListMaster
                dataTable={dataTable}
                SelectedData={SelectedData}
                param={param}
                data={data}
                title={title}
                toggleAdd={toggleAdd}
                toggleEdit={toggleEdit}
                isLoading={isLoading}
                getData={getData}
                columnData={columnData}
                dataMap={dataMap}
                setSelectedData={setSelectedData}
                CellDoubleClick={CellDoubleClick}
                totalRows={totalRows}
                rowsCount={rowsCount}
                setRowsCount={setRowsCount}
                numPage={numPage}
                setNumPage={setNumPage}
                filter={filter}
                setFilter={setFilter}
                filterBy={filterBy}
                setFilterBy={setFilterBy}
                stateX={stateX}
                setStateX={setStateX}
                menuCode={'MWHPC'}
                sortArr={sortArr}
                setSortArr={setSortArr}
            />

            {
                isModal && (
                    <CreateEditProductCategory
                        isModal={isModal}
                        titleModal={titleModal}
                        handleClick={handleClick}
                        title={title}
                        param={param}

                        id={id}
                        code={code}
                        setCode={setCode}
                        name={name}
                        setName={setName}
                        description={description}
                        setDescription={setDescription}
                        siteId={siteId}
                        setSiteId={setSiteId}
                        siteCode={siteCode}
                        setSiteCode={setSiteCode}
                        siteName={siteName}
                        setSiteName={setSiteName}
                        warehouseId={warehouseId}
                        setWarehouseId={setWarehouseId}
                        warehouseCode={warehouseCode}
                        setWarehouseCode={setWarehouseCode}
                        warehouseName={warehouseName}
                        setWarehouseName={setWarehouseName}
                        warehouseLocationTypeId={warehouseLocationTypeId}
                        setWarehouseLocationTypeId={setWarehouseLocationTypeId}
                        warehouseLocationTypeName={warehouseLocationTypeName}
                        setWarehouseLocationTypeName={setWarehouseLocationTypeName}
                        isRack={isRack}
                        setIsRack={setIsRack}
                        locationX={locationX}
                        setLocationX={setLocationX}
                        locationY={locationY}
                        setLocationY={setLocationY}
                        locationZ={locationZ}
                        setLocationZ={setLocationZ}
                        locationXList={locationXList}
                        locationYList={locationYList}
                        locationZList={locationZList}
                        searchLocation={searchLocation}

                        getData={getData}
                    />
                )
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
})

export default ProductCategoryList