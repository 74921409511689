import React, { useState, useEffect } from 'react';
import "jspdf-autotable";
import ApiService from '../../../services/ApiService';
import TempListMaster from '../TempListMaster';
import CreateEditSeriFPDetail from './CreateEditSeriFPDetail';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import { Backdrop } from '@mui/material';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import CustomToastContainer, { showToast } from '../../../components/ui';
import { handleDataTable } from '../../../utils/reusableFunction';

const ListSeriFPDetail = forwardRef((props, ref) => {
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const [data, setData] = useState([]);
    const [titleModal, setTitleModal] = useState('');
    const title = 'Seri Faktur Pajak Detail'
    const [isModal, setIsModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dataMap, setDataMap] = useState([])
    const [dataTable, setDataTable] = useState([])
    const [columnData, setColumnData] = useState([])
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50)
    const [rowsCount, setRowsCount] = useState(50)
    const [numPage, setNumPage] = useState(1)
    const [filter, setFilter] = useState([])
    const [filterBy, setFilterBy] = useState([])

    const param = "seriFPDetail/seriFPDetail";
    const [id, setId] = useState(0);
    const [sfpHeader, setSfpHeader] = useState('');
    const [sfpNoFrom, setSfpNoFrom] = useState(0);
    const [sfpNoTo, setSfpNoTo] = useState(0);

    const [dataSeriFP, setDataSeriFP] = useState([])
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([])

    // const dataLocal = JSON.parse(localStorage.getItem(username + "-DataseriFPDetail"));

    // const simpanLocal = () => {
    //     if (isModal) {
    //         var data;
    //         data =
    //         {
    //             "seriFPDetail": {
    //                 "rowStatus": "ACT",
    //                 "seriFPId": id,
    //                 "countryId": countryId,
    //                 "companyId": companyId,
    //                 "branchId": branchId,
    //                 "sfpHeader": sfpHeader,
    //                 "sfpNoFrom": sfpNoFrom,
    //                 "sfpNoTo": sfpNoTo,
    //                 "userCode": userCode,
    //                 "titleModal": titleModal
    //             }
    //         }
    //         localStorage.setItem(username + "-DataseriFPDetail", JSON.stringify(data));
    //     }
    // }

    useImperativeHandle(ref, () => ({

        localSave() {
            // simpanLocal()
        }

    }));

    // const fetchDataLocal = () => {
    //     var result = dataLocal.seriFPDetail;
    //     setIsModal(true);
    //     setId(result.id);
    //     setTitleModal(result.titleModal);
    //     localStorage.removeItem(username + '-DataseriFPDetail')
    // }


    const toggleAdd = () => {
        getSeriFP()
        setId(0);
        setSfpHeader('');
        setSfpNoFrom(0)
        setSfpNoTo(0)
        handleClick();
        setTitleModal('Add');
    }

    const toggleEdit = () => {
        if (SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' })
        } else {
            // getSeriFP()
            // ApiService.PostById(param, SelectedData.seriFPId).then((res) => {
            //     if (res !== null) {
            //         var result = res.data.data.seriFPDetail;
            //         setValue(result.value);
            //         setSeriFakturPajak(result.seriFakturPajak);
            //         setName(result.name);
            //     }
            //     setId(SelectedData.seriFPId);
            //     setIsModal(true);
            //     setTitleModal('Edit');
            // }).catch(function (error) {
            //     console.error('error saat fetch', error)
            // })
            // setId(SelectedData.id);
            // setSfpHeader(SelectedData.sfpHeader);
            // setSfpNoFrom(SelectedData.sfpNoFrom);
            // setSfpNoTo(SelectedData.sfpNoTo);
            // setIsModal(true);
            // setTitleModal('Edit');
            showToast({ type: 'error', message: 'Can not edit!' })
        }
    }

    let countRefresh = 0;

    useEffect(() => {
        if (countRefresh === 0) {
            ApiService.refreshToken();
            countRefresh++;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = (pageNumber, pageSize, filters, orderBy) => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        } else {
            setIsLoadingBackDrop(true);
        }
        ApiService.DynamicPostByPage(param, 'PAGE', pageNumber, pageSize, [], filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({})
                    setData(response.data.data.seriFPDetail);

                    let temp = response.data.data.seriFPDetail
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMap(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTable, 'seriFPId');
                    setColumnData(response.data.data.columns)
                    setTotalRows(response.data.totalRowCount)

                    setIsLoading(false)
                    setIsLoadingBackDrop(false)
                } else {
                    setIsLoadingBackDrop(false)
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + response.data.message })
                }
                setIsLoading(false)
            })
            .catch(function (error) {
                setIsLoading(false)
                setIsLoadingBackDrop(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error })
            })
    }

    const getSeriFP = () => {
        ApiService.PostByPage('SeriFP/SeriFP', 1, 100).then((res) => {
            if (res.data.code === 200) {
                var result = res.data.data.SeriFP
                setDataSeriFP(result)
            }
        })
            .catch(function (error) {
                console.error('error saat fetch', error)
            })
    }

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
        } else {
            setIsModal(false);
            setNumPage(1)
            setRowsCount(50)
        }
    };

    function CellDoubleClick(row) {
        toggleEdit()
    }


    return (
        <>
            <CustomToastContainer />
            <TempListMaster
                uniqueKey={'seriFPId'}
                dataTable={dataTable}
                SelectedData={SelectedData}
                param={param}
                data={data}
                title={title}
                toggleAdd={toggleAdd}
                toggleEdit={toggleEdit}
                isLoading={isLoading}
                getData={getData}
                columnData={columnData}
                dataMap={dataMap}
                setSelectedData={setSelectedData}
                CellDoubleClick={CellDoubleClick}
                totalRows={totalRows}
                rowsCount={rowsCount}
                setRowsCount={setRowsCount}
                numPage={numPage}
                setNumPage={setNumPage}
                filter={filter}
                setFilter={setFilter}
                filterBy={filterBy}
                setFilterBy={setFilterBy}
                stateX={stateX}
                setStateX={setStateX}
                menuCode={'MSFPD'}
                menuName={'MASTER'}
                sortArr={sortArr}
                setSortArr={setSortArr}
            />

            {
                isModal && (
                    <CreateEditSeriFPDetail
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        title={title}
                        param={param}
                        getData={getData}

                        id={id}
                        setId={setId}
                        sfpHeader={sfpHeader}
                        setSfpHeader={setSfpHeader}
                        sfpNoFrom={sfpNoFrom}
                        setSfpNoFrom={setSfpNoFrom}
                        sfpNoTo={sfpNoTo}
                        setSfpNoTo={setSfpNoTo}
                        dataSeriFP={dataSeriFP}
                    />
                )
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
})

export default ListSeriFPDetail