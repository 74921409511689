import React, { useState, useEffect } from 'react';
import "jspdf-autotable";
import ApiService, { userCode, username } from '../../../services/ApiService';
import TempListMaster from '../TempListMaster';
import CreateEditStorage from './CreateEditStorage';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import { Backdrop } from '@mui/material';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import CustomToastContainer, { showToast } from '../../../components/ui';
import { handleDataTable } from '../../../utils/reusableFunction';

const ListStorage = forwardRef((props, ref) => {
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const [data, setData] = useState([]);
    const [titleModal, setTitleModal] = useState('');
    const title = 'Storage'
    const [isModal, setIsModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [dataTable, setDataTable] = useState([])
    const [dataMap, setDataMap] = useState([])
    const [columnData, setColumnData] = useState([])
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50)
    const [rowsCount, setRowsCount] = useState(50)
    const [numPage, setNumPage] = useState(1)
    const [filter, setFilter] = useState([])
    const [filterBy, setFilterBy] = useState([])

    const param = "storage/storage";
    const [id, setId] = useState(0);
    const [companyId, setCompanyId] = useState(0);
    const [name, setName] = useState('');
    const [shipmentStatus, setShipmentStatus] = useState('');

    const [companyName, setCompanyName] = useState();
    const [dataShipmentStatus, setDataShipmentStatus] = useState([])
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([])

    const dataLocal = JSON.parse(localStorage.getItem(username + "-Datastorage"));

    const simpanLocal = () => {
        if (isModal) {
            var data;
            data =
            {
                "storage": {
                    "rowStatus": "ACT",
                    "id": id,
                    "companyId": companyId,
                    "name": name,
                    "shipmentStatus": shipmentStatus,
                    "deleted": false,
                    "deletedBy": "",
                    "deletedOn": "2022-11-03T07:49:12.656Z",
                    "user": userCode,
                    "titleModal": titleModal
                }
            }
            localStorage.setItem(username + "-Datastorage", JSON.stringify(data));
        }
    }

    useImperativeHandle(ref, () => ({

        localSave() {
            simpanLocal()
        }

    }));

    const fetchDataLocal = () => {
        var result = dataLocal.storage;
        setIsModal(true);
        setCompanyId(result.companyId);
        setName(result.name);
        setShipmentStatus(result.shipmentStatus);
        getCompanyById(result.companyId)
        setId(result.id);
        setTitleModal(result.titleModal);
        localStorage.removeItem(username + '-Datastorage')
    }

    const toggleAdd = () => {
        emptyState()
        handleClick();
        setTitleModal('Add');
    }

    const emptyState = () => {
        setId(0);
        setCompanyId('');
        setName('');
        setShipmentStatus()
        setCompanyName('')
        getShipmentStatus()
    }

    const toggleEdit = () => {
        if (SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' })
        } else {
            getShipmentStatus()
            setId(SelectedData.id);
            setCompanyId(SelectedData.companyId);
            setName(SelectedData.name);
            setShipmentStatus(SelectedData.shipmentStatus);
            getCompanyById(SelectedData.companyId)

            setIsModal(true);
            setTitleModal('Edit');
        }
    }

    const getCompanyById = (id) => {
        ApiService.PostById('company/company', id).then((res) => {
            if (res.data.code === 200) {
                var result = res.data.data.company
                setCompanyName(result.companyName)
            }
        })
            .catch(function (error) {
                console.error('error saat fetch', error)
            })
    }

    const getShipmentStatus = () => {
        ApiService.PostByPage('shipmentStatus/shipmentStatus', 1, 100).then((res) => {
            if (res.data.code === 200) {
                var result = res.data.data.shipmentStatus
                setDataShipmentStatus(result)
            }
        })
            .catch(function (error) {
                console.error('error saat fetch', error)
            })
    }

    let countRefresh = 0;

    useEffect(() => {
        if (countRefresh === 0) {
            ApiService.refreshToken();
            countRefresh++;
            if (dataLocal) {
                setTimeout(function () { fetchDataLocal(); }, 2000);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = (pageNumber, pageSize, filters, orderBy) => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        } else {
            setIsLoadingBackDrop(true);
        }
        ApiService.DynamicPostByPage(param, 'PAGE', pageNumber, pageSize, [], filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({})
                    setData(response.data.data.storage);

                    let temp = response.data.data.storage
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMap(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTable, 'id');
                    setColumnData(response.data.data.columns)
                    setTotalRows(response.data.totalRowCount)

                    setIsLoading(false)
                    setIsLoadingBackDrop(false)
                } else {
                    setIsLoadingBackDrop(false)
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + response.data.message })
                }
                setIsLoading(false)
            })
            .catch(function (error) {
                setIsLoading(false)
                setIsLoadingBackDrop(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error })
            })
    }


    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
        } else {
            setIsModal(false);
            setNumPage(1)
            setRowsCount(50)
        }
    };

    function CellDoubleClick(row) {
        toggleEdit()
    }

    return (
        <>
            <CustomToastContainer />
            <TempListMaster
                dataTable={dataTable}
                SelectedData={SelectedData}
                param={param}
                data={data}
                title={title}
                toggleAdd={toggleAdd}
                toggleEdit={toggleEdit}
                isLoading={isLoading}
                getData={getData}
                columnData={columnData}
                dataMap={dataMap}
                setSelectedData={setSelectedData}
                CellDoubleClick={CellDoubleClick}
                totalRows={totalRows}
                rowsCount={rowsCount}
                setRowsCount={setRowsCount}
                numPage={numPage}
                setNumPage={setNumPage}
                filter={filter}
                setFilter={setFilter}
                filterBy={filterBy}
                setFilterBy={setFilterBy}
                stateX={stateX}
                setStateX={setStateX}
                menuCode={'MSTR'}
                menuName={'MASTER'}
                sortArr={sortArr}
                setSortArr={setSortArr}
            />

            {
                isModal && (
                    <CreateEditStorage
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        title={title}
                        param={param}
                        getData={getData}

                        id={id}
                        companyId={companyId}
                        setCompanyId={setCompanyId}
                        name={name}
                        setName={setName}
                        shipmentStatus={shipmentStatus}
                        setShipmentStatus={setShipmentStatus}
                        companyName={companyName}
                        setCompanyName={setCompanyName}
                        dataShipmentStatus={dataShipmentStatus}
                        setDataShipmentStatus={setDataShipmentStatus}
                    />
                )
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
})

export default ListStorage