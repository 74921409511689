import React from 'react';
import { formatCurrency } from '../../../../../services/ApiService';
import DollarInput, { convertMask } from '../../../../../components/Masks/DollarInput';
import RupiahInput, { convertMaskIDR } from '../../../../../components/Masks/RupiahInput';

import ButtonComponent from '../../../../../components/Buttons/ButtonComponent';
import { Input, showToast } from '../../../../../components/ui';
import { Grid } from '@mui/material';

const TabCash = (props) => {
    function addCash() {
        if (props.selectedPaymentVoucher.sequence !== undefined) {
            let amount = 0;

            if (formatCurrency === 'USD') {
                amount = parseFloat(convertMask(props.CashPayment));
            }
            else {
                amount = parseFloat(convertMaskIDR(props.CashPayment));
            }

            if (amount < 0) {
                showToast({ type: 'error', message: 'Please Input Cash Payment!' });
            }
            else {

                const newArray = props.DataPaymentDetail.slice();
                newArray.forEach(el => {
                    if (props.isAllPPh === true) {
                        el.isPPh23 = true;
                    }

                    if (el.sequence === props.selectedPaymentVoucher.sequence) {
                        el.paymentCash = parseFloat(amount);
                        if (props.TempIsPPh23 === true) {
                            el.isPPh23 = true;
                            props.setTempIsPPh23(false);
                        }
                    }
                });
                props.setDataPaymentDetail(newArray);
                props.setIsAddValue(true);
                props.setIsInput(false);
                props.calculateTotalDebitCredit(newArray, props.Rate, props.PaymentBy, props.PPh23AmountReal);
            }
        }
        else {
            showToast({ type: 'error', message: 'Please Select Detail First!' });
        }
    }


    return (
        <>
            <div>
                <Input
                    label='Cash Payment'
                    value={props.CashPayment}
                    onChange={(e) => props.setCashPayment(formatCurrency === 'USD' ? convertMask(e.target.value, 1) : convertMaskIDR(e.target.value, 1))}
                    disabled={props.isCash || props.printing > 0}
                    fullWidth
                    textAlign='right'
                    formControlProps={{
                        sx: {
                            width: '100%',
                        }
                    }}
                    inputBaseProps={{
                        inputComponent: formatCurrency === 'USD' ? DollarInput : RupiahInput,
                    }}
                />

                <Grid style={{ marginTop: '16px' }}>
                    <Grid item xs={12} className='text-end' display={'flex'} justifyContent={'flex-end'}>
                        {
                            props.printing === 0 ?
                                <>
                                    {ButtonComponent.AddButton(addCash, '', props.isCash)}
                                </>
                                :
                                <>
                                    {ButtonComponent.AddButton(addCash, '', props.printing > 0)}
                                </>
                        }
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default TabCash;