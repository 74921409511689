import React, { forwardRef, useState, useEffect } from 'react';
import "jspdf-autotable";
import ApiService, { username } from '../../../services/ApiService';
import TempListMaster from '../TempListMaster';
import CreateEditRegClosingPeriod from './CreateEditRegClosingPeriod';
import { Backdrop } from '@mui/material';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import CustomToastContainer, { showToast } from '../../../components/ui';
import ModalRestore from './Modals/ModalRestore';
import { updateDateFormat } from '../../../utils/date';
import { handleDataTable } from '../../../utils/reusableFunction';

const ListRegClosingPeriod = forwardRef((props, ref) => {
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const title = 'Reg Closing Period'
    const [data, setData] = useState([]);
    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [dataMap, setDataMap] = useState([])
    const [dataTable, setDataTable] = useState([])
    const [columnData, setColumnData] = useState([])
    const [selectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50)
    const [rowsCount, setRowsCount] = useState(50)
    const [numPage, setNumPage] = useState(1)
    const [filter, setFilter] = useState([])
    const [filterBy, setFilterBy] = useState([])
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([])

    const param = "regClosingPeriod/regClosingPeriod";

    const dataLocal = JSON.parse(localStorage.getItem(username + "-DataRegClosingPeriod"));

    const [isModalRestore, setIsModalRestore] = useState(false);
    const [titleModalRestore, setTitleModalRestore] = useState('');
    const [Period, setPeriod] = useState('');
    const [YearPeriod, setYearPeriod] = useState('');
    const [ValidDate, setValidDate] = useState('');
    const [MinValidDate, setMinValidDate] = useState('');
    const [MaxValidDate, setMaxValidDate] = useState('');

    const fetchDataLocal = () => {
        const result = dataLocal.regClosingPeriod;
        setTitleModal(result.titleModal);
        setSelectedData(result)

        setIsModal(true);
        localStorage.removeItem(username + '-DataRegClosingPeriod')
    }

    const toggleAdd = () => {
        if (selectedData?.id) {
            setSelectedData({})
        }
        handleClick();
        setTitleModal('Add');
    }

    const toggleEdit = () => {
        if (!selectedData?.id) {
            showToast({ type: 'error', message: 'Please Select Data!' })
        } else {
            setIsModal(true);
            setTitleModal('Edit');
        }
    }

    const handleClickRestore = () => {
        if (isModalRestore === false) {
            setIsModalRestore(true);
        } else {
            setIsModalRestore(false);
        }
    };

    const toggleRestore = () => {
        setIsLoadingBackDrop(true);
        // const currentMonth = new Date().getMonth();
        // if (currentMonth !== 0) {
        let closingDate = JSON.parse(localStorage.getItem('closingDate'));

        if (closingDate !== undefined && closingDate !== null) {
            openRestore(closingDate);
            setIsLoadingBackDrop(false);
        }
        else {
            ApiService.PostById('branch/branch', JSON.parse(localStorage.getItem('branchId')))
                .then((res) => {
                    if (res !== null) {
                        let data = res.data.data.branch;
                        openRestore(data.closingDate);
                        localStorage.setItem('closingDate', JSON.stringify(data.closingDate));
                        setIsLoadingBackDrop(false);
                    }
                })
        }
        // }
        // else {
        //     showToast({ type: 'error', message: 'Cannot Restore Data, Because The Current Month Is January!' });
        //     setIsLoadingBackDrop(false);
        // }
    }

    const openRestore = (closingDate) => {
        const date = new Date();
        setPeriod(date.getMonth() !== 0 ? date.getMonth() : 12);
        setYearPeriod(date.getMonth() !== 0 ? date.getFullYear() : date.getFullYear() - 1);
        setValidDate(updateDateFormat(date));
        setMinValidDate(updateDateFormat(date));
        setMaxValidDate(updateDateFormat(new Date(date.getFullYear(), date.getMonth() + 1, closingDate)));

        handleClickRestore();
        setTitleModalRestore('Restore');
    }

    let countRefresh = 0;

    useEffect(() => {
        if (countRefresh === 0) {
            ApiService.refreshToken();
            countRefresh++;
            if (dataLocal) {
                setTimeout(function () { fetchDataLocal(); }, 2000);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = (pageNumber, pageSize, filters, orderBy) => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        } else {
            setIsLoadingBackDrop(true);
        }
        ApiService.DynamicPostByPage(param, 'PAGE', pageNumber, pageSize, [], filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({})
                    setData(response.data.data.closingPeriod);

                    let temp = response.data.data.closingPeriod
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMap(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTable, 'id');
                    setColumnData(response.data.data.columns)
                    setTotalRows(response.data.totalRowCount)

                    setIsLoading(false)
                    setIsLoadingBackDrop(false)
                } else {
                    setIsLoadingBackDrop(false)
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + response.data.message })
                }
                setIsLoading(false)
            })
            .catch(function (error) {
                setIsLoading(false)
                setIsLoadingBackDrop(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error })
            })
    }

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
        } else {
            setIsModal(false);
            setNumPage(1)
            setRowsCount(50)
            setSelectedData({})
        }
    };

    function CellDoubleClick(row) {
        toggleEdit()
    }

    return (
        <>
            <CustomToastContainer />
            <TempListMaster
                dataTable={dataTable}
                SelectedData={selectedData}
                param={param}
                data={data}
                title={title}
                toggleAdd={toggleAdd}
                toggleEdit={toggleEdit}
                RestoreData={toggleRestore}
                isLoading={isLoading}
                getData={getData}
                columnData={columnData}
                dataMap={dataMap}
                setSelectedData={setSelectedData}
                CellDoubleClick={CellDoubleClick}
                totalRows={totalRows}
                rowsCount={rowsCount}
                setRowsCount={setRowsCount}
                numPage={numPage}
                setNumPage={setNumPage}
                filter={filter}
                setFilter={setFilter}
                filterBy={filterBy}
                setFilterBy={setFilterBy}
                stateX={stateX}
                setStateX={setStateX}
                menuCode={'MRCP'}
                menuName={'MASTER'}
                sortArr={sortArr}
                setSortArr={setSortArr}
            />

            {isModal && (
                <CreateEditRegClosingPeriod
                    ref={ref}
                    isModal={isModal}
                    handleClick={handleClick}
                    titleModal={titleModal}
                    title={title}
                    param={param}
                    setIsLoadingBackDrop={setIsLoadingBackDrop}

                    getData={getData}

                    selectedData={selectedData}
                />
            )}

            {isModalRestore && (
                <ModalRestore
                    isModal={isModalRestore}
                    handleClick={handleClickRestore}
                    titleModal={titleModalRestore}
                    title={'Closing Monthly'}
                    param={param}
                    setIsLoadingBackDrop={setIsLoadingBackDrop}

                    getData={getData}

                    Period={Period}
                    setPeriod={setPeriod}
                    YearPeriod={YearPeriod}
                    setYearPeriod={setYearPeriod}
                    ValidDate={ValidDate}
                    setValidDate={setValidDate}
                    MaxValidDate={MaxValidDate}
                    MinValidDate={MinValidDate}
                />
            )}

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => 10000000 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
})

export default ListRegClosingPeriod