import { InputAdornment, MenuItem, FormHelperText, Stack, IconButton } from '@mui/material';
import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import ButtonService from '../ButtonService';
import ApiService, { userCode, username } from '../../../services/ApiService';
import SelectDataCOA from './Selects/SelectDataCOA';
import CreateEditModalMaster from '../CreateEditModalMaster';
import { Icon, Input, Selection, showToast } from '../../../components/ui';
import { handleDataTable } from '../../../utils/reusableFunction';

const CreateEditRegJournalAccount = forwardRef((props, ref) => {
    const { isModal, handleClick, titleModal, title, param, selectedData, getData } = props;

    const sourceData = [
        { id: 1, code: 'OR', name: 'Official Receipt' },
        { id: 2, code: 'PV', name: 'Payment Voucher' },
        { id: 3, code: 'RV', name: 'Receipt Voucher' },
        { id: 4, code: 'TR', name: 'Temporary Receipt' },
        { id: 5, code: 'TP', name: 'Temporary Payment' },
        { id: 6, code: 'DP', name: 'Down Payment' },
    ]

    const paramO = "regChartOfAccount/regChartOfAccount";
    const [isModalO, setIsModalO] = useState(false);
    const [titleModalO, setTitleModalO] = useState('');
    const [isLoadingO, setIsLoadingO] = useState(false);
    const [dataO, setDataO] = useState([]);
    const [dataMapO, setDataMapO] = useState([]);
    const [dataTableO, setDataTableO] = useState([]);
    const [columnDataO, setColumnDataO] = useState([]);
    const [SelectedDataO, setSelectedDataO] = useState({});
    const [totalRowsO, setTotalRowsO] = useState(50);

    useImperativeHandle(ref, () => ({
        localSave() {
            simpanLocal();
        }
    }));

    const validationSchema = Yup.object().shape({
        accountCode: Yup.string().trim().required('This Field is Required.'),
        accountTitle: Yup.string().trim().required('This Field is Required.'),
        description: Yup.string().trim().required('This Field is Required.'),
        entryFor: Yup.string().required('This Field is Required.'),
    })

    const formik = useFormik({
        initialValues: selectedData?.id ?
            {
                ...selectedData,
            }
            :
            {
                accountCode: '',
                accountTitle: '',
                description: '',
                entryFor: '',
            },
        validationSchema,
        onSubmit: (values) => Save(values)
    })

    const errorToast = (location, closeModal = false) => {
        showToast({ type: 'error', message: `Can't Get ${location} Data! Please Contact Administrator` })
        if (location === 'Chart of Account') {
            setIsModalO(false);
        }
        else {
            if (closeModal) handleClick()
        }
    }

    const handleClickCOA = () => {
        if (isModalO === false) {
            setIsModalO(true);
        }
        else {
            setIsModalO(false);
        }
    }

    const searchPaymentType = (value) => {
        if (value === 'search') {
            setTitleModalO('Chart Of Account (COA)');
            getDataCOA(1, 50);
            handleClickCOA();
        }
        else if (value === 'delete') {
            formik.setFieldValue('accountCode', '');
            formik.setFieldValue('accountTitle', '');
        }
    }

    const getDataCOA = (pageNumber, pageSize, filter) => {
        setIsLoadingO(true);
        ApiService.COAPostBySearch(paramO, pageNumber, pageSize, [], filter)
            .then((res) => {
                if (res.data.code === 200) {
                    setSelectedDataO({})
                    setDataO(res.data.data.chartOfAccount);

                    let temp = res.data.data.chartOfAccount
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapO(indexed)
                    handleDataTable(indexed, res.data.data.columns, setDataTableO, 'id');
                    setColumnDataO(res.data.data.columns)
                    setTotalRowsO(res.data.totalRowCount)
                    setIsLoadingO(false)
                }
                else {
                    errorToast('Chart of Account')
                }
                setIsLoadingO(false)
            })
            .catch(function (error) {
                setIsLoadingO(false)
                console.log(error)
                errorToast('Chart of Account')
            })
    }

    const simpanLocal = () => {
        if (isModal) {
            const data = {
                "user": {
                    ...formik.values,
                    rowStatus: formik?.values?.rowStatus || 'ACT',
                    titleModal,
                    user: userCode,
                }
            }
            localStorage.setItem(username + "-DataRCOAE", JSON.stringify(data));
        }
    }

    const Save = (payload) => {
        if (payload?.accountCode === '') {
            return showToast({ type: 'error', message: 'COA Code is Required!' });
        }

        if (payload?.entryFor === '') {
            return showToast({ type: 'error', message: 'Entry For is Required!' });
        }

        const data = {
            "chartOfAccountEntry": {
                "rowStatus": "ACT",
                "id": payload?.id,
                "accountCode": payload?.accountCode,
                "accountTitle": payload?.accountTitle,
                "entryFor": payload?.entryFor,
                "description": payload?.description,
                "userCode": userCode
            }
        }

        ButtonService.Save(titleModal, title, param, data, () => {
            getData(1, 50, []);
            handleClick();
        });
    }

    const mainContainer = (
        <form onSubmit={formik.handleSubmit} style={{ marginTop: '12px' }}>
            <Stack spacing={2} direction="row">
                <Input
                    label='COA Code'
                    formControlProps={{
                        sx: {
                            // width: '100%',
                            // marginTop: '16px'
                        }
                    }}
                    value={formik.values.accountCode}
                    inputBaseProps={{
                        readOnly: true,
                    }}
                    disabled
                />

                <Input
                    label='COA Name'
                    formControlProps={{
                        sx: {
                            width: '100%',
                            // marginTop: '16px'
                        }
                    }}
                    disabled
                    value={formik.values.accountTitle}
                    inputBaseProps={{
                        readOnly: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => searchPaymentType('delete')} hidden={formik.values.accountTitle !== '' ? false : true}>
                                    <Icon
                                        iconName={'close'}
                                        svgIconProps={{
                                            fontSize: 'small',
                                            htmlColor: '#0087C2',
                                            sx: {
                                                height: '12px',
                                                width: '12px'
                                            }
                                        }}
                                    />
                                </IconButton>

                                <IconButton onClick={() => searchPaymentType('search')}>
                                    <Icon
                                        iconName={'search'}
                                        svgIconProps={{
                                            fontSize: 'small',
                                            htmlColor: '#0087C2',
                                            sx: {
                                                height: '12px',
                                                width: '12px'
                                            }
                                        }}
                                    />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </Stack>

            <Selection
                id="source"
                name='source'
                labelId="source"
                label="Entry For"
                placeholder={'Select Entry For'}
                value={formik.values.entryFor || ''}
                onChange={(e) => formik.setFieldValue('entryFor', e.target.value)}
                error={formik.touched.entryFor && Boolean(formik.errors.entryFor)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
            >
                {
                    sourceData.map((data) => (
                        <MenuItem sx={{ fontSize: '12px' }} id={data.id} key={data.id} value={data.code}>
                            {data.name}
                        </MenuItem>
                    ))
                }
                <FormHelperText>{formik.touched.entryFor && formik.errors.entryFor}</FormHelperText>
            </Selection>

            <Input
                multiline
                fullWidth
                id='constantCode'
                name="constantCode"
                label="Description"
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                inputBaseProps={{
                    rows: 4,
                    sx: {
                        padding: '8px',
                    },
                }}
                value={formik.values.constantCode}
                onBlur={formik.handleBlur}
                error={formik.touched.constantCode && Boolean(formik.errors.constantCode)}
                helperText={formik.touched.constantCode && formik.errors.constantCode}
                onChange={(e) => formik.setFieldValue('constantCode', e.target.value)}
            />
        </form>
    )

    return (
        <>
            {
                isModal && (
                    <CreateEditModalMaster
                        isModal={isModal}
                        titleModal={titleModal}
                        title={title}
                        mainContainer={mainContainer}
                        handleClick={handleClick}
                        handleSave={() => Save(formik.values)}
                    />
                )
            }

            {
                isModalO && (
                    <SelectDataCOA
                        isModal={isModalO}
                        handleClick={handleClickCOA}
                        titleModal={titleModalO}
                        setIsModal={setIsModalO}

                        getData={getDataCOA}
                        isLoading={isLoadingO}
                        totalRows={totalRowsO}
                        setTotalRows={setTotalRowsO}
                        SelectedData={SelectedDataO}
                        setSelectedData={setSelectedDataO}
                        data={dataO}
                        columnData={columnDataO}
                        dataMap={dataMapO}
                        dataTable={dataTableO}

                        formik={formik}
                    />
                )
            }
        </>

    )
})

export default CreateEditRegJournalAccount