import { IconButton, InputAdornment, MenuItem } from '@mui/material';
import React from 'react';
import ApiService, { userCode } from '../../../../services/ApiService';
import ButtonService from '../../ButtonService';
import { useState } from 'react';
import SelectDataMaster from '../SelectDataMaster';
import { Icon, Input, Selection, showToast } from '../../../../components/ui';
import CreateEditModalMaster from '../../CreateEditModalMaster';
import { handleDataTable } from '../../../../utils/reusableFunction';

const CreateEditRegProductUnits = (props) => {
    const paramProduct = "warehouse/product/product";
    const paramUnit = "warehouse/units/units";

    let selectParam, selectKey;
    const [selectName, setSelectName] = useState('');

    const [titleModal, setTitleModal] = useState('');
    const [isModal, setIsModal] = useState(false);
    const [isLoadingModal, setIsLoadingModal] = useState(false);
    const [dataModal, setDataModal] = useState([]);
    const [dataMapModal, setDataMapModal] = useState([]);
    const [dataTableModal, setDataTableModal] = useState([]);
    const [columnDataModal, setColumnDataModal] = useState([]);
    const [SelectedDataModal, setSelectedDataModal] = useState({});
    const [totalRowsModal, setTotalRowsModal] = useState(50);

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
            getDataSelect(1, 50);
        }
        else {
            setIsModal(false);
        }
    };

    const getDataSelect = (pageNumber, pageSize, filters) => {
        setIsLoadingModal(true);
        ApiService.PostByPage(selectParam, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataModal({})
                    setDataModal(response.data.data[selectKey]);

                    let temp = response.data.data[selectKey]
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapModal(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTableModal, 'id');
                    setColumnDataModal(response.data.data.columns)
                    setTotalRowsModal(response.data.totalRowCount)

                    setIsLoadingModal(false)
                }
                setIsLoadingModal(false)
            })
            .catch(function (error) {
                setIsLoadingModal(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Failed to fetch data, ' + error });
            })
    };

    const searchProduct = () => {
        setTitleModal("Product");
        selectParam = paramProduct;
        selectKey = 'product';
        setSelectName("product");
        handleClick();
    }

    const searchUnit = () => {
        setTitleModal("Unit");
        selectParam = paramUnit;
        selectKey = 'units';
        setSelectName("units");
        handleClick();
    }

    const selectHandler = (selectedData, name) => {
        if (name === 'product') {
            props.setProductId(selectedData.id);
            props.setProductName(selectedData.name);
        } else {
            props.setUnitId(selectedData.id);
            props.setUnitName(selectedData.name);
        }
    }

    const Save = () => {
        var data =
        {
            "productUnits": {
                "rowStatus": "ACT",
                "productId": props.productId,
                "unitId": props.unitId,
                "id": props.id === 0 ? "" : props.id.toString(),
                "qty": props.qty,
                "isSmallest": JSON.parse(props.isSmallest),
                "userCode": userCode
            }
        }
        // ButtonService.Save(props.titleModal, props.title, props.param, data);
        ButtonService.Save(props.titleModal, props.title, props.param, data, () => {
            props.getData(1, 50, []);
            props.handleClick();
        });
    }

    const mainContainer = (
        <form onSubmit={Save} style={{ marginTop: '12px' }}>
            <Input
                label="Product"
                value={props.productName}
                fullWidth
                formControlProps={{
                    sx: {
                        width: '100%',
                    }
                }}
                disabled
                inputBaseProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => searchProduct()}>
                                <Icon
                                    iconName={'search'}
                                    svgIconProps={{
                                        fontSize: 'small',
                                        htmlColor: '#0087C2',
                                        sx: {
                                            height: '12px',
                                            width: '12px'
                                        }
                                    }}
                                />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />

            <Input
                label="Unit"
                value={props.unitName}
                fullWidth
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                disabled
                inputBaseProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton onClick={() => searchUnit()}>
                                <Icon
                                    iconName={'search'}
                                    svgIconProps={{
                                        fontSize: 'small',
                                        htmlColor: '#0087C2',
                                        sx: {
                                            height: '12px',
                                            width: '12px'
                                        }
                                    }}
                                />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />

            <Input
                label="Qty"
                value={props.qty}
                onChange={(e) => props.setQty(e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px !important'
                    }
                }}
            />

            <Selection
                id='0'
                label={'Smallest'}
                fullWidth={true}
                placeholder={'Select Smallest'}
                value={props.isSmallest}
                onChange={(e) => props.setIsSmallest(e.target.value)}
                formControlProps={{
                    sx: {
                        marginTop: '16px !important'
                    }
                }}
            >
                <MenuItem sx={{ fontSize: '12px' }} key='smallesty' value='true'>Yes</MenuItem>
                <MenuItem sx={{ fontSize: '12px' }} key='smallestn' value='false'>No</MenuItem>
            </Selection>
        </form>
    )

    return (
        <>
            {
                props.isModal && (
                    <CreateEditModalMaster
                        isModal={props.isModal}
                        titleModal={props.titleModal}
                        title={props.title}
                        mainContainer={mainContainer}
                        handleClick={props.handleClick}
                        handleSave={Save}
                    />
                )
            }

            {
                isModal && (
                    <SelectDataMaster
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        setIsModal={setIsModal}

                        getData={getDataSelect}
                        isLoading={isLoadingModal}
                        totalRows={totalRowsModal}
                        setTotalRows={setTotalRowsModal}
                        SelectedData={SelectedDataModal}
                        setSelectedData={setSelectedDataModal}
                        data={dataModal}
                        columnData={columnDataModal}
                        dataMap={dataMapModal}
                        dataTable={dataTableModal}

                        selectHandler={selectHandler}
                        selectName={selectName}
                    />
                )
            }
        </>
    )
}

export default CreateEditRegProductUnits