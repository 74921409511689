import { IconButton, InputAdornment } from '@mui/material';
import React from 'react';
import ApiService, { branchId, companyId, countryId, userCode } from '../../../services/ApiService';
import ButtonService from '../ButtonService';
import ModalSeriFP from './ModalSeriFP';
import { useState } from 'react';
import CreateEditModalMaster from '../CreateEditModalMaster';
import { Icon, Input } from '../../../components/ui';
import { handleDataTable } from '../../../utils/reusableFunction';

const CreateEditSeriFPDetail = (props) => {
    const [isModalSeriFP, setIsModalSeriFP] = useState(false);
    const [titleModalSeriFP, setTitleModalSeriFP] = useState('');

    const [isLoadingSeriFP, setIsLoadingSeriFP] = useState(false);
    const [dataSeriFP, setDataSeriFP] = useState([]);
    const [dataMapSeriFP, setDataMapSeriFP] = useState([])
    const [dataTableSeriFP, setDataTableSeriFP] = useState([]);
    const [columnDataSeriFP, setColumnDataSeriFP] = useState([])
    const [SelectedDataSeriFP, setSelectedDataSeriFP] = useState({});
    const [totalRowsSeriFP, setTotalRowsSeriFP] = useState(50)

    const paramSeriFP = "seriFP/seriFP";

    const Save = () => {
        var data =
        {
            "seriFPDetail": {
                "rowStatus": "ACT",
                "seriFPId": props.id,
                "countryId": countryId,
                "companyId": companyId,
                "branchId": branchId,
                "sfpHeader": props.sfpHeader,
                "sfpNoFrom": props.sfpNoFrom,
                "sfpNoTo": props.sfpNoTo,
                "userCode": userCode
            }
        }

        ButtonService.Save(props.titleModal, props.title, props.param, data, () => {
            props.getData(1, 50, []);
            props.handleClick();
        });
    }

    const SearchSeriFP = () => {
        if (isModalSeriFP === false) {
            setIsModalSeriFP(true);
            setTitleModalSeriFP('Seri Faktur Pajak')
            getDataSeriFP(1, 50)
        } else {
            setIsModalSeriFP(false);
        }
    }

    const getDataSeriFP = (pageNumber, pageSize, filters) => {
        setIsLoadingSeriFP(true);
        ApiService.PostByPage(paramSeriFP, pageNumber, pageSize, filters)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedDataSeriFP({})
                    setDataSeriFP(response.data.data.seriFP);

                    let temp = response.data.data.seriFP
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMapSeriFP(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTableSeriFP, 'id');
                    setColumnDataSeriFP(response.data.data.columns)
                    setTotalRowsSeriFP(response.data.totalRowCount)

                    setIsLoadingSeriFP(false)
                }
                setIsLoadingSeriFP(false)
            })
            .catch(function (error) {
                setIsLoadingSeriFP(false)
                console.error('error saat fetch', error)
            })
    }

    const mainContainer = (
        <form onSubmit={Save} style={{ marginTop: '12px' }}>
            <Input
                name="sfpHeader"
                label="Faktur Pajak Header"
                formControlProps={{
                    sx: {
                        width: '100%',
                        // marginTop: '16px'
                    }
                }}
                disabled
                value={props.sfpHeader}
                inputBaseProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => { SearchSeriFP() }}
                            >
                                <Icon
                                    iconName={'search'}
                                    svgIconProps={{
                                        fontSize: 'small',
                                        htmlColor: '#0087C2',
                                        sx: {
                                            height: '12px',
                                            width: '12px'
                                        }
                                    }}
                                />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />

            <Input
                disabled
                type={'number'}
                name="sfpNoFrom"
                label="No From"
                value={props.sfpNoFrom}
                // onChange={(e) => props.setSfpHeader(e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                inputBaseProps={{
                    inputProps: {
                        inputMode: 'numeric',
                        pattern: '[0-9]*'
                    }
                }}
            />

            <Input
                disabled
                type={'number'}
                name="sfpNoTo"
                label="No To"
                value={props.sfpNoTo}
                // onChange={(e) => props.setSfpNoTo(e.target.value)}
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                inputBaseProps={{
                    inputProps: {
                        inputMode: 'numeric',
                        pattern: '[0-9]*'
                    }
                }}
            />
        </form>
    )

    return (
        <>
            {
                props.isModal && (
                    <CreateEditModalMaster
                        isModal={props.isModal}
                        titleModal={props.titleModal}
                        title={props.title}
                        mainContainer={mainContainer}
                        handleClick={props.handleClick}
                        handleSave={Save}
                    />
                )
            }

            {
                isModalSeriFP && (
                    <ModalSeriFP
                        isModal={isModalSeriFP}
                        handleClick={SearchSeriFP}
                        titleModal={titleModalSeriFP}
                        setIsModal={setIsModalSeriFP}

                        isLoading={isLoadingSeriFP}
                        data={dataSeriFP}
                        dataMap={dataMapSeriFP}
                        dataTable={dataTableSeriFP}
                        columnData={columnDataSeriFP}
                        SelectedData={SelectedDataSeriFP}
                        totalRows={totalRowsSeriFP}
                        setSelectedData={setSelectedDataSeriFP}
                        getData={getDataSeriFP}

                        setId={props.setId}
                        setSfpHeader={props.setSfpHeader}
                        setSfpNoFrom={props.setSfpNoFrom}
                        setSfpNoTo={props.setSfpNoTo}
                    />
                )
            }
        </>
    )
}

export default CreateEditSeriFPDetail