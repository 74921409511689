import { MenuItem, IconButton, InputAdornment, Grid, Typography } from '@mui/material';
import React, { useState, useEffect } from "react";
import { Stack } from "@mui/system";

import ApiService from "../../../services/ApiService";
import SelectChartOfAccount from './Selects/SelectChartOfAccount';
import CreateEditModalMaster from '../CreateEditModalMaster';
import { Icon, Input, Selection, showToast, TickBox } from '../../../components/ui';
import SelectBank from './Selects/SelectBank';
import { handleDataTable } from '../../../utils/reusableFunction';

const CreateEditChartOfAccountBranch = ({
    isModal,
    handleClick,
    titleModal,
    title,
    legacyCode,
    setLegacyCode,
    accountCode,
    setAccountCode,
    accountTitle,
    setAccountTitle,
    accountGroup,
    setAccountGroup,
    accountLevel,
    setAccountLevel,
    accountParent,
    setAccountParent,
    accountParentTitle,
    handleReset,
    setAccountParentTitle,
    bank,
    setBank,
    parentId,
    coaStandardId,
    coaId,
    bankId,
    setBankId,
    bankCode,
    setBankCode,
    bankName,
    setBankName,
    setCOAStandardId,
    coaStandardTitle,
    setCOAStandardTitle,
    setParentId,
    setIsLoadingBackDrop,
    param
}) => {
    const [isModalAccount, setIsModalAccount] = useState(false);
    const [isStandard, setIsStandard] = useState(false);
    const [accountGroupList, setAccountGroupList] = useState([]);
    const [accountLevelList, setAccountLevelList] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [dataMap, setDataMap] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [columnData, setColumnData] = useState([]);
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50);

    const [isModalBank, setIsModalBank] = useState(false);
    const [isLoadingB, setIsLoadingB] = useState(false);
    const [dataB, setDataB] = useState([]);
    const [dataMapB, setDataMapB] = useState([]);
    const [dataTableB, setDataTableB] = useState([]);
    const [columnDataB, setColumnDataB] = useState([]);
    const [SelectedDataB, setSelectedDataB] = useState({});
    const [totalRowsB, setTotalRowsB] = useState(50);

    const paramSelect = "chartOfAccount/chartOfAccount";

    useEffect(() => {
        fetchAccountGroupList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchAccountGroupList = async (rowsCount = 50, NumPage = 1) => {
        try {
            ApiService.PostByPage('chartOfAccountGroup/chartOfAccountGroup', NumPage, rowsCount, [])
                .then(res => {
                    const data = res.data.data.chartOfAccountGroup;
                    if (res.data.code === 200) {
                        setAccountGroupList(data)

                        fetchAccountLevelList(50, 1)
                    }
                })
        }
        catch (err) {
            console.error(err);
            alert(err)
        }
    }

    const fetchAccountLevelList = async (rowsCount = 50, NumPage = 1) => {
        try {
            ApiService.PostByPage('chartOfAccountLevel/chartOfAccountLevel', NumPage, rowsCount, [])
                .then(res => {
                    const data = res.data.data.chartOfAccountLevel
                    if (res.data.code === 200) {
                        setAccountLevelList(data);
                    }
                })
        }
        catch (err) {
            console.error(err);
        }
    }

    const handleClickAccount = (data) => {
        if (isModalAccount === false) {
            setIsModalAccount(true);
            if (data === 'parent') {
                fetchChartOfAccounts();
                setIsStandard(false);
            }
            else {
                fetchStandardCOA();
                setIsStandard(true);
            }
        }
        else {
            setIsModalAccount(false);
        }
    }

    const handleClickBank = () => {
        if (isModalBank === false) {
            setIsModalBank(true);
            fetchBank();
        }
        else {
            setIsModalBank(false);
        }
    }

    const handleLevel = (id) => {
        setAccountLevel(id);
        setAccountParent('');
        setAccountParentTitle('');
        setCOAStandardId(0);
        setCOAStandardTitle('');
        setBank(false);
        setBankId(0);
        setBankCode('');
        setBankName('');
    }

    const handleValidation = () => {
        //validation
        let message = ''
        let status = true
        if (!accountGroup) {
            message += 'Account Group, '
            status = false;
        }
        if (!accountLevel) {
            message += 'Account Level, '
            status = false;
        }
        if (!accountParent) {
            message += 'Parent Account Code, '
            status = false;
        }
        if (!accountTitle) {
            message += 'Account Title, '
            status = false;
        }
        message = message.slice(0, -2);

        if (!status) {
            showToast({ type: 'error', message: message + " Can't Be Empty!" })
            return false;
        }
        return true;
    }

    const handleSubmit = () => {
        if (!handleValidation()) {
            return false;
        }

        let accountType = "BANK";
        if (!bank && accountLevel >= 0 && accountLevel <= 5) {
            accountType = "GENERAL";
        }
        else if (!bank && accountLevel === 6) {
            accountType = "DETAILS";
        }

        let payload = {
            "chartOfAccountBranch": {
                "id": coaId,
                "parentId": parentId,
                "accountParent": accountParent,
                "accountTitle": accountTitle,
                "accountGroup": accountGroup,
                "accountLevel": accountLevel,
                "accountType": accountType,
                "legacyCode": legacyCode,
                "coaStandardId": accountLevel >= 5 ? coaStandardId : 0,
                "bankId": bankId,
            }
        }

        setIsLoadingBackDrop(true);
        if (coaId) {
            ApiService.updateData(param, payload)
                .then(response => {
                    if (response.data.code === 200) {
                        showToast({ type: 'success', message: 'Update Data Chart of Account Success' })
                        handleClick();
                        handleReset();
                        setIsLoadingBackDrop(false);
                    }
                    else {
                        showToast({ type: 'error', message: 'Update Data Failed, Please Contact The Administrator, ' + response.data.message })
                        setIsLoadingBackDrop(false);
                    }
                })
                .catch(error => {
                    showToast({ type: 'error', message: 'Update Data Failed, Please Contact The Administrator, ' + error })
                    setIsLoadingBackDrop(false);
                    console.error(error)
                })
        }
        else {
            ApiService.createData(param, payload)
                .then(response => {
                    if (response.data.code === 200) {
                        showToast({ type: 'success', message: 'Create Data Chart of Account Success' })
                        handleClick();
                        handleReset();
                        setIsLoadingBackDrop(false);
                    }
                    else {
                        showToast({ type: 'error', message: 'Create Data Failed, Please Contact The Administrator, ' + response.data.message })
                        setIsLoadingBackDrop(false);
                    }
                })
                .catch(error => {
                    showToast({ type: 'error', message: 'Create Data Failed, Please Contact The Administrator, ' + error })
                    setIsLoadingBackDrop(false);
                    console.error(error)
                })
        }
    }

    const fetchChartOfAccounts = (NumPage = 1, rowsCount = 50, filters) => {
        setIsLoading(true);
        let accLvl = 0;

        if (accountLevel > 0) {
            accLvl = accountLevel - 1;
        }

        let params = [
            {
                'attr': 'accountLevel',
                'value': '' + accLvl
            }
        ]
        ApiService.DynamicPostByPage(param, 'SEARCH', NumPage, rowsCount, params, filters)
            .then(async res => {
                setSelectedData({});
                let temp = res.data.data.chartOfAccountBranch;
                setData(temp)
                let indexed = temp.map((el, index) => {
                    let indexedTemp = {
                        ...el,
                        index
                    }
                    return indexedTemp
                });
                setDataMap(indexed);
                handleDataTable(indexed, res.data.data.columns, setDataTable, 'id');
                setColumnData(res.data.data.columns);
                setTotalRows(res.data.totalRowCount);
                setIsLoading(false)
            })
            .catch(error => {
                setIsLoading(false)
                console.error(error);
            })
    }

    const fetchStandardCOA = (NumPage = 1, rowsCount = 50, filters) => {
        setIsLoading(true);

        let params = [
            {
                'attr': 'accountLevel',
                'value': '' + accountLevel
            }
        ]
        ApiService.DynamicPostByPage(paramSelect, 'SEARCH', NumPage, rowsCount, params, filters)
            .then(async res => {
                let temp = res.data.data.chartOfAccount;
                setSelectedData({});
                setData(temp)
                let indexed = temp.map((el, index) => {
                    let indexedTemp = {
                        ...el,
                        index
                    }
                    return indexedTemp
                });
                setDataMap(indexed);
                setColumnData(res.data.data.columns);
                setTotalRows(res.data.totalRowCount);
                setIsLoading(false)
            })
            .catch(error => {
                setIsLoading(false)
                console.error(error);
            })
    }

    const fetchBank = (NumPage = 1, rowsCount = 50, filters) => {
        setIsLoadingB(true);
        let params = [{ attr: "rowStatus", value: "ACT" }];
        ApiService.DynamicPostByPage('bank/bank', 'PAGE', NumPage, rowsCount, params, filters)
            .then(async res => {
                if (res.data.code === 200) {
                    let temp = res.data.data.bank;
                    setSelectedDataB({});
                    setDataB(temp)
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    });
                    setDataMapB(indexed);
                    handleDataTable(indexed, res.data.data.columns, setDataTableB, 'id');
                    setColumnDataB(res.data.data.columns);
                    setTotalRowsB(res.data.totalRowCount);
                }
                setIsLoadingB(false)
            })
            .catch(error => {
                setIsLoadingB(false)
                console.error(error);
            })
    }

    function handleChange(name, value) {
        if (name === 'bank') {
            setBank(!value);
            setBankId(0);
            setBankCode('');
            setBankName('');
        }
    }

    const mainContainer = (
        <>
            <Stack direction={'row'} spacing={'16px'}>
                <Selection
                    fullWidth={true}
                    value={accountGroup}
                    label="Account Group"
                    placeholder={'Select Account Group'}
                    formControlProps={{
                        sx: {
                            width: '100%',
                            // marginTop: '16px'
                        }
                    }}
                    onChange={(e) => setAccountGroup(e.target.value)}
                >
                    {
                        accountGroupList.map((el, index) => {
                            return (
                                <MenuItem sx={{ fontSize: '12px' }} id={el.id} key={index} value={el.code}>
                                    {el.name}
                                </MenuItem>
                            )
                        })
                    }
                </Selection>

                <Selection
                    fullWidth={true}
                    value={accountLevel}
                    label="Account Level"
                    placeholder={'Select Account Level'}
                    formControlProps={{
                        sx: {
                            width: '100%',
                            // marginTop: '16px'
                        }
                    }}
                    onChange={(e) => handleLevel(e.target.value)}
                >
                    {
                        accountLevelList.map((el, index) => {
                            return (
                                <MenuItem sx={{ fontSize: '12px' }} id={el.id} key={index} value={el.level}>
                                    {el.name}
                                </MenuItem>
                            )
                        })
                    }
                </Selection>
            </Stack>

            <Input
                label="Parent Account Code"
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                value={accountParentTitle}
                onChange={(e) => setAccountParent(e.target.value)}
                disabled
                hidden={accountLevel > 0 ? false : true}
                inputBaseProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => { handleClickAccount('parent') }}
                                hidden={coaId !== 0 && coaId !== undefined ? true : accountLevel > 0 ? false : true}
                            >
                                <Icon
                                    iconName={'search'}
                                    svgIconProps={{
                                        fontSize: 'small',
                                        htmlColor: '#0087C2',
                                        sx: {
                                            height: '12px',
                                            width: '12px'
                                        }
                                    }}
                                />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />

            <Input
                label="COA Standard Code"
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px'
                    }
                }}
                value={coaStandardTitle}
                onChange={(e) => setAccountParent(e.target.value)}
                hidden={accountLevel >= 5 ? false : true}
                disabled
                inputBaseProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                onClick={() => { handleClickAccount('standard') }}
                                hidden={accountLevel > 0 ? false : true}
                            >
                                <Icon
                                    iconName={'search'}
                                    svgIconProps={{
                                        fontSize: 'small',
                                        htmlColor: '#0087C2',
                                        sx: {
                                            height: '12px',
                                            width: '12px'
                                        }
                                    }}
                                />
                            </IconButton>
                        </InputAdornment>
                    )
                }}
            />

            <Stack direction={'row'} spacing={coaId === 0 ? '0px' : '16px'}>
                <Input
                    label="Account Code"
                    formControlProps={{
                        sx: {
                            // width: '100%',
                            marginTop: '16px !important'
                        }
                    }}
                    disabled
                    value={accountCode}
                    hidden={coaId === 0 ? true : false}
                    onChange={(e) => setAccountCode(e.target.value)}
                />

                <Input
                    label="Account Title"
                    formControlProps={{
                        sx: {
                            width: '100%',
                            marginTop: '16px !important'
                        }
                    }}
                    value={accountTitle}
                    onChange={(e) => setAccountTitle(e.target.value)}
                />
            </Stack>

            <Input
                label="Legacy Code"
                formControlProps={{
                    sx: {
                        width: '100%',
                        marginTop: '16px !important'
                    }
                }}
                value={legacyCode}
                hidden={accountLevel >= 5 ? false : true}
                onChange={(e) => setLegacyCode(e.target.value)}
            />

            <Grid container spacing={'16px'} sx={{ justifyContent: 'center', alignItems: 'center' }} hidden={accountLevel >= 5 ? false : true}>
                <Grid
                    item
                    xl={3}
                    lg={3}
                    sm={3}
                    xs={12}
                >
                    <div style={{ marginTop: '16px' }}>
                        <TickBox
                            baseCheckboxProps={{
                                sx: {
                                    marginTop: '24px !important'
                                }
                            }}
                            size={'medium'}
                            label={
                                <Typography
                                    sx={{
                                        color: 'var(--Blue-Primary-1, #083A50)',
                                        fontFamily: 'Nunito',
                                        fontSize: '14px !important',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: '20px',
                                        marginTop: '24px !important'
                                    }}
                                >
                                    Bank
                                </Typography>
                            }
                            checked={bank}
                            onChange={() => handleChange('bank', bank)}
                        />
                    </div>
                </Grid>

                <Grid
                    item
                    xl={9}
                    lg={9}
                    sm={9}
                    xs={12}
                >

                    <div hidden={!bank}>
                        <Stack direction={'row'} spacing={'16px'}>
                            <Input
                                label="Bank Code"
                                formControlProps={{
                                    sx: {
                                        // width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                                disabled
                                value={bankCode}
                            />

                            <Input
                                label="Bank Name"
                                formControlProps={{
                                    sx: {
                                        width: '100%',
                                        marginTop: '16px !important'
                                    }
                                }}
                                disabled
                                value={bankName}
                                inputBaseProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => { handleClickBank() }}
                                                hidden={accountLevel > 0 ? false : true}
                                            >
                                                <Icon
                                                    iconName={'search'}
                                                    svgIconProps={{
                                                        fontSize: 'small',
                                                        htmlColor: '#0087C2',
                                                        sx: {
                                                            height: '12px',
                                                            width: '12px'
                                                        }
                                                    }}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Stack>
                    </div>
                </Grid>
            </Grid>
        </>
    )

    return (
        <>
            {
                isModal && (
                    <CreateEditModalMaster
                        isModal={isModal}
                        titleModal={titleModal}
                        title={title}
                        mainContainer={mainContainer}
                        handleClick={handleClick}
                        handleSave={handleSubmit}
                    />
                )
            }

            {
                isModalAccount && (
                    <SelectChartOfAccount
                        isModal={isModalAccount}
                        setIsModal={setIsModalAccount}
                        accountLevel={accountLevel}
                        setAccountParent={setAccountParent}
                        setParentId={setParentId}
                        setAccountParentTitle={setAccountParentTitle}
                        setCOAStandardId={setCOAStandardId}
                        setCOAStandardTitle={setCOAStandardTitle}
                        handleClick={handleClickAccount}
                        isStandard={isStandard}

                        isLoading={isLoading}
                        data={data}
                        dataMap={dataMap}
                        dataTable={dataTable}
                        columnData={columnData}
                        SelectedData={SelectedData}
                        totalRows={totalRows}
                        setSelectedData={setSelectedData}
                        getData={isStandard ? fetchStandardCOA : fetchChartOfAccounts}
                    />
                )
            }

            {
                isModalBank && (
                    <SelectBank
                        isModal={isModalBank}
                        setIsModal={setIsModalBank}
                        handleClick={handleClickBank}

                        isLoading={isLoadingB}
                        data={dataB}
                        dataMap={dataMapB}
                        dataTable={dataTableB}
                        columnData={columnDataB}
                        SelectedData={SelectedDataB}
                        totalRows={totalRowsB}
                        setTotalRows={setTotalRowsB}
                        setSelectedData={setSelectedDataB}
                        getData={fetchBank}

                        setBankId={setBankId}
                        setBankCode={setBankCode}
                        setBankName={setBankName}
                    />
                )
            }
        </>
    )
}

export default CreateEditChartOfAccountBranch;