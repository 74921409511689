import React, { useState, useEffect } from 'react';
import "jspdf-autotable";
import ApiService, { username } from '../../../services/ApiService';
import CreateEditRegCOAEntry from './CreateEditRegCOAEntry';
import TempListMaster from '../TempListMaster';
import { forwardRef } from 'react';
import { Backdrop } from '@mui/material';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import CustomToastContainer, { showToast } from '../../../components/ui';
import { handleDataTable } from '../../../utils/reusableFunction';

const ListRegCOAEntry = forwardRef((props, ref) => {
  const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
  const title = 'Reg Chart of Account Entry'
  const [data, setData] = useState([]);
  const [titleModal, setTitleModal] = useState('');
  const [isModal, setIsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [dataMap, setDataMap] = useState([])
  const [dataTable, setDataTable] = useState([])
  const [columnData, setColumnData] = useState([])
  const [selectedData, setSelectedData] = useState({});
  const [totalRows, setTotalRows] = useState(50)
  const [rowsCount, setRowsCount] = useState(50)
  const [numPage, setNumPage] = useState(1)
  const [filter, setFilter] = useState([])
  const [filterBy, setFilterBy] = useState([])
  const [stateX, setStateX] = useState({});
  const [sortArr, setSortArr] = useState([])

  const param = 'regChartOfAccountEntry/regChartOfAccountEntry';

  const dataLocal = JSON.parse(localStorage.getItem(username + "-DataRCOAE"));

  let countRefresh = 0;

  const dropDownList = [
    { label: 'Official Receipt', icon: '', value: 'OR' },
    { label: 'Payment Voucher', icon: '', value: 'PV' },
    { label: 'Receipt Voucher', icon: '', value: 'RV' },
    { label: 'Temporary Payment', icon: '', value: 'TP' },
    { label: 'Temporary Receipt', icon: '', value: 'TR' },
    { label: 'Down Payment', icon: '', value: 'DP' }
  ]

  const fetchDataLocal = () => {
    const result = dataLocal.vessel;
    setTitleModal(result.titleModal);
    setSelectedData(result)

    setIsModal(true);
    localStorage.removeItem(username + '-DataRCOAE')
  }

  const toggleAdd = () => {
    if (selectedData?.id) {
      setSelectedData({})
    }
    handleClick();
    setTitleModal('Add');
  }

  const toggleEdit = () => {
    if (!selectedData?.id) {
      showToast({ type: 'error', message: 'Please Select Data!' })
    } else {
      setIsModal(true);
      setTitleModal('Edit');
    }
  }

  useEffect(() => {
    if (countRefresh === 0) {
      localStorage.setItem('filterNameRCOAE', JSON.stringify('Official Receipt'));
      localStorage.setItem('filterRCOAE', JSON.stringify('OR'));
      ApiService.refreshToken();
      countRefresh++;
      if (dataLocal) {
        setTimeout(function () { fetchDataLocal(); }, 2000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setFilterData = (value) => {
    localStorage.setItem('filterRCOAE', JSON.stringify(value));
    if (value === 'OR') {
      localStorage.setItem('filterNameRCOAE', JSON.stringify('Official Receipt'));
    }
    else if (value === 'PV') {
      localStorage.setItem('filterNameRCOAE', JSON.stringify('Payment Voucher'));
    }
    else if (value === 'RV') {
      localStorage.setItem('filterNameRCOAE', JSON.stringify('Receipt Voucher'));
    }
    else if (value === 'TP') {
      localStorage.setItem('filterNameRCOAE', JSON.stringify('Temporary Payment'));
    }
    else if (value === 'TR') {
      localStorage.setItem('filterNameRCOAE', JSON.stringify('Temporary Receipt'));
    }
    else if (value === 'DP') {
      localStorage.setItem('filterNameRCOAE', JSON.stringify('Down Payment'));
    }

    getData(1, 50, []);
  }

  const getData = (pageNumber, pageSize, filters, orderBy) => {
    if (isLoadingBackDrop === false) {
      setIsLoading(true);
    } else {
      setIsLoadingBackDrop(true);
    }
    let filterData = JSON.parse(localStorage.getItem('filterRCOAE'))
    let params = [
      {
        'attr': 'entryFor',
        'value': '' + filterData,
      }
    ]
    ApiService.DynamicPostByPage(param, 'PAGE', pageNumber, pageSize, params, filters)
      .then((response) => {
        if (response.data.code === 200) {
          setSelectedData({})
          setData(response.data.data.chartOfAccountEntry);

          let temp = response.data.data.chartOfAccountEntry
          let indexed = temp.map((el, index) => {
            let indexedTemp = {
              ...el,
              index
            }
            return indexedTemp
          })

          setDataMap(indexed)
          handleDataTable(indexed, response.data.data.columns, setDataTable, 'id');
          setColumnData(response.data.data.columns)
          setTotalRows(response.data.totalRowCount)

          setIsLoading(false)
          setIsLoadingBackDrop(false)
        }
        else if (response.data.code === 500) {
          showToast({ type: 'error', message: 'Request Timeout, Please Refresh Page!, ' + response.data.message })
          setIsLoadingBackDrop(false)
          setIsLoading(false)
        }
        setIsLoading(false)
      })
      .catch(function (error) {
        setIsLoading(false)
        setIsLoadingBackDrop(false)
        console.error('error saat fetch', error)
        showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error })
      })
  }

  const handleClick = () => {
    if (isModal === false) {
      setIsModal(true);
    } else {
      setIsModal(false);
      setNumPage(1)
      setRowsCount(50)
    }
  };

  function CellDoubleClick(row) {
    toggleEdit()
  }

  return (
    <>
      <CustomToastContainer />
      <TempListMaster
        dataTable={dataTable}
        SelectedData={selectedData}
        param={param}
        data={data}
        title={title}
        toggleAdd={toggleAdd}
        toggleEdit={toggleEdit}
        isLoading={isLoading}
        getData={getData}
        columnData={columnData}
        dataMap={dataMap}
        setSelectedData={setSelectedData}
        CellDoubleClick={CellDoubleClick}
        totalRows={totalRows}
        rowsCount={rowsCount}
        setRowsCount={setRowsCount}
        numPage={numPage}
        setNumPage={setNumPage}
        filter={filter}
        setFilter={setFilter}
        stateX={stateX}
        setStateX={setStateX}
        filterBy={filterBy}
        setFilterBy={setFilterBy}
        menuCode={'MRCOAE'}
        menuName={'MASTER'}
        sortArr={sortArr}
        setSortArr={setSortArr}
        dropDownList={dropDownList}
        handleStatus={setFilterData}
      />

      {
        isModal && (
          <CreateEditRegCOAEntry
            ref={ref}
            isModal={isModal}
            handleClick={handleClick}
            titleModal={titleModal}
            title={title}
            param={param}

            selectedData={selectedData}

            getData={getData}
          />
        )
      }

      {
        isLoadingBackDrop && (
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={isLoadingBackDrop}
          >
            <LoadingSpinner />
          </Backdrop>
        )
      }
    </>
  )
})

export default ListRegCOAEntry