import React, { useState, useEffect, forwardRef } from 'react';
import "jspdf-autotable";
import ApiService, { userCode, username } from '../../../services/ApiService';
import TempListMaster from '../TempListMaster';
import CreateEditBranch from './CreateEditBranch';
import { useImperativeHandle } from 'react';
import { Backdrop } from '@mui/material';
import LoadingSpinner from '../../../components/Components/LoadingSpinner';
import { defaultDate } from '../../../utils/date';
import CustomToastContainer, { showToast } from '../../../components/ui';
import { handleDataTable } from '../../../utils/reusableFunction';

const ListBranch = forwardRef((props, ref) => {
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const [data, setData] = useState([]);
    const [titleModal, setTitleModal] = useState('');
    const title = 'Branch'
    const [isModal, setIsModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dataMap, setDataMap] = useState([])
    const [dataTable, setDataTable] = useState([])
    const [columnData, setColumnData] = useState([])
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50)
    const [rowsCount, setRowsCount] = useState(50)
    const [numPage, setNumPage] = useState(1)
    const [filter, setFilter] = useState([])
    const [filterBy, setFilterBy] = useState([])

    const param = "branch/branch";

    const [id, setId] = useState(0);
    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [abbreviation, setAbbreviation] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [fax, setFax] = useState('');
    const [email, setEmail] = useState('');
    const [npwp, setNpwp] = useState('');
    const [nppkp, setNppkp] = useState('');
    const [approvalWP, setApprovalWP] = useState('');
    const [namaWP, setNamaWP] = useState('');
    const [alamatWP, setAlamatWP] = useState('');
    const [masterCode, setMasterCode] = useState('');
    const [timeZone, setTimeZone] = useState(0);
    const [migrasiEmkl, setMigrasiEmkl] = useState(defaultDate);
    const [migrasiForwarding, setMigrasiForwarding] = useState(defaultDate);
    const [nameHeader, setNameHeader] = useState('');
    const [isVat, setIsVat] = useState(true);
    const [token, setToken] = useState('');
    const [migrasiRE, setMigrasiRE] = useState(defaultDate);
    const [fullName, setFullName] = useState('');
    const [groupId, setGroupId] = useState(0);
    const [emailCS, setEmailCS] = useState('');
    const [kantorPajakPVPPh23, setKantorPajakPVPPh23] = useState('');
    const [useBookingConfirmation, setUseBookingConfirmation] = useState(true);
    const [useBookingConfirmationDate, setUseBookingConfirmationDate] = useState(defaultDate);
    const [refCompanyIdPusat, setRefCompanyIdPusat] = useState(0);
    const [pejkpNamaWP, setPejkpNamaWP] = useState('');
    const [pejkpJabatanWP, setPejkpJabatanWP] = useState('');
    const [migrasiGreatherThan, setMigrasiGreatherThan] = useState(defaultDate);

    const [cityId, setCityId] = useState(0)
    const [companyId, setCompanyId] = useState(0)
    const [zipCode, setZipCode] = useState('')

    const [cityCode, setCityCode] = useState('')
    const [cityName, setCityName] = useState('')
    const [countryName, setCountryName] = useState('')
    const [continentName, setContinentName] = useState('')
    const [companyName, setCompanyName] = useState('')
    const [initialCompany, setInitialCompany] = useState('')
    const [stateX, setStateX] = useState({});
    const dataLocal = JSON.parse(localStorage.getItem(username + "-DataBranch"));
    const [sortArr, setSortArr] = useState([])

    const simpanLocal = () => {
        if (isModal) {
            var data;
            data =
            {
                "branch": {
                    "rowStatus": "ACT",
                    "id": id,
                    "code": code,
                    "name": name,
                    "initialCompany": initialCompany,
                    "abbreviation": abbreviation,
                    "address": address,
                    "zipCode": zipCode,
                    "cityId": cityId,
                    "companyId": companyId,
                    "phone": phone,
                    "fax": fax,
                    "email": email,
                    "npwp": npwp,
                    "nppkp": nppkp,
                    "approvalWP": approvalWP,
                    "namaWP": namaWP,
                    "alamatWP": alamatWP,
                    "masterCode": masterCode,
                    "timeZone": timeZone,
                    "migrasiEmkl": migrasiEmkl,
                    "migrasiForwarding": migrasiForwarding,
                    "nameHeader": nameHeader,
                    "isVat": isVat,
                    "token": token,
                    "migrasiRE": migrasiRE,
                    "fullName": fullName,
                    "groupId": 0,
                    "emailCS": emailCS,
                    "kantorPajakPVPPh23": kantorPajakPVPPh23,
                    "useBookingConfirmation": useBookingConfirmation,
                    "useBookingConfirmationDate": useBookingConfirmationDate,
                    "refCompanyIdPusat": 0,
                    "pejkpNamaWP": pejkpNamaWP,
                    "pejkpJabatanWP": pejkpJabatanWP,
                    "migrasiGreatherThan": migrasiGreatherThan,
                    "deleted": false,
                    "deletedOn": defaultDate,
                    "userCode": userCode,
                    "titleModal": titleModal
                }
            }
            localStorage.setItem(username + "-DataBranch", JSON.stringify(data));

        }
    }

    const fetchDataLocal = () => {
        setIsModal(true)
        var result = dataLocal.branch;
        setCode(result.code);
        setName(result.name);
        setAbbreviation(result.abbreviation);
        setAddress(result.address);
        setPhone(result.phone);
        setFax(result.fax);
        setEmail(result.email);
        setNpwp(result.npwp);
        setNppkp(result.nppkp);
        setApprovalWP(result.approvalWP);
        setNamaWP(result.namaWP);
        setAlamatWP(result.alamatWP);
        setMasterCode(result.masterCode);
        setTimeZone(result.timeZone);
        let date = new Date(result.migrasiEmkl)
        var tgl = date.toLocaleDateString("sv-se").split('T')[0]
        setMigrasiEmkl(tgl);
        date = new Date(result.migrasiForwarding)
        tgl = date.toLocaleDateString("sv-se").split('T')[0]
        setMigrasiForwarding(tgl);
        setNameHeader(result.nameHeader);
        setIsVat(result.isVat);
        setToken(result.token);
        date = new Date(result.migrasiRE)
        tgl = date.toLocaleDateString("sv-se").split('T')[0]
        setMigrasiRE(tgl);
        setFullName(result.fullName);
        setGroupId(result.groupId);
        setEmailCS(result.emailCS);
        setKantorPajakPVPPh23(result.kantorPajakPVPPh23);
        setUseBookingConfirmation(result.useBookingConfirmation);
        date = new Date(result.useBookingConfirmationDate)
        tgl = date.toLocaleDateString("sv-se").split('T')[0]
        setUseBookingConfirmationDate(tgl);
        setRefCompanyIdPusat(result.refCompanyIdPusat);
        setPejkpNamaWP(result.pejkpNamaWP);
        setPejkpJabatanWP(result.pejkpJabatanWP);
        date = new Date(result.migrasiGreatherThan)
        tgl = date.toLocaleDateString("sv-se").split('T')[0]
        setMigrasiGreatherThan(tgl);

        setZipCode(result.zipCode)
        setInitialCompany(result.initialCompany)
        setId(result.id);
        getCityById(result.cityId)
        getCompanyById(result.companyId)
        setTitleModal(result.titleModal);
        localStorage.removeItem(username + '-DataBranch')
    }

    useImperativeHandle(ref, () => ({

        localSave() {
            simpanLocal()
        }

    }));
    const toggleAdd = () => {
        emptyState()
        handleClick();
        setTitleModal('Add');
    }

    const emptyState = () => {
        setId(0);
        setCode('');
        setName('');
        setAbbreviation('');
        setAddress('');
        setPhone('');
        setFax('');
        setEmail('');
        setNpwp('');
        setNppkp('');
        setApprovalWP('');
        setNamaWP('');
        setAlamatWP('');
        setMasterCode('');
        setTimeZone(0);
        setMigrasiEmkl(defaultDate);
        setMigrasiForwarding(defaultDate);
        setNameHeader('');
        setIsVat(true);
        setToken('');
        setMigrasiRE(defaultDate);
        setFullName('');
        setEmailCS('');
        setKantorPajakPVPPh23('');
        setUseBookingConfirmation(true);
        setUseBookingConfirmationDate(defaultDate);
        setRefCompanyIdPusat(0);
        setPejkpNamaWP('');
        setPejkpJabatanWP('');
        setMigrasiGreatherThan(defaultDate);

        setCityId(0)
        setCompanyId(0)
        setZipCode('')
        setCityCode('')
        setCityName('')
        setCountryName('')
        setContinentName('')
        setCompanyName('')
        setInitialCompany('')

    }
    const toggleEdit = () => {
        if (SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' });
        } else {
            ApiService.PostById(param, SelectedData.id).then((res) => {
                if (res !== null) {
                    // getDataCoa()
                    var result = res.data.data.branch;
                    setCode(result.code);
                    setName(result.name);
                    setAbbreviation(result.abbreviation);
                    setAddress(result.address);
                    setPhone(result.phone);
                    setFax(result.fax);
                    setEmail(result.email);
                    setNpwp(result.npwp);
                    setNppkp(result.nppkp);
                    setApprovalWP(result.approvalWP);
                    setNamaWP(result.namaWP);
                    setAlamatWP(result.alamatWP);
                    setMasterCode(result.masterCode);
                    setTimeZone(result.timeZone);
                    let date = new Date(result.migrasiEmkl)
                    var tgl = date.toLocaleDateString("sv-se").split('T')[0]
                    setMigrasiEmkl(tgl);
                    date = new Date(result.migrasiForwarding)
                    tgl = date.toLocaleDateString("sv-se").split('T')[0]
                    setMigrasiForwarding(tgl);
                    setNameHeader(result.nameHeader);
                    setIsVat(result.isVat);
                    setToken(result.token);
                    date = new Date(result.migrasiRE)
                    tgl = date.toLocaleDateString("sv-se").split('T')[0]
                    setMigrasiRE(tgl);
                    setFullName(result.fullName);
                    setGroupId(result.groupId);
                    setEmailCS(result.emailCS);
                    setKantorPajakPVPPh23(result.kantorPajakPVPPh23);
                    setUseBookingConfirmation(result.useBookingConfirmation);
                    date = new Date(result.useBookingConfirmationDate)
                    tgl = date.toLocaleDateString("sv-se").split('T')[0]
                    setUseBookingConfirmationDate(tgl);
                    setRefCompanyIdPusat(result.refCompanyIdPusat);
                    setPejkpNamaWP(result.pejkpNamaWP);
                    setPejkpJabatanWP(result.pejkpJabatanWP);
                    date = new Date(result.migrasiGreatherThan)
                    tgl = date.toLocaleDateString("sv-se").split('T')[0]
                    setMigrasiGreatherThan(tgl);

                    setZipCode(result.zipCode)
                    setInitialCompany(result.initialCompany)
                }
                setId(SelectedData.id);
                getCityById(SelectedData.cityId)
                getCompanyById(SelectedData.companyId)
                setIsModal(true);
                setTitleModal('Edit');
            }).catch(function (error) {
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
        }
    }

    let countRefresh = 0;

    useEffect(() => {
        if (countRefresh === 0) {
            ApiService.refreshToken();
            countRefresh++;
            if (dataLocal) {
                setTimeout(function () { fetchDataLocal(); }, 2000);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = (pageNumber, pageSize, filters, orderBy) => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        } else {
            setIsLoadingBackDrop(true);
        }
        ApiService.DynamicPostByPage(param, 'PAGE', pageNumber, pageSize, [], filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({})
                    setData(response.data.data.branch);

                    let temp = response.data.data.branch
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMap(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTable, 'id');
                    setColumnData(response.data.data.columns)
                    setTotalRows(response.data.totalRowCount)

                    setIsLoading(false)
                    setIsLoadingBackDrop(false)
                } else {
                    setIsLoadingBackDrop(false)
                    showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + response.data.message });
                }
                setIsLoading(false)
            })
            .catch(function (error) {
                setIsLoading(false)
                setIsLoadingBackDrop(false)
                console.error('error saat fetch', error)
                showToast({ type: 'error', message: 'Error When Fetch Data, Please Contact The Administrator, ' + error });
            })
    }

    const getCityById = (id) => {
        ApiService.PostById('city/city', id).then((res) => {
            if (res.data.code === 200) {
                var result = res.data.data.city
                setCityCode(result.code)
                setCityName(result.name)
                setCountryName(result.countryName)
                setContinentName(result.continentName)
            }
        })
            .catch(function (error) {
                console.error('error saat fetch', error)
            })
    }

    const getCompanyById = (id) => {
        ApiService.PostById('company/company', id).then((res) => {
            if (res.data.code === 200) {
                var result = res.data.data.city
                setCompanyName(result.name)
            }
        })
            .catch(function (error) {
                console.error('error saat fetch', error)
            })
    }

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
        } else {
            setIsModal(false);
            setNumPage(1)
            setRowsCount(50)
        }
    };

    function CellDoubleClick(row) {
        toggleEdit()
    }



    return (
        <>
            <CustomToastContainer />
            <TempListMaster
                dataTable={dataTable}
                SelectedData={SelectedData}
                param={param}
                data={data}
                title={title}
                toggleAdd={toggleAdd}
                toggleEdit={toggleEdit}
                isLoading={isLoading}
                getData={getData}
                columnData={columnData}
                dataMap={dataMap}
                setSelectedData={setSelectedData}
                CellDoubleClick={CellDoubleClick}
                totalRows={totalRows}
                rowsCount={rowsCount}
                setRowsCount={setRowsCount}
                numPage={numPage}
                setNumPage={setNumPage}
                filter={filter}
                setFilter={setFilter}
                filterBy={filterBy}
                setFilterBy={setFilterBy}
                stateX={stateX}
                setStateX={setStateX}
                menuCode={'MBRA'}
                menuName={'MASTER'}
                sortArr={sortArr}
                setSortArr={setSortArr}
            />

            {
                isModal && (
                    <CreateEditBranch
                        isModal={isModal}
                        handleClick={handleClick}
                        titleModal={titleModal}
                        title={title}
                        param={param}
                        getData={getData}

                        id={id}
                        code={code}
                        setCode={setCode}
                        name={name}
                        setName={setName}
                        abbreviation={abbreviation}
                        setAbbreviation={setAbbreviation}
                        address={address}
                        setAddress={setAddress}
                        phone={phone}
                        setPhone={setPhone}
                        fax={fax}
                        setFax={setFax}
                        email={email}
                        setEmail={setEmail}
                        npwp={npwp}
                        setNpwp={setNpwp}
                        nppkp={nppkp}
                        setNppkp={setNppkp}
                        approvalWP={approvalWP}
                        setApprovalWP={setApprovalWP}
                        namaWP={namaWP}
                        setNamaWP={setNamaWP}
                        alamatWP={alamatWP}
                        setAlamatWP={setAlamatWP}
                        masterCode={masterCode}
                        setMasterCode={setMasterCode}
                        timeZone={timeZone}
                        setTimeZone={setTimeZone}
                        migrasiEmkl={migrasiEmkl}
                        setMigrasiEmkl={setMigrasiEmkl}
                        migrasiForwarding={migrasiForwarding}
                        setMigrasiForwarding={setMigrasiForwarding}
                        nameHeader={nameHeader}
                        setNameHeader={setNameHeader}
                        isVat={isVat}
                        setIsVat={setIsVat}
                        token={token}
                        setToken={setToken}
                        migrasiRE={migrasiRE}
                        setMigrasiRE={setMigrasiRE}
                        fullName={fullName}
                        setFullName={setFullName}
                        groupId={groupId}
                        setGroupId={setGroupId}
                        emailCS={emailCS}
                        setEmailCS={setEmailCS}
                        kantorPajakPVPPh23={kantorPajakPVPPh23}
                        setKantorPajakPVPPh23={setKantorPajakPVPPh23}
                        useBookingConfirmation={useBookingConfirmation}
                        setUseBookingConfirmation={setUseBookingConfirmation}
                        useBookingConfirmationDate={useBookingConfirmationDate}
                        setUseBookingConfirmationDate={setUseBookingConfirmationDate}
                        refCompanyIdPusat={refCompanyIdPusat}
                        setRefCompanyIdPusat={setRefCompanyIdPusat}
                        pejkpNamaWP={pejkpNamaWP}
                        setPejkpNamaWP={setPejkpNamaWP}
                        pejkpJabatanWP={pejkpJabatanWP}
                        setPejkpJabatanWP={setPejkpJabatanWP}
                        migrasiGreatherThan={migrasiGreatherThan}
                        setMigrasiGreatherThan={setMigrasiGreatherThan}
                        zipCode={zipCode}
                        setZipCode={setZipCode}
                        cityId={cityId}
                        setCityId={setCityId}
                        companyId={companyId}
                        setCompanyId={setCompanyId}

                        cityCode={cityCode}
                        setCityCode={setCityCode}
                        cityName={cityName}
                        setCityName={setCityName}
                        countryName={countryName}
                        setCountryName={setCountryName}
                        continentName={continentName}
                        setContinentName={setContinentName}
                        companyName={companyName}
                        setCompanyName={setCompanyName}
                        initialCompany={initialCompany}
                        setInitialCompany={setInitialCompany}
                    />
                )
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
})

export default ListBranch