import React, { useState, useEffect } from 'react';
import "jspdf-autotable";
import { useNavigate } from 'react-router-dom';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';

import TempListMaster from '../../TempListMaster';
import CreateEditProduct from './CreateEditProduct';
import ApiService, { userCode, username } from '../../../../services/ApiService';
import { Backdrop } from '@mui/material';
import LoadingSpinner from '../../../../components/Components/LoadingSpinner';
import CustomToastContainer, { showToast } from '../../../../components/ui';
import { handleDataTable } from '../../../../utils/reusableFunction';

const ProductList = forwardRef((props, ref) => {
    const [isLoadingBackDrop, setIsLoadingBackDrop] = useState(true);
    const [data, setData] = useState([]);
    const [titleModal, setTitleModal] = useState('');
    const title = 'Product'
    const [isModal, setIsModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [dataMap, setDataMap] = useState([])
    const [dataTable, setDataTable] = useState([])
    const [columnData, setColumnData] = useState([])
    const [SelectedData, setSelectedData] = useState({});
    const [totalRows, setTotalRows] = useState(50)
    const history = useNavigate()
    const [rowsCount, setRowsCount] = useState(50)
    const [numPage, setNumPage] = useState(1)
    const [filter, setFilter] = useState([])
    const [filterBy, setFilterBy] = useState([])

    const param = "warehouse/product/product";
    const paramProductCategory = "warehouse/productCategory/productCategory";
    const paramUnit = "warehouse/units/units";
    const [id, setId] = useState(0);
    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [productCategoryId, setProductCategoryId] = useState('');
    const [productCategoryName, setProductCategoryName] = useState('');
    const [productCategoryCode, setProductCategoryCode] = useState('');
    const [unitId, setUnitId] = useState('');
    const [unitName, setUnitName] = useState('');
    const [pickingStrategyId, setPickingStrategyId] = useState('');
    const [pickingStrategyName, setPickingStrategyName] = useState('');
    const [listProductCategory, setListProduckCategory] = useState([]);
    const [listUnit, setListUnit] = useState([]);
    const [stateX, setStateX] = useState({});
    const [sortArr, setSortArr] = useState([])

    let countErr = 0

    const dataLocal = JSON.parse(localStorage.getItem(username + "-DataProduct"));

    const simpanLocal = () => {
        if (isModal) {
            var data;
            data =
            {
                "product": {
                    "rowStatus": "ACT",
                    "id": id,
                    "code": code,
                    "name": name,
                    "description": description,
                    "productCategoryId": productCategoryId,
                    "productCategoryName": productCategoryName,
                    productCategoryCode,
                    pickingStrategyId,
                    pickingStrategyName,
                    "unitId": unitId,
                    "unitName": unitName,
                    "userCode": userCode,
                    "titleModal": titleModal
                }
            }
            localStorage.setItem(username + "-DataProduct", JSON.stringify(data));
        }
    }

    useImperativeHandle(ref, () => ({

        localSave() {
            simpanLocal()
        }

    }));

    const fetchDataLocal = () => {
        var result = dataLocal.product;
        setIsModal(true);
        setCode(result.code);
        setName(result.name);
        setDescription(result.description);
        setProductCategoryId(result.productCategoryId);
        setProductCategoryName(result.productCategoryName);
        setProductCategoryCode(result.productCategoryCode);
        setUnitId(result.unitId);
        setUnitName(result.unitName);
        setPickingStrategyId(result.pickingStrategyId);
        setPickingStrategyName(result.pickingStrategyName);
        setId(result.id);
        setTitleModal(result.titleModal);
        localStorage.removeItem(username + '-DataProduct')
    }


    const toggleAdd = () => {
        getListProductCategory();
        getListUnit();
        setId(0);
        setCode('');
        setName('');
        setDescription('');
        setProductCategoryId('');
        setProductCategoryName('');
        setProductCategoryCode('');
        setUnitId('');
        setUnitName('');
        setPickingStrategyId('');
        setPickingStrategyName('');
        handleClick();
        setTitleModal('Add');
    }

    const toggleEdit = () => {
        if (SelectedData.id === undefined) {
            showToast({ type: 'error', message: 'Please Select Data!' })
        } else {
            getListProductCategory();
            getListUnit();
            setId(SelectedData.id);
            setCode(SelectedData.code);
            setName(SelectedData.name);
            setDescription(SelectedData.description);
            setProductCategoryId(SelectedData.productCategoryId);
            setProductCategoryName(SelectedData.productCategoryName);
            setProductCategoryCode(SelectedData.productCategoryCode);
            setPickingStrategyId(SelectedData.pickingStrategyId);
            setPickingStrategyName(SelectedData.pickingStrategyName);
            setUnitId(SelectedData.unitId);
            setIsModal(true);
            setTitleModal('Edit');
            ApiService.PostById(paramProductCategory, SelectedData.productCategoryId)
                .then((res) => {
                    setProductCategoryName(res.data.data.productCategory.name);
                })
            // ApiService.PostById(paramUnit, SelectedData.unitId)
            // .then((res) => {
            //     setUnitName(res.data.data.units.name);
            // })
        }
    }

    let countRefresh = 0;

    useEffect(() => {
        if (countRefresh === 0) {
            ApiService.refreshToken();
            countRefresh++;
            if (dataLocal) {
                setTimeout(function () { fetchDataLocal(); }, 2000);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = (pageNumber, pageSize, filters, orderBy) => {
        if (isLoadingBackDrop === false) {
            setIsLoading(true);
        } else {
            setIsLoadingBackDrop(true);
        }
        ApiService.DynamicPostByPage(param, 'PAGE', pageNumber, pageSize, [], filters, orderBy)
            .then((response) => {
                if (response.data.code === 200) {
                    setSelectedData({})
                    setData(response.data.data.product);

                    let temp = response.data.data.product
                    let indexed = temp.map((el, index) => {
                        let indexedTemp = {
                            ...el,
                            index
                        }
                        return indexedTemp
                    })

                    setDataMap(indexed)
                    handleDataTable(indexed, response.data.data.columns, setDataTable, 'id');
                    setColumnData(response.data.data.columns)
                    setTotalRows(response.data.totalRowCount)

                    setIsLoading(false)
                    setIsLoadingBackDrop(false)
                } else {
                    setIsLoading(false)
                    setIsLoadingBackDrop(false)
                }
            })
            .catch(function (error) {
                setIsLoading(false)
                setIsLoadingBackDrop(false)
                console.error('error saat fetch', error)
                countErr++
                if (countErr < 3) {
                    getData(1, 50)
                } else {
                    ApiService.logOut()
                    history('/')
                }
            })
    }

    const handleClick = () => {
        if (isModal === false) {
            setIsModal(true);
        } else {
            setIsModal(false);
            setNumPage(1)
            setRowsCount(50)
            setId(0);
            setCode('');
            setName('');
            setDescription('');
            setPickingStrategyId('');
            setPickingStrategyName('');
            setProductCategoryId('');
            setProductCategoryName('');
            setProductCategoryCode('');
            setUnitId('');
        }
    };

    function CellDoubleClick(row) {
        toggleEdit()
    }

    const getListProductCategory = () => {
        ApiService.DynamicPostByPage(paramProductCategory, 'PAGE', 1, 99999, [], [], [{
            "attr": "name",
            "method": "asc"
        }])
            .then((response) => {
                setListProduckCategory(response.data.data.productCategory);
            })
            .catch((error) => {
                console.error('error saat fetch', error)
            })
    }

    const getListUnit = () => {
        ApiService.DynamicPostByPage(paramUnit, 'PAGE', 1, 99999, [], [], [{
            "attr": "name",
            "method": "asc"
        }])
            .then((response) => {
                setListUnit(response.data.data.units);
            })
            .catch((error) => {
                console.error('error saat fetch', error)
            })
    }

    return (
        <>
            <CustomToastContainer />
            <TempListMaster
                dataTable={dataTable}
                SelectedData={SelectedData}
                param={param}
                data={data}
                title={title}
                toggleAdd={toggleAdd}
                toggleEdit={toggleEdit}
                isLoading={isLoading}
                getData={getData}
                columnData={columnData}
                dataMap={dataMap}
                setSelectedData={setSelectedData}
                CellDoubleClick={CellDoubleClick}
                totalRows={totalRows}
                rowsCount={rowsCount}
                setRowsCount={setRowsCount}
                numPage={numPage}
                setNumPage={setNumPage}
                filter={filter}
                setFilter={setFilter}
                filterBy={filterBy}
                setFilterBy={setFilterBy}
                stateX={stateX}
                setStateX={setStateX}
                menuCode={'MWHPR'}
                sortArr={sortArr}
                setSortArr={setSortArr}
            />

            {
                isModal && (
                    <CreateEditProduct
                        isModal={isModal}
                        titleModal={titleModal}
                        handleClick={handleClick}
                        title={title}
                        param={param}

                        id={id}
                        code={code}
                        setCode={setCode}
                        name={name}
                        setName={setName}
                        description={description}
                        setDescription={setDescription}
                        productCategoryId={productCategoryId}
                        setProductCategoryId={setProductCategoryId}
                        productCategoryName={productCategoryName}
                        setProductCategoryName={setProductCategoryName}
                        productCategoryCode={productCategoryCode}
                        setProductCategoryCode={setProductCategoryCode}
                        unitId={unitId}
                        setUnitId={setUnitId}
                        unitName={unitName}
                        setUnitName={setUnitName}
                        pickingStrategyId={pickingStrategyId}
                        setPickingStrategyId={setPickingStrategyId}
                        pickingStrategyName={pickingStrategyName}
                        setPickingStrategyName={setPickingStrategyName}
                        listProductCategory={listProductCategory}
                        listUnit={listUnit}

                        getData={getData}
                    />
                )
            }

            {
                isLoadingBackDrop && (
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoadingBackDrop}
                    >
                        <LoadingSpinner />
                    </Backdrop>
                )
            }
        </>
    )
})

export default ProductList